import { Col, Container, Row } from "react-bootstrap";

function GDPRPolicy() {
  return (
    <Container fluid>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <div style={{ minHeight: "100vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 20,
                flexDirection: "column",
              }}
            >
              <img
                src={require("../assets/LOGO.png")}
                style={{ width: 250, height: 100 }}
              />

              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  marginTop: 20,

                  fontWeight: "bold",
                }}
              >
                GDPR POLICY PAGE
              </h1>
            </div>

            <div style={{ padding: 20 }}>
              <dl style={{ listStyle: "decimal" }}>
                <dt
                  style={{
                    fontSize: 28,
                    fontWeight: "400",
                    fontFamily: "poppins",
                    color: "green",
                  }}
                >
                  GDPR POLICY
                </dt>
                <dd style={{ fontWeight: "bold", fontSize: 14 }}>
                  UK Data Privacy/Data Protection Law as of 25th May 2018.
                </dd>

                <p
                  style={{
                    marginTop: 20,
                    fontSize: 14,
                    fontFamily: "sans-serif",
                    fontWeight: "500",
                  }}
                >
                  The General Data Protection Regulation (or GDPR for short) is
                  a positive step towards you having more control over how your
                  data is used and how you are contacted by us.
                </p>

                <p
                  style={{
                    marginTop: 20,
                    fontFamily: "sans-serif",
                    fontSize: 14,
                    fontWeight: "500",
                  }}
                >
                  If you are an individual, the rights you have under the GDPR
                  include the following:{" "}
                </p>

                <ul
                  style={{
                    marginTop: 20,
                    marginLeft: 40,
                    listStyle: "circle",
                    fontWeight: "500",
                  }}
                >
                  <li>The right to be informed.</li>
                  <li>The right to data portability.</li>
                  <li>The right to object.</li>
                  <li>The right of access.</li>
                  <li>The right to rectification</li>
                  <li>The right to erasure.</li>
                  <li>The right to restrict processing.</li>
                  <li>
                    Rights in relation to automated decision-making and
                    profiling.
                  </li>
                  <li>
                    The changes will also help to better protect your Personal
                    Data. We have therefore updated our privacy notice to
                    reflect these changes.
                  </li>
                  <li>
                    We use your personal data to help us provide you a service,
                    which includes tailoring the information we share with you
                    to help ensure that it’s relevant and useful.
                  </li>
                  <li>
                    We will respect your privacy and work hard to ensure we meet
                    strict regulatory requirements.
                  </li>
                  <li>We will not sell your personal data to third parties.</li>
                  <li>
                    We will provide you with easy ways to manage and review your
                    marketing choices if you receive direct marketing
                    communications from us.
                  </li>
                  <li>
                    It is already part of the daily operations of our company to
                    keep your information secure and private.
                  </li>
                </ul>

                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Personal Data We Use
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  We may use the following types of personal data:
                </p>

                <ul
                  style={{
                    marginTop: 20,
                    marginLeft: 40,
                    listStyle: "circle",
                    fontWeight: "500",
                  }}
                >
                  <li>Your Name</li>
                  <li>Home address</li>
                  <li>Business address</li>
                  <li>
                    Contact details such as phone numbers and email addresses
                  </li>
                  <li>
                    Data that identifies you by cookies when you use our website
                  </li>
                </ul>

                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Your refusal to provide Personal Data requested
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  If you refuse to provide the information requested, then it
                  may cause delay and we may be unable to continue to act for
                  you or complete your requests.
                </p>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Marketing Information
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  We may from time to time send you letters or emails about some
                  of our services.
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  You have the right to object and to ask us to stop sending you
                  marketing information by contacting us at any time. You can of
                  course change your mind and ask us to send the information
                  again.
                </p>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Where we store your personal data
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  The data that we collect from you from our website (Contact
                  Form Emails) is stored securely on our email hosting servers.
                  All of admin the access to this data is secured using SSL
                  Encryption and security measures. We will take all steps
                  reasonably necessary to ensure that your data is treated
                  securely and in accordance with this privacy policy. We always
                  encrypt all information sent across using Secure Socket Layer
                  Technology (SSL) to ensure further security of your personal
                  data.
                </p>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  How We Protect Your Information
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  We are concerned with protecting your privacy and data. When
                  you enter sensitive information (such as log in credentials)
                  or contact form submissions, we encrypt that information using
                  secure socket layer technology (SSL). No method of
                  transmission over the Internet or method of electronic
                  storage, is 100% secure. Therefore, we cannot guarantee its
                  absolute security.
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  How to get a copy of your Personal Information
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  If you wish to access your personal data then write to:
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Sawari Pakistan Limited <br /> R1, 179 Bordesley Green East{" "}
                  <br />
                  Birmingham. West Midlands, <br /> England, B9 5SR <br />{" "}
                  United Kingdom
                </p>

                <p style={{ marginTop: 20 }}>or</p>
                <p style={{ marginTop: 20 }}>
                  email:{" "}
                  <a href="mailto:hello@sawaripakistan.com">
                    {" "}
                    hello@sawaripakistan.com{" "}
                  </a>
                </p>

                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Telling us if your Personal Information is incorrect
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  If you think any information, we have about you is incomplete
                  or wrong, then you have the right to ask us to correct it.
                  Please contact us as above.
                </p>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Other Rights
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  As mentioned above you also have other rights, namely
                </p>

                <ul
                  style={{
                    marginTop: 20,
                    marginLeft: 40,
                    listStyle: "circle",
                    fontWeight: "500",
                  }}
                >
                  <li>The right to erasure.</li>
                  <li>The right to restrict processing of information.</li>
                  <li>The right to information portability.</li>
                  <li>
                    You have the right to ask us to delete (erase) or stop us
                    using your data if there is no longer any need for us to
                    keep it (e.g. under a legal obligation).
                  </li>
                </ul>

                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  How to raise a complaint
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  If you are unhappy about how we are using your Personal Data
                  then you can complain to us using the contact information
                  above
                </p>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  You also have the right to complain to the Information
                  Commissioner’s Office (ICO). Further, details on how to raise
                  a concern with the ICO can be found on the ICO’s website:
                  https://ico.org.uk/concerns
                </p>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Cookies
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  We use cookies for certain areas of our website.
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Cookies are small files that store information on your hard
                  drive or browser that means that our website can recognise
                  that you have visited our website before. Cookies can’t harm
                  your computer.
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  This website, like majority of other websites, stores and
                  retrieves information on your browser using cookies. This
                  information is used to make the site work as you expect it to.
                  It is not personally identifiable to you, but it can be used
                  to give you a more personalised web experience.
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  You can, if you really want to, disable the cookies from your
                  browser and delete all cookies currently stored on your
                  computer. Your cookie settings are set separately for each
                  different device, computer or browser and if you want to
                  change your cookie settings you will need to do so for each
                  device, computer or browser.
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  If you disable cookies, you’ll still be able to browse the
                  site but you will not be able to use all the features and the
                  website may not work as intended to. Most web browsers have
                  cookies enabled by default, if you want to review your cookie
                  setting please see the section entitled Managing cookies.
                </p>

                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Further reading about Cookies:
                </h1>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Information about cookies
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  More information about cookies is available at:
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  <a
                    style={{
                      color: "blue",
                    }}
                    href="http://www.allaboutcookies.org"
                  >
                    http://www.allaboutcookies.org
                  </a>
                </p>

                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Internet Advertising Bureau
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  A guide to behavioural advertising and online privacy produced
                  by the internet advertising
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  industry is available at:{" "}
                  <a
                    style={{ color: "blue" }}
                    href="http://www.youronlinechoices.eu"
                  >
                    {" "}
                    http://www.youronlinechoices.eu{" "}
                  </a>
                </p>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Please note that we take no responsibility for the content of
                  these or any other third party websites. Cookies which we may
                  use?
                </p>

                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Required Cookies
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  These cookies are necessary for the website to function and
                  cannot be switched off in our systems. They are usually only
                  set in response to actions made by you which amount to a
                  request for services, such as setting your privacy
                  preferences, logging in or filling in forms. You can set your
                  browser to block or alert you about these cookies, but some
                  parts of the site may not work then.
                </p>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Performance Cookies & Third Party Cookies
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  These cookies allow our website to count visits and traffic
                  sources, so we can determine and improve the performance of
                  our site. They help us know which pages are the most and least
                  popular and see how visitors move around our website. All
                  information these cookies collect is aggregated and therefore
                  anonymous. If you do not allow these cookies, we will not know
                  when you have visited our site. Third party cookies may
                  include Google Analytics and Bing, Facebook and other search
                  engines and service providers.
                </p>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  Updating this Notice
                </h1>

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 20,
                    fontFamily: "sans-serif",
                  }}
                >
                  We will, from time to time, update this Privacy Notice after
                  25th May 2018 to reflect any relevant changes in the law or
                  regulations.
                </p>
              </dl>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default GDPRPolicy;
