import React from 'react'
import { OnewayAndReturn } from '../AirportService/components/OnewayAndReturn'
import { Map } from './components/Map'
import SideBar from './components/SideBar'

export const SidebarNew = () => {
  return (
    <>
    <Map/>
    <OnewayAndReturn/>
    <SideBar/>

    </>
  )
}
