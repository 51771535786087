import React from 'react'
import { Signup } from './components/Signup'

export const SignupMain = () => {
  return (
    <>
    <Signup/>
    </>
  )
}
