import { Button } from "bootstrap";
import React, { useContext } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import loginContext from "../../../Constant/LoginContext/loginContext";
import { useNavigate } from "react-router-dom";

export const SectionThree = ({ scrollToCar }) => {
  const navigate = useNavigate();

  const context = useContext(loginContext);
  let { userData, setUserData } = context;

  const navigateToAirportService = () => {
    if (!userData || !Object.keys(userData).length > 0) {
      navigate("/Login");

      return;
    }

    navigate("/AirportService");
  };

  return (
    <>
      <Container fluid className="ATS">
        <Container>
          <div className="webkit">
            <h2
              className="smallScreenHeading text-5xl font-extrabold"
              style={{ color: "#00803E" }}
            >
              Airport Transfer Services
            </h2>
            <h3 className="smallScreenText alignleft text-sm font-bold text-white mt-2">
              Your journey starts here, with seamless airport transfers,
              chauffeured vehicle rentals, and unforgettable tourism packages.
              <br />
              Get an instant online quotation, pay with ease, and book in
              advance for hassle-free travel experiences.
            </h3>
          </div>
          <Row>
            <Col lg="12" className="py-2">
              <Image src={require("../../assets/airoplane.png")} />
            </Col>
            <Col lg="6">
              <div className="p-2">
                <Image
                  style={{ width: "80%" }}
                  src={require("../../assets/brvnews.png")}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="p-2" style={{ textAlign: "-webkit-right", }}>
                <div

                  className="fullwidth mb-4"
                  style={{
                    background: "rgb(255 255 255 / 26%)",
                    padding: "10px",
                    textAlign: "left",
                    width: "70%",

                  }}
                >
                  <p className="mediumText text-sm text-white" style={{ fontSize: 18 }}>
                    Don't miss out - book now and
                    <br />
                    get ready to make unforgettable memories!.
                  </p>
                  <button
                    onClick={scrollToCar}
                    className="smallScreenText text-white rounded-lg bgGreen py-2 px-5 d-flex gap-2 align-items-center my-3"

                  >
                    Book Now <BsArrowRight />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
