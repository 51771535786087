import React, { useContext, useRef } from "react";
import { MainBannerRent } from "./components/MainBannerRent";
import OurBestCars from "./components/OurBestCars";
import loginContext from "../../Constant/LoginContext/loginContext";
import { useLocation } from "react-router-dom";

export const MainRentCar = () => {
  const location = useLocation();

  const carRef = useRef(null);

  const scrollToCar = () => {
    carRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <MainBannerRent scrollToCar={scrollToCar} />
      <OurBestCars ref={carRef} />
    </>
  );
};
