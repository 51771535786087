import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Image, FormCheck } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import Accordion from "react-bootstrap/Accordion";
// import { BsArrowRight, BsHeartFill } from "react-icons/bs";
import Passengers from "../../Screens/AirportServiceTabs/Passengers";
import Bags from "../../Screens/AirportServiceTabs/Bags";
import Transmissions from "../../Screens/AirportServiceTabs/Transmissions";
import UserReview from "../../Screens/AirportServiceTabs/UserReview";
// import GoldenTouch from "../../Screens/AirportServiceTabs/GoldenTouch";
// import ContactUs from "../../Screens/AirportServiceTabs/ContactUs";
// import DISCLAIMER from "../../Screens/AirportServiceTabs/DISCLAIMER";
// import MyFavorite from "../../Screens/AirportServiceTabs/MyFavorite";
// import Default from "../../Screens/AirportServiceTabs/Default";
// import Form from "react-bootstrap/Form";
// import { BrowserRouter as Route, Router, Link, Switch } from "react-router-dom";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { CapeTown } from "./CityComponents/CapeTown";
// import Durban from "./CityComponents/Durban";
import Vehicle from "../../Screens/AirportServiceTabs/Vehicle";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";

export const SideBar = (prop) => {
  const { name } = prop;
  const [isLogin, setIsLogin] = useState("false");
  const [isheart, setIsHeart] = useState("false");
  const [profileTabNavigation, setProfileTabNavigation] = useState("");

  const heartFunc = () => {
    if (isheart === "true") {
      setIsHeart("false");
      // alert('checkbox is false')
      // setIsCheckBox("false");
      // setProfileExp({ ...profileExp, currentlyWorking: "off" });
    } else {
      setIsHeart("true");
      // setIsCheckBox("true");
      // setProfileExp({ ...profileExp, currentlyWorking: "on" });
    }
  };

  const checkBoxHandleChange = () => {
    if (isLogin === "true") {
      setIsLogin("false");
      // alert('checkbox is false')
      // setIsCheckBox("false");
      // setProfileExp({ ...profileExp, currentlyWorking: "off" });
    } else {
      setIsLogin("true");
      // setIsCheckBox("true");
      // setProfileExp({ ...profileExp, currentlyWorking: "on" });
    }
  };

  const testingFuc = (e) => {
    // alert("worrrrrrrrrrrrrrrrrrrrrrrrrrrrrking");
    // console.log(e, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    setProfileTabNavigation(e);
  };
  const capeTownFunc = (e) => {
    // alert("worrrrrrrrrrrrrrrrrrrrrrrrrrrrrking");
    // console.log(e, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    setProfileTabNavigation(e);
  };

  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const [isOpen1, setIsOpen1] = useState(true);

  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };
  const [isOpen2, setIsOpen2] = useState(true);

  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };
  const [isOpen3, setIsOpen3] = useState(true);

  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };
  const [isOpen4, setIsOpen4] = useState(true);

  const toggleAccordion4 = () => {
    setIsOpen4(!isOpen4);
  };
  const Component = () => {
    if (profileTabNavigation === "Passengers") {
      return <Passengers />;
    } else if (profileTabNavigation === "Bags") {
      return <Bags />;
    } else if (profileTabNavigation === "Transmission") {
      return <Transmissions />;
    } else if (profileTabNavigation === "UserReview") {
      return <UserReview />;
    } else if (profileTabNavigation === "Vehicle") {
      return <Vehicle onDataFromChild={capeTownFunc} />;
    } else {
      return <Vehicle onDataFromChild={capeTownFunc} />;
    }
  };

  // const Filter = [
  //   {
  //     heading : "Vehicle"
  //     option1 : ""
  //   },

  // ]
  return (
    <Container fluid>
      <Container id="sideSS">
        <Tabs>
          <Col lg="3" className="clacla">
            <h2 className="text-2xl font-bold">Filter</h2>
            <hr className="my-2" />
            <TabList>
              <Tab onClick={() => setProfileTabNavigation("Vehicle")}>
                <div
                  className="accordion-btn flex justify-between"
                  // onClick={toggleAccordion}
                >
                  <span className="text-[#00803e] font-bold text-lg">
                    Vehicle{" "}
                  </span>
                  {/* {isOpen ? ( */}
                  {/* <BiUpArrowAlt className="text-[#00803e] font-bold text-lg" /> */}
                  {/* ) : ( */}
                  <BiDownArrowAlt className="text-[#00803e] font-bold text-lg" />
                  {/* )} */}
                </div>
                {/* {isOpen && ( */}
                <div className="accordion-content">
                  {/* Your dropdown content goes here */}
                  <ul>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck defaultChecked />
                        Economy
                      </span>
                      <span className="float-right">90</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />
                        Compact
                      </span>
                      <span className="float-right">20</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />
                        Midsize
                      </span>
                      <span className="float-right">25</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck defaultChecked />
                        Standard
                      </span>
                      <span className="float-right">80</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />
                        Full Size
                      </span>
                      <span className="float-right">30</span>
                    </li>
                  </ul>
                </div>
                {/* )} */}
              </Tab>
              <Tab onClick={() => setProfileTabNavigation("Passengers")}>
                <div
                  className="accordion-btn flex justify-between"
                  // onClick={toggleAccordion1}
                >
                  <span className="text-[#00803e] font-bold text-lg">
                    Passengers
                  </span>
                  {/* {isOpen1 ? ( */}
                  {/* <BiUpArrowAlt className="text-[#00803e] font-bold text-lg" /> */}
                  {/* ) : ( */}
                  <BiDownArrowAlt className="text-[#00803e] font-bold text-lg" />
                  {/* )} */}
                </div>
                {/* {isOpen1 && ( */}
                <div className="accordion-content">
                  {/* Your dropdown content goes here */}
                  <ul>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck defaultChecked />1 to 3 passengers
                      </span>
                      <span className="float-right">115</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />3 to 5 passengers
                      </span>
                      <span className="float-right">62</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />6 or more
                      </span>
                      <span className="float-right">35</span>
                    </li>
                  </ul>
                </div>
                {/* )} */}
              </Tab>

              <Tab onClick={() => setProfileTabNavigation("Bags")}>
                <div
                  className="accordion-btn flex justify-between"
                  // onClick={toggleAccordion2}
                >
                  <span className="text-[#00803e] font-bold text-lg">Bags</span>
                  {/* {isOpen2 ? ( */}
                  {/* <BiUpArrowAlt className="text-[#00803e] font-bold text-lg" /> */}
                  {/* ) : ( */}
                  <BiDownArrowAlt className="text-[#00803e] font-bold text-lg" />
                  {/* )} */}
                </div>
                {/* {isOpen2 && ( */}
                <div className="accordion-content">
                  {/* Your dropdown content goes here */}
                  <ul>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck defaultChecked />1 to 2 bags
                      </span>
                      <span className="float-right">105</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />3 to 4 bags
                      </span>
                      <span className="float-right">80</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />5 or more
                      </span>
                      <span className="float-right">15</span>
                    </li>
                  </ul>
                </div>
                {/* )} */}
              </Tab>
              <Tab onClick={() => setProfileTabNavigation("Transmission")}>
                <div
                  className="accordion-btn flex justify-between"
                  // onClick={toggleAccordion3}
                >
                  <span className="text-[#00803e] font-bold text-lg">
                    Transmission
                  </span>
                  {/* {isOpen3 ? ( */}
                  {/* <BiUpArrowAlt className="text-[#00803e] font-bold text-lg" /> */}
                  {/* ) : ( */}
                  <BiDownArrowAlt className="text-[#00803e] font-bold text-lg" />
                  {/* )} */}
                </div>
                {/* {isOpen3 && ( */}
                <div className="accordion-content">
                  {/* Your dropdown content goes here */}
                  <ul>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck defaultChecked />
                        Automatic
                      </span>
                      <span className="float-right">105</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />
                        Manual
                      </span>
                      <span className="float-right">80</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />
                        Option 1
                      </span>
                      <span className="float-right">90</span>
                    </li>
                  </ul>
                </div>
                {/* )} */}
              </Tab>
              <Tab onClick={() => setProfileTabNavigation("UserReview")}>
                <div
                  className="accordion-btn flex justify-between"
                  // onClick={toggleAccordion4}
                >
                  <span className="text-[#00803e] font-bold text-lg">
                    User Review
                  </span>
                  {/* {isOpen4 ? ( */}
                  {/* <BiUpArrowAlt className="text-[#00803e] font-bold text-lg" /> */}
                  {/* // ) : ( */}
                  <BiDownArrowAlt className="text-[#00803e] font-bold text-lg" />
                  {/* // )} */}
                </div>
                {/* {isOpen4 && ( */}
                <div className="accordion-content">
                  {/* Your dropdown content goes here */}
                  <ul>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck defaultChecked />
                        Excellent
                      </span>
                      <span className="float-right">420</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />
                        Good
                      </span>
                      <span className="float-right">330</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />
                        Fair
                      </span>
                      <span className="float-right">225</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="flex gap-2">
                        <FormCheck />
                        Poor
                      </span>
                      <span className="float-right">20</span>
                    </li>
                  </ul>
                </div>
                {/* )} */}
              </Tab>
            </TabList>
          </Col>
          <Col lg="9">
            <view>
              <Component />
            </view>
          </Col>
        </Tabs>
      </Container>
    </Container>
  );
};

export default SideBar;
