import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export const SectionSix = () => {
  return (
    <>
      <Container fluid className="bg-tour">
        <Container>
          <Row className="align-items-center">
            <Col lg="4">
              <div className="py-7">
                <h2 className="smallScreenHeading text-3xl text-white font-extrabold">
                  Tourism Packages
                </h2>
                <p className="smallScreenText text-lg text-white font-semibold">
                  Embrace the magic of Pakistan's hidden gems and let your
                  senses come alive with our extraordinary tourism packages.
                  From the majestic mountains of the Himalayas to the vibrant
                  cultural tapestry of ancient cities, each package unveils the
                  true essence of Pakistan's beauty and hospitality. Get ready
                  to explore breath-taking landscapes, savour mouth-watering
                  cuisine, and forge unforgettable connections. Let Pakistan be
                  the backdrop to your most cherished memories - book your
                  extraordinary journey today!
                </p>
                {/* <h2 className="text-2xl text-white mt-7">COMING SOON</h2> */}
              </div>
            </Col>
            <Col lg="8">
              <div className="py-2">
                <Image src={require("../../assets/tourTravel.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
