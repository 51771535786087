import React, { useEffect, useState } from "react";
import MapKeyContext from "./mapKeycontext";

const MapKeyState = (prop) => {
  const [googleMapKey, setGoogleMapKey] = useState("");






  return (
    <MapKeyContext.Provider value={{ googleMapKey, setGoogleMapKey }}>
      {prop.children}
    </MapKeyContext.Provider>
  );
};

export default MapKeyState;
