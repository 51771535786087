import React, { useState, useEffect, useContext } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
// import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar/index.tsx";
import { Footermain } from "./Components/Footer";
import { Home } from "./Components/Screens/Home";
import { AirportService } from "./Components/Screens/AirportService";
import { RentACar } from "./Components/Screens/RentACar";
import { Login } from "./Components/Screens/Login";
import { Signup } from "./Components/Screens/Signup";
import { MainTravel } from "./Components/TravelAndTour";
import { SidebarNew } from "./Components/AirportServiceFilter";
import LoginState from "./Constant/LoginContext/loginState";
import MapScreen from "./Components/Screens/mapScreen";
import { ForgotPassword } from "./Components/Screens/ForgotPass";
import RentACarBooking from "./Components/RentACar/components/RentACarBooking";
import MainProfile from "./Components/Screens/MainProfile";
import RentBook from "./Components/Screens/RentBook";
import AirportConfirmBooking from "./Components/Screens/airportConfirmBooking";
import BookingHistory from "./Components/Screens/bookingHistory";
import RentConfirmBooking from "./Components/Screens/RentConfirmationBooking";
import ViewTravelDetails from "./Components/Screens/ViewTravelDetails";
import TourConfirmBooking from "./Components/Screens/tourConfirmationBooking";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { EditProfile } from "./Components/Screens/editProfile";
import { UpdatePassword } from "./Components/Screens/updatePass";
import AboutUs from "./Components/Screens/Aboutus";
import PrivacyPolicy from "./Components/Screens/PrivacyPolicy";
import TermsAndCondition from "./Components/Screens/termsAndConditions";
import GDPRPolicy from "./Components/Screens/GDPRPolicyPage";
import ContactUs from "./Components/Screens/ContactUs";
import MapKeyState from "./Constant/GoogleMapKeyContext/mapkeystate";
import { useStatStyles } from "@chakra-ui/react";
import BaseUri from "./Constant/BaseUri";
import axios from "axios";
import { GOOGLE_API_KEY } from "./config/GoogleMapKey";
import mapKeyContext from "./Constant/GoogleMapKeyContext/mapKeycontext";

function App() {
  const stripePromise = loadStripe(
    "pk_live_51NVhzCKuG8Lm0NkMxUiNwvSr5xv0iHb3qpab01nMmkd4W62uY1ldr9mfcWseCVHjUf4zFt5aPygHUZoJ4mJU20MG00fxDzsDG1"
  );


  const [googleMapKey, setGoogleMapKey] = useState("")

  const getGoogleMapKey = () => {
    axios.get(`${BaseUri}get_all_api_keys.php`).then((res) => {
      let { data } = res;

      if (data && data.length > 0) {
        let key = data[0]?.api_key;
        setGoogleMapKey(key);
      }
    });
  };

  useEffect(() => {
    getGoogleMapKey();
  }, []);


  return (
    <>
      <Elements stripe={stripePromise}>
        <MapKeyState>
          <LoginState>
            <div className="bg-[#edeef0]">
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/AirportService" element={<AirportService />} />
                <Route path="/RentACar" element={<RentACar />} />
                <Route path="/RentBooking" element={<RentBook />} />
                <Route path="/MainTravel" element={<MainTravel />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/Signup" element={<Signup />} />
                <Route path="/SidebarNew" element={<SidebarNew />} />
                <Route path="/MapScreen" element={<MapScreen />} />
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                <Route path="/UpdatePassword" element={<UpdatePassword />} />
                {/* <Route path="/Profile" element={<MainProfile />} /> */}
                <Route
                  path="/AirportConfirmBooking"
                  element={<AirportConfirmBooking />}
                />
                <Route path="/BookingHistory" element={<BookingHistory />} />
                <Route
                  path="/RentConfirmBooking"
                  element={<RentConfirmBooking />}
                />
                <Route path="/TravelDetails" element={<ViewTravelDetails />} />
                <Route
                  path="/TourConfirmBooking"
                  element={<TourConfirmBooking />}
                />
                <Route path="/EditProfile" element={<EditProfile />} />
                <Route path="/AboutUs" element={<AboutUs />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route
                  path="/TermsAndConditions"
                  element={<TermsAndCondition />}
                />
                <Route path="/GDPRPolicy" element={<GDPRPolicy />} />
                <Route path="/ContactUs" element={<ContactUs />} />

              </Routes>
              <Footermain />
            </div>
          </LoginState>
        </MapKeyState>
      </Elements>
    </>
  );
}

export default App;
