import React from "react";
import { LoginMain } from "../Login";

export const Login = () => {
  return (
    <>
      <LoginMain />
    </>
  );
};
