import { Col, Container, Row } from "react-bootstrap";

function AboutUs() {
  return (
    <Container fluid>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <div style={{ height: "100vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 20,
                flexDirection: "column",
              }}
            >
              <img
                src={require("../assets/LOGO.png")}
                style={{ width: 250, height: 100 }}
              />

              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                ABOUT US PAGE
              </h1>
            </div>
            <div style={{ padding: 20 }}>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 5,
                }}
              >
                Welcome to Sawari Pakistan Limited, a leading transportation and
                tourism service provider operating in collaboration with Sawari
                Airport Transfers (SMC-Private) Limited. With a strong presence
                in both the United Kingdom and Pakistan, we pride ourselves on
                delivering outstanding airport transfer services, vehicle
                rentals, and exceptional tourism packages all across Pakistan.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Our commitment to excellence and customer satisfaction has led
                us to become a trusted choice for locals and international
                travellers alike. With a wealth of experience in the industry, we
                understand the importance of seamless and reliable
                transportation solutions, as well as creating unforgettable
                experiences for those exploring the beauty of Pakistan.
              </p>

              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                At Sawari Pakistan Limited, we strive to exceed expectations
                through our comprehensive range of services. Whether you need a
                convenient airport transfer, reliable vehicle rental for your
                travel needs, or a tailored tourism package to explore the
                hidden gems of Pakistan, we have you covered.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                As technology-driven professionals, we have developed a dynamic
                website and mobile applications that allow you to access instant
                quotations. With just a few simple details, our user provide you
                with accurate pricing information.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                As the sole owner of these companies, my name is Mr. Mohammad
                Asif. With a deep passion for providing exceptional services, I
                have devoted my time and expertise to ensuring that both Sawari
                Pakistan Limited and Sawari Airport Transfers (SMC-Private) Limited are
                synonymous with reliability, professionalism, and customer
                satisfaction.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Our team of dedicated and experienced professionals are always
                available to assist you, whether you have queries about our
                services or need guidance in planning your perfect travel
                itinerary. We take great pride in offering personalized support
                and ensuring that every customer's individual needs are met with
                care and attention to detail.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                We believe in promoting the beauty, cultural richness, and
                diversity of Pakistan. Our commitment to sustainable tourism
                practices ensures that our operations contribute positively to
                the local communities we serve. By choosing our services, you
                are not only experiencing the best that Pakistan supporting
                responsible and ethical travel.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Thank you for choosing Sawari Pakistan Limited and Sawari
                Airport Transfers (SMC-Private) Limited. We look forward to
                being a part of your memorable journey, creating unforgettable
                experiences, and being your trusted partner in transportation
                and tourism services throughout Pakistan!
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
