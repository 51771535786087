import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Image,
} from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Screens/AirportServiceTabs/map.css";

import { BiUser } from "react-icons/bi";
import { BsBagCheck, BsFillFuelPumpFill } from "react-icons/bs";
import { SlBag } from "react-icons/sl";
import { GrSettingsOption } from "react-icons/gr";
import { TbTopologyStar3 } from "react-icons/tb";
import { AiFillCheckCircle } from "react-icons/ai";
import marker from "../assets/marker.png";
import {
  AiFillCalendar,
  AiFillClockCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useEditable } from "@chakra-ui/react";
import axios from "axios";
import BaseUri from "../../Constant/BaseUri";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Pagination } from "swiper";

import loginContext from "../../Constant/LoginContext/loginContext";
import Polyline from "@mapbox/polyline";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import mapKeyContext from "../../Constant/GoogleMapKeyContext/mapKeycontext";

// import './MapScreen.css'; // You can create this CSS file to style your components

const MapMarker = ({ text }) => (
  <div style={{ width: 50, textAlign: "center" }} className="map-marker">
    {/* {text}
    <img
      src={marker} // Replace with the path to your location icon image
      alt="Location Marker"
      style={{ width: 50, height: 50, alignSelf: "center" }}
      className="location-marker"
    /> */}
  </div>
);

const MapScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [totalDistance, setTotalDistance] = useState(0);
  const [carSort, setCarSort] = useState("lowToHigh");
  const [passengers, setPassengers] = useState(location?.state?.passengers);
  const [pickupLocation, setPickupLocation] = useState(
    location?.state?.booking_pickup
  );
  const [dropoffLocation, setDropoffLocation] = useState(
    location?.state?.booking_dropoff
  );
  const [dropoffCords, setDropoffCords] = useState(
    location?.state?.dropoffCords
  );
  const [pickupCords, setPickupCords] = useState(location?.state?.pickupCords);
  const [viaLocation, setViaLocation] = useState(
    location?.state?.booking_via_location
  );
  const [viaCords, setViaCords] = useState(location.state.viaCords);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const mapKeyCont = useContext(mapKeyContext);
  let { googleMapKey, setGoogleMapKey } = mapKeyCont;

  // const [googleMapKey, setGoogleMapKey] = useState("");

  const directionsService = new window.google.maps.DirectionsService();
  const directionsRendererRef = useRef(null); // Ref to hold directionsRenderer instance

  const context = useContext(loginContext);
  let { userData, setUserData } = context;

  const [cars, setCars] = useState([]);

  const getGoogleMapKey = () => {
    axios.get(`${BaseUri}get_all_api_keys.php`).then((res) => {
      let { data } = res;

      if (data && data.length > 0) {
        let key = data[0]?.api_key;
        setGoogleMapKey(key);
      }
    });
  };

  useEffect(() => {
    getGoogleMapKey();
  }, []);

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  const handleGetPickupCords = async () => {
    try {
      const apiKey = googleMapKey;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        pickupLocation
      )}&key=${apiKey}`;
      const response = await axios.get(apiUrl);

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setPickupCords({
          lat: lat,
          lng: lng,
        });
      } else {
        console.error("No results found for the given address.");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching geolocation data:",
        error
      );
    }
  };

  const handleGetDropoffCords = async () => {
    try {
      const apiKey = googleMapKey;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        dropoffLocation
      )}&key=${apiKey}`;

      const response = await axios.get(apiUrl);

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setDropoffCords({
          lat: lat,
          lng: lng,
        });
      } else {
        console.error("No results found for the given address.");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching geolocation data:",
        error
      );
    }
  };

  useEffect(() => {
    if (pickupLocation) {
      handleGetPickupCords();
    }
    if (dropoffLocation) {
      handleGetDropoffCords();
    }
  }, [pickupLocation, dropoffLocation, googleMapKey]);

  const handleSelect = async (selectedAddress) => {
    setPickupLocation(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);
      setPickupCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const handleViaSelect = async (selectedAddress) => {
    setViaLocation(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);

      setViaCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const handleDropoffSelect = async (selectedAddress) => {


    setDropoffLocation(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);
      setDropoffCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const getAvailableCars = () => {
    let totalCars = [];

    axios
      .get(`${BaseUri}get_airport_cars.php`, {
        params: { seats: passengers ? passengers : location.state.passengers },
      })
      .then((res) => {
        let { data } = res;

        let myData =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            let baseCharge = e.car_min_fare;
            let baseChargeUsd = e.car_min_fare_usd;
            let baseChargeGbp = e.car_min_fare_gbp;

            if (totalDistance <= 20) {
              let otherCharge = totalDistance * e.car_fare_less_20_km;
              let otherChargeUsd = totalDistance * e.car_fare_less_20_km_usd;
              let otherChargeGbp = totalDistance * e.car_fare_less_20_km_gbp;

              let totalCharge = Number(otherCharge) + Number(baseCharge);
              let totalChargeUsd =
                Number(otherChargeUsd) + Number(baseChargeUsd);
              let totalChargeGbp =
                Number(otherChargeGbp) + Number(baseChargeGbp);

              e.booking_fare = totalCharge;
              e.booking_fare_usd = totalChargeUsd;
              e.booking_fare_gbp = totalChargeGbp;

              return e;
            } else {
              let aboveKm = totalDistance - 20;

              let above20Charge = e.car_fare_above_20_km * aboveKm;
              let above20ChargeUsd = e.car_fare_above_20_km_usd * aboveKm;
              let above20ChargeGbp = e.car_fare_above_20_km_gbp * aboveKm;

              let remainingCharge = e.car_fare_less_20_km * 20;
              let remainingChargeUsd = e.car_fare_less_20_km_usd * aboveKm;
              let remainingChargeGbp = e.car_fare_less_20_km_gbp * aboveKm;

              let totalCharges =
                Number(baseCharge) +
                Number(above20Charge) +
                Number(remainingCharge);

              let totalChargesUsd =
                Number(baseChargeUsd) +
                Number(above20ChargeUsd) +
                Number(remainingChargeUsd);

              let totalChargesGbp =
                Number(baseChargeGbp) +
                Number(above20ChargeGbp) +
                Number(remainingChargeGbp);

              e.booking_fare = totalCharges;
              e.booking_fare_usd = totalChargesUsd;
              e.booking_fare_gbp = totalChargesGbp;

              return e;
            }
          });

        if (carSort == "lowToHigh") {
          let sortData =
            myData &&
            myData.length > 0 &&
            myData.sort((a, b) =>
              Number(a?.car_min_fare - Number(b?.car_min_fare))
            );
          setCars(sortData ?? []);
        } else {
          let sortData =
            myData &&
            myData.length > 0 &&
            myData.sort((a, b) =>
              Number(b?.car_min_fare - Number(a?.car_min_fare))
            );
          setCars(sortData ?? []);
        }
      })
      .catch((error) => {
      });
  };

  console.log(cars, "carsss")

  React.useEffect(() => {
    handleCheckUser();
  }, []);

  React.useEffect(() => {
    if (totalDistance) {
      getAvailableCars();
    }
  }, [totalDistance, passengers]);

  React.useEffect(() => {
    if (carSort == "lowToHigh") {
      getAvailableCars();
    } else {
      getAvailableCars();
    }
  }, [carSort]);

  // const markers = [
  //   {
  //     id: 1,
  //     lat: changedPickupCords
  //       ? changedPickupCords.lat
  //       : location?.state?.pickupCords?.lat,
  //     lng: changedPickupCords
  //       ? changedPickupCords.lng
  //       : location?.state?.pickupCords?.lng,
  //     text: "Pickup",
  //   },
  //   {
  //     id: 2,
  //     lat: location?.state?.viaCords?.lat && location?.state?.viaCords?.lat,
  //     lng: location?.state?.viaCords?.lng && location?.state?.viaCords?.lng,
  //     text: "Via",
  //   },
  //   {
  //     id: 3,
  //     lat: location?.state?.dropoffCords?.lat,
  //     lng: location?.state?.dropoffCords?.lng,
  //     text: "Dropoff",
  //   },
  //   // Add more marker data as needed
  // ];

  const defaultProps = {
    center: {
      lat: location?.state?.dropoffCords?.lat,
      lng: location?.state?.dropoffCords.lng,
    }, // New York City coordinates
    zoom: 10,
  };

  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: pickupCords,
      zoom: 10,
    });

    directionsRendererRef.current = new window.google.maps.DirectionsRenderer({
      map: map,
    });

    calculateAndDisplayRoute();
  }, [pickupCords, dropoffCords, viaCords]);

  const calculateAndDisplayRoute = () => {
    if (!pickupCords || !dropoffCords) {
      return;
    }

    // const start = markers[0]; // First marker as the starting point
    // const via =  location?.state?.viaCords?.lat && markers[1]; // Second marker as the ending point
    // const end = markers[2]; // Second marker as the ending point

    if (viaCords && Object.keys(viaCords).length > 0) {
      directionsService.route(
        {
          origin: {
            lat: pickupCords.lat,
            lng: pickupCords.lng,
          },
          waypoints: [{ location: { lat: viaCords.lat, lng: viaCords.lng } }],
          destination: { lat: dropoffCords.lat, lng: dropoffCords.lng },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            directionsRendererRef?.current?.setDirections(response);
            const route = response.routes[0];
            let total = 0;
            for (let i = 0; i < route.legs.length; i++) {
              total += route.legs[i].distance.value;
            }
            setTotalDistance(total / 1000);
          } else {
            console.error("Directions request failed due to " + status);
          }
        }
      );

      return;
    }

    directionsService.route(
      {
        origin: {
          lat: pickupCords.lat,
          lng: pickupCords.lng,
        },
        destination: { lat: dropoffCords.lat, lng: dropoffCords.lng },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK") {
          directionsRendererRef?.current?.setDirections(response);
          const route = response.routes[0];
          let total = 0;
          for (let i = 0; i < route.legs.length; i++) {
            total += route.legs[i].distance.value;
          }
          setTotalDistance(total / 1000);
        } else {
          console.error("Directions request failed due to " + status);
        }
      }
    );
  };


  const routeToConfirmBooking = async (item) => {
    if (!userData) {
      alert("Login to book your ride");
      return;
    }

    let formattedDate = "";
    let formattedTime = "";

    if (date) {
      let format = new Date(date);
      let date1 = format.getDate();
      let month1 = format.getMonth();
      let year1 = format.getFullYear();
      formattedDate = `${date1} / ${month1} / ${year1}`;
    }

    if (time) {
      formattedTime = await convertTo12HourFormat(time);
    }

    let { state } = location;

    location.state.passengers = passengers
      ? passengers
      : location.state.passengers;

    let dataToSend = {
      booking_date: date ? date : location.state.booking_date,
      booking_time: time ? time : location?.state?.booking_time,
      booking_dropoff: dropoffLocation,
      booking_pickup: pickupLocation,
      booking_via_location: viaLocation,
      dropoffCords: dropoffCords,
      formattedDate: formattedDate
        ? formattedDate
        : location.state.formattedDate,
      formattedTime: formattedTime
        ? formattedTime
        : location.state.formattedTime,
      passengers: passengers,
      pickupCords: pickupCords,
      user_id: location?.state?.user_id,
      viaCords: viaCords,
      pickupPlace: location?.state?.pickupPlace,
    };

    let routeData = { ...dataToSend, ...item };

    routeData.booking_distance = totalDistance;
    navigate("/AirportConfirmBooking", { state: routeData });
  };

  const handleApiLoaded = () => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: pickupCords,
      zoom: 10,
    });

    directionsRendererRef.current = new window.google.maps.DirectionsRenderer({
      map: map,
    });

    // Call the route calculation function
    calculateAndDisplayRoute();
  };

  const updateVia = (e) => {


    if (e == "") {
      setViaLocation("");
      setViaCords("");
      return;
    }
    setViaLocation(e);
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const parsedHours = parseInt(hours, 10);
    const period = parsedHours >= 12 ? "PM" : "AM";
    const adjustedHours = parsedHours % 12 || 12;
    return `${adjustedHours}:${minutes} ${period}`;
  };

  const formateDateAndTime = async () => { };

  return (
    location.state && (
      <Container
        fluid
        style={{ margin: 0, padding: 0, backgroundColor: "white" }}
      >
        <Col className="heigth350" sm={12} md={12} lg={12} xl={12} style={{ height: "400px" }}>
          <div className="heigth350" style={{ height: 400, width: "100%" }} id="map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: googleMapKey,
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={10}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={(map) => {
                handleApiLoaded(map);
              }}
              // onGoogleApiLoaded={({ map, maps }) => {
              //   directionsRenderer.setMap(map);
              //   calculateAndDisplayRoute();
              // }}
              style={{ height: 200 }}
            ></GoogleMapReact>
          </div>
        </Col>
        <Row>
          <Col
            lg="12"
            md={12}
            xl={12}
            sm={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                position: "absolute",
                width: "95%",
                alignSelf: "center",
                zindex: 1,
                marginTop: 120,
                height: 220,
              }}

              className="marginTable heigthTable oneway py-11"
            >
              <Tabs style={{ marginTop: -10 }}>
                <TabList
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab
                    style={{
                      textTransform: "uppercase",
                      textDecoration: "none",
                      color: "white",
                      marginTop: -10,
                    }}
                    className="fourteen"
                  >
                    One Way
                  </Tab>
                  <Tab
                    style={{
                      // textTransform: "uppercase",
                      textDecoration: "none",
                      color: "white",
                      marginTop: -10,
                      fontSize: 14,
                      color: "yellow",
                    }}
                    className="smallScreenText"
                  >
                    Note: You have to make a seperate booking for return
                    journey.
                  </Tab>
                </TabList>

                <TabPanel>
                  <div style={{ zindex: 100, marginTop: -20, padding: 0 }}>
                    <Row>
                      <Col xz xl="4" lg="4" sm="4" xs="12">
                        {location.state.pickupPlace == "location" ? (
                          <div className="fullwidth" style={{ marginTop: 20, }}>
                            <PlacesAutocomplete
                              className="fullwidth About_main_images_search"
                              value={pickupLocation}
                              onChange={setPickupLocation}
                              onSelect={handleSelect}
                              style={{
                                zindex: 20,
                                color: "gray",
                                marginTop: 20,
                                border: "2px solid",
                              }}

                              googleCallbackName="initMap"
                            // searchOptions={{
                            //   types: ["geocode"], // Limit results to addresses only
                            // }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                              }) => (
                                <div className="autocomplete-input-container">
                                  <input
                                    {...getInputProps({
                                      placeholder: "Enter Pickup Address",
                                      className: "autocomplete-input",
                                    })}
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      color: "gray",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                    }}
                                  />
                                  {pickupLocation && (
                                    <div className="autocomplete-dropdown-container">
                                      {suggestions.map((suggestion, index) => (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion
                                          )}
                                          className="autocomplete-suggestion"
                                          style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            color: "black",
                                            backgroundColor: "#fff",
                                            borderBottom: "1px solid #ccc",
                                          }}
                                        >
                                          {suggestion.description}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        ) : (
                          <div className="About_main_images_search">
                            <select
                              style={{ color: "black" }}
                              className="w-full"
                              value={pickupLocation}
                              // style={{ width: "100%", padding: 10 }}
                              onChange={(e) =>
                                setPickupLocation(e.target.value)
                              }
                            >
                              {/* <option
                                      style={{ color: "black" }}
                                    >
                                      {pickupLocation}
                                    </option> */}
                              {location.state.allAirports &&
                                location.state.allAirports.length > 0 &&
                                location.state.allAirports.map((e, i) => {
                                  return (
                                    <option
                                      style={{ color: "black" }}
                                      value={e.airport_address}
                                      key={i}
                                    >
                                      {e.airport_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        )}
                      </Col>
                      <Col xl="4" lg="4" sm="4" xs="12">
                        <div className="marginTop marginTopTen" style={{ marginTop: 20 }}>
                          <PlacesAutocomplete
                            value={viaLocation}
                            onChange={(e) => updateVia(e)}
                            onSelect={handleViaSelect}
                            style={{ zindex: 20, marginTop: 20 }}
                            googleCallbackName="initMap"
                          // searchOptions={{
                          //   types: ["geocode"], // Limit results to addresses only
                          // }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                            }) => (
                              <div
                                style={{ marginTop: 20 }}
                                className="marginTop marginTopTen autocomplete-input-container"
                              >
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter Via Address",
                                    className: "autocomplete-input",
                                  })}
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    color: "gray",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                  }}
                                />
                                {viaLocation && (
                                  <div className="autocomplete-dropdown-container">
                                    {suggestions.map((suggestion, index) => (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion)}
                                        className="autocomplete-suggestion"
                                        style={{
                                          padding: "5px",
                                          cursor: "pointer",
                                          color: "black",
                                          backgroundColor: "#fff",
                                          borderBottom: "1px solid #ccc",
                                        }}
                                      >
                                        {suggestion.description}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" sm="4" xs="12">
                        {location.state.pickupPlace == "airport" ? (
                          <div style={{ marginTop: 20 }}>
                            <PlacesAutocomplete
                              className="About_main_images_search"
                              value={dropoffLocation}
                              onChange={setDropoffLocation}
                              onSelect={handleDropoffSelect}
                              style={{
                                zindex: 20,
                              }}
                              googleCallbackName="initMap"
                            // searchOptions={{
                            //   types: ["geocode"], // Limit results to addresses only
                            // }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                              }) => (
                                <div className="autocomplete-input-container">
                                  <input
                                    {...getInputProps({
                                      placeholder: "Enter Pickup Address",
                                      className: "autocomplete-input",
                                    })}
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      color: "gray",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                    }}
                                  />
                                  {dropoffLocation && (
                                    <div className="autocomplete-dropdown-container">
                                      {suggestions.map((suggestion, index) => (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion
                                          )}
                                          className="autocomplete-suggestion"
                                          style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            color: "black",
                                            backgroundColor: "#fff",
                                            borderBottom: "1px solid #ccc",
                                          }}
                                        >
                                          {suggestion.description}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        ) : (
                          <div className="About_main_images_search">
                            <select
                              style={{ color: "black" }}
                              className="w-full"
                              value={dropoffLocation}
                              // style={{ width: "100%", padding: 10 }}
                              onChange={(e) =>
                                setDropoffLocation(e.target.value)
                              }
                            >
                              {location.state.allAirports &&
                                location.state.allAirports.length > 0 &&
                                location.state.allAirports.map((e, i) => {
                                  return (
                                    <option
                                      style={{ color: "black" }}
                                      value={e.airport_address}
                                      key={i}
                                    >
                                      {e.airport_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        )}
                      </Col>
                      <Col xl="4" lg="4" sm="4" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              style={{
                                fontFamily: "sans-serif",
                                color: "gray",
                              }}
                              placeholder="Pickup Date"
                              onChange={(e) => setDate(e.target.value)}
                              value={
                                date ? date : location?.state?.formattedDate
                              }
                              type={date ? "date" : "text"}
                              className="border-white"
                            ></input>
                            <AiFillCalendar
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="4" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              onChange={(e) => setTime(e.target.value)}
                              style={{
                                fontFamily: "sans-serif",
                                color: "gray",
                              }}
                              type={time ? "time" : "text"}
                              placeholder="Pickup Time"
                              className="border-white"
                              value={
                                time ? time : location?.state?.formattedTime
                              }
                            ></input>
                            <AiFillClockCircle
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="4" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <select
                              name="format"
                              value={passengers ?? location.state.passengers}
                              id="format"
                              style={{ fontFamily: "sans-serif" }}
                              onChange={(e) => setPassengers(e.target.value)}
                              className="w-full"
                            >
                              <option>
                                {passengers
                                  ? passengers
                                  : location.state.passengers}
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="5">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                            </select>

                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                    </Row>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    <Row className="align-items-center">
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Pickup Location"
                              className="border-white"
                            ></input>
                            <AiOutlineSearch
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Add Via Location"
                              className="border-white"
                            ></input>
                            <AiOutlineSearch
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Drop off Location"
                              className="border-white"
                            ></input>
                            <AiOutlineSearch
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Pickup Date"
                              className="border-white"
                            ></input>
                            <AiFillCalendar
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Pickup Time"
                              className="border-white"
                            ></input>
                            <AiFillClockCircle
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <select
                              name="format"
                              value={
                                passengers
                                  ? passengers
                                  : location.state.passengers
                              }
                              id="format"
                              style={{ fontFamily: "sans-serif" }}
                              onChange={(e) => setPassengers(e.target.value)}
                              className="w-full"
                            >
                              <option>
                                {passengers
                                  ? passengers
                                  : location.state.passengers}
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="5">6</option>
                              <option value="7">7</option>
                              <option value="7">8</option>
                              <option value="7">9</option>
                              <option value="7">10</option>
                              <option value="7">11</option>
                              <option value="7">12</option>
                              <option value="7">13</option>
                              <option value="7">14</option>
                              <option value="7">15</option>
                              <option value="7">16</option>
                              <option value="7">17</option>
                              <option value="7">18</option>
                              <option value="7">19</option>
                              <option value="7">20</option>
                            </select>
                          </div>
                        </fieldset>
                      </Col>
                      <Col lg="12">
                        <div className="webkit pt-5">
                          <h2 className="text-lg text-white">
                            Or Book By Phone On 0349-3499847
                          </h2>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </Col>
        </Row>
        <div
          style={{
            zindex: 100,
            padding: 0,
            right: 0,
            width: "100%",
            marginTop: 200,
            backgroundColor: "white",
          }}
          className="marginTableCar"
        >
          <Row style={{ margin: 0 }} className="py-2 align-items-center">
            <Col lg="9">
              <div className="flex flex-col md:flex-row md:flex gap-4 items-center">
                <h2 className="text-4xl colorGreen font-bold"> </h2>
                {cars && cars.length > 0 ? (
                  <h2
                    className="fourteen text-lg font-medium"
                    style={{
                      color: "green",
                      width: "100%",
                      fontFamily: "sans-serif",
                      textAlign: "center",
                    }}

                  >
                    <span className="text-4xl font-bold averageText "> Hooray! </span> We've
                    found the perfect wheels for your journey. Get ready to hit
                    the road with a smile on your face!{" "}
                    <span style={{ marginLeft: 10 }}> Scroll Down </span>
                  </h2>
                ) : (
                  <h2
                    className="text-lg font-medium"
                    style={{
                      color: "green",
                      width: "100%",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <span className="text-4xl font-bold "> Sorry! </span> No car
                    found
                  </h2>
                )}
              </div>
            </Col>
            <Col lg="3">
              <div className="p-2">
                <div className="d-flex gap-6 float-none justify-center md:justify-end md:float-right">
                  <div className="d-flex gap-3 align-items-center">
                    <h2 className="text-xl text-black font-semibold">
                      Sort By:
                    </h2>
                    <select
                      name="size"
                      id="size"
                      value={carSort}
                      className="p-2 bg-white text-green"
                      style={{ color: "green" }}
                      onChange={(e) => setCarSort(e.target.value)}
                    >
                      <option value="lowToHigh" selected="">
                        Low to High
                      </option>
                      <option value="highToLow" selected="">
                        High to Low
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {cars && cars.length > 0 ? (
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: 0,
              }}
            >
              <Swiper
                id="mm1"
                // install Swiper modules
                modules={[Navigation, A11y, Pagination]}

                spaceBetween={20}
                loop={true}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                breakpoints={{
                  700: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  750: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                {cars &&
                  cars.length > 0 &&
                  cars.map((e, i) => {
                    return (
                      <SwiperSlide>
                        <div
                          style={{
                            backgroundColor: "rgba(220,220,220,0.6)",
                            width: "100%",
                          }}
                          className="text-center  border-black rounded-md"
                        >
                          <div className="rounded-md">
                            <img
                              src={`https://smaztech.com/sawari/${e.car_photo}`}
                              alt="Image"
                              className="height250"
                            />
                          </div>
                          <div className="p-2 webkit justify-center">
                            <h2 className="font-semibold text-xl text-black">
                              {e.car_title}
                            </h2>
                            <Row className="justify-center rrr py-2">
                              <Col>
                                <div>
                                  <h2 className="fourteen text-lg text-black">
                                    PKR{" "}
                                    <span className="fourteen colorGreen font-extrabold text-2xl">
                                      {new Intl.NumberFormat("en-PK", {
                                        style: "currency",
                                        currency: "PKR",
                                        maximumFractionDigits: 0,
                                      }).format(Number(e?.booking_fare))}
                                    </span>
                                  </h2>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <h2 className="fourteen text-lg text-black">
                                    GBP{" "}
                                    <span className="fourteen colorGreen font-extrabold text-2xl">
                                      {e?.booking_fare_gbp.toLocaleString(
                                        "en-GB",
                                        {
                                          style: "currency",
                                          currency: "GBP",
                                        }
                                      )}
                                    </span>
                                  </h2>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <h2 className="fourteen text-lg text-black">
                                    USD{" "}
                                    <span className="fourteen colorGreen font-extrabold text-2xl">
                                      {e?.booking_fare_usd.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}
                                    </span>
                                  </h2>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr className="hr" />
                          <Row className="p-2 justify-center align-items-center">
                            <Col className="text-right">
                              <button
                                onClick={() => routeToConfirmBooking(e)}
                                style={{ backgroundColor: "#00803E" }}
                                className="btn text-white font-semibold"
                              >
                                Book Now
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Row>
          ) : (
            <Row
              style={{
                width: "100%",
                display: "flex",
                margin: 0,
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: 32,
                  color: "green",
                }}
              >
                No Car Found
              </h1>
            </Row>
          )}
        </div>
      </Container>
    )
  );
};

export default MapScreen;
