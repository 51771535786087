import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Image, Row, FormCheck, Form } from "react-bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Stack,
  Box,
  SimpleGrid,
  List,
  ListItem,
  ListIcon,
  Select,
  useStatStyles,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdCheckCircle, MdSettings, MdOutlineSell } from "react-icons/md";
import axios from "axios";
import BaseUri from "../../Constant/BaseUri";
import loginContext from "../../Constant/LoginContext/loginContext";
import { MDBContainer } from "mdb-react-ui-kit";
import { RingLoader } from "react-spinners";

export const EditProfile = () => {
  const navigate = useNavigate();

  const [selectedGender, setSelectedGender] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [userDetail, setUserDetail] = useState("");
  const [profileError, setProfileError] = useState("");

  let loginCont = useContext(loginContext);
  const { userData, setUserData } = loginCont;

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  const getUserDetail = () => {
    let data = {
      id: userData.id,
    };

    axios
      .get(`${BaseUri}user-details-by-id.php`, { params: data })
      .then((res) => {
        let { data } = res;
        let { result } = data;

        setUserDetail(result);
      });
  };


  React.useEffect(() => {
    handleCheckUser();
  }, []);
  React.useEffect(() => {
    if (userData.id) {
      getUserDetail();
    }
  }, [userData]);

  function objectToFormData(obj) {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
      }
    }

    return formData;
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleEditProfile = async () => {
    const phoneRegex = /^\+\d+$/;

    let checkPhoneNumber = phoneRegex.test(userData.phone);

    let error = {};

    if (!userData.address) {
      error.address = true;
    }
    if (!userData.dob) {
      error.dob = true;
    }
    if (!userData.gender) {
      error.gender = true;
    }
    if (!userData.name) {
      error.name = true;
    }
    if (!userData.phone) {
      error.phone = true;
    }
    if (!checkPhoneNumber) {
      error.invalidPhone = true;
    }

    if (error && Object.keys(error).length > 0) {
      setProfileError(error);
      return;
    }

    setProfileError(error);

    if (selectedImage) {
      setLoading(true);
      userData.user_id = userData.id;

      const formData = await objectToFormData(userData);
      axios
        .post(`${BaseUri}edit_user_profile.php`, formData)
        .then((res) => {
          const formData = new FormData();
          formData.append("user_avatar_upload", selectedImage);
          formData.append("user_id", userData.id);

          axios.post(`${BaseUri}edit_avatar.php`, formData).then((res) => {
            let id = {
              id: userData.id,
            };

            axios
              .get(`${BaseUri}user-details-by-id.php`, { params: id })
              .then((res) => {
                let { data } = res;
                let { result } = data;
                setLoading(false);
                result.id = userData.id;
                let dataToSave = JSON.stringify(result);
                localStorage.setItem("userData", dataToSave);
                setUserData(result);
                alert("Profile has been successfully updated");
                navigate("/");
              })
              .catch((error) => {
                setLoading(false);
                console.log(error, "error");
              });
          });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error");
        });
    } else {
      setLoading(true);

      userData.user_id = userData.id;
      const formData = await objectToFormData(userData);
      axios
        .post(`${BaseUri}edit_user_profile.php`, formData)
        .then((res) => {
          let dataToSave = JSON.stringify(userData);
          setLoading(false);
          localStorage.setItem("userData", dataToSave);
          setUserData(userData);
          alert("Profile has been successfully updated");
          navigate("/");
        })
        .catch((error) => {
          console.log(error, "error");
          setLoading(false);
        });
    }
  };

  return (
    userData && (
      <>
        <Container fluid className="py-5">
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <div className="webkit p-2">
                  <div>
                    <Image src={require("../assets/LoginSignup.png")} />
                  </div>
                </div>
              </Col>
              <Col
                lg="6"
                className="shadow-lg rounded-2xl shadow-gray-50 py-14 px-3 mt-4"
              >
                <div className="webkit">
                  <div className="border-b-black border-b-4 pb-1 inline-block">
                    <h2 className="colorGreen font-semibold text-4xl">
                      Edit Profile
                    </h2>
                  </div>

                  <MDBContainer
                    style={{ width: 125, height: 80, flexDirection: "column" }}
                    className="my-5 d-flex justify-content-center "
                  >
                    <div>
                      <label htmlFor="avatar">
                        <img
                          src={
                            imagePreview
                              ? imagePreview
                              : userDetail?.photo
                              ? `https://smaztech.com/sawari/${userDetail.photo}`
                              : require("../assets/avatar.avif")
                          }
                          className="avatar-image"
                        />
                      </label>
                      <input
                        type="file"
                        id="avatar"
                        accept="image/*"
                        style={{
                          display: "none",
                          border: "2px solid red",
                          padding: 10,
                        }}
                        onChange={handleImageChange}
                      />
                    </div>
                  </MDBContainer>
                </div>

                <div className="d-flex flex-col gap-3 py-6">
                  <div>
                    <fieldset className="py-4">
                      <Stack spacing={4}>
                        {/* <Select variant="outline" placeholder="Personal">
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                      </Select> */}
                        <div style={{flexWrap:"wrap"}} className="d-flex gap-2">
                          <div style={{ width: "100%" }}>
                            <InputGroup>
                              <Input
                                className="py-2"
                                type="text"
                                value={userData.name}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    name: e.target.value,
                                  })
                                }
                                placeholder="Name"
                              />
                            </InputGroup>

                            {profileError && profileError.name && (
                              <h1
                                style={{
                                  color: "red",
                                  marginLeft: 2,
                                  fontWeight: "500",
                                }}
                              >
                                Name is missing
                              </h1>
                            )}
                          </div>
                          <div style={{ width: "100%" }}>
                            <InputGroup>
                              <Input
                                className="py-2"
                                type="tel"
                                value={userData.phone}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    phone: e.target.value,
                                  })
                                }
                                placeholder="Phone"
                              />
                            </InputGroup>
                            {profileError && profileError.phone ? (
                              <h1
                                style={{
                                  color: "red",
                                  marginLeft: 2,
                                  fontWeight: "500",
                                }}
                              >
                                Phone Number is missing
                              </h1>
                            ) : profileError.invalidPhone ? (
                              <h1
                                style={{
                                  color: "red",
                                  marginLeft: 2,
                                  fontWeight: "500",
                                }}
                              >
                                Invalid Phone Number or country code is missing
                              </h1>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <InputGroup>
                          <Input
                            className="py-2"
                            value={userData.email}
                            editable={false}
                            type="email"
                            placeholder="Email"
                          />
                        </InputGroup>
                        <div style={{ width: "100%" }}>
                          <InputGroup>
                            <Input
                              className="py-2"
                              type="number"
                              value={userData.dob}
                              placeholder="Enter Birth Year.."
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  dob: e.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          {profileError && profileError.dob && (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              DOB is missing
                            </h1>
                          )}
                        </div>
                        <div style={{ width: "100%" }}>
                          <InputGroup>
                            <Input
                              className="py-2"
                              type="text"
                              value={userData.address}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  address: e.target.value,
                                })
                              }
                              placeholder="Enter Address..."
                            />
                          </InputGroup>

                          {profileError && profileError.address && (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              Address is missing
                            </h1>
                          )}
                        </div>

                        <div style={{ padding: "0px 10px" }}>
                          <h1 style={{ fontSize: 18, fontWeight: "bold" }}>
                            Gender:
                          </h1>

                          <Form>
                            {["radio"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3 mt-1">
                                <Form.Check
                                  inline
                                  label="Male"
                                  name="gender"
                                  value="Male"
                                  checked={userData.gender == "Male"}
                                  type={type}
                                  id={`inline-${type}-1`}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      gender: e.target.value,
                                    })
                                  }
                                />
                                <Form.Check
                                  inline
                                  label="Female"
                                  value="Female"
                                  checked={userData.gender == "Female"}
                                  name="gender"
                                  type={type}
                                  onChange={(e) =>
                                    setUserData({
                                      ...userData,
                                      gender: e.target.value,
                                    })
                                  }
                                  id={`inline-${type}-2`}
                                />
                              </div>
                            ))}
                          </Form>
                          {profileError && profileError.gender && (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              Gender is missing
                            </h1>
                          )}
                        </div>

                        {/* <h2 className="colorGreen font-medium text-base text-right">
                        Forgot Password ?
                      </h2> */}
                      </Stack>
                    </fieldset>
                  </div>
                  <div className="webkit">
                    <button
                      className="btn btn--form py-2 font-semibold  w-full"
                      type="submit"
                      value="Log in"
                      onClick={handleEditProfile}
                    >
                      {loading ? (
                        <RingLoader loading={loading} size={30} color="black" />
                      ) : (
                        "Edit Profile"
                      )}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            {/* <Row className="align-items-center mt-4">
              <Col lg="6">
                <div className="p-2">
                  <SimpleGrid>
                    <h2 className="colorGreen font-extrabold text-4xl">
                      Sawari Advantages
                    </h2>
                    <List py={3}>
                      <ListItem className="text-gray-500 text-xl">
                        <ListIcon as={MdCheckCircle} className="text-black" />
                        Booking car services provide a convenient mode of
                        transportation.
                      </ListItem>
                      <ListItem className="text-gray-500 text-xl">
                        <ListIcon as={MdCheckCircle} className="text-black" />
                        Car services save time by reducing the need to navigate
                        traffic,find parking, or wait for public transportation.
                      </ListItem>
                      <ListItem className="text-gray-500 text-xl">
                        <ListIcon as={MdCheckCircle} className="text-black" />
                        Car services operate around the clock, providing
                        transportation services even during late-night or
                        early-morning hours.
                      </ListItem>

                      <ListItem className="text-gray-500 text-xl">
                        <ListIcon as={MdCheckCircle} className="text-black" />
                        Car services typically employ professional drivers who
                        are trained and experienced in providing safe and
                        reliable transportation.
                      </ListItem>
                      <ListItem className="text-gray-500 text-xl">
                        <ListIcon as={MdCheckCircle} className="text-black" />
                        Car services prioritize passenger safety. Vehicles
                        undergo regular inspections, and drivers often go
                        through background checks.
                      </ListItem>
                    </List>
                  </SimpleGrid>
                </div>
              </Col>
            </Row> */}
          </Container>
        </Container>
      </>
    )
  );
};
