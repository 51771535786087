import React from 'react'
import { Login } from './components/Login'

export const LoginMain = () => {
  return (
    <>
    <Login />
    </>
  )
}
