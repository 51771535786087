import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import {
  AiFillCalendar,
  AiOutlineArrowRight,
  AiOutlineSearch,
} from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import loginContext from "../../../Constant/LoginContext/loginContext";
import { useLocation, useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GOOGLE_API_KEY } from "../../../config/GoogleMapKey";
import DateTimePicker from "react-datetime-picker";
import Modals from "../../modal/modal";
import BaseUri from "../../../Constant/BaseUri";
import axios from "axios";

// import "react-datetime-picker/dist/DateTimePicker.css";
// import "react-calendar/dist/Calendar.css";
// import "react-clock/dist/Clock.css";

export const SectionOne = React.forwardRef((props, ref) => {
  const context = useContext(loginContext);
  let { userData, setUserData } = context;
  const [address, setAddress] = useState("");
  const [viaLocation, setViaLocation] = useState("");
  const [dropoffAddress, setDropoffAddress] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [dateClicked, setDateClicked] = useState(false);
  const [timeClicked, setTimeClicked] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  const [formError, setFormError] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [passenger, setPassenger] = useState(0);
  const [pickupCord, setPickupCords] = useState("");
  const [viaCords, setViaCords] = useState("");
  const [dropoffCords, setDropoffCords] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [pickupPlace, setPickupPlace] = useState("");
  const [allAirports, setAllAirports] = useState([]);
  const [googleMapKey, setGoogleMapKey] = useState("");

  const getGoogleMapKey = () => {
    axios.get(`${BaseUri}get_all_api_keys.php`).then((res) => {
      let { data } = res;

      if (data && data.length > 0) {
        let key = data[0]?.api_key;
        setGoogleMapKey(key);
      }
    });
  };

  useEffect(() => {
    getGoogleMapKey();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateClicked(false);
  };
  const handleSelectTime = (time) => {
    setSelectedTime(time);
    setTimeClicked(false);
  };

  const location = useLocation();

  const handleSelect = async (selectedAddress) => {

    setAddress(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);
      setPickupCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };
  const handleViaSelect = async (selectedAddress) => {


    setViaLocation(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);

      setViaCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };
  const handleDropoffSelect = async (selectedAddress) => {


    setDropoffAddress(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);
      setDropoffCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const navigate = useNavigate();

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  const getAllAirports = () => {
    axios
      .get(`${BaseUri}get_all_airports.php`)
      .then((res) => {
        let { data } = res;

        setAllAirports(data);
      })
      .catch((error) => {
        // alert("Internal Server Error");
      });
  };

  useEffect(() => {
    handleCheckUser();
    getAllAirports();
  }, []);

  const handleGetDropoffCords = async () => {
    try {
      const apiKey = googleMapKey;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        dropoffAddress
      )}&key=${apiKey}`;

      const response = await axios.get(apiUrl);

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setDropoffCords({
          lat: lat,
          lng: lng,
        });
      } else {
        console.error("No results found for the given address.");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching geolocation data:",
        error
      );
    }
  };

  console.log(address, "address")


  const handleGetPickupCords = async () => {
    try {
      const apiKey = googleMapKey;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${apiKey}`;
      const response = await axios.get(apiUrl);

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setPickupCords({
          lat: lat,
          lng: lng,
        });
      } else {
        console.error("No results found for the given address.");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching geolocation data:",
        error
      );
    }
  };

  useEffect(() => {
    if (pickupPlace == "location" && dropoffAddress) {
      handleGetDropoffCords();
    } else if (pickupPlace == "airport" && address) {
      handleGetPickupCords();
    }
  }, [pickupPlace, address, dropoffAddress]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const parsedHours = parseInt(hours, 10);

    const period = parsedHours >= 12 ? "PM" : "AM";
    const adjustedHours = parsedHours % 12 || 12;

    return `${adjustedHours}:${minutes} ${period}`;
  };

  const navigateToMapScreen = async () => {
    let error = {};

    if (!address) {
      error.pickupAddress = true;
    }
    if (!dropoffAddress) {
      error.dropoffAddress = true;
    }
    if (!pickupPlace) {
      error.pickupPlace = true;
    }

    if (!selectedDate) {
      error.date = true;
    }

    if (!selectedTime) {
      error.time = true;
    }

    if (!passenger) {
      error.passenger = true;
    }

    if (error && Object.keys(error).length > 0) {
      setFormError(error);

      return;
    }

    setFormError(error);

    let format = new Date(selectedDate);

    let date1 = format.getDate();
    let month1 = format.getMonth();
    let year1 = format.getFullYear();

    let formattedDate = `${date1} / ${month1} / ${year1}`;

    let formattedTime = await convertTo12HourFormat(selectedTime);

    let data = {
      user_id: userData.id,
      booking_date: selectedDate,
      booking_time: selectedTime,
      formattedDate: formattedDate,
      formattedTime: formattedTime,
      booking_pickup: address,
      booking_dropoff: dropoffAddress,
      pickupPlace: pickupPlace,
      booking_via_location: viaLocation,
      pickupCords: pickupCord,
      viaCords: viaCords,
      dropoffCords: dropoffCords,
      passengers: passenger,
      allAirports: allAirports,
    };

    let nowMonth = new Date().getMonth();
    let nowYear = new Date().getFullYear();
    let nowDate = new Date().getDate();

    let date = new Date(selectedDate);

    let month = date.getMonth();
    let year = date.getFullYear();
    let selectDate = date.getDate();

    if (year < nowYear) {
      // alert("Can't choose previous year");
      setModalHeading("Date Alert");
      setModalText("You can't choose previous year to book ride");
      setModalVisible(true);
      return;
    }

    if (year == nowYear && month < nowMonth) {
      setModalHeading("Date Alert");
      setModalText("You can't choose previous month to book ride");
      setModalVisible(true);

      return;
    }

    if (month == nowMonth && year == nowYear && selectDate < nowDate) {
      setModalHeading("Date Alert");
      setModalText("You can't choose previos date to book ride");
      setModalVisible(true);
      return;
    }

    let nowHours = new Date().getHours();
    let hours = selectedTime.slice(0, 2);

    if (month == nowMonth && year == nowYear && selectDate == nowDate) {
      if (nowHours >= Number(hours)) {
        setModalHeading("Time Alert");
        setModalText("selected time must be greater than current time");
        setModalVisible(true);
        return;
      }
    }

    navigate("/MapScreen", { state: data });
  };

  return (
    <Container style={{ backgroundColor: "white" }} fluid className="py-4">
      <Container  >
        <Row className="align-items-end">
          <Col lg="6" xl={8} >
            <Row className="mt-8">
              <Col lg="8">
                <div ref={ref}>
                  <h2 className="xlText smallScreenHeading text-5xl font-extrabold">
                    Airport Transfer
                    <br />
                    <span className="largeText" style={{ color: "#00803E" }}>
                      {" "}
                      Service in Pakistan
                    </span>
                  </h2>
                  <h3 className="marginTop mediumText smallScreenText text-sm font-bold text-black">
                    Book With An Approved, Fixed-price, And Reliable Taxi
                    Company To Pay Online Securely where You Can Even Book
                    Months In Advance.
                  </h3>
                  <button
                    // onClick={() => navigateToAirportScreen()}
                    className="text-white rounded-lg bgGreen py-2 px-5 d-flex gap-2 align-items-center my-3"
                  >
                    Booking Now <BsArrowRight />
                  </button>
                </div>
              </Col>
              <Col lg="4">
                <div className="mt-6">
                  <Image src={require("../../assets/mainAiro.png")} />
                </div>
              </Col>
            </Row>
            <Row className="pt-11">
              <Col lg="12">
                <div>
                  <Image
                    style={{ position: "relative", zIndex: "1",width:"100%" }}
                    src={require("../../assets/homecar.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="6" xl={4} >
            <div className="webkit py-5">
              <div
                className="webkit text-white"
                style={{
                  backgroundColor: "#101010C4",
                  padding: 15,
                  borderRadius: 10,
                }}
              >
                <h2 className="text-lg">
                  <span
                    style={{
                      fontSize: 20,
                      color: "white",
                      textTransform: "uppercase",
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Search now for an instant quote{" "}
                  </span>
                  <br />
                  and unlock the door to seamless travel planning
                  <br />
                </h2>

                <form>
                  {!pickupPlace ? (
                    <div>
                      <div className="About_main_images_search">
                        <select
                          name="format"
                          id="format"
                          className="w-full"
                          // style={{ width: "100%", padding: 10 }}
                          onChange={(e) => setPickupPlace(e.target.value)}
                        >
                          <option selected disabled>
                            Select Pickup Location
                          </option>
                          <option value="location">Your Location</option>
                          <option value="airport">Airport</option>
                        </select>
                      </div>
                      {formError && formError.pickupPlace && (
                        <h1
                          style={{
                            color: "red",
                            fontFamily: "sans-serif",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          choose pickup Place
                        </h1>
                      )}
                    </div>
                  ) : (
                    <div>
                      {pickupPlace == "airport" ? (
                        <div className="About_main_images_search">
                          <select

                            className="w-full"
                            value={address}
                            // style={{ width: "100%", padding: 10 }}
                            onClick={(e) => setAddress(e.target.value)}
                            onChange={(e) => setAddress(e.target.value)}
                          >
                            <option selected disabled>
                              Select Pickup Location
                            </option>

                            {allAirports &&
                              allAirports.length > 0 &&
                              allAirports.map((e, i) => {
                                return (
                                  <option value={e.airport_address}>
                                    {e.airport_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      ) : (
                        <PlacesAutocomplete
                          value={address}
                          onChange={setAddress}
                          onSelect={handleSelect}
                          style={{ zindex: 20 }}
                          googleCallbackName="initMap"
                        // searchOptions={{
                        //   types: ["geocode"], // Limit results to addresses only
                        // }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                          }) => (
                            <div className="autocomplete-input-container">
                              <input
                                {...getInputProps({
                                  placeholder: "Enter Pickup Address",
                                  className: "autocomplete-input",
                                })}
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  color: "black",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                }}
                              />
                              {address && (
                                <div className="autocomplete-dropdown-container">
                                  {suggestions.map((suggestion, index) => (
                                    <div
                                      key={index}
                                      {...getSuggestionItemProps(suggestion)}
                                      className="autocomplete-suggestion"
                                      style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        color: "black",
                                        backgroundColor: "#fff",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                    >
                                      {suggestion.description}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </PlacesAutocomplete>
                      )}
                      {formError && formError.pickupAddress && (
                        <h1
                          style={{
                            color: "red",
                            fontFamily: "sans-serif",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          choose pickup address
                        </h1>
                      )}
                      <PlacesAutocomplete
                        value={viaLocation}
                        onChange={setViaLocation}
                        onSelect={handleViaSelect}
                        style={{ zindex: 20, marginTop: 20 }}
                        googleCallbackName="initMap"
                      // searchOptions={{
                      //   types: ["geocode"], // Limit results to addresses only
                      // }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                        }) => (
                          <div
                            style={{ marginTop: 20 }}
                            className="autocomplete-input-container"
                          >
                            <input
                              {...getInputProps({
                                placeholder: "Enter Via Address",
                                className: "autocomplete-input",
                              })}
                              style={{
                                width: "100%",
                                padding: "10px",
                                color: "black",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                              }}
                            />
                            {viaLocation && (
                              <div className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion, index) => (
                                  <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion)}
                                    className="autocomplete-suggestion"
                                    style={{
                                      padding: "5px",
                                      cursor: "pointer",
                                      color: "black",
                                      backgroundColor: "#fff",
                                      borderBottom: "1px solid #ccc",
                                    }}
                                  >
                                    {suggestion.description}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {formError && formError.viaLocation && (
                        <h1
                          style={{
                            color: "red",
                            fontFamily: "sans-serif",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          choose via address
                        </h1>
                      )}
                      {pickupPlace == "location" ? (
                        <div className="About_main_images_search">
                          <select
                            name="format"
                            id="format"
                            className="w-full"
                            // style={{ width: "100%", padding: 10 }}
                            onChange={(e) => setDropoffAddress(e.target.value)}
                          >
                            <option selected disabled>
                              Select Dropoff Location
                            </option>
                            {allAirports &&
                              allAirports.length > 0 &&
                              allAirports.map((e, i) => {
                                return (
                                  <option value={e.airport_address}>
                                    {e.airport_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      ) : (
                        <PlacesAutocomplete
                          value={dropoffAddress}
                          onChange={setDropoffAddress}
                          onSelect={handleDropoffSelect}
                          style={{ zindex: 20, marginTop: 20 }}
                          googleCallbackName="initMap"
                        // searchOptions={{
                        //   types: ["geocode"], // Limit results to addresses only
                        // }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                          }) => (
                            <div
                              style={{ marginTop: 20 }}
                              className="autocomplete-input-container"
                            >
                              <input
                                {...getInputProps({
                                  placeholder: "Enter Dropoff Address",
                                  className: "autocomplete-input",
                                })}
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  color: "black",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                }}
                              />
                              {dropoffAddress && (
                                <div className="autocomplete-dropdown-container">
                                  {suggestions.map((suggestion, index) => (
                                    <div
                                      key={index}
                                      {...getSuggestionItemProps(suggestion)}
                                      className="autocomplete-suggestion"
                                      style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                        color: "black",
                                        backgroundColor: "#fff",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                    >
                                      {suggestion.description}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </PlacesAutocomplete>
                      )}
                      {formError && formError.dropoffAddress && (
                        <h1
                          style={{
                            color: "red",
                            fontFamily: "sans-serif",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          choose dropoff address
                        </h1>
                      )}
                    </div>
                  )}

                  <div>
                    <Row>
                      <Col xl="6" lg="6" sm="12" md="12" xs="12">
                        <div
                          style={{ Width: "100%" }}
                          className="About_main_images_search"
                        >
                          <input
                            placeholder="Pickup Date"
                            type={"date"}
                            style={{
                              Width: "100%",
                              fontFamily: "sans-serif",
                              fontSize: 16,
                              color: "gray",
                              fontWeight: "500",
                            }}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            className="border-white"
                          />
                        </div>

                        {formError && formError.date && (
                          <h1
                            style={{
                              color: "red",
                              fontFamily: "sans-serif",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            choose pickup date
                          </h1>
                        )}
                      </Col>

                      <Col xl="6" lg="6" sm="12" md="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Pickup Date"
                              type={"time"}
                              style={{
                                Width: "100%",
                                fontFamily: "sans-serif",
                                fontSize: 16,
                                color: "gray",
                                fontWeight: "500",
                              }}
                              onChange={(e) => setSelectedTime(e.target.value)}
                              className="border-white"
                            ></input>
                            <AiFillCalendar
                              size={25}
                              className="text-black border-rounded"
                            />
                          </div>
                        </fieldset>
                        {formError && formError.time && (
                          <h1
                            style={{
                              color: "red",
                              fontFamily: "sans-serif",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            choose pickup time
                          </h1>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <fieldset>
                    <div className="About_main_images_search">
                      <select
                        onChange={(e) => setPassenger(e.target.value)}
                        name="format"
                        id="format"
                        className="w-full"
                      >
                        <option selected disabled>
                          Passenger Count
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>

                      {/* <Button>Search</Button> */}
                    </div>
                  </fieldset>
                  {formError && formError.passenger && (
                    <h1
                      style={{
                        color: "red",
                        fontFamily: "sans-serif",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      choose passenger count
                    </h1>
                  )}

                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      fontFamily: "sans-serif",
                      color: "#ffcc00",
                    }}
                  >
                    {" "}
                    Note: You have to make a seperate booking for return
                    journey.
                  </span>

                  {/* <div onClick={()=>routeToMap()} className="my-3 flex justify-end items-center">
                    <button  className="text-white rounded-l-lg bgGreen py-2 px-4 d-flex">
                      Booking Now
                    </button>
                    <BsArrowRight className="text-white bg-black text-4xl  rounded-r-lg p-1" />
                  </div> */}
                </form>

                <button
                  onClick={() => navigateToMapScreen()}
                  style={{
                    marginTop: 15,
                    padding: 7,
                    borderRadius: 10,
                    backgroundColor: "#00803E",
                    width: 150,
                    alignSelf: "flex-end",
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </Col>
          {modalVisible && (
            <Modals
              visible={modalVisible}
              heading={modalHeading}
              text={modalText}
              close={() => setModalVisible(false)}
            />
          )}
        </Row>
      </Container>
    </Container>
  );
});
