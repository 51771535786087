import React from "react";
import { SignupMain } from "../Signup";

export const Signup = () => {
  return (
    <>
      <SignupMain />
    </>
  );
};
