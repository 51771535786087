import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

export const SectionFive = () => {
  return (
    <Container>
      <Row className="align-items-center">
        <Col lg="6">
          <div className="p-2">
            <Row className="justify-center">
              <Col lg="6" sm="6" xs="6">
                <div className="p-2 webkit justify-center">
                  <div
                    className="bg-black webkit d-flex flex-col align-items-center justify-center widthres"
                    style={{ border: "2px solid green" }}
                  >
                    <h2 className="largeText colorGreen font-bold">1000+</h2>
                    <p className="mediumText font-semibold text-white">Vehicle in Stock</p>
                  </div>
                </div>
              </Col>
              <Col lg="6" sm="6" xs="6" className="resmar">
                <div className="p-2 webkit justify-center">
                  <div
                    className="bg-black webkit d-flex flex-col align-items-center justify-center widthres"
                    style={{ border: "2px solid green" }}
                  >
                    <h2 className="largeText colorGreen font-bold">5000+</h2>
                    <p className="mediumText font-semibold text-white">
                      Satisfied Customers
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-center">
              <Col lg="6" sm="6" xs="6">
                <div className="p-2 webkit justify-center">
                  <div
                    className="bg-black webkit d-flex flex-col align-items-center justify-center widthres"
                    style={{ border: "2px solid green" }}
                  >
                    <h2 className="largeText colorGreen font-bold">10+</h2>
                    <p className="mediumText font-semibold text-white">Top Brands</p>
                  </div>
                </div>
              </Col>
              <Col lg="6" sm="6" xs="6" className="resmar">
                <div className="p-2 webkit justify-center">
                  <div
                    className="bg-black webkit d-flex flex-col align-items-center justify-center widthres"
                    style={{ border: "2px solid green" }}
                  >
                    <h2 className="largeText colorGreen font-bold">100+</h2>
                    <p className="mediumText font-semibold text-white">Location</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg="6">
          <Image src={require("../../assets/vigo.png")} />
        </Col>
      </Row>
    </Container>
  );
};
