import React from 'react'
import Footer from './components/Footer'

export const Footermain = () => {
  return (
    <>
    <Footer />
    </>
  )
}

