import { Col, Container, Row } from "react-bootstrap";

function TermsAndCondition() {
  return (
    <Container fluid>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <div style={{ minHeight: "100vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 20,
                flexDirection: "column",
              }}
            >
              <img
                src={require("../assets/LOGO.png")}
                style={{ width: 250, height: 100 }}
              />

              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  marginTop: 20,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Terms And Condition Page
              </h1>
            </div>

            <div style={{ padding: 20 }}>
              <dl style={{ listStyle: "decimal" }}>
                <dt style={{ fontSize: 18, fontWeight: "bold" }}>
                  1. IMPORTANT
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      1.1. Sawari Airport Transfers (SMC- Private) Limited is a
                      Pakistan registered company which operates in
                      collaboration with Sawari Pakistan Limited which is UK
                      registered company. Both companies share domain name
                      <a
                        href="https://www.sawaripakistan.com"
                        style={{ color: "blue" }}
                      >
                        {" "}
                        www.sawaripakistan.com{" "}
                      </a>{" "}
                      to generate bookings.
                    </p>
                  </div>
                </dd>

                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  2. GENERAL
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        2.1 If You book a taxi, minicab, minibus or coach via
                        this website, You agree to the terms below as the
                        exclusive basis which governs such booking by ticking
                        the "I agree to Sawari Pakistan Terms and Conditions".
                        If you are booking on behalf of someone else, by
                        clicking "I agree to Sawari Pakistan Terms &
                        Conditions", You are representing that You have their
                        authority to accept these terms and conditions on their
                        behalf.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        2.2. Deviations from these Terms are only valid if and
                        to the extent that these are confirmed explicitly and in
                        writing by Sawari Pakistan Limited.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        2.3. Should any provision of these Terms be pronounced
                        invalid or in violation of public order or law by the
                        competent judge, then only the provision in question
                        will be void, but the rest of these Terms will remain
                        fully in effect. Instead of the invalid provision, a
                        provision will apply that best approached the intention
                        of the parties.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        2.4. Sawari Pakistan Limited shall act as an agent on
                        Supplier’s behalf in establishing a contract between YOU
                        and the Supplier. Sawari Pakistan Limited will never
                        become a party to a contract for the provision of
                        transport service by Supplier to You. The contract for
                        the provision of the transport service is directly
                        between You and Supplier. The Services shall only be
                        account of the Supplier at the prices and the conditions
                        fixed by the Supplier.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        2.5. Where in the Booking You have selected more than
                        one Supplier to provide Your Trips, then the contract
                        for the provision of the transport service relating to
                        each Trip is directly between You and the respective
                        Supplier you booking with.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  3. USE OF WEBSITE
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        3.1 In making a Booking via this Website You must
                        provide accurate Details and are responsible for any
                        failure to do so.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        3.2. You hereby warrant to Sawari Pakistan Limited and
                        the Supplier that You are at least eighteen years of age
                        and legally able to make the booking
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        3.3. Sawari Pakistan Limited does not guarantee that it
                        will be able to fulfil Your requirements and reserves
                        the rights to refuse any Bookings which You wish to make
                        and to cancel any Bookings You have made, and in such
                        case to refund to You, in full and final settlement of
                        any claims you might have, the Fee paid by You.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        3.4. Your Details and data relating to Your use of the
                        Website will be recorded by Sawari Pakistan Limited but
                        Your Personal Data shall not, subject to the provisions
                        of the Sawari Pakistan Limited Privacy Policy presented
                        below and Clause 3.5 below, be disclosed to third
                        parties other than the Supplier or used for any purpose
                        unrelated to the provision of the services pursuant
                        hereto
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        3.5. Sawari Pakistan Limited may send a small file to
                        Your computer when You visit the Website. This "cookie"
                        will enable to track Sawari Pakistan Limited Your
                        behaviour on the Website and to identify particular
                        areas of interest so as to enhance Your future visits to
                        the Website. The cookie will enable Sawari Pakistan
                        Limited to identify You and Sawari Pakistan Limited
                        shall not use it otherwise than in relation to this
                        Website. You can set Your computer browser to reject
                        cookies but this may preclude use of certain parts of
                        this Website Sawari Pakistan Limited may disclose Your
                        Personal Data if compelled to do so by law, or at the
                        request of a law enforcement agency or governmental
                        authority.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  4. BOOKING
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        4.1 When You enter the details of the Trip, Sawari
                        Pakistan Limited will endeavour to provide You via the
                        Website with Quotes from suppliers that are able to
                        fulfil Your requirement. The Quotes are the current
                        special "one off" offers available from suppliers, which
                        are only available at the time of making Your Booking.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.2. It is Your responsibility to ensure that Your
                        Details and Trip are correct and accurate by providing,
                        but not limited to; selecting a vehicle suitable for
                        your luggage requirements, a valid postcode which also
                        does not commit a traffic violation, correct date and
                        time for the journeys to take place, correct customer
                        name, correct and valid email address, correct and valid
                        telephone number. If the Trip details are inaccurate and
                        an invalid or no postcode or a postcode which causes a
                        traffic violation is provided or an; incorrect; date,
                        time, customer name, email address, telephone number
                        provided you may incur additional charges and may not be
                        due a refund, See 7.10. When You make a booking, this
                        constitutes an offer which Sawari Pakistan Limited may
                        or may not accept.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.3. In the event, you need to amend or correct a
                        booking you most contact Sawari Pakistan Limited
                        directly. An administration fee will be charged on all
                        booking amendments. If there is a price increase caused
                        by any change you request to the Services that you have
                        booked, you will be required to pay the difference in
                        the price between the services originally booked by you,
                        and the price of the new services you have requested. If
                        there is a price decrease caused by any change you
                        request to the Services that you have booked, you will
                        be refunded the difference in the price between the
                        services originally booked by you, and the price of the
                        new services you have requested minus the admin charge.
                        This charge will be applied to your original payment
                        method.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.4. Any Booking You make may also be subject to the
                        Supplier T&C, although where there is any conflict
                        between the Supplier T&C and the Terms, the provisions
                        contained in the Terms override those in the Supplier
                        T&C.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.5. If the Supplier wishes to impose the Supplier T&C
                        on You, it shall be responsible for achieving this
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.6. Sawari Pakistan Limited cannot confirm the ability
                        of the Supplier to accommodate any Particular
                        Requirements and in the event that You have such
                        Particular Requirements You should not use this Website
                        but instead make alternative travel arrangements that
                        address your Particular Requirements.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.7. Sawari Pakistan Limited reserves the right to
                        correct typographical errors in any elements of the
                        information that appears on the Website including
                        pricing mistakes. If, once You are informed of such
                        error, You wish to withdraw Your offer and do not wish
                        to proceed with the purchase, You shall be free to do so
                        and any monies already paid by You will be refunded in
                        full.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.8. You agree that in the fulfilment of the Booking,
                        where the Supplier feels it is appropriate, the Supplier
                        gives Sawari Pakistan Limited the right to novate the
                        contract for the provision of transport services between
                        You and the Supplier to another suitable party, to
                        ensure the fulfilment of the Booking as effected via
                        this Website.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.9. Sawari Pakistan Limited reserves the right to
                        change the type of vehicle you have booked, to ensure
                        the fulfilment of the Booking. With large vehicles
                        subject to availability, in the event the Supplier is no
                        longer able to provide their vehicle, Sawari Pakistan
                        Limited will endeavour to ensure You are offered
                        alternatives of a similar type and class.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        4.10 Sawari Pakistan Limited reserves the right to use
                        third party companies such as Uber, Careem and local
                        supplier to ensure the fulfilment of the Booking, in the
                        case that the original Supplier is unable to fulfill the
                        Booking. For avoidance of doubt, You certify and agree
                        to receive SMS messages from Uber, Careem and local
                        supplier regarding any trip and You give consent to
                        Uber, Careem and local supplier to share the rider's
                        information, including trip status, with Sawari Pakistan
                        Limited.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  5. PAYMENT
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        5.1 When booking with the ‘Pay by Card’ option, You
                        agree to pay the Fare at the time You make Your Booking.
                        Sawari Pakistan Limited is entitled to collect all
                        monies due for the booked Trip from You acting entirely
                        and settle all Card Costs of the booked Trip on behalf
                        of the Supplier, in advance of making the payment due to
                        the Supplier for the booked Trip as the Supplier’s
                        agent. 5.2.When paying by Card you agree to Sawari
                        Pakistan Limited using an authorisation token on your
                        Card to allow us to charge your Card in event of any
                        amendments to your Booking. Sawari Pakistan Limited will
                        not store any of your Card details. In the event that
                        Your Card payment is refused by Your Card issuer for
                        whatever reason, Sawari Pakistan Limited will not be
                        able to fulfil Your Booking.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        5.2. When booking with the ‘Pay by Cash’ option, You
                        agree to pay the Fare to the Supplier’s driver. When
                        booking with the ‘BACS’ option, You agree to pay the
                        Fare to the Supplier’s bank account. When booking with
                        the ‘Credit Balance’ option, You agree to pay the Fare
                        to Sawari Pakistan Limited nominated bank account.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        5.3. Once You have confirmed Your Booking on the payment
                        screen, it cannot be cancelled or changed except in
                        accordance with clause 7 of these Terms
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        5.4. If paying by Card, in the event that Your Card
                        payment is refused by Your Card issuer for whatever
                        reason, Sawari Pakistan Limited will not be able to
                        fulfil Your Booking.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        5.5. Sawari Pakistan Limited will provide You with a
                        receipt for Payment by email to the email address you
                        registered with the Booking.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        5.6. Payment will not cover any extra requirements you
                        request, such as child seats. If you do not indicate any
                        additional luggage when booking, resulting in a larger
                        size vehicle being required, then the Supplier may
                        charge you extra, which you must pay them directly for.
                        If You wish to give a tip to the driver, You should do
                        so directly at your own discretion.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        5.7. In the event that You make any deviations from the
                        route that You entered into the Website, You may be
                        subject to additional charges by the Supplier in respect
                        thereof and will make such payment directly to the
                        Supplier.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        5.8. Sawari Pakistan Limited offer no warranties on
                        payments made direct to the Supplier with Cash or Bank
                        Transfers.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  6. INSURANCE
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        6.1 Neither Sawari Pakistan Limited nor the Supplier is
                        responsible to You for, or insured in respect of, any
                        damage or loss that may be sustained by the Goods in the
                        course of a Trip and it is Your exclusive responsibility
                        to have such Goods insured in respect of such damage or
                        loss.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        6.2 Travellers are responsible to carry out their own travel insurance policy
                        which will cover them for any accidents and medical.

                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  7. CANCELLATION OF BOOKING AND REFUNDS
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        7.1 Cancellations must be made by you using our website.
                        You will be entitled to a refund, depending on when you
                        cancel your Booking.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.2 Customer(s) who paid a deposit will be refunded or
                        charged depending on the amount due as per condition.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.3 At Sawari Pakistan Limited discretion, a
                        cancellation charge may be refunded on the basis that
                        You re-book within 24 (Twenty Four) hours, minus
                        administration charge. Only once the Journey is
                        re-booked, the refund can be processed. However, Sawari
                        Pakistan Limited reserves the right to refuse a
                        cancellation refund at any time.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.4 In the event that the Supplier does not turn up at
                        the Agreed Pick-Up Point within 30 minutes of the Agreed
                        Time - or within 60 minutes if the Agreed Pick-up Point
                        is an airport - and You do not use the Supplier for the
                        Trip, You will be entitled to a full refund of the Fare,
                        along with any Card Costs, if you paid them in advance
                        by Card, as full and final settlement of any claim You
                        might have in respect of such failure. However should
                        You, at Your own discretion, decide to continue with the
                        Trip where the Supplier turns up after 30 minutes from
                        the Agreed time, then you will not be entitled to any
                        such refund.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.5 In the event that You are not available at the
                        Agreed Pick-Up Point within 15 minutes of the Agreed
                        Time, it will be at the Supplier’s discretion to treat
                        Your non-availability as a cancellation, and You will
                        not be entitled to any refund of the Fare , and any Card
                        Costs that you may have paid in advance. Where the
                        Supplier decides to wait more than 15 minutes from the
                        Agreed Time, then You may be subject to additional
                        charges by the Supplier in respect of any additional
                        waiting time and/or parking charges and will make such
                        payment directly to the Supplier.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.6 In the event that you do not wish to use the
                        Services for any reason and do not cancel the Booking
                        using the website or by contacting{" "}
                        <a href="https://www.sawaripakistan.com">
                          www.sawaripakistan.com
                        </a>{" "}
                        , no refunds will be provided.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.7 In the event that you wish to cancel a return leg of
                        your journey, you will not be entitled to any refund.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.8 For pre-paid Bookings, any refunds due to you to for
                        cancellations or complaints will be made to the card
                        used to make the payment within up to 5 business days
                        (business days being Monday to Friday).
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.9 Customers with credit accounts or account balances
                        will be exempt from cancellation charges.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.10 In the event of a Booking cancellation or no show
                        by a Supplier, you must contact Sawari Pakistan Limited
                        within 7 days to issue your refund.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        7.11 In the event that You provide inaccurate details
                        including but not limited to an invalid or missing;
                        postcode, postcode causing traffic violation, customer
                        name, email address, telephone number, you will not be
                        entitled any refund and may incur additional charges
                        from the Supplier in order to complete the Trip.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  8. WARRANTY AND LIMITATION OF LIBRARY
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        8.1 You acknowledge and accept that Sawari Pakistan
                        Limited is acting as the agent of the Supplier and that
                        whilst Sawari Pakistan Limited will try to assist with
                        any complaints You may have in respect of the Supplier’s
                        services, any legal remedy You seek will be sought
                        against the Supplier not Sawari Pakistan Limited.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.2 The Supplier has warranted to Sawari Pakistan
                        Limited that it is licensed by the appropriate Licensing
                        Authority(ies) but Sawari Pakistan Limited shall not be
                        responsible for verification of such warranty and if You
                        are in any doubt, You should contact the Private Hire
                        Operator and rely on Your own verification.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.3 Sawari Pakistan Limited shall not be liable for any
                        delays in pick-up or drop-off times or for any failure
                        of the Supplier to arrive at all or for any failure of
                        the Supplier to provide its services in accordance with
                        Your requirements or expectations.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.4 Sawari Pakistan Limited shall not in any event be
                        liable (whether in contract or otherwise) for any
                        indirect loss or consequential loss or loss of revenue,
                        howsoever arising, suffered by You in connection with
                        any failure by the Supplier or otherwise in connection
                        with these Terms or otherwise and if You consider that
                        You have any claim in respect of any failure by the
                        Supplier You agree to make such claim directly against
                        the Supplier.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.5 Sawari Pakistan Limited aggregate liability in
                        respect of these Terms and any matter arising out of it
                        (including claims whether made in contract or tort)
                        shall be limited to £100.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.6 Subject as expressly provided in these Terms all
                        warranties, conditions or other terms implied by statute
                        or common law are excluded as far as legally possible.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.7 Nothing in this clause shall operate so as to
                        exclude any rights You may have under consumer
                        legislation or limit the Supplier's liability for death
                        or personal injury arising out of its negligence.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.8 Any estimated trip times and times of arrival
                        presented by Sawari Pakistan Limited on its Public
                        website or otherwise, are only to be taken as
                        indicative, and are not subject to traffic and/or
                        weather conditions and final route taken by driver.
                        Therefore, You agree that You are responsible for
                        setting an appropriate Agreed Time that allows You
                        sufficient time to reach Your destination to
                        subsequently check-in for a flight, take a train or
                        fulfil any other dependencies You might have.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.9 Sawari Pakistan Limited may keep You informed of the
                        status of your Booking by sending You email, and SMS
                        text alerts, although Sawari Pakistan Limited does not
                        guarantee the delivery nor the timing of these email and
                        SMS text alerts, and shall not in any event be liable
                        (whether in contract or otherwise) for any indirect loss
                        or consequential loss or loss of revenue, howsoever
                        arising from the omission or delay of such email and SMS
                        text alerts.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        8.10 Each foregoing sub-clause of this clause shall
                        constitute a separate and severable provision. The
                        provisions of this clause shall continue in effect
                        notwithstanding the termination, completion or any other
                        matter which might otherwise cause these Terms to become
                        ineffective.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  9. BEHAVIOUR
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        9.1 The Supplier reserves the right within its
                        reasonable discretion to terminate the Trip, if You or
                        Your party's conduct or behaviour is disruptive in any
                        way and/or affects your safety or that of the driver of
                        the vehicle. Neither Sawari Pakistan Limited nor the
                        Supplier accepts liability for any extra costs incurred
                        by You/or Your party as a result of the Supplier doing
                        so.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        9.2 Passengers are not permitted to take alcoholic
                        drinks onto the vehicles for the purpose of consuming
                        them during the Trip.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        9.3 The Supplier further reserves the right to refuse
                        carriage to any person who is thought to be under the
                        influence of alcohol or drugs.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  9. LIMITATION AND EXCLUSIONS
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        10.1 The Supplier shall not undertake the carriage or
                        delivery of:
                        <ul>
                          <li style={{ listStyle: "circle" }}>
                            money or securities, antiques, precious metals,
                            furs, or jewellery or value, any goods or property
                            (of whatsoever nature) of an intrinsic value of more
                            than £10.
                          </li>
                          <li style={{ listStyle: "circle" }}>
                            any goods or property of a hazardous, dangerous,
                            inflammable, explosive or noxious nature, or are
                            illegal to possess under existing English Law,
                            and/or
                          </li>
                          <li style={{ listStyle: "circle" }}>
                            any goods or property (of whatsoever nature) which
                            may deteriorate in transit.
                          </li>
                        </ul>
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        10.2 The Supplier shall have no liability whatsoever for
                        loss or damage, however arising in relation to the
                        clause 10.1 of this agreement.
                      </p>

                      <p style={{ fontWeight: "500" }}>
                        10.3 Without prejudice to the provisions of clause the
                        Supplier shall not in any event be liable directly or
                        indirectly for:
                        <ul>
                          <li style={{ listStyle: "circle" }}>
                            consequential loss (whether for loss or profit or
                            otherwise); and/or
                          </li>
                          <li style={{ listStyle: "circle" }}>
                            loss, damage and/or breakage to any fragile items
                            whatsoever whether arising from the acts, omissions
                            or negligence of the Supplier and/or its employees
                            and/or agents or arising otherwise howsoever.
                          </li>
                        </ul>
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        10.4 Without prejudice to the generality of clauses 10.1
                        and 10.2 in particular the Supplier shall not be liable
                        for any loss and/or damage arising directly or
                        indirectly from:
                        <ul>
                          <li style={{ listStyle: "circle" }}>
                            breakdown, accident, adverse weather conditions.
                          </li>
                          <li style={{ listStyle: "circle" }}>
                            any act or omission on the part of the Customer.
                          </li>
                          <li style={{ listStyle: "circle" }}>
                            any clause, act or circumstance beyond the control
                            of the Supplier (including, without limitation, any
                            strike, (official or not) lock-out or other form of
                            industrial action or labour dispute, governmental
                            regulations, legal restrictions, embargoes, fire,
                            flood, Act of God, any consequence of riot, war,
                            invasion, act of foreign enemy, hostilities (whether
                            war be declared or not) civil war, acts of
                            terrorism, rebellion, military or usurped power,
                            confiscation, requisition or destruction of or
                            damage to property by or upon the order of or in the
                            name of any Government or public local authority, or
                            other conditions amounting to force majeure).
                          </li>
                        </ul>
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        10.5 The provision of clauses 10.1, 10.2, 10.3, 10.4 and
                        11.1 apply to liability for loss or damage to goods or
                        property and do not apply to liability for death or
                        personal injury.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        10.6 By using the website you agree that use of the site
                        is non-exclusive.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        Every time a booking is generated, you agree at the date
                        and time of the booking, that the terms & Conditions
                        take precedence over any previous agreements or your own
                        terms and conditions.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  11. DISPUTES
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        11.1 If You have any complaint in respect of the service
                        provided by the Supplier, You agree to first contact the
                        Supplier directly (by the phone or email) to resolve
                        this with them within 24 hours of the Agreed Time.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        11.2 If you are subsequently unable to resolve your
                        complaint with the Supplier, you agree to notify Sawari
                        Pakistan Limited thereof via the form on the Contact Us page or
                        by email to{" "}
                        <a
                          href="mailto:hello@SawariPakistan.com"
                          style={{ color: "blue" }}
                        >
                          {" "}
                          hello@SawariPakistan.com
                        </a>{" "}
                        within 72 hours of the Agreed Time. In the event that
                        You fail to do so, any claim You might otherwise have
                        shall be invalidated.
                      </p>

                      <p style={{ fontWeight: "500" }}>
                        11.3 If Sawari Pakistan Limited is then unable to
                        resolve any claim You may have in respect of the service
                        provided by the Supplier to the mutual satisfaction of
                        You and the Supplier, Sawari Pakistan Limited shall be
                        entitled to submit such claim to the determination of an
                        independent third party selected by Sawari Pakistan
                        Limited and You agree to be bound by the decision of
                        such third party.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  12. PRIVACY POLICY
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        12.1 If Sawari Pakistan Limited will maintain the
                        confidentiality of Personal Data collected as part of
                        making a Booking. This data will not be shared with
                        third parties without Your consent. Sawari Pakistan
                        Limited may amend this Privacy Policy, and will make
                        such changes public via the Website.
                      </p>
                    </div>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  13. MISCELLANEOUS
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        13.1 You warrant that You have not relied on any
                        representation made by Sawari Pakistan Limited in
                        entering into the agreement with the Supplier.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        13.2 Failure by either party at any time to enforce any
                        right claim or provision of these Terms or arising
                        hereunder shall not be construed as a waiver of such
                        right, claim or provision.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        13.3 All notices to Sawari Pakistan Limited shall be
                        given by You in writing to{" "}
                        <a
                          href="mailto:hello@SawariPakistan.com"
                          style={{ color: "blue" }}
                        >
                          hello@SawariPakistan.com
                        </a>
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        13.4 All notices to You shall be given in writing to the
                        email address You have provided as part of the Details.
                        All notices may be served by email and shall be deemed
                        to have been given 1 hour after transmission thereof.
                      </p>
                      {/* <p style={{ fontWeight: "500" }}>
                        13.5 European law applies to all transactions for which
                        these Terms apply, with the exclusion of the provisions
                        of international treaties, to the extent these do not
                        contain imperative law.
                      </p> */}
                    </div>
                  </div>
                </dd>
              </dl>
            </div>

            {/* <div style={{ padding: 20 }}>
              <h1
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Overview
              </h1>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 5,
                }}
              >
                Welcome to Sawari Pakistan Limited, a leading transportation and
                tourism service provider operating in collaboration with Sawari
                Airport Transfers (SMC-Private) Limited. With a strong presence
                in both the United Kingdom and Pakistan, we pride ourselves on
                delivering outstanding airport transfer services, vehicle
                rentals, and exceptional tourism packages all across Pakistan.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Our commitment to excellence and customer satisfaction has led
                us to become a trusted choice for locals and international
                travelers alike. With a wealth of experience in the industry, we
                understand the importance of seamless and reliable
                transportation solutions, as well as creating unforgettable
                experiences for those exploring the beauty of Pakistan.
              </p>

              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                At Sawari Pakistan Limited, we strive to exceed expectations
                through our comprehensive range of services. Whether you need a
                convenient airport transfer, reliable vehicle rental for your
                travel needs, or a tailored tourism package to explore the
                hidden gems of Pakistan, we have you covered
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                As technology-driven professionals, we have developed a dynamic
                website and mobile applications that allow you to access instant
                quotations. With just a few simple details, our user provide you
                with accurate pricing information, ensu way
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "550",
                  marginTop: 10,
                }}
              >
                As the sole owner of these companies, my name is Mr. Mohammad
                Asif. With a deep passion for providing exceptional services, I
                have devoted my time and expertise to ensuring that both Sawari
                Pakistan and Sawari Airport Transfers (SMC-Private) Limited are
                synonymous with reliability, professionalism, and customer
                satisfaction.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Our team of dedicated and experienced professionals are always
                available to assist you, whether you have queries about our
                services or need guidance in planning your perfect travel
                itinerary. We take great pride in offering personalized support
                and ensuring that every customer's individual needs are met with
                care and attention to detail.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                We believe in promoting the beauty, cultural richness, and
                diversity of Pakistan. Our commitment to sustainable tourism
                practices ensures that our operations contribute positively to
                the local communities we serve. By choosing our services, you
                are not only experiencing the best that Pakista supporting
                responsible and ethical travel.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Thank you for choosing Sawari Pakistan Limited and Sawari
                Airport Transfers (SMC-Private) Limited. We look forward to
                being a part of your memorable journey, creating unforgettable
                experiences, and being your trusted partner in transportation
                and tourism services throughout Pakistan!
              </p>
            </div> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default TermsAndCondition;
