import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const RentBookDes = () => {


  const location = useLocation()

  let {state} = location



  return (
    <>
      <Container>
        <Row>
          <Col lg="6">
            <h2 className="text-2xl py-2 font-semibold">Description</h2>
            <p>
              {state.car_description}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RentBookDes;
