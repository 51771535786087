import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Image, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
import BaseUri from "../../../Constant/BaseUri";
import { Login } from "../../Screens/Login";
import loginContext from "../../../Constant/LoginContext/loginContext";
import { useNavigate } from "react-router-dom";

export const SectionFour = () => {
  const navigate = useNavigate();

  const [rentCars, setRentCars] = useState([]);

  const context = useContext(loginContext);

  let { userData } = context;

  const getRentCars = () => {
    axios
      .get(`${BaseUri}get_rent_cars.php`)
      .then((res) => {

        // const API_KEY = "0353612d1ee85cdb7ac032026472206f";
        // // const baseCurrency = "PKR";

        // // Make a request to the API
        // axios
        //   .get(
        //     `https://api.exchangeratesapi.io/v1/latest?access_key=${API_KEY}`)
        //   .then((response) => {
        //     // const exchangeRates = response.data.rates;

        //     console.log(response,"ratesss")

        //     // Now you can perform your currency conversions using these rates
        //   })
        //   .catch((error) => {
        //     console.error("Error fetching exchange rates:", error);
        //   });

        if (res?.data?.length > 0) {
          setRentCars(res?.data ? res.data : []);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    getRentCars();
  }, []);


  const navigateToBookRentCars = (car) => {
    if (userData && Object.keys(userData).length > 0) {
      navigate("/RentBooking", { state: car });
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Container fluid>
        <Container>
          <div className="pb-2 pt-5">
            <h2 className="smallScreenHeading colorGreen text-4xl font-extrabold">
              Vehicle Rentals
            </h2>
            <p className="smallScreenText text-black text-lg font-semibold">
              Ready to embark on an incredible travel experience? Discover
              hassle-free chauffeured vehicle rentals,
              <br />
              and unforgettable tourism packages. Get an instant online
              quotation, conveniently pay with your card, and
              <br />
              secure your spot in advance.
            </p>
          </div>
          {rentCars && rentCars.length > 0 && <Row>
            <Swiper
              id="mm1"
              // install Swiper modules
              modules={[Navigation, A11y, Pagination]}
              spaceBetween={50}
              slidesPerView={3}
              loop={true}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                "@1.00": {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                "@1.50": {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
            >
              {rentCars &&
                rentCars.length > 0 &&
                rentCars.map((e, i) => {
                  return (
                    <SwiperSlide>
                      <div
                        style={{ backgroundColor: "rgba(220,220,220,0.6)" }}
                        className="text-center  border-black rounded-md"
                      >
                        <div className="rounded-md">
                          <img
                            src={`https://smaztech.com/sawari/${e.car_photo}`}
                            alt="Image"
                          />
                        </div>
                        <div className="p-2 webkit justify-center">
                          <h2 className="font-semibold text-xl text-black">
                            {e.car_title}
                          </h2>
                          <Row className="justify-center rrr py-2">
                            <Col>
                              <div>
                                <h2 className="text-lg text-black">
                                  PKR{" "}
                                  <span className="colorGreen font-extrabold text-2xl">
                                    {new Intl.NumberFormat("en-PK", {
                                      style: "currency",
                                      currency: "PKR",
                                      maximumFractionDigits: 0,
                                    }).format(Number(e?.car_per_day_charges))}
                                  </span>
                                </h2>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <h2 className="text-lg text-black">
                                  GBP{" "}
                                  <span className="colorGreen font-extrabold text-2xl">
                                    {e?.car_per_day_charges_gbp.toLocaleString(
                                      "en-GB",
                                      {
                                        style: "currency",
                                        currency: "GBP",
                                      }
                                    )}
                                  </span>
                                </h2>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <h2 className="text-lg text-black">
                                  USD{" "}
                                  <span className="colorGreen font-extrabold text-2xl">
                                    {e?.car_per_day_charges_usd.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                      }
                                    )}
                                  </span>
                                </h2>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <hr className="hr" />
                        <Row className="p-2 justify-center align-items-center">
                          <Col className="text-left">
                            <p className="text-black font-medium">
                              Approximately per day price
                            </p>
                          </Col>
                          <Col className="text-right">
                            <button
                              onClick={() => navigateToBookRentCars(e)}
                              style={{ backgroundColor: "#00803E" }}
                              className="btn text-white font-semibold"
                            >
                              Book Now
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Row>}
        </Container>
      </Container>
    </>
  );
};
