import axios from "axios";
import React, { useContext, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BaseUri from "../../../Constant/BaseUri";
import { useStatStyles } from "@chakra-ui/react";
import loginContext from "../../../Constant/LoginContext/loginContext";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation, A11y, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const OurBestCars = React.forwardRef((props, ref) => {
  const [seater, setSeater] = React.useState("3");

  const userCont = useContext(loginContext);
  let { userData, setUserData } = userCont;

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  React.useEffect(() => {
    handleCheckUser();
  }, []);

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getRentCars = () => {
    axios
      .get(`${BaseUri}get_rent_cars.php`)
      .then((res) => {
        let { data } = res;

        let seaterFilter =
          data &&
          data.length > 0 &&
          data.filter((e, i) => {
            return Number(e.car_seater) >= Number(seater);
          });

        let sortData =
          seaterFilter &&
          seaterFilter.length > 0 &&
          seaterFilter.sort(
            (a, b) =>
              Number(a.car_per_day_charges) - Number(b.car_per_day_charges)
          );

        setData(sortData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getRentCars();
  }, [seater]);

  const navigateToRentCarBooking = (car) => {
    navigate("/RentBooking", { state: car });
  };

  return (
    <Container fluid>
      <Container className="py-4">
        <Row className="py-4 align-items-center">
          <Col lg="6">
            <div className="p-2">
              <h2 className="text-4xl colorGreen font-bold">
                Sawari Pakistan Fleet
              </h2>
              <h2 className="text-lg colorGreen ">
                Vehicles are provided with drivers only.
              </h2>
            </div>
          </Col>
          <Col lg="6">
            <div className="p-2">
              <div className="d-flex gap-6 float-right">
                {/* <div>
            <h2 className="text-lg" style={{ color: "#C7C7C7" }}>
              View as
            </h2>
          </div> */}
                <div className="d-flex gap-3 align-items-center car" ref={ref}>
                  <h2 className="text-xl text-black font-semibold">
                    Car Type:
                  </h2>
                  <select
                    onChange={(e) => setSeater(e.target.value)}
                    name="size"
                    id="size"
                    className="p-2"
                  >
                    <option value="3" selected="3">
                      3 Passengers
                    </option>

                    <option value="4">4 Passengers</option>
                    <option value="5">5 Passengers</option>
                    <option value="6">6 Passengers</option>
                    <option value="7">7 Passengers</option>
                    <option value="8">8 Passengers</option>
                    <option value="8">9 Passengers</option>
                    <option value="10">10 Passengers</option>
                    <option value="11">11 Passengers</option>
                    <option value="12">12 Passengers</option>
                    <option value="13">13 Passengers</option>
                    <option value="14">14 Passengers</option>
                    <option value="15">15 Passengers</option>
                    <option value="16">16 Passengers</option>
                    <option value="17">17 Passengers</option>
                    <option value="18">18 Passengers</option>
                    <option value="19">19 Passengers</option>
                    <option value="20">20 Passengers</option>
                  </select>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Swiper
            id="mm1"
            // install Swiper modules
            modules={[Navigation, A11y, Pagination]}
            spaceBetween={50}
            slidesPerView={3}
            loop={true}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              "@1.50": {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
          >
            {data &&
              data.length > 0 &&
              data.map((e, i) => {
                return (
                  <SwiperSlide>
                    <div
                      style={{ backgroundColor: "rgba(220,220,220,0.6)" }}
                      className="text-center  border-black rounded-md"
                    >
                      <div className="rounded-md">
                        <img
                          src={`https://smaztech.com/sawari/${e.car_photo}`}
                          alt="Image"
                        />
                      </div>
                      <div className="p-2 webkit justify-center">
                        <h2 className="font-semibold text-xl text-black">
                          {e.car_title}
                        </h2>
                        <Row className="justify-center rrr py-2">
                          <Col>
                            <div>
                              <h2 className="text-lg text-black">
                                PKR{" "}
                                <span className="colorGreen font-extrabold text-2xl">
                                  {new Intl.NumberFormat("en-PK", {
                                    style: "currency",
                                    currency: "PKR",
                                    maximumFractionDigits: 0,
                                  }).format(Number(e?.car_per_day_charges))}
                                </span>
                              </h2>
                            </div>
                          </Col>
                          <Col>
                            <div>
                              <h2 className="text-lg text-black">
                                GBP{" "}
                                <span className="colorGreen font-extrabold text-2xl">
                                  {e?.car_per_day_charges_gbp.toLocaleString(
                                    "en-GB",
                                    {
                                      style: "currency",
                                      currency: "GBP",
                                    }
                                  )}
                                </span>
                              </h2>
                            </div>
                          </Col>
                          <Col>
                            <div>
                              <h2 className="text-lg text-black">
                                USD{" "}
                                <span className="colorGreen font-extrabold text-2xl">
                                  {e?.car_per_day_charges_usd.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "USD",
                                    }
                                  )}
                                </span>
                              </h2>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr className="hr" />
                      <Row className="p-2 justify-center align-items-center">
                        <Col className="text-left">
                          <p className="text-black font-medium">
                            Approximately per day price.
                          </p>
                        </Col>
                        <Col className="text-right">
                          <button
                            onClick={() => navigateToRentCarBooking(e)}
                            style={{ backgroundColor: "#00803E" }}
                            className="btn text-white font-semibold"
                          >
                            Book Now
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Row>
      </Container>
    </Container>
  );
});

export default OurBestCars;
