import { Col, Container, Row } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <Container fluid>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <div style={{ minHeight: "100vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 20,
                flexDirection: "column",
              }}
            >
              <img
                src={require("../assets/LOGO.png")}
                style={{ width: 250, height: 100 }}
              />

              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                PRIVACY POLICY PAGE
              </h1>
            </div>

            <div style={{ padding: 20 }}>
              <dl style={{ listStyle: "decimal" }}>
                <dt style={{ fontSize: 18, fontWeight: "bold" }}>
                  1. Overview
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      We are committed to safeguarding the privacy of our
                      website visitors; this policy sets out how we will treat
                      your personal information.
                    </p>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      Our website uses cookies. By using our website and
                      agreeing to this policy, you consent to our use of cookies
                      in accordance with the terms of this policy.
                    </p>
                  </div>
                </dd>

                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  2. What information do we collect?
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      We may collect, store and use the following kinds of
                      personal information:
                    </p>

                    <div style={{ marginLeft: 20 }}>
                      <p style={{ fontWeight: "500" }}>
                        (a) Information about your computer and about your
                        visits to and use of this website (including your IP
                        address geographical location, browser type and version,
                        operating system, referral source, length of visit, page
                        views, website navigation.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        (b) Information relating to any transactions carried out
                        between you and us on or in relation to this website,
                        including information relating to any purchases you make
                        of our goods or services.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        (c) Information that you provide to us for the purpose
                        of registering with us.
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        (d) Information that you provide to us for the purpose
                        of subscribing to our website services, email
                        notifications and/or newsletters
                      </p>
                      <p style={{ fontWeight: "500" }}>
                        (e) Any other information that you choose to send to us.
                      </p>
                    </div>
                  </div>
                </dd>

                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  3. Cookies
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      A cookie consists of information sent by a web server to a
                      web browser, and stored by the browser. The information is
                      then sent back to the server each time the browser
                      requests a page from the server. This enables the web
                      server to identify and track the web browser.
                      <br />
                      We may use both "session" cookies and "persistent" cookies
                      on the website. We will use the session cookies to: keep
                      track of you whilst you navigate the website. We will use
                      the persistent cookies to: enable our website to recognise
                      you when you visit.
                      <br />
                      Session cookies will be deleted from your computer when
                      you close your browser. Persistent cookies will remain
                      stored on your computer until deleted, or until they reach
                      a specified expiry date.
                      <br />
                      We use Google Analytics to analyse the use of this
                      website. Google Analytics generates statistical and other
                      information about website use by means of cookies, which
                      are stored on users' computers. The information generated
                      relating to our website is used to create reports about
                      the use of the website. Google will store this
                      information. Google's privacy policy is available at:
                      <a
                        style={{ color: "blue" }}
                        href="http://www.google.com/privacypolicy.html"
                      >
                        {" "}
                        http://www.google.com/privacypolicy.html
                      </a>{" "}
                      Our advertisers/payment services providers may also send
                      you cookies.
                      <br />
                      Most browsers allow you to reject all cookies, whilst some
                      browsers allow you to reject just third party cookies. For
                      example, in Internet Explorer you can refuse all cookies
                      by clicking "Tools", "Internet Options", "Privacy", and
                      selecting "Block all cookies" using the sliding selector.
                      Blocking all cookies will, however, have a negative impact
                      upon the usability of many websites.
                    </p>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  4. Use your personal Information
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      Personal information submitted to us via this website will
                      be used for the purposes specified in this privacy policy
                      or in relevant parts of the website. <br />
                      We may use your personal information to:
                      <ul style={{ marginLeft: 20, listStyle: "circle" }}>
                        <li>Administer the website;</li>
                        <li>
                          Improve your browsing experience by personalising the
                          website;
                        </li>
                        <li>
                          Enable your use of the services available on the
                          website;
                        </li>
                        <li>
                          Send to you goods purchased via the website, and
                          supply to you services purchased via the website;
                        </li>
                        <li>
                          Send statements and invoices to you, and collect
                          payments from you;
                        </li>
                        <li>
                          Send you general (non-marketing) commercial
                          communications;
                        </li>
                        <li>
                          Send you email notifications which you have
                          specifically requested;
                        </li>
                        <li>
                          Send to you our newsletter and other marketing
                          communications relating to our business or the
                          businesses of carefully-selected third parties which
                          we think may be of interest to you by post or, where
                          you have specifically agreed to this, by email or
                          similar technology (you can inform us at any time if
                          you no longer require marketing communications);
                        </li>
                        <li>
                          provide third parties with statistical information
                          about our users - but this information will not be
                          used to identify any individual user;
                        </li>
                        <li>
                          Deal with enquiries and complaints made by or about
                          you relating to the website; and
                        </li>
                        <li>Other uses.</li>

                        <li style={{ listStyle: "none", marginLeft: -20 }}>
                          Where you submit personal information for publication
                          on our website, we will publish and otherwise use that
                          information in accordance with the license you grant
                          to us. We will not without your express consent
                          provide your personal information to any third parties
                          for the purpose of direct marketing.
                          <br />
                          All our website financial transactions are handled
                          through our payment services provider as follows;
                          Stripe. You can review their privacy policies at the
                          following sites.{" "}
                          <a
                            href="https://www.stripe.com"
                            style={{ color: "blue" }}
                          >
                            {" "}
                            https://www.stripe.com
                          </a>
                          <br />
                          We will share information with these only to the
                          extent necessary for the purposes of processing
                          payments you make via our website and dealing with
                          complaints and queries relating to such payments.
                        </li>
                      </ul>
                    </p>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  5. Disclocures
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      We may disclose information about you to any of our
                      employees, officers, agents, suppliers or subcontractors
                      insofar as reasonably necessary for the purposes as set
                      out in this privacy policy.
                      <br />
                      In addition, we may disclose your personal information:
                      <ul style={{ marginLeft: 20, listStyle: "circle" }}>
                        <li>
                          to the extent that we are required to do so by law;
                        </li>
                        <li>
                          in connection with any legal proceedings or
                          prospective legal proceedings;
                        </li>
                        <li>
                          in order to establish, exercise or defend our legal
                          rights (including providing information to others for
                          the purposes of fraud prevention and reducing credit
                          risk);
                        </li>
                        <li>
                          Send to you goods purchased via the website, and
                          supply to you services purchased via the website;
                        </li>
                        <li>
                          to the purchaser (or prospective purchaser) of any
                          business or asset which we are (or are contemplating)
                          selling; and
                        </li>
                        <li>
                          to any person who we reasonably believe may apply to a
                          court or other competent authority for disclosure of
                          that personal information where, in our reasonable
                          opinion, such court or authority would be reasonably
                          likely to order disclosure of that personal
                          information.
                        </li>
                        <li style={{ listStyle: "none", marginLeft: -20 }}>
                          Except as provided in this privacy policy, we will not
                          provide your information to third parties.
                        </li>
                      </ul>
                    </p>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  6. International data transfers
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontWeight: "500" }}>
                      Information that we collect may be stored and processed in
                      and transferred between any of the countries in which we
                      operate in order to enable us to use the information in
                      accordance with this privacy policy.
                      <br /> Information which you provide may be transferred to
                      countries (including the United States, which do not have
                      data protection laws equivalent to those in force in the
                      European Economic Area. <br /> In addition, personal
                      information that you submit for publication on the website
                      will be published on the internet and may be available,
                      via the internet, around the world. We cannot prevent the
                      use or misuse of such information by others.
                      <br /> You expressly agree to such transfers of personal
                      information.
                    </p>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  7. Security of your personal Information
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontWeight: "500" }}>
                      We will take reasonable technical and organisational
                      precautions to prevent the loss, misuse or alteration of
                      your personal information.
                      <br /> We will store all the personal information you
                      provide on our secure (password- and firewall- protected)
                      servers. All electronic transactions you make to or
                      receive from us will be encrypted using SSL technology.
                      <br /> Of course, data transmission over the internet is
                      inherently insecure, and we cannot guarantee the security
                      of data sent over the internet.
                      <br /> You are responsible for keeping your password and
                      user details confidential. We will not ask you for your
                      password (except when you log in to the website).
                    </p>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  8. Policy amendments
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontWeight: "500" }}>
                      We may update this privacy policy from time-to-time by
                      posting a new version on our website. You should check
                      this page occasionally to ensure you are happy with any
                      changes.
                      <br /> We may also notify you of changes to our privacy
                      policy by email.
                    </p>
                  </div>
                </dd>

                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  9. Rights
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      You may instruct us to provide you with any personal
                      information we hold about you. Provision of such
                      information will be subject to:
                      <ul style={{ marginLeft: 20, listStyle: "circle" }}>
                        <li>
                          the payment of a fee (currently fixed at 10%); and
                        </li>
                        <li>
                          the supply of appropriate evidence of your identity
                          (for this purpose, we will usually accept a photocopy
                          of your passport certified by a solicitor or bank plus
                          an original copy of a utility bill showing your
                          current address).
                        </li>
                        <li style={{ listStyle: "none", marginLeft: -20 }}>
                          We may withhold such personal information to the
                          extent permitted by law. You may instruct us not to
                          process your personal information for marketing
                          purposes by email at any time. In practice, you will
                          usually either expressly agree in advance to our use
                          of your personal information for marketing purposes,
                          or we will provide you with an opportunity to opt-out
                          of the use of your personal information for marketing
                          purposes.
                        </li>
                      </ul>
                    </p>
                  </div>
                </dd>

                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  10. Third party websites
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontWeight: "500" }}>
                      The website contains links to other websites. We are not
                      responsible for the privacy policies or practices of third
                      party websites.
                    </p>
                  </div>
                </dd>
                <dt style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                  11. Updating information
                </dt>
                <dd>
                  <div style={{ marginLeft: 20 }}>
                    <p style={{ fontWeight: "500" }}>
                      Please let us know if the personal information which we
                      hold about you needs to be corrected or updated.
                    </p>
                  </div>
                </dd>
              </dl>
            </div>

            {/* <div style={{ padding: 20 }}>
              <h1
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Overview
              </h1>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 5,
                }}
              >
                Welcome to Sawari Pakistan Limited, a leading transportation and
                tourism service provider operating in collaboration with Sawari
                Airport Transfers (SMC-Private) Limited. With a strong presence
                in both the United Kingdom and Pakistan, we pride ourselves on
                delivering outstanding airport transfer services, vehicle
                rentals, and exceptional tourism packages all across Pakistan.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Our commitment to excellence and customer satisfaction has led
                us to become a trusted choice for locals and international
                travelers alike. With a wealth of experience in the industry, we
                understand the importance of seamless and reliable
                transportation solutions, as well as creating unforgettable
                experiences for those exploring the beauty of Pakistan.
              </p>

              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                At Sawari Pakistan Limited, we strive to exceed expectations
                through our comprehensive range of services. Whether you need a
                convenient airport transfer, reliable vehicle rental for your
                travel needs, or a tailored tourism package to explore the
                hidden gems of Pakistan, we have you covered
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                As technology-driven professionals, we have developed a dynamic
                website and mobile applications that allow you to access instant
                quotations. With just a few simple details, our user provide you
                with accurate pricing information, ensu way
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "550",
                  marginTop: 10,
                }}
              >
                As the sole owner of these companies, my name is Mr. Mohammad
                Asif. With a deep passion for providing exceptional services, I
                have devoted my time and expertise to ensuring that both Sawari
                Pakistan and Sawari Airport Transfers (SMC-Private) Limited are
                synonymous with reliability, professionalism, and customer
                satisfaction.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Our team of dedicated and experienced professionals are always
                available to assist you, whether you have queries about our
                services or need guidance in planning your perfect travel
                itinerary. We take great pride in offering personalized support
                and ensuring that every customer's individual needs are met with
                care and attention to detail.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                We believe in promoting the beauty, cultural richness, and
                diversity of Pakistan. Our commitment to sustainable tourism
                practices ensures that our operations contribute positively to
                the local communities we serve. By choosing our services, you
                are not only experiencing the best that Pakista supporting
                responsible and ethical travel.
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  marginTop: 10,
                }}
              >
                Thank you for choosing Sawari Pakistan Limited and Sawari
                Airport Transfers (SMC-Private) Limited. We look forward to
                being a part of your memorable journey, creating unforgettable
                experiences, and being your trusted partner in transportation
                and tourism services throughout Pakistan!
              </p>
            </div> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PrivacyPolicy;
