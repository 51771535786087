import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export const SectionTwo = () => {
  return (
    <>
      <Container fluid className="sec2 pb-10">
        <Container>
          <div className="pt-24 pb-3 webkit">
            <h2 className="smallScreenHeading text-5xl text-white">Our Services</h2>
          </div>
          <Row className="webkit mt-5 justify-center pcol2">
            <Col lg="4" sm="6" xs="8">
              <div className="p-2">
                <div className="boxser">
                  <Image src={require("../../assets/AirportService.png")} />
                </div>
                <p className="smallScreenText text-lg text-white py-2">Airport Transfers</p>
              </div>
            </Col>
            <Col lg="4" sm="6" xs="8">
              <div className="p-2">
                <div className="boxser">
                  <Image src={require("../../assets/rentAcar.png")} />
                </div>
                <p className="smallScreenText text-lg text-white py-2">Vehicle Rentals</p>
              </div>
            </Col>
            <Col lg="4" sm="6" xs="8">
              <div className="p-2">
                <div className="boxser">
                  <Image src={require("../../assets/TravelAndTour.png")} />
                </div>
                <p className="smallScreenText text-lg text-white py-2">Tourism Packages</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
