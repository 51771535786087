import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { BsDot, BsEnvelope, BsEye, BsPhone, BsPinMap } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const navigateToAboutUs = () => {
    navigate("/AboutUs");
    window.scrollTo(0, 0);
  };

  const navigateToPrivacyPolicy = () => {
    navigate("/PrivacyPolicy");
    window.scrollTo(0, 0);
  };

  const navigateToTermsAndConditions = () => {
    navigate("/TermsAndConditions");
    window.scrollTo(0, 0);
  };

  const navigateToGdprPolicy = () => {
    navigate("/GDPRPolicy");
    window.scrollTo(0, 0);
  };

  const navigateToContactUs = () => {
    navigate("/ContactUs");
    window.scrollTo(0, 0);
  };

  return (
    <Container style={{ border: "2xp solid red", overflow: "scroll" }} fluid className="text-center text-lg-start text-white bgGreen">
      {/* <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div>
          <a href="" className="me-4 text-reset"></a>
          <a href="" className="me-4 text-reset"></a>
          <a href="" className="me-4 text-reset"></a>
          <a href="" className="me-4 text-reset"></a>
          <a href="" className="me-4 text-reset"></a>
          <a href="" className="me-4 text-reset"></a>
        </div>
      </section> */}

      <section className="">
        <Container className="text-left text-md-start pt-5">
          <Row className="mt-3">
            <Col xs="12" sm="12" md="12" lg="7" xl={4} className="mx-auto mb-4">
              <div className="p-2">
                <div className="py-2">
                  <Image
                    className="logoFooter"
                    src={require("../../assets/white-logo.png")}
                  />
                </div>
                <div className="py-2">
                  <p
                    className="extraSmall text-white font-normal text-sm"
                    style={{
                      marginLeft: 0,
                      fontSize: 18,
                      textAlign:"center"
                    }}
                  >
                    Airport Transfers | Vehicle Rentals | Tourism Packages
                  </p>
                </div>
              </div>
            </Col>

            <Col xs="12" sm="12" md="12" lg="12" xl={7} className="mx-auto mb-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h6 className="text-uppercase fw-bold mb-2 text-lg font-semibold">
                  Get in Touch
                </h6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Row>
                    <Col xs="12" sm={12} md={12} lg={4} xl={5}>
                      <h2
                        className="extraSmall text-xl font-normal text-white"
                        style={{
                          textAlign: "left",
                          fontSize: 14,
                          width: "100%",
                        }}
                      >
                        Sawari Pakistan Limited
                        <br />
                        R1, 179 Bordesley Green East, Birmingham,
                        <br />
                        West Midlands, England, B9 5SR.
                        <br />
                        United Kingdom
                      </h2>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={4} xl={2}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "100%",
                          marginBottom: 10,
                          paddingRight: 25,
                        }}

                      >
                        <img
                          src={require("../../assets/hands.png")}
                          style={{ width: 80, height: 80 }}
                        />
                        <h1
                          style={{
                            fontSize: 10,
                            fontWeight: "600",
                            color: "black",
                          }}
                        >
                          In Collabaration with
                        </h1>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={5}>
                      {/* <div>
                      <GoLocation style={{ fontSize: "25px" }} />
                    </div> */}
                      <h2
                        className="extraSmall text-xl font-normal text-white"
                        style={{
                          textAlign: "left",

                          fontSize: 14,
                        }}
                      >
                        Sawari Airport Transfers (SMC-Private) Limited
                        <br />
                        Office No. 3, Rehmat House, Sandal Road,
                        <br />
                        Ward No. 14, Gujarkhan, Rawalpindi,
                        <br />
                        Pakistan
                      </h2>

                    </Col>
                  </Row>

                </div>
                <Row style={{ margin: 0, padding: 0 }} >

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "90%",
                      marginTop: 10,

                    }}
                    className="fullwidth"
                  >

                    <div
                      style={{ flexDirection: "column", width: "100%" }}
                      className="d-flex gap-1 justify-content-center mt-2"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Col xs={6} >
                          <h1
                            style={{
                              textTransform: "uppercase",
                              marginBottom: 10,
                            }}
                            className="smallScreenText text-uppercase fw-bold mb-2 text-lg font-semibold"
                          >
                            24/7 Support
                          </h1>
                        </Col>

                        <h1
                          style={{
                            textTransform: "uppercase",
                            marginBottom: 10,

                          }}
                          className="extraSmall text-uppercase fw-bold mb-2 text-lg font-semibold"
                        >
                          Download mobile application
                        </h1>

                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <BsPhone style={{ fontSize: "25px" }} />
                        </div>
                        <div>
                          <img
                            src={require("../../assets/whatsapp.png")}
                            style={{ width: 35 }}
                          />
                        </div>
                        <div>
                          <h2 className="smallScreenText text-lg font-normal text-white">
                            <a href="tel:0092 336 4400044"> 0092 336 4400044 </a>
                          </h2>
                        </div>
                      </div>
                      <div className="d-flex gap-1 align-items-center mt-2">
                        <div>
                          <BsEnvelope style={{ fontSize: "25px" }} />
                        </div>
                        <div>
                          <h2 className="smallScreenText text-lg font-normal text-white">
                            <a href="mailto:hello@sawaripakistan.com">
                              hello@sawaripakistan.com
                            </a>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
                <h6
                  style={{ marginTop: 10 }}
                  className="smallScreenText text-uppercase fw-bold mb-2 text-xl font-semibold"
                >
                  Account
                </h6>
                <div className="align-items-center mt-2">
                  <button onClick={navigateToAboutUs}>
                    <h2 className="smallScreenText text-xl font-normal text-white">About Us</h2>
                  </button>
                </div>
                <div className="align-items-center mt-2">
                  <button onClick={navigateToPrivacyPolicy}>
                    <h2 className="smallScreenText text-xl font-normal text-white">
                      Privacy Policy
                    </h2>
                  </button>
                </div>
                <div className=" align-items-center mt-2">
                  <button onClick={navigateToTermsAndConditions}>
                    <h2 className="smallScreenText text-xl font-normal text-white">
                      Terms And Condition
                    </h2>
                  </button>
                </div>
                <div className="align-items-center mt-2">
                  <button onClick={navigateToContactUs}>
                    <h2 className="smallScreenText text-xl font-normal text-white">
                      Contact Us
                    </h2>
                  </button>
                </div>
                <div className=" align-items-center mt-2">
                  <button onClick={navigateToGdprPolicy}>
                    <h2 className="smallScreenText text-xl font-normal text-white">
                      GDPR Policy
                    </h2>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="webkit py-3">
            <h2 className="smallScreenText text-footer">
              © {new Date().getFullYear()}. All Rights Reserved by Sawari
              Pakistan Limited.
            </h2>
          </div>
        </Container>
      </section>
    </Container>
  );
};

export default Footer;
