import { ArrowLeftIcon, ArrowRightIcon, StarIcon } from "@chakra-ui/icons";
import React, { useContext, useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Image,
  Button,
  FormCheck,
  InputGroup,
} from "react-bootstrap";
import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import loginContext from "../../../Constant/LoginContext/loginContext";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import BaseUri from "../../../Constant/BaseUri";
import Modals from "../../modal/modal";
import { RingLoader } from "react-spinners";

const RentACarBooking = () => {
  // useEffect(() => {
  //   if (!userData) {
  //     navigate("/");
  //   }
  // }, []);

  const location = useLocation();
  const navigate = useNavigate();
  let { state } = location;

  if (!state) {
    navigate("/");
  }

  const images = [
    `https://smaztech.com/sawari/${state?.car_photo}`,
    `https://smaztech.com/sawari/${state?.car_photo}`,
    `https://smaztech.com/sawari/${state?.car_photo}`,
    `https://smaztech.com/sawari/${state?.car_photo}`,
    `https://smaztech.com/sawari/${state?.car_photo}`,
  ];

  const context = useContext(loginContext);
  let { userData, setUserData } = context;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [pickupCord, setPickupCords] = useState("");
  const [address, setAddress] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [formError, setFormError] = useState("");
  const [modalVisible, setModalVisible] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [loading, setLoading] = useState(false);
  const [returnTime, setReturnTime] = useState("");

  // const handleCheckUser = async () => {
  //   let data = await localStorage.getItem("userData");
  //   data = JSON.parse(data);
  //   if (data && Object.keys(data).length > 0) {
  //     setUserData(data);
  //   }
  // };

  // useEffect(() => {
  //   handleCheckUser();
  // }, []);

  const handleArrowClick = (direction) => {
    let newImageIndex = currentImageIndex + direction;
    if (newImageIndex < 0) {
      newImageIndex = images.length - 1;
    } else if (newImageIndex >= images.length) {
      newImageIndex = 0;
    }

    setCurrentImageIndex(newImageIndex);
  };

  const handleSelect = async (selectedAddress) => {


    setAddress(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);
      setPickupCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const bookRentCar = () => {
    if (!userData) {
      navigate("/login");
      return;
    }

    let pickDate = new Date(selectedDate);
    let returns = new Date(returnDate);

    let pickGetTime = pickDate.getTime();
    let returnGetTime = returns.getTime();

    let diff = returnGetTime - pickGetTime;

    let days = diff / 1000 / 60 / 60 / 24;

    let fare = 0;
    let usdFare = 0;
    let gbpFare = 0;

    if (days < 7) {
      fare = Number(days) * state?.car_per_day_charges;
      usdFare = Number(days) * state?.car_per_day_charges_usd;
      gbpFare = Number(days) * state?.car_per_day_charges_gbp;
    } else if (days > 7 && days < 30) {
      let week = days / 7;

      let split = week.toString().split(".");

      let totalweeks = split[0];

      let totalDays = split[1];
      totalDays = Math.round(`0.${totalDays}` * 7);

      let weeklyFare = totalweeks * state?.car_per_week_charges;
      let dailyFare = totalDays * state?.car_per_day_charges;

      let weeklyUsdFare = totalweeks * state?.car_per_week_charges_usd;
      let dailyUsdFare = totalDays * state?.car_per_day_charges_usd;

      let weeklyGbpFare = totalweeks * state?.car_per_week_charges_gbp;
      let dailyGbpFare = totalDays * state?.car_per_day_charges_gbp;

      fare = Number(weeklyFare) + Number(dailyFare);
      usdFare = Number(weeklyUsdFare) + Number(dailyUsdFare);
      gbpFare = Number(weeklyGbpFare) + Number(dailyGbpFare);
    } else {
      let month = days / 30;

      let split = month.toString().split(".");

      let totalMonths = split[0];
      let remainingDays = split[1];
      remainingDays = Math.round(`0.${Number(remainingDays)}` * 30);

      let week = remainingDays / 7;
      let splitWeek = week.toString().split(".");
      let totalweeks = splitWeek[0];

      let totalDays = splitWeek[1];

      totalDays = Math.round(`0.${totalDays}` * 7);

      let monthlyFare = totalMonths * state?.car_per_month_charges;
      let weeklyFare = totalweeks * state?.car_per_week_charges;
      let dailyFare = totalDays * state?.car_per_day_charges;

      let monthlyUsdFare = totalMonths * state?.car_per_month_charges_usd;
      let weeklyUsdFare = totalweeks * state?.car_per_week_charges_usd;
      let dailyUsdFare = totalDays * state?.car_per_day_charges_usd;

      let monthlyGbpFare = totalMonths * state?.car_per_month_charges_gbp;
      let weeklyGbpFare = totalweeks * state?.car_per_week_charges_gbp;
      let dailyGbpFare = totalDays * state?.car_per_day_charges_gbp;

      fare = Number(monthlyFare) + Number(weeklyFare) + Number(dailyFare);
      usdFare =
        Number(monthlyUsdFare) + Number(weeklyUsdFare) + Number(dailyUsdFare);
      gbpFare =
        Number(monthlyGbpFare) + Number(weeklyGbpFare) + Number(dailyGbpFare);
    }

    let dataToSend = {
      user_id: userData.id,
      car_id: state?.id,
      booking_start_date: selectedDate,
      booking_end_date: returnDate,
      booking_total_days: days,
      booking_total_fare: fare,
      booking_total_usd_fare: usdFare,
      booking_total_gbp_fare: gbpFare,
      booking_address: address,
      booking_reporting_time: selectedTime,
      booking_end_time: returnTime,
      type: "rent a car",
      carDetails: state,
    };

    let error = {};

    if (!dataToSend.booking_address) {
      error.pickupAddress = true;
    }
    if (!dataToSend.booking_start_date) {
      error.pickupDate = true;
    }
    if (!dataToSend.booking_end_date) {
      error.returnDate = true;
    }
    if (!dataToSend.booking_reporting_time) {
      error.pickupTime = true;
    }
    if (!dataToSend.booking_end_time) {
      error.returnTime = true;
    }

    if (error && Object.keys(error).length > 0) {
      setFormError(error);
      return;
    }

    setFormError(error);

    console.log(dataToSend, "dataToSend")

    if (!dataToSend?.booking_total_days) {
      setModalText("Booking days must be greater then 0");
      setModalHeading("Alert");
      setModalVisible(true);
      return;
    }

    let values = Object.values(dataToSend);

    let flag = values.some((e, i) => !e);

    if (flag) {
      alert("Required fields are missing");
      return;
    }

    let currentGetTime = new Date().getTime();
    let now = new Date();
    let today = now.getDate();
    let month = now.getMonth();
    let year = now.getFullYear();
    let pickDay = new Date(selectedDate);
    let pickDates = pickDay.getDate();
    let pickMonth = pickDay.getMonth();
    let pickYears = pickDay.getFullYear();

    let returnGet = new Date(returnDate).getTime();
    let pickGet = new Date(selectedDate).getTime();

    if (pickGet > returnGet) {
      setModalHeading("Date Alert");
      setModalText("Return Date must be greater then pickup date");
      setModalVisible(true);
      setLoading(false);
      return;
    }

    if (today == pickDates && pickMonth == month && year == pickYears) {
      let hours = new Date().getHours();
      let minutes = new Date().getMinutes();
      let selectTime = selectedTime.toString().split(":");
      let selectHours = selectTime[0];
      if (Number(hours >= selectHours)) {
        setModalHeading("Date Alert");
        setModalText("Hours must be greater then the current hours");
        setModalVisible(true);
        return;
      } else {
        let send = {
          id: state?.id,
        };
        setLoading(true);
        // axios
        //   .get(`${BaseUri}get_rent_car_booking_schedules.php`, { params: send })
        //   .then((res) => {
        //     let { data } = res;
        // let { availability_dates } = data;

        // let isBookingAvailable = !availability_dates.some((e) => {
        //   let checkStart = new Date(e.start_date);
        //   let checkEnd = new Date(e.end_date);

        //   let checkStartGet = checkStart.getTime();
        //   let checkEndGet = checkEnd.getTime();
        //   let selectStartDate = new Date(selectedDate).getTime();
        //   let selectEndDate = new Date(returnDate).getTime();

        //   // Check if the selected start date or return date falls within the booked date range
        //   if (
        //     (selectStartDate >= checkStartGet &&
        //       selectStartDate <= checkEndGet) ||
        //     (selectEndDate >= checkStartGet && selectEndDate <= checkEndGet)
        //   ) {
        //     return true; // Overlapping date ranges, booking not available
        //   }

        //   return false; // No overlapping date ranges found
        // });

        // if (!isBookingAvailable) {
        //   // Show error message - cars are not available on the selected dates
        //   // alert("Car is not available on the selected dates.");
        //   setModalHeading("Alert");
        //   setLoading(false);
        //   setModalText("Car is not available on the selected dates.");
        //   setModalVisible(true);
        //   return;
        // } else {
        // Proceed with booking logic
        navigate("/RentConfirmBooking", { state: dataToSend });
        setLoading(false);
        // }
        // })
        // .catch((error) => {
        //   setLoading(false);
        //   console.error("An error occurred:", error);
        // });
      }

      return;
    }
    if (currentGetTime > pickGetTime) {
      setModalHeading("Alert");
      setModalText("Pickup Date must be greater then current date.");
      setModalVisible(true);
      setLoading(false);
      return;
    }

    if (days.toString().includes("-")) {
      // alert("Start date must be before end date");
      setModalHeading("Alert");
      setModalText("Start date must be before end date.");
      setModalVisible(true);
      return;
    }

    let data = {
      id: state?.id,
    };

    setLoading(true);
    // axios
    //   .get(`${BaseUri}get_rent_car_booking_schedules.php`, { params: data })
    //   .then((res) => {
    //     let { data } = res;
    //     let { availability_dates } = data;

    //     let isBookingAvailable = !availability_dates.some((e) => {
    //       let checkStart = new Date(e.start_date);
    //       let checkEnd = new Date(e.end_date);
    //       let checkStartGet = checkStart.getTime();
    //       let checkEndGet = checkEnd.getTime();
    //       let selectStartDate = new Date(selectedDate).getTime();
    //       let selectEndDate = new Date(returnDate).getTime();

    //       // Check if the selected start date or return date falls within the booked date range
    //       if (
    //         (selectStartDate >= checkStartGet &&
    //           selectStartDate <= checkEndGet) ||
    //         (selectEndDate >= checkStartGet && selectEndDate <= checkEndGet)
    //       ) {
    //         return true; // Overlapping date ranges, booking not available
    //       }

    //       return false; // No overlapping date ranges found
    //     });

    // if (!isBookingAvailable) {
    //   // Show error message - cars are not available on the selected dates
    //   // alert("Car is not available on the selected dates.");
    //   setModalHeading("Alert");
    //   setLoading(false);
    //   setModalText("Car is not available on the selected dates.");
    //   setModalVisible(true);
    // }
    // else {
    // Proceed with booking logic
    setLoading(false);
    navigate("/RentConfirmBooking", { state: dataToSend });
    // }
    // })
    // .catch((error) => {
    //   setLoading(false);
    //   console.error("An error occurred:", error);
    // });
  };

  return (
    <>
      <Container>
        <Row>
          <Col lg="8">
            <Row className="items-center">
              <Col lg="12">
                <div className="m-3 p-3  rounded-2xl flex items-center justify-center">
                  <Image className="w-10/12" src={images[currentImageIndex]} />
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg="4">
            <div className="webkit py-2">
              <div className="bg-[#00803e] webkit text-white rounded-3xl py-4 p-4 flex flex-col gap-3">
                <h2
                  style={{ textTransform: "uppercase" }}
                  className="text-2xl text-white font-bold"
                >
                  Instant Quotation
                  <br />
                  <h4
                    style={{
                      textTransform: "capitalize",
                      fontSize: 16,
                      marginTop: 5,
                    }}
                    className="text-white font-bold"
                  >
                    Discover the freedom of the open road.
                  </h4>
                </h2>
                <form>
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                    style={{ zindex: 20 }}
                    googleCallbackName="initMap"
                    // searchOptions={{
                    //   types: ["geocode"], // Limit results to addresses only
                    // }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                    }) => (
                      <div className="autocomplete-input-container">
                        <input
                          {...getInputProps({
                            placeholder: "Enter Pickup Location",
                            className: "autocomplete-input",
                          })}
                          style={{
                            width: "100%",
                            padding: "10px",
                            color: "black",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        />
                        {address && (
                          <div className="autocomplete-dropdown-container">
                            {suggestions.map((suggestion, index) => (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion)}
                                className="autocomplete-suggestion"
                                style={{
                                  padding: "5px",
                                  cursor: "pointer",
                                  color: "black",
                                  backgroundColor: "#fff",
                                  borderBottom: "1px solid #ccc",
                                }}
                              >
                                {suggestion.description}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {formError && formError.pickupAddress && (
                    <h1
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        textAlign: "left",
                      }}
                    >
                      Pickup address is missing
                    </h1>
                  )}
                  <div className="flexWrap d-flex gap-2">
                    <fieldset className="largeScreenFullWidth fullwidth w-6/12">
                      <p
                        style={{
                          textAlign: "left",
                          fontFamily: "sans-serif",
                          marginTop: 10,
                        }}
                      >
                        Pickup Date
                      </p>
                      <div
                        className="About_main_images_search"
                        style={{ marginTop: 0 }}
                      >
                        <input
                          placeholder="Pickup Date"
                          type={"date"}
                          style={{
                            Width: "100%",
                            fontFamily: "sans-serif",
                            fontSize: 16,
                            color: "gray",
                            fontWeight: "500",
                          }}
                          onChange={(e) => setSelectedDate(e.target.value)}
                          className="border-white"
                        ></input>
                        {/* <AiOutlineSearch
                          size={25}
                          className="text-black border-rounded"
                        /> */}
                        {/* <Button>Search</Button> */}
                      </div>
                      {formError && formError.pickupDate && (
                        <h1
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            textAlign: "left",
                          }}
                        >
                          Pickup date is missing
                        </h1>
                      )}
                    </fieldset>
                    <fieldset className="largeScreenFullWidth fullwidth w-6/12">
                      <p
                        style={{
                          textAlign: "left",
                          fontFamily: "sans-serif",
                          marginTop: 10,
                        }}
                      >
                        Pickup Time
                      </p>

                      <div
                        className="About_main_images_search"
                        style={{ marginTop: 0 }}
                      >
                        <input
                          placeholder="Pickup Time"
                          style={{
                            Width: "100%",
                            fontFamily: "sans-serif",
                            fontSize: 16,
                            color: "gray",
                            fontWeight: "500",
                          }}
                          type={"time"}
                          onChange={(e) => setSelectedTime(e.target.value)}
                          className="border-white"
                        ></input>
                        {/* <AiOutlineSearch
                          size={25}
                          className="text-black border-rounded"
                        /> */}
                        {/* <Button>Search</Button> */}
                      </div>
                      {formError && formError.pickupTime && (
                        <h1
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            textAlign: "left",
                          }}
                        >
                          Pickup time is missing
                        </h1>
                      )}
                    </fieldset>
                  </div>
                  <div className="flexWrap d-flex gap-2">
                    <fieldset className="largeScreenFullWidth fullwidth w-6/12">
                      <p
                        style={{
                          textAlign: "left",
                          fontFamily: "sans-serif",
                          marginTop: 10,
                        }}
                      >
                        Return Date
                      </p>
                      <div
                        className="About_main_images_search"
                        style={{ marginTop: 0 }}
                      >
                        <input
                          placeholder="Return Date"
                          type={"date"}
                          style={{
                            Width: "100%",
                            fontFamily: "sans-serif",
                            fontSize: 16,
                            color: "gray",
                            fontWeight: "500",
                          }}
                          onChange={(e) => setReturnDate(e.target.value)}
                          className="border-white"
                        ></input>
                        {/* <AiOutlineSearch
                          size={25}
                          className="text-black border-rounded"
                        /> */}
                        {/* <Button>Search</Button> */}
                      </div>
                      {formError && formError.returnDate && (
                        <h1
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            textAlign: "left",
                          }}
                        >
                          Return date is missing
                        </h1>
                      )}
                    </fieldset>
                    <fieldset className="largeScreenFullWidth fullwidth w-6/12">
                      <p
                        style={{
                          textAlign: "left",
                          fontFamily: "sans-serif",
                          marginTop: 10,
                        }}
                      >
                        Return Time
                      </p>
                      <div
                        style={{ marginTop: 0 }}
                        className="About_main_images_search"
                      >
                        <input
                          placeholder="Return Time"
                          style={{
                            Width: "100%",
                            fontFamily: "sans-serif",
                            fontSize: 16,
                            color: "gray",
                            fontWeight: "500",
                          }}
                          type={"time"}
                          onChange={(e) => setReturnTime(e.target.value)}
                          className="border-white"
                        ></input>
                        {/* <AiOutlineSearch
                          size={25}
                          className="text-black border-rounded"
                        /> */}
                        {/* <Button>Search</Button> */}
                      </div>
                      {formError && formError.returnTime && (
                        <h1
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                            textAlign: "left",
                          }}
                        >
                          Return time is missing
                        </h1>
                      )}
                    </fieldset>
                  </div>
                  <Row className="justify-center rrr py-2 mt-4">
                    <Col>
                      <div>
                        <h2 className="fourteen text-lg text-white">
                          PKR{" "}
                          <span className="averageText text-white font-extrabold text-2xl">
                            {new Intl.NumberFormat("en-PK", {
                              style: "currency",
                              currency: "PKR",
                              maximumFractionDigits: 0,
                            }).format(Number(state?.car_per_day_charges))}
                          </span>
                        </h2>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <h2 className="fourteen text-lg text-white">
                          GBP{" "}
                          <span className="averageText text-white font-extrabold text-2xl">
                            {state?.car_per_day_charges_gbp.toLocaleString(
                              "en-GB",
                              {
                                style: "currency",
                                currency: "GBP",
                              }
                            )}
                          </span>
                        </h2>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <h2 className="fourteen text-lg text-white">
                          USD{" "}
                          <span className="averageText text-white font-extrabold text-2xl">
                            {state?.car_per_day_charges_usd.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                              }
                            )}
                          </span>
                        </h2>
                      </div>
                    </Col>
                  </Row>
                </form>
                <button
                  onClick={() => bookRentCar()}
                  style={{
                    backgroundColor: "#ffcc00",
                    borderRadius: 10,
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                  className="py-2 px-4"
                >
                  {loading ? (
                    <RingLoader size={30} color={"white"} loading={loading} />
                  ) : (
                    "Booking Now"
                  )}
                </button>
              </div>
            </div>
          </Col>
          {/* <Col lg="12">
            <div className="webkit py-4">
              <h2 className="flex gap-2 p-2 justify-center w-24 items-center rounded-lg border-2 border-[#00803E] text-xl font-bold">
                <StarIcon className="startnew" />
                <span>4.7</span>
              </h2>
            </div>
          </Col> */}
        </Row>
        {modalVisible && (
          <Modals
            visible={modalVisible}
            heading={modalHeading}
            text={modalText}
            close={() => setModalVisible(false)}
          />
        )}
      </Container>
    </>
  );
};

export default RentACarBooking;
