import React from "react";
import TravelBooking from "../TravelAndTours/travelForm";
import TravelDetails from "../TravelAndTours/TravelDetails";
import { useLocation } from "react-router-dom";

const ViewTravelDetails = () => {
  return (
    <>
      <TravelBooking />
      <TravelDetails />
    </>
  );
};

export default ViewTravelDetails;
