import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";
import loginContext from "../../Constant/LoginContext/loginContext";
import BaseUri from "../../Constant/BaseUri";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import Modals from "../modal/modal";
import PaymentModals from "../modal/paymentModal";
import { RingLoader } from "react-spinners";
import { Row, Col, Container } from "react-bootstrap";

function TourConfirmBooking() {
  const [userData, setUserData] = useState({});
  const stripe = useStripe();
  const elements = useElements();

  const [paymentMethod, setPaymentMethod] = useState("");
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [additionalInfo, setAddtionalInfo] = useState("");
  const [mainPassengerName, setMainPassengerName] = useState("");
  const [mainPassengerTel, setMainPassengerTel] = useState("");

  const navigate = useNavigate();

  const getUserDatA = async () => {
    let userData = await localStorage.getItem("userData");

    userData = JSON.parse(userData);

    setUserData(userData);
  };

  useEffect(() => {
    getUserDatA();
  }, []);

  function objectToFormData(obj) {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
      }
    }

    return formData;
  }

  const handleBookCar = async () => {
    if (!mainPassengerName) {
      setVisible(true);
      setModalHeading("Error Alert");
      setModalText("Main Passenger Name is missing");
      return;
    }
    if (!mainPassengerTel) {
      setVisible(true);
      setModalHeading("Error Alert");
      setModalText("Passenger Phone Number is missing");
      return;
    }

    const phoneRegex = /^\+\d+$/;

    let checkPhoneNumber = phoneRegex.test(mainPassengerTel);

    if (!checkPhoneNumber) {
      setVisible(true);
      setModalHeading("Error Alert");
      setModalText("Invalid Phone number or missing country code");
      return;
    }

    if (!paymentMethod) {
      setVisible(true);
      setModalHeading("Error Alert");
      setModalText("Kindly Choose payment method");
      return;
    }

    if (paymentMethod == "card") {
      if (!stripe || !elements) {
        return;
      }

      setIsProcessing(true);

      const cardElement = elements.getElement(CardNumberElement);
      const cvcElement = elements.getElement(CardCvcElement);
      const expiryElement = elements.getElement(CardExpiryElement);
      const addressElement = elements.getElement(AddressElement);


      let addressValues = await addressElement.getValue();

      if (!addressValues.complete) {
        setVisible(true);
        setModalText("Your Address fields are missing");
        setModalHeading("Missing Fields");
        return;
      }

      try {
        const { token, error } = await stripe.createToken(cardElement);

        if (error) {
          setVisible(true);
          setModalText(error.message);
          setModalHeading("Missing Fields");
          return;
        } else {
          let dataToSend = {
            user_id: userData.id,
            tour_id: state.tour_id,
            tour_title: state?.tour_title,
            booking_persons: state?.booking_persons,
            booking_price: state?.booking_price,
            booking_date: state?.booking_date,
            payment_type: "card",
            payment_id: token.id,
            booked_person: mainPassengerName,
            person_contact: mainPassengerTel,
            additional_note: additionalInfo,
          };

          const formData = await objectToFormData(dataToSend);
          setLoading(true);
          axios
            .post(`${BaseUri}book_tour.php`, formData)
            .then((res) => {

              if (res?.data?.success) {
                setLoading(false);
                setModalVisible(true);
                setModalHeading("Payment Successful");
                setModalText("Your car has been successfully booked");
              }
            })
            .catch((error) => {
              setLoading(false);
            });
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        setError("An error occurred. Please try again.");
      }

      setIsProcessing(false);

      return;
    }

    let dataToSend = {
      user_id: userData.id,
      tour_id: state.tour_id,
      tour_title: state?.tour_title,
      booking_persons: state?.booking_persons,
      booking_price: state?.booking_price,
      booking_date: state?.booking_date,
      payment_type: "cash",
      payment_id: "",
      booked_person: mainPassengerName,
      person_contact: mainPassengerTel,
      additional_note: additionalInfo,
    };



    const formData = await objectToFormData(dataToSend);
    setLoading(true);
    axios
      .post(`${BaseUri}book_tour.php`, formData)
      .then((res) => {
        if (res?.data?.success) {
          setLoading(false);
          setModalVisible(true);
          setModalHeading("Payment Successful");
          setModalText("Your tour package has been successfully booked");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const screenContainerStyle = {
    display: "flex",
    paddingLeft: 80,
    paddingRight: 80,
  };

  const location = useLocation();

  let { state } = location;

  const journeyDetailsStyle = {
    flex: 1,

    padding: "20px",
  };

  const cardStyle = {
    backgroundColor: "white",
    width: "80%",
    borderRadius: "10px",
    marginBottom: "20px",
  };

  const accountCardStyle = {
    ...cardStyle,
    width: "100%",
  };

  const customerCardStyle = {
    ...cardStyle,
    width: "100%",
  };

  const paymentCardStyle = {
    ...cardStyle,
    width: "100%",
  };
  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover", // This makes the image cover the container while preserving aspect ratio
  };


  return (
    state && (
      <Container fluid>
        <Row
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Col xl={5} lg={5} sm={12} xs={12}>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <div style={customerCardStyle}>
                  <div
                    style={{
                      padding: 10,
                      width: "100%",
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        fontSize: 24,
                        textTransform: "capitalize",
                        paddingLeft: 10,
                      }}
                      className="averageText"
                    >
                      Tour Package Details
                    </h1>
                  </div>

                  <div
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      display: "flex",
                      width: "95%",
                      justifyContent: "space-around",
                      borderBottom: "1px solid gray",
                      marginBottom: 10,
                      marginLeft: 15,
                      marginRight: 15,
                    }}
                  >
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        borderRight: "2px solid gray",
                        paddingRight: 10,
                        display: "flex",
                        justifyContent: "center",
                        width: "33%",
                      }}
                    >
                      <p
                        style={{
                          color: "black",
                          fontSize: 14,
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                        }}
                        className="fourteen"
                      >
                        <span
                          style={{
                            fontSize: 24,
                            fontWeight: "bold",
                            color: "green",
                            fontFamily: "sans-serif",
                          }}
                          className="fourteen"
                        >
                          {new Intl.NumberFormat("en-PK", {
                            style: "currency",
                            currency: "PKR",
                            maximumFractionDigits: 0,
                          }).format(Number(state?.booking_price))}
                        </span>{" "}
                      </p>
                    </div>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        borderRight: "2px solid gray",
                        paddingRight: 20,
                        display: "flex",
                        justifyContent: "center",
                        width: "33%",
                      }}
                      className="paddingZaro"
                    >
                      <p
                        style={{
                          color: "black",
                          fontSize: 14,
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          textAlign: "center",


                        }}

                        className="fullWidth fourteen"
                      >
                        <span
                          style={{
                            fontSize: 24,
                            fontWeight: "bold",
                            color: "green",
                          }}
                          className="fourteen"
                        >
                          {state.booking_price_gbp.toLocaleString("en-GB", {
                            style: "currency",
                            currency: "GBP",
                          })}
                        </span>{" "}
                      </p>
                    </div>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        display: "flex",
                        justifyContent: "center",
                        width: "33%",
                      }}
                    >
                      <p
                        style={{
                          color: "black",
                          fontSize: 14,
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                          fontWeight: "400",

                        }}
                        className="fourteen"
                      >
                        <span
                          style={{
                            fontSize: 24,
                            fontWeight: "bold",
                            color: "green",
                          }}
                          className="fourteen"
                        >
                          {state.booking_price_usd.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </span>{" "}
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "1px solid gray",
                      marginLeft: 15,
                      marginRight: 15,
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="fullwidth"
                    >
                      <p
                        style={{
                          fontSize: 16,
                          color: "gray",
                          width: "30%",
                          fontFamily: "sans-serif",

                        }}
                        className="fourteen"
                      >
                        Package
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontSize: 16,
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          width: "70%",
                        }}
                        className="smallScreenText"
                      >
                        {state.tour_title}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "1px solid gray",
                      marginLeft: 15,
                      marginRight: 15,
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="fullwidth"
                    >
                      <p
                        style={{
                          fontSize: 16,
                          color: "gray",
                          width: "30%",
                          fontFamily: "sans-serif",
                        }}
                        className="fourteen"
                      >
                        Start Date
                      </p>
                      <p
                        style={{
                          fontSize: 16,
                          color: "black",
                          fontSize: 14,
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          width: "70%",
                        }}
                        className="smallScreenText"
                      >
                        {state.booking_date}
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: 15,
                      marginRight: 15,
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="fullwidth"
                    >
                      <p
                        style={{
                          fontSize: 16,
                          color: "gray",
                          width: "30%",
                          fontFamily: "sans-serif",
                        }}
                        className="fourteen"                      >
                        Duration
                      </p>
                      <p
                        style={{
                          fontSize: 16,
                          color: "black",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          width: "70%",
                        }}
                        className="smallScreenText"
                      >
                        {state.tour_days} Day
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} xs={12} lg={12} xl={12}>
                <div style={customerCardStyle}>
                  {/* Customer details content */}
                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        fontSize: 24,
                        paddingLeft: 10,
                      }}
                      className="averageText"

                    >
                      Passenger Details
                    </h1>
                  </div>

                  <div
                    style={{
                      padding: 20,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Main Passenger Name:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <input
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                            width: "100%",
                          }}
                          onChange={(e) => setMainPassengerName(e.target.value)}
                          placeholder="Enter Name..."
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Telephone:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <input
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                            width: "100%",
                          }}
                          maxLength={13}
                          type="tel"
                          onChange={(e) => setMainPassengerTel(e.target.value)}
                          placeholder="+92 xxx xxxxxxx"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Passneger Count:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                            width: "100%",
                          }}
                        >
                          {state?.booking_persons}
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Additional Info:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <textarea
                          onChange={(e) => setAddtionalInfo(e.target.value)}
                          col="4"
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                            width: "100%",
                          }}
                          placeholder="Optional"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={5} lg={5} sm={12} xs={12}>
            <Row>
              <Col sm={12} xs={12} lg={12} xl={12}>
                <div style={accountCardStyle}>
                  {/* Account details content */}

                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 24,
                        fontFamily: "sans-serif",
                        paddingLeft: 10,
                      }}
                      className="averageText"
                    >
                      Account Details
                    </h1>
                  </div>

                  <div
                    style={{
                      padding: 10,
                      justifyContent: "space-between",
                      display: "flex",
                      height: 120,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <MDBContainer
                        style={{ height: 130, width: 165 }}
                        className="my-5 d-flex justify-content-center "
                      >
                        <img
                          src={
                            userData.photo
                              ? `https://smaztech.com/sawari/${userData.photo}`
                              : require("../assets/avatar.avif")
                          }
                          className="rounded-circle"
                          alt="Avatar"
                          style={imageStyle}
                        />
                      </MDBContainer>

                      <div style={{ marginLeft: 5, width: "100%" }}>
                        <p
                          style={{
                            color: "green",
                            fontSize: 18,
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                          }}
                          className="fourteen"
                        >
                          {userData.name}
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontSize: 14,
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                          }}
                          className="smallScreenText"
                        >
                          This booking will be saved to your account
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} xs={12} lg={12} xl={12}>
                <div style={customerCardStyle}>
                  {/* Customer details content */}
                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        fontSize: 24,
                        paddingLeft: 10,
                      }}
                      className="averageText"
                    >
                      Account Holder Details
                    </h1>
                  </div>

                  <div
                    style={{
                      padding: 20,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          fontFamily: "sans-serif",
                          color: "gray",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Booking Type:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                          }}
                        >
                          Personal
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Account Holder Name:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                          }}
                        >
                          {userData.name}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Telephone:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontFamily: "sans-serif",
                            fontSize: 14,
                          }}
                        >
                          {userData.phone}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          fontFamily: "sans-serif",
                          color: "gray",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Email:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          fontFamily: "sans-serif",
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: 14,
                          }}
                        >
                          {userData.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} xs={12} lg={12} xl={12}>
                <div style={paymentCardStyle}>
                  {/* Payment details content */}

                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",

                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        fontSize: 24,
                        paddingLeft: 10,
                      }}
                      className="averageText"
                    >
                      Payment Details
                    </h1>
                  </div>

                  <div style={{ padding: 20, width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Available payment methods:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                          marginBottom: 10,
                        }}
                      >
                        <select
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          name="format"
                          id="format"
                          className="w-full"
                        >
                          <option selected disabled>
                            Payment Method
                          </option>
                          <option value="card">Credit/Debit Card</option>
                          {/* <option value="Cash">Cash</option> */}
                        </select>
                      </div>
                    </div>

                    {paymentMethod == "card" && (
                      <div style={{ width: "100%" }}>
                        <label
                          style={{
                            width: "100%",
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                          }}
                        >
                          Card Number:
                          <div
                            style={{
                              border: "1px solid gray",
                              padding: 10,
                              borderRadius: 5,
                            }}
                          >
                            <CardNumberElement />
                          </div>
                        </label>
                        <label
                          style={{
                            fontFamily: "sans-serif",
                            width: "100%",
                            marginTop: 10,
                            fontWeight: "400",
                          }}
                        >
                          Expiry:
                          <div
                            style={{
                              border: "1px solid gray",

                              padding: 10,
                              borderRadius: 5,
                            }}
                          >
                            <CardExpiryElement />
                          </div>
                        </label>
                        <label
                          style={{
                            fontFamily: "sans-serif",
                            width: "100%",
                            marginTop: 10,
                            fontWeight: "400",
                          }}
                        >
                          CVC:
                          <div
                            style={{
                              border: "1px solid gray",
                              padding: 10,

                              borderRadius: 5,
                            }}
                          >
                            <CardCvcElement />
                          </div>
                        </label>
                        <label
                          style={{
                            fontFamily: "sans-serif",
                            width: "100%",
                            marginTop: 10,
                          }}
                        >
                          Address
                          <div
                            style={{
                              padding: 8,
                              borderRadius: 5,
                            }}
                          >
                            <AddressElement options={{ mode: "billing" }} />
                          </div>
                        </label>
                      </div>
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          fontSize: 14,
                          padding: 8,
                          width: 200,
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "center",
                          fontFamily: "sans-serif",
                          borderRadius: 5,
                        }}
                        onClick={() => !loading && handleBookCar()}
                      >
                        {loading ? (
                          <RingLoader
                            size={30}
                            color="white"
                            loading={loading}
                          />
                        ) : (
                          "Pay now"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {modalVisible && (
          <PaymentModals
            visible={modalVisible}
            heading={modalHeading}
            text={modalText}
            close={() => navigate("/")}
          />
        )}

        {visible && (
          <Modals
            visible={visible}
            heading={modalHeading}
            text={modalText}
            close={() => setVisible(false)}
          />
        )}
      </Container>
    )
  );
}

export default TourConfirmBooking;
// state && (
//   <div style={screenContainerStyle}>
//     <div style={journeyDetailsStyle}>
//       {/* Long card showing journey details */}
//       <div style={cardStyle}>
//         <div
//           style={{
//             padding: 10,
//             backgroundColor: "green",
//             borderTopRightRadius: "10px",
//             borderTopLeftRadius: "10px",
//           }}
//         >
//           <h1
//             style={{
//               color: "white",
//               fontWeight: "bold",
//               fontSize: 24,
//               paddingLeft: 10,
//             }}
//           >
//             Rent Car Details
//           </h1>
//         </div>
//         <div style={{ paddingLeft: 15, paddingRight: 15 }}>
//           <p
//             style={{
//               padding: 20,
//               textAlign: "center",
//               fontSize: 16,
//               fontWeight: "400",
//               borderBottom: "1px solid gray",
//             }}
//           >
//             Booking assinged to you at this place
//           </p>
//         </div>

//         <div
//           style={{
//             paddingLeft: 15,
//             paddingRight: 15,
//             display: "flex",
//             justifyContent: "space-between",
//             borderBottom: "1px solid gray",
//             marginBottom: 10,
//             marginLeft: 15,
//             marginRight: 15,
//           }}
//         >
//           <div
//             style={{
//               marginTop: 20,
//               marginBottom: 20,
//               borderRight: "1px solid gray",
//               paddingRight: 10,
//             }}
//           >
//             <p style={{ color: "black", fontSize: 14, fontWeight: "bold" }}>
//               {" "}
//               PKR{" "}
//               <span
//                 style={{ fontSize: 24, fontWeight: "bold", color: "green" }}
//               >
//                 {Math.ceil(state?.booking_price)}
//               </span>{" "}
//             </p>
//           </div>
//           <div
//             style={{
//               marginTop: 20,
//               marginBottom: 20,
//               borderRight: "1px solid gray",
//               paddingRight: 20,
//             }}
//           >
//             <p style={{ color: "black", fontSize: 14, fontWeight: "bold" }}>
//               {" "}
//               GBP{" "}
//               <span
//                 style={{ fontSize: 24, fontWeight: "bold", color: "green" }}
//               >
//                 1000
//               </span>{" "}
//             </p>
//           </div>
//           <div style={{ marginTop: 20, marginBottom: 20 }}>
//             <p style={{ color: "black", fontSize: 14, fontWeight: "bold" }}>
//               {" "}
//               USD{" "}
//               <span
//                 style={{ fontSize: 24, fontWeight: "bold", color: "green" }}
//               >
//                 2000
//               </span>{" "}
//             </p>
//           </div>
//         </div>

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             borderBottom: "1px solid gray",
//             marginLeft: 15,
//             marginRight: 15,
//             paddingBottom: 10,
//             marginBottom: 10,
//           }}
//         >
//           <div
//             style={{
//               width: "80%",
//               alignSelf: "center",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <p style={{ fontSize: 14, color: "gray", width: "30%" }}>
//               Tour Title
//             </p>
//             <p
//               style={{
//                 fontSize: 14,
//                 color: "black",
//                 fontSize: 14,
//                 fontWeight: "bold",
//                 width: "70%",
//               }}
//             >
//               {state?.tour_title}
//             </p>
//           </div>
//         </div>

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             borderBottom: "1px solid gray",
//             marginLeft: 15,
//             marginRight: 15,
//             paddingBottom: 10,
//           }}
//         >
//           <div
//             style={{
//               width: "80%",
//               alignSelf: "center",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <p style={{ fontSize: 14, color: "gray", width: "30%" }}>
//               Tour Date
//             </p>
//             <p
//               style={{
//                 fontSize: 14,
//                 color: "black",
//                 fontSize: 14,
//                 fontWeight: "bold",
//                 width: "70%",
//               }}
//             >
//               {state?.booking_date}
//             </p>
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             borderBottom: "1px solid gray",
//             marginLeft: 15,
//             marginRight: 15,
//             paddingBottom: 10,
//           }}
//         >
//           <div
//             style={{
//               width: "80%",
//               alignSelf: "center",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <p style={{ fontSize: 14, color: "gray", width: "30%" }}>
//               Booking Persons
//             </p>
//             <p
//               style={{
//                 fontSize: 14,
//                 color: "black",
//                 fontSize: 14,
//                 fontWeight: "bold",
//                 width: "70%",
//               }}
//             >
//               {state?.booking_persons}
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>

//     <div
//       style={{
//         flex: 1,
//         padding: "20px",
//         display: "flex",
//         flexDirection: "column",
//       }}
//     >
//       {/* Account details */}
//       <div style={accountCardStyle}>
//         {/* Account details content */}

//         <div
//           style={{
//             padding: 10,
//             backgroundColor: "green",
//             borderTopRightRadius: "10px",
//             borderTopLeftRadius: "10px",
//           }}
//         >
//           <h1
//             style={{
//               color: "white",
//               fontWeight: "bold",
//               fontSize: 24,
//               paddingLeft: 10,
//             }}
//           >
//             Account Details
//           </h1>
//         </div>

//         <div
//           style={{
//             padding: 10,
//             justifyContent: "space-between",
//             display: "flex",
//             height: 120,
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               alignItems: "center",
//             }}
//           >
//             <MDBContainer
//               style={{ height: 115, width: 150 }}
//               className="my-5 d-flex justify-content-center "
//             >
//               <img
//                 src={
//                   userData.photo
//                     ? `https://smaztech.com/sawari/${userData.photo}`
//                     : "https://mdbcdn.b-cdn.net/img/new/avatars/4.webp"
//                 }
//                 className="rounded-circle"
//                 alt="Avatar"
//                 stlye={{ width: 50, height: 100 }}
//               />
//             </MDBContainer>

//             <div style={{ marginLeft: 5, width: "100%" }}>
//               <p
//                 style={{ color: "green", fontSize: 18, fontWeight: "bold" }}
//               >
//                 {userData.name}
//               </p>
//               <p
//                 style={{ color: "black", fontSize: 14, fontWeight: "400" }}
//               >
//                 This booking will be saved to your account
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Customer details */}
//       <div style={customerCardStyle}>
//         {/* Customer details content */}
//         <div
//           style={{
//             padding: 10,
//             backgroundColor: "green",
//             borderTopRightRadius: "10px",

//             borderTopLeftRadius: "10px",
//           }}
//         >
//           <h1
//             style={{
//               color: "white",
//               fontWeight: "bold",
//               fontSize: 24,
//               paddingLeft: 10,
//             }}
//           >
//             Customer Details
//           </h1>
//         </div>

//         <div style={{ padding: 20, width: "100%" }}>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               width: "100%",
//               alignItems: "center",
//             }}
//           >
//             <p
//               style={{
//                 fontSize: 14,
//                 color: "gray",
//                 fontWeight: "400",
//                 width: "30%",
//               }}
//             >
//               Booking Type:
//             </p>

//             <div
//               style={{
//                 border: "1px solid gray",
//                 padding: 7,
//                 paddingLeft: 10,
//                 width: "60%",
//                 marginLeft: 30,
//                 borderRadius: 5,
//                 marginBottom: 10,
//               }}
//             >
//               <p
//                 style={{ color: "black", fontWeight: "500", fontSize: 14 }}
//               >
//                 Personal
//               </p>
//             </div>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               width: "100%",
//               alignItems: "center",
//             }}
//           >
//             <p
//               style={{
//                 fontSize: 14,
//                 color: "gray",
//                 fontWeight: "400",
//                 width: "30%",
//               }}
//             >
//               Customer Name:
//             </p>

//             <div
//               style={{
//                 border: "1px solid gray",
//                 padding: 7,
//                 paddingLeft: 10,
//                 width: "60%",
//                 marginLeft: 30,
//                 borderRadius: 5,
//                 marginBottom: 10,
//               }}
//             >
//               <p
//                 style={{ color: "black", fontWeight: "500", fontSize: 14 }}
//               >
//                 {userData.name}
//               </p>
//             </div>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               width: "100%",
//               alignItems: "center",
//             }}
//           >
//             <p
//               style={{
//                 fontSize: 14,
//                 color: "gray",
//                 fontWeight: "400",
//                 width: "30%",
//               }}
//             >
//               Telephone:
//             </p>

//             <div
//               style={{
//                 border: "1px solid gray",
//                 padding: 7,
//                 paddingLeft: 10,
//                 width: "60%",
//                 marginLeft: 30,
//                 borderRadius: 5,
//                 marginBottom: 10,
//               }}
//             >
//               <p
//                 style={{ color: "black", fontWeight: "500", fontSize: 14 }}
//               >
//                 {userData.phone}
//               </p>
//             </div>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               width: "100%",
//               alignItems: "center",
//             }}
//           >
//             <p
//               style={{
//                 fontSize: 14,
//                 color: "gray",
//                 fontWeight: "400",
//                 width: "30%",
//               }}
//             >
//               Email:
//             </p>

//             <div
//               style={{
//                 border: "1px solid gray",
//                 padding: 7,
//                 paddingLeft: 10,
//                 width: "60%",
//                 marginLeft: 30,
//                 borderRadius: 5,
//                 marginBottom: 10,
//               }}
//             >
//               <p
//                 style={{ color: "black", fontWeight: "500", fontSize: 14 }}
//               >
//                 {userData.email}
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Payment details */}
//       <div style={paymentCardStyle}>
//         {/* Payment details content */}

//         <div
//           style={{
//             padding: 10,
//             backgroundColor: "green",
//             borderTopRightRadius: "10px",

//             borderTopLeftRadius: "10px",
//           }}
//         >
//           <h1
//             style={{
//               color: "white",
//               fontWeight: "bold",
//               fontSize: 24,
//               paddingLeft: 10,
//             }}
//           >
//             Payment Details
//           </h1>
//         </div>

//         <div style={{ padding: 20, width: "100%" }}>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-start",
//               width: "100%",
//               alignItems: "center",
//             }}
//           >
//             <p
//               style={{
//                 fontSize: 14,
//                 color: "gray",
//                 fontWeight: "400",
//                 width: "30%",
//               }}
//             >
//               Available payment methods:
//             </p>

//             <div
//               style={{
//                 border: "1px solid gray",
//                 padding: 7,
//                 paddingLeft: 10,
//                 width: "60%",
//                 marginLeft: 30,
//                 borderRadius: 5,
//                 marginBottom: 10,
//               }}
//             >
//               <select
//                 onChange={(e) => setPaymentMethod(e.target.value)}
//                 name="format"
//                 id="format"
//                 className="w-full"
//               >
//                 <option selected disabled>
//                   Payment Method
//                 </option>
//                 <option value="card">Credit/Debit Card</option>
//                 <option value="Cash">Cash</option>
//               </select>
//             </div>
//           </div>

//           {paymentMethod == "card" && (
//             <div style={{ width: "100%" }}>
//               <label style={{ width: "100%" }}>
//                 Card Number
//                 <div
//                   style={{
//                     border: "1px solid black",
//                     padding: 8,
//                     borderRadius: 5,
//                   }}
//                 >
//                   <CardNumberElement />
//                 </div>
//               </label>
//               <label style={{ width: "100%" }}>
//                 Expiry
//                 <div
//                   style={{
//                     border: "1px solid black",
//                     padding: 8,
//                     borderRadius: 5,
//                   }}
//                 >
//                   <CardExpiryElement />
//                 </div>
//               </label>
//               <label style={{ width: "100%" }}>
//                 CVC
//                 <div
//                   style={{
//                     border: "1px solid black",
//                     padding: 8,
//                     borderRadius: 5,
//                   }}
//                 >
//                   <CardCvcElement />
//                 </div>
//               </label>
//               <label style={{ width: "100%" }}>
//                 Address
//                 <div
//                   style={{
//                     border: "1px solid black",
//                     padding: 8,
//                     borderRadius: 5,
//                   }}
//                 >
//                   <AddressElement options={{ mode: "billing" }} />
//                 </div>
//               </label>
//             </div>
//           )}
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//             }}
//           >
//             <button
//               style={{
//                 backgroundColor: "black",
//                 color: "white",
//                 fontSize: 14,
//                 display: "flex",
//                 justifyContent: "center",
//                 padding: 8,
//                 width: 200,
//                 marginTop: 10,
//                 borderRadius: 5,
//               }}
//               onClick={() => handleBookCar()}
//             >
//               {loading ? (
//                 <RingLoader color="white" size={30} loading={loading} />
//               ) : (
//                 "Pay now"
//               )}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//     {modalVisible && (
//       <PaymentModals
//         visible={modalVisible}
//         heading={modalHeading}
//         text={modalText}
//         close={() => navigate("/")}
//       />
//     )}

//     {visible && (
//       <Modals
//         visible={visible}
//         heading={modalHeading}
//         text={modalText}
//         close={() => setVisible(false)}
//       />
//     )}
//   </div>
// )
