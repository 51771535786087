import React from 'react'
import { BannerTravel } from './components/BannerTravel'

export const MainTravel = () => {
  return (
    <>
    <BannerTravel/>
    </>
  )
}
