import React, { useContext, useEffect } from "react";
import { Main } from "../HomeComponents";
import mapKeyContext from "../../Constant/GoogleMapKeyContext/mapKeycontext";
import { useSafeLayoutEffect } from "@chakra-ui/react";
import axios from "axios";
import BaseUri from "../../Constant/BaseUri";

export const Home = () => {
  const mapKeyCont = useContext(mapKeyContext);

  let { googleMapKey, setGoogleMapKey } = mapKeyCont;

  const getGoogleMapKey = () => {
    axios.get(`${BaseUri}get_all_api_keys.php`).then((res) => {
      let { data } = res;

      if (data && data.length > 0) {
        let key = data[0]?.api_key;

        setGoogleMapKey(key);
      }
    });
  };

  useEffect(() => {
    getGoogleMapKey();
  }, []);

  return (
    <>
      <Main />
    </>
  );
};
