import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import mapKeyContext from "../../../Constant/GoogleMapKeyContext/mapKeycontext";
import axios from "axios";
import BaseUri from "../../../Constant/BaseUri";
import "../style.css"

export const BannerSection = () => {
  const location = useLocation();
  const [googleMapKey, setGoogleMapKey] = useState("");

  const [bookingData, setBookingData] = useState("");

  useEffect(() => {
    if (location?.state) {
      setBookingData(location.state);
    }
  }, []);

  const getGoogleMapKey = () => {
    axios.get(`${BaseUri}get_all_api_keys.php`).then((res) => {
      let { data } = res;

      if (data && data.length > 0) {
        let key = data[0]?.api_key;
        setGoogleMapKey(key);
      }
    });
  };

  useEffect(() => {
    getGoogleMapKey();
  }, []);

  const [directions, setDirections] = useState(null);

  // const google = window.google;

  useEffect(() => {
    if (bookingData) {
      // if (!google) return;
      //   const directionsService = new google.maps.DirectionsService();
      //   const origin = new google.maps.LatLng(37.7749, -122.4194); // Example origin coordinates
      //   const via = new google.maps.LatLng(36.7783, -119.4179);   // Example via coordinates
      //   const destination = new google.maps.LatLng(34.0522, -118.2437); // Example destination coordinates
      //   directionsService.route(
      //     {
      //       origin: origin,
      //       waypoints: [{ location: via }],
      //       destination: destination,
      //       travelMode: google.maps.TravelMode.DRIVING,
      //     },
      //     (result, status) => {
      //       if (status === google.maps.DirectionsStatus.OK) {
      //         setDirections(result);
      //       } else {
      //         console.error("Error fetching directions:", status);
      //       }
      //     }
      //   );
    }
  }, [bookingData]);

  // const defaultCenter = { lat: 37.7749, lng: -122.4194 }; // Example initial center

  // const renderMarkers = (map, maps) => {
  //   new maps.Marker({
  //     position: { lat: 37.7749, lng: -122.4194 }, // Example origin coordinates
  //     map,
  //     title: "Origin",
  //   });

  //   new maps.Marker({
  //     position: { lat: 36.7783, lng: -119.4179 }, // Example via coordinates
  //     map,
  //     title: "Via",
  //   });

  //   new maps.Marker({
  //     position: { lat: 34.0522, lng: -118.2437 }, // Example destination coordinates
  //     map,
  //     title: "Destination",
  //   });
  // };

  // const MarkerComponent = ({ text, color, id, focus }) => {
  //   return (
  //     <button

  //       style={{ width: 30, height: 30 }}
  //     >
  //       {/* <FaMapMarkerAlt size={40} color={color} /> */}
  //         <p
  //           style={{
  //             fontSize: 15,
  //             color: "black",
  //             width: 80,
  //             textAlign: "left",
  //           }}
  //         >
  //           {text}
  //         </p>

  //     </button>
  //   );
  // };

  const mapOptions = {
    styles: [
      // Add your custom map styles here
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#a2daf2",
          },
        ],
      },
      // Add more styles as needed
    ],
  };

  const mapStyles = {
    width: "100%",
    height: "150px", // Set the desired height here
  };

  return (
    <>
      {bookingData &&
        Object.keys(bookingData).length > 0 &&
        bookingData.pickupCords.lat &&
        bookingData.pickupCords.lng ? (
        <div style={{ width: 100, height: "150px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: googleMapKey, // Replace with your Google Maps API key
              //  libraries: "geometry,drawing,places",
            }}
            center={{
              lat: bookingData?.pickupCords?.lat,
              lng: bookingData?.pickupCords?.lng,
            }}
            style={mapStyles}
            options={mapOptions}
            //  defaultCenter={defaultCenter}
            defaultZoom={8}
          //  yesIWantToUseGoogleMapApiInternals
          //  onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          />
        </div>
      ) : (
        <Container fluid className="bgAS" style={{ height: 600, width: "100%" }} >
          <Row>
            <Col sm={12}   >

              <div className="webkit ser" style={{ width: "80vw" }} >
                <h2
                  className="mediumText text-white font-extrabold"
                  style={{ width: "100%", textTransform: "uppercase", marginBottom: 10, fontSize: 32 }}
                >
                  AirPORT Transfers
                </h2>
                <h4
                  className="extraSmall text-white font-extrabold"
                  style={{ fontSize: 18, width: "100%", }}
                >
                  Arrive in style, with Sawari Pakistan - your trusted partner for
                  seamless airport transfers across Pakistan.
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
