import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { RingLoader } from "react-spinners";
function CancelBookingModal(props) {
  let { showModal, heading, open, close, reason, loading } = props;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{ color: "red", fontWeight: "bold" }}>
          Write Cancellation Reason
        </h4>
        <textarea
          onChange={(e) => reason(e.target.value)}
          placeholder="Enter Reason..."
          style={{
            marginTop: 10,
            border: "1px solid black",
            width: "100%",
            maxHeight: 150,
            minHeight: 100,
            padding: 5,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ display: "flex", justifyContent: "center",minWidth:100 }}
          className="btn  btn-hover"
          onClick={() => close()}
        >
          {loading ? (
            <RingLoader color="white" size={30} loading={loading} />
          ) : (
            "Cancel Booking"
          )}
        </Button>
        <Button className="btn btn-alert btn-hover" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CancelBookingModal;
