import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import loginContext from "../../Constant/LoginContext/loginContext";
import axios from "axios";
import BaseUri from "../../Constant/BaseUri";
import CancelBookingModal from "../Navbar/Components/cancelModal";
import { Text, Textarea } from "@chakra-ui/react";
import { RingLoader } from "react-spinners";
import jsPDF from "jspdf";
import logo from "../assets/LOGO.png";
import html2canvas from "html2canvas"


function BookingHistory() {
  const userContext = useContext(loginContext);

  let { userData, setUserData } = userContext;
  const [Currency, setCurrency] = useState("PKR");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [dataClicked, setDataClicked] = useState({});
  const [status, setStatus] = useState("upcoming");
  const [rentStatus, setRentStatus] = useState("upcoming");
  const [RentACarBooking, setRentACarBooking] = useState([]);
  const [tourStatus, setTourStatus] = useState("upcoming");
  const [tourBooking, setTourBooking] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState("airport");
  const [totalAirportBookings, setTotalAirportBookings] = useState([]);
  const [totalRentalBooking, setTotalRentalBookings] = useState([]);
  const [totalTourBooking, setTotalTourBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState(0);
  const [upcomingBooking, setUpcomingBookings] = useState(0);
  const [cancelledBooking, setCancelledBookings] = useState(0);

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };



  React.useEffect(() => {
    handleCheckUser();
  }, []);

  const [AirportBooking, setAirportBooking] = useState([]);

  const getAirportBookingData = () => {
    let id = {
      id: userData.id,
    };

    axios
      .get(`${BaseUri}get_user_airport_bookings.php`, { params: id })
      .then((res) => {
        let { data } = res;


        setTotalAirportBookings(data);

        let myData =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            let now = new Date();
            let nowTime = now.getTime();

            let bookingDate = new Date(e.booking_date);

            let bookingHours = bookingDate.getHours();

            let selectedTime = e.booking_time;


            let splitSelectedTime = selectedTime.split(":");
            let selectedHoursBooking = splitSelectedTime[0];
            let selectedMinutesBooking = splitSelectedTime[1].slice(0, 2);


            let bookedMinutes = Number(selectedMinutesBooking);

            let bookedMinutesConvert = Math.round(bookedMinutes / 60);
            let bookedHours =
              Number(selectedHoursBooking) + Number(bookedMinutesConvert);
            let remainingBookedHours = bookedHours - bookingHours;

            let bookingTime = bookingDate.getTime();

            let diff = bookingTime - nowTime;
            let diffHours = diff / 1000 / 60 / 60;

            let split = diffHours.toString().split(".");
            let hours = Number(split[0]) + Number(remainingBookedHours);

            let minutes = split[1];

            let totalMinutes = `0.${minutes}` * 60;

            let splitMinutes = totalMinutes.toString().split(".");

            let remaningMinutes = Number(splitMinutes[0]);
            let seconds = splitMinutes[1];
            let totalSeconds = `0.${seconds}` * 60;
            totalSeconds =
              totalSeconds.length == 1 ? `0${totalSeconds}` : totalSeconds;

            let days = null;
            let totalMonth;
            let totalYears;
            let totalDays;

            if (Number(hours > 24)) {
              let month;
              let year;
              let day;

              days = hours / 24;

              if (Number(days) > 360) {
                year = days / 360;

                let split = year.toString().split(".");

                year = split[0];

                let remaingingDays = split[1];

                remaingingDays = `0.${remaingingDays}` * 360;

                let month = remaingingDays / 30;

                let moreSplit = month.toString().split(".");

                month = moreSplit[0];

                day = Math.round(Number(`0.${moreSplit[1]}` * 30));
              } else if (days >= 30) {
                month = days / 30;

                let split = month.toString().split(".");

                month = split[0];

                let remaingingDays = `0.${split[1]}` * 30;

                day = Math.round(Number(remaingingDays));
              } else {
                day = Math.round(days);
              }

              totalMonth = month;
              totalYears = year;
              totalDays = day;
            }

            let remainingTime = `${hours ? hours : 0}hr  ${remaningMinutes ? remaningMinutes : 0
              }min  ${Math.ceil(Number(totalSeconds))}sec`;

            let remaingingDays;

            if (totalYears && totalMonth) {
              remaingingDays = `${totalYears ?? 0}y    ${totalMonth ?? 0
                }m     ${totalDays ?? 0}d `;
            } else if (totalMonth) {
              remaingingDays = `${totalMonth ?? 0}m     ${totalDays ?? 0}d `;
            } else {
              remaingingDays = `${totalDays ?? 0}d`;
            }


            return {
              ...e,
              remainingTime: days ? remaingingDays : remainingTime,
              type: "airport",
            };
          });

        let filteredData =
          myData &&
          myData.length > 0 &&
          myData.filter((e, i) => {
            if (status == "All") {
              return e;
            } else if (status == "upcoming") {
              return e.booking_status == "upcoming";
            } else if (status == "cancelled") {
              return e.booking_status == "cancelled";
            } else {
              return e.booking_status == "completed";
            }
          });

        let sortData =
          filteredData &&
          filteredData.length > 0 &&
          filteredData.sort(
            (a, b) => new Date(a.booking_date) - new Date(b.booking_date)
          );

        setAirportBooking(sortData);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getRentCarBookingData = () => {
    let id = {
      id: userData.id,
    };

    axios
      .get(`${BaseUri}get_user_rent_a_car_bookings.php`, { params: id })
      .then((res) => {
        let { data } = res;

        setTotalRentalBookings(data);

        let myData =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            let now = new Date();
            let nowTime = now.getTime();

            let bookingDate = new Date(e.booking_start_date);

            let bookingTime = bookingDate.getTime();

            let diff = bookingTime - nowTime;
            let diffHours = diff / 1000 / 60 / 60;

            let split = diffHours.toString().split(".");
            let hours = Number(split[0]);
            let minutes = split[1];

            let totalMinutes = `0.${minutes}` * 60;

            let splitMinutes = totalMinutes.toString().split(".");

            let remaningMinutes = Number(splitMinutes[0]);
            let seconds = splitMinutes[1];

            let totalSeconds = `0.${seconds}` * 60;

            totalSeconds =
              totalSeconds.length == 1 ? `0${totalSeconds}` : totalSeconds;

            let days = null;
            let totalMonth;
            let totalYears;
            let totalDays;

            if (Number(hours > 24)) {
              let month;
              let year;
              let day;

              days = hours / 24;

              if (Number(days) > 360) {
                year = days / 360;

                let split = year.toString().split(".");

                year = split[0];

                let remaingingDays = split[1];

                remaingingDays = `0.${remaingingDays}` * 360;

                let month = remaingingDays / 30;

                let moreSplit = month.toString().split(".");

                month = moreSplit[0];

                day = Math.round(Number(`0.${moreSplit[1]}` * 30));

              } else if (days >= 30) {
                month = days / 30;

                let split = month.toString().split(".");

                month = split[0];

                let remaingingDays = `0.${split[1]}` * 30;

                day = Math.round(Number(remaingingDays));
              } else {
                day = Math.round(days);
              }

              totalMonth = month;
              totalYears = year;
              totalDays = day;
            }

            let remainingTime = `${hours}hr  ${remaningMinutes}min  ${Math.ceil(
              Number(totalSeconds)
            )}sec`;

            let remaingingDays;

            if (totalYears && totalMonth) {
              remaingingDays = `${totalYears ?? 0}y    ${totalMonth ?? 0
                }m     ${totalDays ?? 0}d `;
            } else if (totalMonth) {
              remaingingDays = `${totalMonth ?? 0}m     ${totalDays ?? 0}d `;
            } else {
              remaingingDays = `${totalDays ?? 0}d`;
            }

            return {
              ...e,
              remainingTime: days ? remaingingDays : remainingTime,
              type: "rent",
            };
          });

        let filteredData =
          myData &&
          myData.length > 0 &&
          myData.filter((e, i) => {
            if (rentStatus == "All") {
              return e;
            } else if (rentStatus == "upcoming") {
              return e.booking_status == "upcoming";
            } else if (rentStatus == "cancelled") {
              return e.booking_status == "cancelled";
            } else {
              return e.booking_status == "completed";
            }
          });

        let sortData =
          filteredData &&
          filteredData.length > 0 &&
          filteredData.sort(
            (a, b) =>
              new Date(a.booking_start_date) - new Date(b.booking_start_date)
          );

        setRentACarBooking(sortData ? sortData : []);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getTourBookingData = () => {
    let id = {
      id: userData.id,
    };

    // setLoading(true);

    axios
      .get(`${BaseUri}get_user_tour_bookings.php`, { params: id })
      .then((res) => {
        let { data } = res;

        setTotalTourBookings(data);

        let myData =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            let now = new Date();
            let nowTime = now.getTime();

            let bookingDate = new Date(e.booking_date);
            let bookingTime = bookingDate.getTime();
            let diff = bookingTime - nowTime;
            let diffHours = diff / 1000 / 60 / 60;

            let split = diffHours.toString().split(".");
            let hours = Number(split[0]);

            let minutes = split[1];

            let totalMinutes = `0.${minutes}` * 60;

            let splitMinutes = totalMinutes.toString().split(".");

            let remaningMinutes = Number(splitMinutes[0]);
            let seconds = splitMinutes[1];

            let totalSeconds = `0.${seconds}` * 60;

            let days = null;
            let totalMonth;
            let totalYears;
            let totalDays;

            if (Number(hours > 24)) {
              let month;
              let year;
              let day;

              days = hours / 24;

              if (Number(days) > 360) {
                year = days / 360;

                let split = year.toString().split(".");

                year = split[0];

                let remaingingDays = split[1];

                remaingingDays = `0.${remaingingDays}` * 360;

                let month = remaingingDays / 30;

                let moreSplit = month.toString().split(".");

                month = moreSplit[0];

                day = Math.round(Number(`0.${moreSplit[1]}` * 30));

              } else if (days >= 30) {
                month = days / 30;

                let split = month.toString().split(".");

                month = split[0];

                let remaingingDays = `0.${split[1]}` * 30;

                day = Math.round(Number(remaingingDays));
              } else {
                day = Math.round(days);
              }

              totalMonth = month;
              totalYears = year;
              totalDays = day;
            }

            let remainingTime = `${hours}hr  ${remaningMinutes}min  ${Math.ceil(
              Number(totalSeconds)
            )}sec`;

            let remaingingDays;

            if (totalYears && totalMonth) {
              remaingingDays = `${totalYears ?? 0}y    ${totalMonth ?? 0
                }m     ${totalDays ?? 0}d `;
            } else if (totalMonth) {
              remaingingDays = `${totalMonth ?? 0}m     ${totalDays ?? 0}d `;
            } else {
              remaingingDays = `${totalDays ?? 0}d`;
            }

            return {
              ...e,
              remainingTime: days ? remaingingDays : remainingTime,
              type: "tour",
            };
          });


        let filteredData =
          myData &&
          myData.length > 0 &&
          myData.filter((e, i) => {
            if (tourStatus == "All") {
              return e;
            } else if (tourStatus == "upcoming") {
              return e.booking_status == "upcoming";
            } else if (tourStatus == "cancelled") {
              return e.booking_status == "cancelled";
            } else {
              return e.booking_status == "completed";
            }
          });
        setLoading(false);

        let sortData =
          filteredData &&
          filteredData.length > 0 &&
          filteredData.sort(
            (a, b) => new Date(a.booking_date) - new Date(b.booking_date)
          );

        setTourBooking(sortData);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      });
  };

  React.useEffect(() => {
    let interval;
    if (userData.id) {
      interval = setInterval(() => {
        getAirportBookingData();
        getRentCarBookingData();
        getTourBookingData();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [userData?.id, status, rentStatus, tourStatus]);

  useEffect(() => {
    let allBookings = [
      ...totalAirportBookings,
      ...totalRentalBooking,
      ...totalTourBooking,
    ];


    let completed =
      allBookings &&
      allBookings.length > 0 &&
      allBookings.filter((e, i) => e.booking_status == "completed");
    let cancelled =
      allBookings &&
      allBookings.length > 0 &&
      allBookings.filter((e, i) => e.booking_status == "cancelled");
    let upcoming =
      allBookings &&
      allBookings.length > 0 &&
      allBookings.filter((e, i) => e.booking_status == "upcoming");

    setCompletedBookings(completed.length);
    setCancelledBookings(cancelled.length);
    setUpcomingBookings(upcoming.length);
  }, [totalAirportBookings, totalRentalBooking, totalTourBooking]);

  function waitForImagesToLoad(content) {
    const images = content.querySelectorAll('img');
    const imagePromises = Array.from(images).map((img) => {
      return new Promise((resolve) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
        }
      });
    });
    return Promise.all(imagePromises);
  }



  const generatePdf = async (booking) => {


    const content = document.createElement('div');

    // Define the HTML content for the PDF
    content.innerHTML = `
    <div>  
    <div style="width:700px;margin:0px auto;">
    	<table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td width="30%" style="width:30%;"><img src="https://smaztech.com/sawari/assets/email-template/logo.jpg" alt="SAWARI PAKISTAN" style="width:200px;"></td>
            <td width="70%" style="width:70%;">
            	<div style="text-align:right; font-family:Arial, Helvetica, sans-serif; line-height:18px;">
                	<div style="font-size:12px; color:#000; font-weight:bold;">SAWARI AIRPORT TRANSFERS (SMC-PRIVATE) LIMITED</div>
        			<div style="font-size:13px; color:#000;">Office No. 3, Rehmat House, Sandal Road, Gujarkhan, Rawalpindi, Pakistan.</div>
					<div style="font-size:13px; color:#000; font-weight:bold;"><span style="color:#006600;">24 Hour Support</span> <a href="mailto:hello@sawaripakistan.com" style="color:#000; text-decoration:none;">hello@sawaripakistan.com</a></div>
                    <div style="font-size:24px; color:#000; font-weight:bold; line-height:24px;">+92 336 4400044</div>
                </div>
            </td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
        </table>
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:16px; color:#fff; font-weight:bold; background-color:#006600; border-radius:8px; padding:10px; text-align:center; margin-bottom:10px;"><p style="margin-bottom:10px;">BOOKING CONFIRMATION</p></div>
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; font-weight:bold; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;margin-left:10px;margin-left:5px;font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:11px; color:#000;" >Booking Reference Number : <span style="color:#ff0000;">${booking.id}</span></p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Main Passenger :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${booking.passenger_name}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.passenger_contact}</td>
                      </tr>
                    </table>
                </div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Paid Amount :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">PKR ${booking.booking_total_fare}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Payment Method :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.payment_type}</td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:11px; color:#000; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;margin-left:5px";font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:11px; color:#000; ><span style="font-weight:bold; ">Booked Date &amp; Time :</span> ${booking.booking_date} ${booking.booking_time}</p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;display:flex;flex-direction:column;justify-content;flex-start;padding-bottom:20px">
                	<table width="100%" border="1" cellspacing="0" cellpadding="0" >
                      <tr  >
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Account Holder :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${userData.name}</td>
                      </tr>
                      
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${userData.phone}</td>
                      </tr>
                      <tr  >
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Email :</td>
                        <td  style="padding-top:5px; padding-bottom:5px; color:#000; text-decoration:none;">${userData.email}</td>
                      </tr>
                      <tr  >
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:25px;">Account Type :</td>
                        <td  style="padding-top:5px; padding-bottom:25px;">Personal</td>
                      </tr>
                      
                      
                    </table>
                </div>
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" >
        
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tr>
    <td style="font-weight:bold; width:18%;  padding-top:5px; padding-bottom:5px;">Journey Type :</td>
    <td style="padding-top:5px; padding-bottom:5px;">Airport Transfer</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Date :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_date}</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Time :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_time}</td>
  </tr>
  <tr>
    <td style="vertical-align:top; font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.pick_up}
    	${(booking.flight_arrival_time || booking.flight_no) && `<div>
        	<table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td width="13%" style="width:13%">Flight No:</td>
                <td width="12%" style="width:12%">${booking.flight_no}</td>
                <td width="15%" style="width:14%">&nbsp;</td>
                <td width="15%" style="width:15%">Arrival Time:</td>
                <td width="12%" style="width:12%">${booking.flight_arrival_time}</td>
                <td>&nbsp;</td>
              </tr>
            </table>
        </div>`}
    </td>
  </tr>
  
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Via :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.via_location}</td>
  </tr>
   <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Destination :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.drop_off}</td>
  </tr>
   <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Distance :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_distance} km</td>
  </tr>
</table>

        	 
        </div>
        
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
                <div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:6px; padding-bottom:5px;">Vehicle Type :</td>
                        <td style="padding-top:6px; padding-bottom:5px;">${booking.car_title}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:10px;"></td>
                        <td  style="padding-top:5px; padding-bottom:10px;"></td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; line-height:20px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" ><span style="font-weight:bold; ">Important Info :</span> ${booking.additional_note ? booking.additional_note : "No Additional Information Provided"}</div>
                
               
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; background-color:#595959; border-radius:8px; padding:8px; text-align:center; margin-bottom:10px;"><a href="#" style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; text-decoration:none;"><p style="color:#ffffff;margin-bottom:10px">TERMS &amp; CONDITIONS</p></a></div>

        <div style="width:700px;background-color:#e6e6e6;margin-top:0px;margin-bottom:10px,padding-left:10px;padding-right:10px;border-radius:10px;height:260px" >
        
        <p style="font-size:5px;font-family:Arial, Helvetica, sans-serif;text-align:left;padding:0px;text-align:justify;word-wrap: break-word;margin-left:8px" >
        
       1. IMPORTANT 1.1. Sawari Airport Transfers (SMC- Private) Limited is a Pakist Pakistan Limited which is UK registered company. bookings. 2. GENERAL 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis whic governs such booking by ticking the "I agree to else, by clicking "I agree to Sawari Pakistan Terms & Conditions", You are
representing that You have their authority to accept these terms and conditions on their behalf. 2.2. Deviations from these Terms are only valid if and to the extent that these are confirmed explic Pakistan. 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, only the provision in question will be void, but the rest of
these Terms will remain fully i provision will apply that best approached the intention of the parties. 2.4. Sawari Pakistan shall act as an agent on Supplier’s behalf Pakistan will never become a party to a contract for the provision of transport service by Supplier to You. The contract for the provision of the transport service is directly between You and Supplier. The Services shall only be offered
in the name and f account of the Supplier at the prices and the conditions fixed by the Supplier. 2.5. Where in the Booking You have selected more the transport service relating to each Trip is directly between Booking. 3. USE OF WEBSITE 3.1. In making a Booking via this Website You must provide accurate Details and are responsible for any failure to do so. 3.2. You hereby warrant to Sawari
Pakistan and the Supplier that You are at least eighteen years of age and legally able to make the Booking. 3.3. Sawari Pakistan does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any Bookings which You wish to make and to cancel any Bookings You have made, and in such case to refund to You, in full and final settlement of any claims you might
have, the Fee paid by You. 3.4. Your Details and data relating to Your use of the Website will be recorded by not, subject to the provisions of the Sawari Pakistan parties other than the Supplier or used for any purpose unrelated to the provision of the services pursuant heret 3.5. Sawari Pakistan may send a small file to Your computer when You visit the Website. This "cookie" will enable to
track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to th Website. The cookie will not enable Sawari Pakistan to this Website. You can set Your computer browser to reject cookies but this may preclude use of certain parts of this Websi Sawari Pakistan may disclose Your Personal Data if compelled to do so by law, or at the request
of a law enforcement agency or governmental authority. 4. BOOKINGS 4.1. When You enter the details of the Trip, Sawari Pakistan suppliers that are able to fulfil Your requirement. The Quotes are the current special "one off" offers available from suppli are only available at the time of making Your Booking. 4.2. It is Your responsibility to ensure that Your Details and vehicle suitable for your
luggage requirements, a valid postcode which also does not commit a traffic violation, correct date time for the journeys to take place, correct custo the Trip details are inaccurate and an invalid or no postcode or a postcode which causes a traffic violation is provided or a incorrect; date, time, customer name, email ad due a refund, See 7.10. When You make a Booking, this constitutes an offer
which 4.3. In the event, you need to amend or correct a booking you charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you hav booked, you will be required to pay the difference in the price new services you have requested. If there is a price decrease caused by any change you request to the Services that you have TERMS &
CONDITIONS PAGE Private) Limited is a Pakistan registered company which operates in collaboration UK registered company. Both companies share domain name www.sawaripakistan.com 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis whic governs such booking by ticking the "I agree to Sawari Pakistan Terms and
Conditions". If you are booking on behalf of someone Terms & Conditions", You are representing that You have their authority to accept 2.2. Deviations from these Terms are only valid if and to the extent that these are confirmed explicitly and in writing by 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, only the
provision in question will be void, but the rest of these Terms will remain fully in effect. Instead of the invalid provision, a provision will apply that best approached the intention of the parties. an agent on Supplier’s behalf in establishing a contract between YOU and the Supplier. come a party to a contract for the provision of transport service by Supplier to You. The contract for the provision
of the transport service is directly between You and Supplier. The Services shall only be offered in the name and f pplier at the prices and the conditions fixed by the Supplier. 2.5. Where in the Booking You have selected more than one Supplier to provide Your Trips, then the contract for the provision of the transport service relating to each Trip is directly between You and the respective
Supplier You have selected for that Trip in the 3.1. In making a Booking via this Website You must provide accurate Details and are responsible for any failure to do so. and the Supplier that You are at least eighteen years of age and legally able to make does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any nd to cancel any Bookings You have
made, and in such case to refund to You, in full and final settlement of any claims you might have, the Fee paid by You. 3.4. Your Details and data relating to Your use of the Website will be recorded by Sawari Pakistan but Your Sawari Pakistan Privacy Policy presented below and Clause 3.5 below, be disclosed to third parties other than the Supplier or used for any purpose unrelated to the
provision of the services pursuant heret may send a small file to Your computer when You visit the Website. This "cookie" will enable to track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to th Sawari Pakistan to identify You and Sawari Pakistan shall not use it otherwise than in relation to this Website. You can set Your computer
browser to reject cookies but this may preclude use of certain parts of this Websi se Your Personal Data if compelled to do so by law, or at the request of a law enforcement agency or Sawari Pakistan will endeavour to provide You via the Website with Quotes fro suppliers that are able to fulfil Your requirement. The Quotes are the current special "one off" offers available from suppli are only
available at the time of making Your Booking. 4.2. It is Your responsibility to ensure that Your Details and Trip are correct and accurate by providing, but not limited to; selecting a vehicle suitable for your luggage requirements, a valid postcode which also does not commit a traffic violation, correct date time for the journeys to take place, correct customer name, correct and valid email
address, correct and valid telephone number. If the Trip details are inaccurate and an invalid or no postcode or a postcode which causes a traffic violation is provided or a incorrect; date, time, customer name, email address, telephone number provided you may incur additional charges and may not be When You make a Booking, this constitutes an offer which Sawari Pakistan 4.3. In the
event, you need to amend or correct a booking you most contact Sawari Pakistan directly. An charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you hav booked, you will be required to pay the difference in the price between the services originally booked by you, and the price of the new services you have requested. If there
is a price decrease caused by any change you request to the Services that you have n registered company which operates in collaboration with Sawari www.sawaripakistan.com to generate 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis which Terms and Conditions". If you are booking on behalf of someone Terms &
Conditions", You are representing that You have their authority to accept itly and in writing by Sawari 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, then tead of the invalid provision, a in establishing a contract between YOU and the Supplier. Sawari come a party to a contract for the provision of transport service
by Supplier to You. The contract for the provision of the transport service is directly between You and Supplier. The Services shall only be offered in the name and for the one Supplier to provide Your Trips, then the contract for the provision of You and the respective Supplier You have selected for that Trip in the 3.1. In making a Booking via this Website You must provide accurate Details
and are responsible for any failure to do so. and the Supplier that You are at least eighteen years of age and legally able to make does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any nd to cancel any Bookings You have made, and in such case to refund to You, in full and final but Your Personal Data shall Privacy Policy presented below and
Clause 3.5 below, be disclosed to third parties other than the Supplier or used for any purpose unrelated to the provision of the services pursuant hereto. may send a small file to Your computer when You visit the Website. This "cookie" will enable Sawari Pakistan to track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to the shall
not use it otherwise than in relation to this Website. You can set Your computer browser to reject cookies but this may preclude use of certain parts of this Website. se Your Personal Data if compelled to do so by law, or at the request of a law enforcement agency or will endeavour to provide You via the Website with Quotes from suppliers that are able to fulfil Your requirement. The Quotes
are the current special "one off" offers available from suppliers, which Trip are correct and accurate by providing, but not limited to; selecting a vehicle suitable for your luggage requirements, a valid postcode which also does not commit a traffic violation, correct date and mer name, correct and valid email address, correct and valid telephone number. If the Trip details are inaccurate and an
invalid or no postcode or a postcode which causes a traffic violation is provided or an; dress, telephone number provided you may incur additional charges and may not be Sawari Pakistan may or may not accept. rectly. An administration fee will be charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you have between the
services originally booked by you, and the price of the new services you have requested. If there is a price decrease caused by any change you request to the Services that you have booked, you will be refunded the difference in the price between the services originally booked by you, and the price of the new services you have requested minus the admin charge. This charge will be applied to
your original payment method. 4.4. Any Booking You make may also be subject to the Supplier T&C, although where there is any conflict between the Supplier T&C and the Terms, the provisions contained in the Terms override those in the Supplier T&C. 4.5. If the Supplier wishes to impose the Supplier T&C on You, it shall be responsible for achieving this. 4.6. Sawari Pakistan cannot confirm
the ability of the Supplier to accommodate any Particular Requirements and in the event that You have such Particular Requirements You should not use this Website but instead make alternative travel arrangements that address your Particular Requirements. 4.7. Sawari Pakistan reserves the right to correct typographical errors in any elements of the information that appears on the Website
including pricing mistakes. If, once You are informed of such error, You wish to withdraw Your offer and do not wish to proceed with the purchase, You shall be free to do so and any monies already paid by You will be refunded in full. 4.8. You agree that in the fulfilment of the Booking, where the Supplier feels it is appropriate, the Supplier gives Sawari Pakistan the right to novate the
contract for the provision of transport services between You and the Supplier to another suitable party, to ensure the fulfilment of the Booking as effected via this Website. 4.9. Sawari Pakistan reserves the right to change the type of vehicle you have booked, to ensure the fulfilment of the Booking. With large vehicles subject to availability, in the event the Supplier is no longer able to
provide their vehicle, Sawari Pakistan will endeavour to ensure You are offered alternatives of a similar type and class. 4.10 Sawari Pakistan reserves the right to use third party companies such as Uber, Careem and local supplier to ensure the fulfilment of the Booking, in the case that the original Supplier is unable to fulfill the Booking. For avoidance of doubt, You certify and agree to receive
SMS messages from Uber, Careem and local supplier regarding any trip and You give consent to Uber, Careem and local supplier to share the rider's information, including trip status, with Sawari Pakistan. 5. PAYMENT 5.1. When booking with the ‘Pay by Card’ option, You agree to pay the Fare at the time You make Your Booking. Sawari Pakistan is entitled to collect all monies due for the
booked Trip from You acting entirely and settle all Card Costs of the booked Trip on behalf of the Supplier, in advance of making the payment due to the Supplier for the booked Trip as the Supplier’s agent. 5.2.When paying by Card you agree to Sawari Pakistan using an authorisation token on your Card to allow us to charge your Card in event of any amendments to your Booking. Sawari
Pakistan will not store any of your Card details. In the event that Your Card payment is refused by Your Card issuer for whatever reason, Sawari Pakistan will not be able to fulfil Your Booking. 5.3. When booking with the ‘Pay by Cash’ option, You agree to pay the Fare to the Supplier’s driver. When booking with the ‘BACS’ option, You agree to pay the Fare to the Supplier’s bank account. When
booking with the ‘Credit Balance’ option, You agree to pay the Fare to Sawari Pakistan's nominated bank account. 5.4. Once You have confirmed Your Booking on the payment screen, it cannot be cancelled or changed except in accordance with clause 7 of these Terms. 5.4. If paying by Card, in the event that Your Card payment is refused by Your Card issuer for whatever reason, Sawari
Pakistan will not be able to fulfil Your Booking. 5.5. Sawari Pakistan will provide You with a receipt for Payment by email to the email address you registered with the Booking. 5.6. Payment will not cover any extra requirements you request, such as child seats. If you do not indicate any additional luggage when booking, resulting in a larger size vehicle being required, then the Supplier may
charge you extra, which you must pay them directly for. If You wish to give a tip to the driver, You should do so directly at your own discretion. 5.7. In the event that You make any deviations from the route that You entered into the Website, You may be subject to additional charges by the Supplier in respect thereof and will make such payment directly to the Supplier. 5.8. Sawari Pakistan
offer no warranties on payments made direct to the Supplier with Cash or Bank Transfers. 6. INSURANCE 6.1. Neither Sawari Pakistan nor the Supplier is responsible to You for, or insured in respect of, any damage or loss that may be sustained by the Goods in the course of a Trip and it is Your exclusive responsibility to have such Goods insured in respect of such damage or loss. 7.
CANCELLATION OF BOOKINGS AND REFUNDS 7.1. Cancellations must be made by you using our website. You will be entitled to a refund, depending on when you cancel your Booking. 7.2. Customer(s) who paid a deposit will be refunded or charged depending on the amount due as per condition 7.1. 7.3. At Sawari Pakistan’s discretion, a cancellation charge may be refunded on the basis that
You re-book within 24 (Twenty Four) hours, minus administration charge. Only once the Journey is re-booked, the refund can be processed. However, Sawari Pakistan reserves the right to refuse a cancellation refund at any time. 7.4. In the event that the Supplier does not turn up at the Agreed Pick-Up Point within 15 minutes of the Agreed Time - or within 30 minutes if the Agreed Pick-up
Point is an airport - and You do not use the Supplier for the Trip, You will be entitled to a full refund of the Fare, along with any Card Costs, if you paid them in advance by Card, as full and final settlement of any claim You might have in respect of such failure. However should You, at Your own discretion, decide to continue with the Trip where the Supplier turns up after 15 minutes from the
Agreed time, then you will not be entitled to any such refund. 7.5. In the event that You are not available at the Agreed Pick-Up Point within 15 minutes of the Agreed Time, it will be at the Supplier’s discretion to treat Your non-availability as a cancellation, and You will not be entitled to any refund of the Fare , and any Card Costs that you may have paid in advance. Where the Supplier
decides to wait more than 15 minutes from the Agreed Time, then You may be subject to additional charges by the Supplier in respect of any additional waiting time and/or parking charges and will make such payment directly to the Supplier. 7.6. In the event that you do not wish to use the Services for any reason and do not cancel the Booking using the website or by contacting Sawari
Pakistan.com, no refunds will be provided. 7.7. In the event that you wish to cancel a return leg of your journey, you will not be entitled to any refund. 7.8. For pre-paid Bookings, any refunds due to you to for cancellations or complaints will be made to the card used to make the payment within up to 5 business days (business days being Monday to Friday). 7.9. Customers with credit
accounts or account balances will be exempt from cancellation charges. 7.10. In the event of a Booking cancellation or no show by a Supplier, you must contact Sawari Pakistan within 7 days to issue your refund. 7.11. In the event that You provide inaccurate details including but not limited to an invalid or missing; postcode, postcode causing traffic violation, customer name, email address,
telephone number, you will not be entitled any refund and may incur additional charges from the Supplier in order to complete the Trip. 8. WARRANTY AND LIMITATION OF LIABILITY 8.1. You acknowledge and accept that Sawari Pakistan is acting as the agent of the Supplier and that whilst Sawari Pakistan will try to assist with any complaints You may have in respect of the Supplier’s services,
any legal remedy You seek will be sought against the Supplier not Sawari Pakistan. 8.2. The Supplier has warranted to Sawari Pakistan that it is licensed by the appropriate Licensing Authority(ies) but Sawari Pakistan shall not be responsible for verification of such warranty and if You are in any doubt, You should contact the Private Hire Operator and rely on Your own verification. 8.3. Sawari
Pakistan shall not be liable for any delays in pick-up or drop-off times or for any failure of the Supplier to arrive at all or for any failure of the Supplier to provide its services in accordance with Your requirements or expectations. 8.4. Sawari Pakistan shall not in any event be liable (whether in contract or otherwise) for any indirect loss or consequential loss or loss of revenue, howsoever arising,
suffered by You in connection with any failure by the Supplier or otherwise in connection with these Terms or otherwise and if You consider that You have any claim in respect of any failure by the Supplier You agree to make such claim directly against the Supplier. 8.5. Sawari Pakistan's aggregate liability in respect of these Terms and any matter arising out of it (including claims whether
made in contract or tort) shall be limited to £100. 8.6. Subject as expressly provided in these Terms all warranties, conditions or other terms implied by statute or common law are excluded as far as legally possible. 8.7. Nothing in this clause shall operate so as to exclude any rights You may have under consumer legislation or limit the Supplier's liability for death or personal injury arising out
of its negligence. 8.8. Any estimated trip times and times of arrival presented by Sawari Pakistan on its Public website or otherwise, are only to be taken as indicative, and are not subject to traffic and/or weather conditions and final route taken by driver. Therefore, You agree that You are responsible for setting an appropriate Agreed Time that allows You sufficient time to reach Your
destination to subsequently check-in for a flight, take a train or fulfil any other dependencies You might have. 8.9. Sawari Pakistan may keep You informed of the status of your Booking by sending You email, and SMS text alerts, although Sawari Pakistan does not guarantee the delivery nor the timing of these email and SMS text alerts, and shall not in any event be liable (whether in contract
or otherwise) for any indirect loss or consequential loss or loss of revenue, howsoever arising from the omission or delay of such email and SMS text alerts. 8.10. Each foregoing sub-clause of this clause shall constitute a separate and severable provision. The provisions of this clause shall continue in effect notwithstanding the termination, completion or any other matter which might
otherwise cause these Terms to become ineffective. 9. BEHAVIOUR 9.1. The Supplier reserves the right within its reasonable discretion to terminate the Trip, if You or Your party's conduct or behaviour is disruptive in any way and/or affects your safety or that of the driver of the vehicle. Neither Sawari Pakistan nor the Supplier accepts liability for any extra costs incurred by You/or Your party
as a result of the Supplier doing so. 9.2. Passengers are not permitted to take alcoholic drinks onto the vehicles for the purpose of consuming them during the Trip. 9.3. The Supplier further reserves the right to refuse carriage to any person who is thought to be under the influence of alcohol or drugs. 10. LIMITATIONS AND EXCLUSIONS 10.1. The Supplier shall not undertake the carriage or
delivery of:  money or securities, antiques, precious metals, furs, or jewellery or value, any goods or property (of whatsoever nature) of an intrinsic value of more than £10.  any goods or property of a hazardous, dangerous, inflammable, explosive or noxious nature, or are illegal to possess under existing English Law, and/or  any goods or property (of whatsoever nature) which may
deteriorate in transit. 10.2. The Supplier shall have no liability whatsoever for loss or damage, however arising in relation to the clause 10.1 of this agreement. 10.3. Without prejudice to the provisions of clause 10.1 the Supplier shall not in any event be liable directly or indirectly for:  consequential loss (whether for loss or profit or otherwise); and/or  loss, damage and/or breakage to any
fragile items whatsoever whether arising from the acts, omissions or negligence of the Supplier and/or its employees and/or agents or arising otherwise howsoever. 10.4. Without prejudice to the generality of clauses 10.1 and 10.2 in particular the Supplier shall not be liable for any loss and/or damage arising directly or indirectly from:  breakdown, accident, adverse weather conditions  any
act or omission on the part of the Customer.  any clause, act or circumstance beyond the control of the Supplier (including, without limitation, any strike, (official or not) lock-out or other form of industrial action or labour dispute, governmental regulations, legal restrictions, embargoes, fire, flood, Act of God, any consequence of riot, war, invasion, act of foreign enemy, hostilities (whether
war be declared or not) civil war, acts of terrorism, rebellion, military or usurped power, confiscation, requisition or destruction of or damage to property by or upon the order of or in the name of any Government or public local authority, or other conditions amounting to force majeure). 10.5. The provision of clauses 10.1, 10.2, 10.3, 10.4 and 11.1 apply to liability for loss or damage to goods
or property and do not apply to liability for death or personal injury. 10.6 - By using the website you agree that use of the site is non-exclusive. 10.7 - Every time a booking is generated, you agree at the date and time of the booking, that the terms & Conditions t








        </p>

        
        
        </div>


    </div>
    </div>

  

  `;

    // Add the content to the document body (necessary for html2canvas to render it)
    document.body.appendChild(content);


    await waitForImagesToLoad(content);



    // const companyLogo = document.getElementById('companylogo');

    // await new Promise((resolve) => {

    //   companyLogo.onload = resolve;
    // });

    try {
      // Generate the PDF using html2canvas

      const canvas = await html2canvas(content, { useCORS: true }, { scale: 3 }); // Adjust the scale as needed for zoom
      const imgData = canvas.toDataURL('image/png');
      const contentWidth = 400; // Your content width in pixels
      const contentHeight = canvas.height * (contentWidth / canvas.width);// Keep the original content height
      const pdfWidth = 550 / 2.83; // Convert content width to mm
      const pdfHeight = 530;

      // Calculate horizontal position to center the content
      const horizontalPos = (pdfWidth - contentWidth) / 2;

      // Calculate vertical position to center the content
      const verticalPos = (pdfHeight - contentHeight) / 2;

      const pdf = new jsPDF('p', 'mm', [pdfWidth, 280]);
      pdf.addImage(imgData, 'PNG', horizontalPos, 0, contentWidth, contentHeight);
      pdf.save(`bookingReceipt${booking.id}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      // Remove the temporary content from the document body
      document.body.removeChild(content);
    }

  };


  const generateRentPdf = async (booking) => {

    const content = document.createElement('div');


    // Define the HTML content for the PDF
    content.innerHTML = `
    <div>  
    <div style="width:700px;margin:0px auto;">
    	<table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td width="30%" style="width:30%;"><img src="https://smaztech.com/sawari/assets/email-template/logo.jpg" alt="SAWARI PAKISTAN" style="width:200px;"></td>
            <td width="70%" style="width:70%;">
            	<div style="text-align:right; font-family:Arial, Helvetica, sans-serif; line-height:18px;">
                	<div style="font-size:12px; color:#000; font-weight:bold;">SAWARI AIRPORT TRANSFERS (SMC-PRIVATE) LIMITED</div>
        			<div style="font-size:13px; color:#000;">Office No. 3, Rehmat House, Sandal Road, Gujarkhan, Rawalpindi, Pakistan.</div>
					<div style="font-size:13px; color:#000; font-weight:bold;"><span style="color:#006600;">24 Hour Support</span> <a href="mailto:hello@sawaripakistan.com" style="color:#000; text-decoration:none;">hello@sawaripakistan.com</a></div>
                    <div style="font-size:24px; color:#000; font-weight:bold; line-height:24px;">+92 336 4400044</div>
                </div>
            </td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
        </table>
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:16px; color:#fff; font-weight:bold; background-color:#006600; border-radius:8px; padding:10px; text-align:center; margin-bottom:10px;"><p style="margin-bottom:10px;">BOOKING CONFIRMATION</p></div>
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; font-weight:bold; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;margin-left:5px;" >Booking Reference Number : <span style="color:#ff0000;">${booking.id}</span></p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Main Passenger :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${booking.passenger_name}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.passenger_contact}</td>
                      </tr>
                    </table>
                </div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Paid Amount :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">PKR ${booking.booking_total_charges}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Payment Method :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.payment_type}</td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:11px; color:#000; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;margin-left:5px;" ><span style="font-weight:bold; ">Booked Date &amp; Time :</span> ${booking.booking_start_date} ${booking.booking_reporting_time}</p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;padding-bottom:20px">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Account Holder :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${userData.name}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${userData.phone}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Email :</td>
                        <td  style="padding-top:5px; padding-bottom:5px; color:#000; text-decoration:none;">${userData.email}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:25px;">Account Type :</td>
                        <td  style="padding-top:5px; padding-bottom:25px;">Personal</td>
                      </tr>
                      
                    </table>
                </div>
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" >
        
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tr>
    <td style="font-weight:bold; width:18%;  padding-top:5px; padding-bottom:5px;">Journey Type :</td>
    <td style="padding-top:5px; padding-bottom:5px;">Vehicle Rentals</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Date :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_start_date}</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Time :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_reporting_time}</td>
  </tr>
  <tr>
    <td style="vertical-align:top; font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_address}
    </td>
  </tr>
  
  
   <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Destination :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_address}</td>
  </tr>
   <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Dropoff Date :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_end_date}</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Dropoff Time :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_end_time}</td>
  </tr>
</table>

        	 
        </div>
        
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
                <div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:6px; padding-bottom:5px;">Vehicle Type :</td>
                        <td style="padding-top:6px; padding-bottom:5px;">${booking.car_title}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:10px;"></td>
                        <td  style="padding-top:5px; padding-bottom:10px;"></td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; line-height:20px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" ><span style="font-weight:bold; ">Important Info :</span> ${booking.additional_note ? booking.additional_note : "No Additional Information Provided"}</div>
                
               
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; background-color:#595959; border-radius:8px; padding:8px; text-align:center; margin-bottom:10px;"><a href="#" style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; text-decoration:none;"><p style="color:#ffffff;margin-bottom:10px">TERMS &amp; CONDITIONS</p></a></div>

        <div style="width:700px;background-color:#e6e6e6;margin-top:0px;margin-bottom:10px,padding-left:10px;padding-right:10px;border-radius:10px;height:260px" >
        
        <p style="font-size:5px;font-family:Arial, Helvetica, sans-serif;text-align:left;padding:0px;text-align:justify;word-wrap: break-word;margin-left:8px" >
        
       1. IMPORTANT 1.1. Sawari Airport Transfers (SMC- Private) Limited is a Pakist Pakistan Limited which is UK registered company. bookings. 2. GENERAL 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis whic governs such booking by ticking the "I agree to else, by clicking "I agree to Sawari Pakistan Terms & Conditions", You are
representing that You have their authority to accept these terms and conditions on their behalf. 2.2. Deviations from these Terms are only valid if and to the extent that these are confirmed explic Pakistan. 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, only the provision in question will be void, but the rest of
these Terms will remain fully i provision will apply that best approached the intention of the parties. 2.4. Sawari Pakistan shall act as an agent on Supplier’s behalf Pakistan will never become a party to a contract for the provision of transport service by Supplier to You. The contract for the provision of the transport service is directly between You and Supplier. The Services shall only be offered
in the name and f account of the Supplier at the prices and the conditions fixed by the Supplier. 2.5. Where in the Booking You have selected more the transport service relating to each Trip is directly between Booking. 3. USE OF WEBSITE 3.1. In making a Booking via this Website You must provide accurate Details and are responsible for any failure to do so. 3.2. You hereby warrant to Sawari
Pakistan and the Supplier that You are at least eighteen years of age and legally able to make the Booking. 3.3. Sawari Pakistan does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any Bookings which You wish to make and to cancel any Bookings You have made, and in such case to refund to You, in full and final settlement of any claims you might
have, the Fee paid by You. 3.4. Your Details and data relating to Your use of the Website will be recorded by not, subject to the provisions of the Sawari Pakistan parties other than the Supplier or used for any purpose unrelated to the provision of the services pursuant heret 3.5. Sawari Pakistan may send a small file to Your computer when You visit the Website. This "cookie" will enable to
track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to th Website. The cookie will not enable Sawari Pakistan to this Website. You can set Your computer browser to reject cookies but this may preclude use of certain parts of this Websi Sawari Pakistan may disclose Your Personal Data if compelled to do so by law, or at the request
of a law enforcement agency or governmental authority. 4. BOOKINGS 4.1. When You enter the details of the Trip, Sawari Pakistan suppliers that are able to fulfil Your requirement. The Quotes are the current special "one off" offers available from suppli are only available at the time of making Your Booking. 4.2. It is Your responsibility to ensure that Your Details and vehicle suitable for your
luggage requirements, a valid postcode which also does not commit a traffic violation, correct date time for the journeys to take place, correct custo the Trip details are inaccurate and an invalid or no postcode or a postcode which causes a traffic violation is provided or a incorrect; date, time, customer name, email ad due a refund, See 7.10. When You make a Booking, this constitutes an offer
which 4.3. In the event, you need to amend or correct a booking you charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you hav booked, you will be required to pay the difference in the price new services you have requested. If there is a price decrease caused by any change you request to the Services that you have TERMS &
CONDITIONS PAGE Private) Limited is a Pakistan registered company which operates in collaboration UK registered company. Both companies share domain name www.sawaripakistan.com 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis whic governs such booking by ticking the "I agree to Sawari Pakistan Terms and
Conditions". If you are booking on behalf of someone Terms & Conditions", You are representing that You have their authority to accept 2.2. Deviations from these Terms are only valid if and to the extent that these are confirmed explicitly and in writing by 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, only the
provision in question will be void, but the rest of these Terms will remain fully in effect. Instead of the invalid provision, a provision will apply that best approached the intention of the parties. an agent on Supplier’s behalf in establishing a contract between YOU and the Supplier. come a party to a contract for the provision of transport service by Supplier to You. The contract for the provision
of the transport service is directly between You and Supplier. The Services shall only be offered in the name and f pplier at the prices and the conditions fixed by the Supplier. 2.5. Where in the Booking You have selected more than one Supplier to provide Your Trips, then the contract for the provision of the transport service relating to each Trip is directly between You and the respective
Supplier You have selected for that Trip in the 3.1. In making a Booking via this Website You must provide accurate Details and are responsible for any failure to do so. and the Supplier that You are at least eighteen years of age and legally able to make does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any nd to cancel any Bookings You have
made, and in such case to refund to You, in full and final settlement of any claims you might have, the Fee paid by You. 3.4. Your Details and data relating to Your use of the Website will be recorded by Sawari Pakistan but Your Sawari Pakistan Privacy Policy presented below and Clause 3.5 below, be disclosed to third parties other than the Supplier or used for any purpose unrelated to the
provision of the services pursuant heret may send a small file to Your computer when You visit the Website. This "cookie" will enable to track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to th Sawari Pakistan to identify You and Sawari Pakistan shall not use it otherwise than in relation to this Website. You can set Your computer
browser to reject cookies but this may preclude use of certain parts of this Websi se Your Personal Data if compelled to do so by law, or at the request of a law enforcement agency or Sawari Pakistan will endeavour to provide You via the Website with Quotes fro suppliers that are able to fulfil Your requirement. The Quotes are the current special "one off" offers available from suppli are only
available at the time of making Your Booking. 4.2. It is Your responsibility to ensure that Your Details and Trip are correct and accurate by providing, but not limited to; selecting a vehicle suitable for your luggage requirements, a valid postcode which also does not commit a traffic violation, correct date time for the journeys to take place, correct customer name, correct and valid email
address, correct and valid telephone number. If the Trip details are inaccurate and an invalid or no postcode or a postcode which causes a traffic violation is provided or a incorrect; date, time, customer name, email address, telephone number provided you may incur additional charges and may not be When You make a Booking, this constitutes an offer which Sawari Pakistan 4.3. In the
event, you need to amend or correct a booking you most contact Sawari Pakistan directly. An charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you hav booked, you will be required to pay the difference in the price between the services originally booked by you, and the price of the new services you have requested. If there
is a price decrease caused by any change you request to the Services that you have n registered company which operates in collaboration with Sawari www.sawaripakistan.com to generate 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis which Terms and Conditions". If you are booking on behalf of someone Terms &
Conditions", You are representing that You have their authority to accept itly and in writing by Sawari 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, then tead of the invalid provision, a in establishing a contract between YOU and the Supplier. Sawari come a party to a contract for the provision of transport service
by Supplier to You. The contract for the provision of the transport service is directly between You and Supplier. The Services shall only be offered in the name and for the one Supplier to provide Your Trips, then the contract for the provision of You and the respective Supplier You have selected for that Trip in the 3.1. In making a Booking via this Website You must provide accurate Details
and are responsible for any failure to do so. and the Supplier that You are at least eighteen years of age and legally able to make does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any nd to cancel any Bookings You have made, and in such case to refund to You, in full and final but Your Personal Data shall Privacy Policy presented below and
Clause 3.5 below, be disclosed to third parties other than the Supplier or used for any purpose unrelated to the provision of the services pursuant hereto. may send a small file to Your computer when You visit the Website. This "cookie" will enable Sawari Pakistan to track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to the shall
not use it otherwise than in relation to this Website. You can set Your computer browser to reject cookies but this may preclude use of certain parts of this Website. se Your Personal Data if compelled to do so by law, or at the request of a law enforcement agency or will endeavour to provide You via the Website with Quotes from suppliers that are able to fulfil Your requirement. The Quotes
are the current special "one off" offers available from suppliers, which Trip are correct and accurate by providing, but not limited to; selecting a vehicle suitable for your luggage requirements, a valid postcode which also does not commit a traffic violation, correct date and mer name, correct and valid email address, correct and valid telephone number. If the Trip details are inaccurate and an
invalid or no postcode or a postcode which causes a traffic violation is provided or an; dress, telephone number provided you may incur additional charges and may not be Sawari Pakistan may or may not accept. rectly. An administration fee will be charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you have between the
services originally booked by you, and the price of the new services you have requested. If there is a price decrease caused by any change you request to the Services that you have booked, you will be refunded the difference in the price between the services originally booked by you, and the price of the new services you have requested minus the admin charge. This charge will be applied to
your original payment method. 4.4. Any Booking You make may also be subject to the Supplier T&C, although where there is any conflict between the Supplier T&C and the Terms, the provisions contained in the Terms override those in the Supplier T&C. 4.5. If the Supplier wishes to impose the Supplier T&C on You, it shall be responsible for achieving this. 4.6. Sawari Pakistan cannot confirm
the ability of the Supplier to accommodate any Particular Requirements and in the event that You have such Particular Requirements You should not use this Website but instead make alternative travel arrangements that address your Particular Requirements. 4.7. Sawari Pakistan reserves the right to correct typographical errors in any elements of the information that appears on the Website
including pricing mistakes. If, once You are informed of such error, You wish to withdraw Your offer and do not wish to proceed with the purchase, You shall be free to do so and any monies already paid by You will be refunded in full. 4.8. You agree that in the fulfilment of the Booking, where the Supplier feels it is appropriate, the Supplier gives Sawari Pakistan the right to novate the
contract for the provision of transport services between You and the Supplier to another suitable party, to ensure the fulfilment of the Booking as effected via this Website. 4.9. Sawari Pakistan reserves the right to change the type of vehicle you have booked, to ensure the fulfilment of the Booking. With large vehicles subject to availability, in the event the Supplier is no longer able to
provide their vehicle, Sawari Pakistan will endeavour to ensure You are offered alternatives of a similar type and class. 4.10 Sawari Pakistan reserves the right to use third party companies such as Uber, Careem and local supplier to ensure the fulfilment of the Booking, in the case that the original Supplier is unable to fulfill the Booking. For avoidance of doubt, You certify and agree to receive
SMS messages from Uber, Careem and local supplier regarding any trip and You give consent to Uber, Careem and local supplier to share the rider's information, including trip status, with Sawari Pakistan. 5. PAYMENT 5.1. When booking with the ‘Pay by Card’ option, You agree to pay the Fare at the time You make Your Booking. Sawari Pakistan is entitled to collect all monies due for the
booked Trip from You acting entirely and settle all Card Costs of the booked Trip on behalf of the Supplier, in advance of making the payment due to the Supplier for the booked Trip as the Supplier’s agent. 5.2.When paying by Card you agree to Sawari Pakistan using an authorisation token on your Card to allow us to charge your Card in event of any amendments to your Booking. Sawari
Pakistan will not store any of your Card details. In the event that Your Card payment is refused by Your Card issuer for whatever reason, Sawari Pakistan will not be able to fulfil Your Booking. 5.3. When booking with the ‘Pay by Cash’ option, You agree to pay the Fare to the Supplier’s driver. When booking with the ‘BACS’ option, You agree to pay the Fare to the Supplier’s bank account. When
booking with the ‘Credit Balance’ option, You agree to pay the Fare to Sawari Pakistan's nominated bank account. 5.4. Once You have confirmed Your Booking on the payment screen, it cannot be cancelled or changed except in accordance with clause 7 of these Terms. 5.4. If paying by Card, in the event that Your Card payment is refused by Your Card issuer for whatever reason, Sawari
Pakistan will not be able to fulfil Your Booking. 5.5. Sawari Pakistan will provide You with a receipt for Payment by email to the email address you registered with the Booking. 5.6. Payment will not cover any extra requirements you request, such as child seats. If you do not indicate any additional luggage when booking, resulting in a larger size vehicle being required, then the Supplier may
charge you extra, which you must pay them directly for. If You wish to give a tip to the driver, You should do so directly at your own discretion. 5.7. In the event that You make any deviations from the route that You entered into the Website, You may be subject to additional charges by the Supplier in respect thereof and will make such payment directly to the Supplier. 5.8. Sawari Pakistan
offer no warranties on payments made direct to the Supplier with Cash or Bank Transfers. 6. INSURANCE 6.1. Neither Sawari Pakistan nor the Supplier is responsible to You for, or insured in respect of, any damage or loss that may be sustained by the Goods in the course of a Trip and it is Your exclusive responsibility to have such Goods insured in respect of such damage or loss. 7.
CANCELLATION OF BOOKINGS AND REFUNDS 7.1. Cancellations must be made by you using our website. You will be entitled to a refund, depending on when you cancel your Booking. 7.2. Customer(s) who paid a deposit will be refunded or charged depending on the amount due as per condition 7.1. 7.3. At Sawari Pakistan’s discretion, a cancellation charge may be refunded on the basis that
You re-book within 24 (Twenty Four) hours, minus administration charge. Only once the Journey is re-booked, the refund can be processed. However, Sawari Pakistan reserves the right to refuse a cancellation refund at any time. 7.4. In the event that the Supplier does not turn up at the Agreed Pick-Up Point within 15 minutes of the Agreed Time - or within 30 minutes if the Agreed Pick-up
Point is an airport - and You do not use the Supplier for the Trip, You will be entitled to a full refund of the Fare, along with any Card Costs, if you paid them in advance by Card, as full and final settlement of any claim You might have in respect of such failure. However should You, at Your own discretion, decide to continue with the Trip where the Supplier turns up after 15 minutes from the
Agreed time, then you will not be entitled to any such refund. 7.5. In the event that You are not available at the Agreed Pick-Up Point within 15 minutes of the Agreed Time, it will be at the Supplier’s discretion to treat Your non-availability as a cancellation, and You will not be entitled to any refund of the Fare , and any Card Costs that you may have paid in advance. Where the Supplier
decides to wait more than 15 minutes from the Agreed Time, then You may be subject to additional charges by the Supplier in respect of any additional waiting time and/or parking charges and will make such payment directly to the Supplier. 7.6. In the event that you do not wish to use the Services for any reason and do not cancel the Booking using the website or by contacting Sawari
Pakistan.com, no refunds will be provided. 7.7. In the event that you wish to cancel a return leg of your journey, you will not be entitled to any refund. 7.8. For pre-paid Bookings, any refunds due to you to for cancellations or complaints will be made to the card used to make the payment within up to 5 business days (business days being Monday to Friday). 7.9. Customers with credit
accounts or account balances will be exempt from cancellation charges. 7.10. In the event of a Booking cancellation or no show by a Supplier, you must contact Sawari Pakistan within 7 days to issue your refund. 7.11. In the event that You provide inaccurate details including but not limited to an invalid or missing; postcode, postcode causing traffic violation, customer name, email address,
telephone number, you will not be entitled any refund and may incur additional charges from the Supplier in order to complete the Trip. 8. WARRANTY AND LIMITATION OF LIABILITY 8.1. You acknowledge and accept that Sawari Pakistan is acting as the agent of the Supplier and that whilst Sawari Pakistan will try to assist with any complaints You may have in respect of the Supplier’s services,
any legal remedy You seek will be sought against the Supplier not Sawari Pakistan. 8.2. The Supplier has warranted to Sawari Pakistan that it is licensed by the appropriate Licensing Authority(ies) but Sawari Pakistan shall not be responsible for verification of such warranty and if You are in any doubt, You should contact the Private Hire Operator and rely on Your own verification. 8.3. Sawari
Pakistan shall not be liable for any delays in pick-up or drop-off times or for any failure of the Supplier to arrive at all or for any failure of the Supplier to provide its services in accordance with Your requirements or expectations. 8.4. Sawari Pakistan shall not in any event be liable (whether in contract or otherwise) for any indirect loss or consequential loss or loss of revenue, howsoever arising,
suffered by You in connection with any failure by the Supplier or otherwise in connection with these Terms or otherwise and if You consider that You have any claim in respect of any failure by the Supplier You agree to make such claim directly against the Supplier. 8.5. Sawari Pakistan's aggregate liability in respect of these Terms and any matter arising out of it (including claims whether
made in contract or tort) shall be limited to £100. 8.6. Subject as expressly provided in these Terms all warranties, conditions or other terms implied by statute or common law are excluded as far as legally possible. 8.7. Nothing in this clause shall operate so as to exclude any rights You may have under consumer legislation or limit the Supplier's liability for death or personal injury arising out
of its negligence. 8.8. Any estimated trip times and times of arrival presented by Sawari Pakistan on its Public website or otherwise, are only to be taken as indicative, and are not subject to traffic and/or weather conditions and final route taken by driver. Therefore, You agree that You are responsible for setting an appropriate Agreed Time that allows You sufficient time to reach Your
destination to subsequently check-in for a flight, take a train or fulfil any other dependencies You might have. 8.9. Sawari Pakistan may keep You informed of the status of your Booking by sending You email, and SMS text alerts, although Sawari Pakistan does not guarantee the delivery nor the timing of these email and SMS text alerts, and shall not in any event be liable (whether in contract
or otherwise) for any indirect loss or consequential loss or loss of revenue, howsoever arising from the omission or delay of such email and SMS text alerts. 8.10. Each foregoing sub-clause of this clause shall constitute a separate and severable provision. The provisions of this clause shall continue in effect notwithstanding the termination, completion or any other matter which might
otherwise cause these Terms to become ineffective. 9. BEHAVIOUR 9.1. The Supplier reserves the right within its reasonable discretion to terminate the Trip, if You or Your party's conduct or behaviour is disruptive in any way and/or affects your safety or that of the driver of the vehicle. Neither Sawari Pakistan nor the Supplier accepts liability for any extra costs incurred by You/or Your party
as a result of the Supplier doing so. 9.2. Passengers are not permitted to take alcoholic drinks onto the vehicles for the purpose of consuming them during the Trip. 9.3. The Supplier further reserves the right to refuse carriage to any person who is thought to be under the influence of alcohol or drugs. 10. LIMITATIONS AND EXCLUSIONS 10.1. The Supplier shall not undertake the carriage or
delivery of:  money or securities, antiques, precious metals, furs, or jewellery or value, any goods or property (of whatsoever nature) of an intrinsic value of more than £10.  any goods or property of a hazardous, dangerous, inflammable, explosive or noxious nature, or are illegal to possess under existing English Law, and/or  any goods or property (of whatsoever nature) which may
deteriorate in transit. 10.2. The Supplier shall have no liability whatsoever for loss or damage, however arising in relation to the clause 10.1 of this agreement. 10.3. Without prejudice to the provisions of clause 10.1 the Supplier shall not in any event be liable directly or indirectly for:  consequential loss (whether for loss or profit or otherwise); and/or  loss, damage and/or breakage to any
fragile items whatsoever whether arising from the acts, omissions or negligence of the Supplier and/or its employees and/or agents or arising otherwise howsoever. 10.4. Without prejudice to the generality of clauses 10.1 and 10.2 in particular the Supplier shall not be liable for any loss and/or damage arising directly or indirectly from:  breakdown, accident, adverse weather conditions  any
act or omission on the part of the Customer.  any clause, act or circumstance beyond the control of the Supplier (including, without limitation, any strike, (official or not) lock-out or other form of industrial action or labour dispute, governmental regulations, legal restrictions, embargoes, fire, flood, Act of God, any consequence of riot, war, invasion, act of foreign enemy, hostilities (whether
war be declared or not) civil war, acts of terrorism, rebellion, military or usurped power, confiscation, requisition or destruction of or damage to property by or upon the order of or in the name of any Government or public local authority, or other conditions amounting to force majeure). 10.5. The provision of clauses 10.1, 10.2, 10.3, 10.4 and 11.1 apply to liability for loss or damage to goods
or property and do not apply to liability for death or personal injury. 10.6 - By using the website you agree that use of the site is non-exclusive. 10.7 - Every time a booking is generated, you agree at the date and time of the booking, that the terms & Conditions t



        </p>

        </div>
    </div>
    </div>

  

  `;

    // Add the content to the document body (necessary for html2canvas to render it)
    document.body.appendChild(content);


    // const companyLogo = document.getElementById('companylogo');

    // await new Promise((resolve) => {

    //   companyLogo.onload = resolve;
    // });

    try {
      // Generate the PDF using html2canvas

      const canvas = await html2canvas(content, { useCORS: true }, { scale: 3 }); // Adjust the scale as needed for zoom
      const imgData = canvas.toDataURL('image/png');
      const contentWidth = 350; // Your content width in pixels
      const contentHeight = canvas.height * (contentWidth / canvas.width);// Keep the original content height
      const pdfWidth = 500 / 2.83; // Convert content width to mm
      const pdfHeight = 360;

      // Calculate horizontal position to center the content
      const horizontalPos = (pdfWidth - contentWidth) / 2;

      // Calculate vertical position to center the content
      const verticalPos = (pdfHeight - contentHeight) / 2;

      const pdf = new jsPDF('p', 'mm', [pdfWidth, 250]);
      pdf.addImage(imgData, 'PNG', horizontalPos, 0, contentWidth, contentHeight);
      pdf.save(`bookingReceipt${booking.id}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      // Remove the temporary content from the document body
      document.body.removeChild(content);
    }

  }

  const generateTourPdf = async (booking) => {


    const content = document.createElement('div');


    // Define the HTML content for the PDF
    content.innerHTML =
      `
    <div>  
    <div style="width:700px;margin:0px auto;">
    	<table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td width="30%" style="width:30%;"><img src="https://smaztech.com/sawari/assets/email-template/logo.jpg" alt="SAWARI PAKISTAN" style="width:200px;"></td>
            <td width="70%" style="width:70%;">
            	<div style="text-align:right; font-family:Arial, Helvetica, sans-serif; line-height:18px;">
                	<div style="font-size:12px; color:#000; font-weight:bold;">SAWARI AIRPORT TRANSFERS (SMC-PRIVATE) LIMITED</div>
        			<div style="font-size:13px; color:#000;">Office No. 3, Rehmat House, Sandal Road, Gujarkhan, Rawalpindi, Pakistan.</div>
					<div style="font-size:13px; color:#000; font-weight:bold;"><span style="color:#006600;">24 Hour Support</span> <a href="mailto:hello@sawaripakistan.com" style="color:#000; text-decoration:none;">hello@sawaripakistan.com</a></div>
                    <div style="font-size:24px; color:#000; font-weight:bold; line-height:24px;">+92 336 4400044</div>
                </div>
            </td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
        </table>
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:16px; color:#fff; font-weight:bold; background-color:#006600; border-radius:8px; padding:10px; text-align:center; margin-bottom:10px;"><p style="margin-bottom:10px;">BOOKING CONFIRMATION</p></div>
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; font-weight:bold; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;margin-left:5px;" >Booking Reference Number : <span style="color:#ff0000;">${booking.id}</span></p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Main Passenger :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${booking.booked_person}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.person_contact}</td>
                      </tr>
                    </table>
                </div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Paid Amount :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">PKR ${booking.booking_price}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Payment Method :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.payment_type}</td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:11px; color:#000; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;margin-left:5px;" ><span style="font-weight:bold; ">Booked Date &amp; Time :</span> ${booking.booking_date}</p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;padding-bottom:20px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Account Holder :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${userData.name}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${userData.phone}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Email :</td>
                        <td  style="padding-top:5px; padding-bottom:5px; color:#000; text-decoration:none;">${userData.email}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:25px;">Account Type :</td>
                        <td  style="padding-top:5px; padding-bottom:25px;">Personal</td>
                      </tr>
                      
                    </table>
                </div>
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" >
        
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tr>
    <td style="font-weight:bold; width:18%;  padding-top:5px; padding-bottom:5px;">Journey Type :</td>
    <td style="padding-top:5px; padding-bottom:5px;">Tourism Packages</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Date :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_date}</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Tour Title :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.tour_title}</td>
  </tr>
  <tr>
    <td style="vertical-align:top; font-weight:bold;  padding-top:5px; padding-bottom:5px;">Persons :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_persons}
    </td>
  </tr>

</table>

        	 
        </div>
        
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
                <div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:6px; padding-bottom:5px;">Tour Title :</td>
                        <td style="padding-top:6px; padding-bottom:5px;">${booking.tour_title}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:10px;"></td>
                        <td  style="padding-top:5px; padding-bottom:10px;"></td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; line-height:20px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" ><span style="font-weight:bold; ">Important Info :</span> ${booking.additional_note ? booking.additional_note : "No Additional Information Provided"}</div>
                
               
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; background-color:#595959; border-radius:8px; padding:8px; text-align:center; margin-bottom:10px;"><a href="#" style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; text-decoration:none;"><p style="color:#ffffff;margin-bottom:10px">TERMS &amp; CONDITIONS</p></a></div>

        <div style="width:700px;background-color:#e6e6e6;margin-top:0px;margin-bottom:10px,padding-left:10px;padding-right:10px;border-radius:10px;height:260px" >
        
        <p style="font-size:5px;font-family:Arial, Helvetica, sans-serif;text-align:left;padding:0px;text-align:justify;word-wrap: break-word;margin-left:8px" >
        
       1. IMPORTANT 1.1. Sawari Airport Transfers (SMC- Private) Limited is a Pakist Pakistan Limited which is UK registered company. bookings. 2. GENERAL 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis whic governs such booking by ticking the "I agree to else, by clicking "I agree to Sawari Pakistan Terms & Conditions", You are
representing that You have their authority to accept these terms and conditions on their behalf. 2.2. Deviations from these Terms are only valid if and to the extent that these are confirmed explic Pakistan. 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, only the provision in question will be void, but the rest of
these Terms will remain fully i provision will apply that best approached the intention of the parties. 2.4. Sawari Pakistan shall act as an agent on Supplier’s behalf Pakistan will never become a party to a contract for the provision of transport service by Supplier to You. The contract for the provision of the transport service is directly between You and Supplier. The Services shall only be offered
in the name and f account of the Supplier at the prices and the conditions fixed by the Supplier. 2.5. Where in the Booking You have selected more the transport service relating to each Trip is directly between Booking. 3. USE OF WEBSITE 3.1. In making a Booking via this Website You must provide accurate Details and are responsible for any failure to do so. 3.2. You hereby warrant to Sawari
Pakistan and the Supplier that You are at least eighteen years of age and legally able to make the Booking. 3.3. Sawari Pakistan does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any Bookings which You wish to make and to cancel any Bookings You have made, and in such case to refund to You, in full and final settlement of any claims you might
have, the Fee paid by You. 3.4. Your Details and data relating to Your use of the Website will be recorded by not, subject to the provisions of the Sawari Pakistan parties other than the Supplier or used for any purpose unrelated to the provision of the services pursuant heret 3.5. Sawari Pakistan may send a small file to Your computer when You visit the Website. This "cookie" will enable to
track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to th Website. The cookie will not enable Sawari Pakistan to this Website. You can set Your computer browser to reject cookies but this may preclude use of certain parts of this Websi Sawari Pakistan may disclose Your Personal Data if compelled to do so by law, or at the request
of a law enforcement agency or governmental authority. 4. BOOKINGS 4.1. When You enter the details of the Trip, Sawari Pakistan suppliers that are able to fulfil Your requirement. The Quotes are the current special "one off" offers available from suppli are only available at the time of making Your Booking. 4.2. It is Your responsibility to ensure that Your Details and vehicle suitable for your
luggage requirements, a valid postcode which also does not commit a traffic violation, correct date time for the journeys to take place, correct custo the Trip details are inaccurate and an invalid or no postcode or a postcode which causes a traffic violation is provided or a incorrect; date, time, customer name, email ad due a refund, See 7.10. When You make a Booking, this constitutes an offer
which 4.3. In the event, you need to amend or correct a booking you charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you hav booked, you will be required to pay the difference in the price new services you have requested. If there is a price decrease caused by any change you request to the Services that you have TERMS &
CONDITIONS PAGE Private) Limited is a Pakistan registered company which operates in collaboration UK registered company. Both companies share domain name www.sawaripakistan.com 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis whic governs such booking by ticking the "I agree to Sawari Pakistan Terms and
Conditions". If you are booking on behalf of someone Terms & Conditions", You are representing that You have their authority to accept 2.2. Deviations from these Terms are only valid if and to the extent that these are confirmed explicitly and in writing by 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, only the
provision in question will be void, but the rest of these Terms will remain fully in effect. Instead of the invalid provision, a provision will apply that best approached the intention of the parties. an agent on Supplier’s behalf in establishing a contract between YOU and the Supplier. come a party to a contract for the provision of transport service by Supplier to You. The contract for the provision
of the transport service is directly between You and Supplier. The Services shall only be offered in the name and f pplier at the prices and the conditions fixed by the Supplier. 2.5. Where in the Booking You have selected more than one Supplier to provide Your Trips, then the contract for the provision of the transport service relating to each Trip is directly between You and the respective
Supplier You have selected for that Trip in the 3.1. In making a Booking via this Website You must provide accurate Details and are responsible for any failure to do so. and the Supplier that You are at least eighteen years of age and legally able to make does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any nd to cancel any Bookings You have
made, and in such case to refund to You, in full and final settlement of any claims you might have, the Fee paid by You. 3.4. Your Details and data relating to Your use of the Website will be recorded by Sawari Pakistan but Your Sawari Pakistan Privacy Policy presented below and Clause 3.5 below, be disclosed to third parties other than the Supplier or used for any purpose unrelated to the
provision of the services pursuant heret may send a small file to Your computer when You visit the Website. This "cookie" will enable to track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to th Sawari Pakistan to identify You and Sawari Pakistan shall not use it otherwise than in relation to this Website. You can set Your computer
browser to reject cookies but this may preclude use of certain parts of this Websi se Your Personal Data if compelled to do so by law, or at the request of a law enforcement agency or Sawari Pakistan will endeavour to provide You via the Website with Quotes fro suppliers that are able to fulfil Your requirement. The Quotes are the current special "one off" offers available from suppli are only
available at the time of making Your Booking. 4.2. It is Your responsibility to ensure that Your Details and Trip are correct and accurate by providing, but not limited to; selecting a vehicle suitable for your luggage requirements, a valid postcode which also does not commit a traffic violation, correct date time for the journeys to take place, correct customer name, correct and valid email
address, correct and valid telephone number. If the Trip details are inaccurate and an invalid or no postcode or a postcode which causes a traffic violation is provided or a incorrect; date, time, customer name, email address, telephone number provided you may incur additional charges and may not be When You make a Booking, this constitutes an offer which Sawari Pakistan 4.3. In the
event, you need to amend or correct a booking you most contact Sawari Pakistan directly. An charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you hav booked, you will be required to pay the difference in the price between the services originally booked by you, and the price of the new services you have requested. If there
is a price decrease caused by any change you request to the Services that you have n registered company which operates in collaboration with Sawari www.sawaripakistan.com to generate 2.1. If You book a taxi, minicab, minibus or coach via this website, You agree to the terms below as the exclusive basis which Terms and Conditions". If you are booking on behalf of someone Terms &
Conditions", You are representing that You have their authority to accept itly and in writing by Sawari 2.3. Should any provision of these Terms be pronounced invalid or in violation of public order or law by the competent judge, then tead of the invalid provision, a in establishing a contract between YOU and the Supplier. Sawari come a party to a contract for the provision of transport service
by Supplier to You. The contract for the provision of the transport service is directly between You and Supplier. The Services shall only be offered in the name and for the one Supplier to provide Your Trips, then the contract for the provision of You and the respective Supplier You have selected for that Trip in the 3.1. In making a Booking via this Website You must provide accurate Details
and are responsible for any failure to do so. and the Supplier that You are at least eighteen years of age and legally able to make does not guarantee that it will be able to fulfil Your requirements and reserves the rights to refuse any nd to cancel any Bookings You have made, and in such case to refund to You, in full and final but Your Personal Data shall Privacy Policy presented below and
Clause 3.5 below, be disclosed to third parties other than the Supplier or used for any purpose unrelated to the provision of the services pursuant hereto. may send a small file to Your computer when You visit the Website. This "cookie" will enable Sawari Pakistan to track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to the shall
not use it otherwise than in relation to this Website. You can set Your computer browser to reject cookies but this may preclude use of certain parts of this Website. se Your Personal Data if compelled to do so by law, or at the request of a law enforcement agency or will endeavour to provide You via the Website with Quotes from suppliers that are able to fulfil Your requirement. The Quotes
are the current special "one off" offers available from suppliers, which Trip are correct and accurate by providing, but not limited to; selecting a vehicle suitable for your luggage requirements, a valid postcode which also does not commit a traffic violation, correct date and mer name, correct and valid email address, correct and valid telephone number. If the Trip details are inaccurate and an
invalid or no postcode or a postcode which causes a traffic violation is provided or an; dress, telephone number provided you may incur additional charges and may not be Sawari Pakistan may or may not accept. rectly. An administration fee will be charged on all booking amendments. If there is a price increase caused by any change you request to the Services that you have between the
services originally booked by you, and the price of the new services you have requested. If there is a price decrease caused by any change you request to the Services that you have booked, you will be refunded the difference in the price between the services originally booked by you, and the price of the new services you have requested minus the admin charge. This charge will be applied to
your original payment method. 4.4. Any Booking You make may also be subject to the Supplier T&C, although where there is any conflict between the Supplier T&C and the Terms, the provisions contained in the Terms override those in the Supplier T&C. 4.5. If the Supplier wishes to impose the Supplier T&C on You, it shall be responsible for achieving this. 4.6. Sawari Pakistan cannot confirm
the ability of the Supplier to accommodate any Particular Requirements and in the event that You have such Particular Requirements You should not use this Website but instead make alternative travel arrangements that address your Particular Requirements. 4.7. Sawari Pakistan reserves the right to correct typographical errors in any elements of the information that appears on the Website
including pricing mistakes. If, once You are informed of such error, You wish to withdraw Your offer and do not wish to proceed with the purchase, You shall be free to do so and any monies already paid by You will be refunded in full. 4.8. You agree that in the fulfilment of the Booking, where the Supplier feels it is appropriate, the Supplier gives Sawari Pakistan the right to novate the
contract for the provision of transport services between You and the Supplier to another suitable party, to ensure the fulfilment of the Booking as effected via this Website. 4.9. Sawari Pakistan reserves the right to change the type of vehicle you have booked, to ensure the fulfilment of the Booking. With large vehicles subject to availability, in the event the Supplier is no longer able to
provide their vehicle, Sawari Pakistan will endeavour to ensure You are offered alternatives of a similar type and class. 4.10 Sawari Pakistan reserves the right to use third party companies such as Uber, Careem and local supplier to ensure the fulfilment of the Booking, in the case that the original Supplier is unable to fulfill the Booking. For avoidance of doubt, You certify and agree to receive
SMS messages from Uber, Careem and local supplier regarding any trip and You give consent to Uber, Careem and local supplier to share the rider's information, including trip status, with Sawari Pakistan. 5. PAYMENT 5.1. When booking with the ‘Pay by Card’ option, You agree to pay the Fare at the time You make Your Booking. Sawari Pakistan is entitled to collect all monies due for the
booked Trip from You acting entirely and settle all Card Costs of the booked Trip on behalf of the Supplier, in advance of making the payment due to the Supplier for the booked Trip as the Supplier’s agent. 5.2.When paying by Card you agree to Sawari Pakistan using an authorisation token on your Card to allow us to charge your Card in event of any amendments to your Booking. Sawari
Pakistan will not store any of your Card details. In the event that Your Card payment is refused by Your Card issuer for whatever reason, Sawari Pakistan will not be able to fulfil Your Booking. 5.3. When booking with the ‘Pay by Cash’ option, You agree to pay the Fare to the Supplier’s driver. When booking with the ‘BACS’ option, You agree to pay the Fare to the Supplier’s bank account. When
booking with the ‘Credit Balance’ option, You agree to pay the Fare to Sawari Pakistan's nominated bank account. 5.4. Once You have confirmed Your Booking on the payment screen, it cannot be cancelled or changed except in accordance with clause 7 of these Terms. 5.4. If paying by Card, in the event that Your Card payment is refused by Your Card issuer for whatever reason, Sawari
Pakistan will not be able to fulfil Your Booking. 5.5. Sawari Pakistan will provide You with a receipt for Payment by email to the email address you registered with the Booking. 5.6. Payment will not cover any extra requirements you request, such as child seats. If you do not indicate any additional luggage when booking, resulting in a larger size vehicle being required, then the Supplier may
charge you extra, which you must pay them directly for. If You wish to give a tip to the driver, You should do so directly at your own discretion. 5.7. In the event that You make any deviations from the route that You entered into the Website, You may be subject to additional charges by the Supplier in respect thereof and will make such payment directly to the Supplier. 5.8. Sawari Pakistan
offer no warranties on payments made direct to the Supplier with Cash or Bank Transfers. 6. INSURANCE 6.1. Neither Sawari Pakistan nor the Supplier is responsible to You for, or insured in respect of, any damage or loss that may be sustained by the Goods in the course of a Trip and it is Your exclusive responsibility to have such Goods insured in respect of such damage or loss. 7.
CANCELLATION OF BOOKINGS AND REFUNDS 7.1. Cancellations must be made by you using our website. You will be entitled to a refund, depending on when you cancel your Booking. 7.2. Customer(s) who paid a deposit will be refunded or charged depending on the amount due as per condition 7.1. 7.3. At Sawari Pakistan’s discretion, a cancellation charge may be refunded on the basis that
You re-book within 24 (Twenty Four) hours, minus administration charge. Only once the Journey is re-booked, the refund can be processed. However, Sawari Pakistan reserves the right to refuse a cancellation refund at any time. 7.4. In the event that the Supplier does not turn up at the Agreed Pick-Up Point within 15 minutes of the Agreed Time - or within 30 minutes if the Agreed Pick-up
Point is an airport - and You do not use the Supplier for the Trip, You will be entitled to a full refund of the Fare, along with any Card Costs, if you paid them in advance by Card, as full and final settlement of any claim You might have in respect of such failure. However should You, at Your own discretion, decide to continue with the Trip where the Supplier turns up after 15 minutes from the
Agreed time, then you will not be entitled to any such refund. 7.5. In the event that You are not available at the Agreed Pick-Up Point within 15 minutes of the Agreed Time, it will be at the Supplier’s discretion to treat Your non-availability as a cancellation, and You will not be entitled to any refund of the Fare , and any Card Costs that you may have paid in advance. Where the Supplier
decides to wait more than 15 minutes from the Agreed Time, then You may be subject to additional charges by the Supplier in respect of any additional waiting time and/or parking charges and will make such payment directly to the Supplier. 7.6. In the event that you do not wish to use the Services for any reason and do not cancel the Booking using the website or by contacting Sawari
Pakistan.com, no refunds will be provided. 7.7. In the event that you wish to cancel a return leg of your journey, you will not be entitled to any refund. 7.8. For pre-paid Bookings, any refunds due to you to for cancellations or complaints will be made to the card used to make the payment within up to 5 business days (business days being Monday to Friday). 7.9. Customers with credit
accounts or account balances will be exempt from cancellation charges. 7.10. In the event of a Booking cancellation or no show by a Supplier, you must contact Sawari Pakistan within 7 days to issue your refund. 7.11. In the event that You provide inaccurate details including but not limited to an invalid or missing; postcode, postcode causing traffic violation, customer name, email address,
telephone number, you will not be entitled any refund and may incur additional charges from the Supplier in order to complete the Trip. 8. WARRANTY AND LIMITATION OF LIABILITY 8.1. You acknowledge and accept that Sawari Pakistan is acting as the agent of the Supplier and that whilst Sawari Pakistan will try to assist with any complaints You may have in respect of the Supplier’s services,
any legal remedy You seek will be sought against the Supplier not Sawari Pakistan. 8.2. The Supplier has warranted to Sawari Pakistan that it is licensed by the appropriate Licensing Authority(ies) but Sawari Pakistan shall not be responsible for verification of such warranty and if You are in any doubt, You should contact the Private Hire Operator and rely on Your own verification. 8.3. Sawari
Pakistan shall not be liable for any delays in pick-up or drop-off times or for any failure of the Supplier to arrive at all or for any failure of the Supplier to provide its services in accordance with Your requirements or expectations. 8.4. Sawari Pakistan shall not in any event be liable (whether in contract or otherwise) for any indirect loss or consequential loss or loss of revenue, howsoever arising,
suffered by You in connection with any failure by the Supplier or otherwise in connection with these Terms or otherwise and if You consider that You have any claim in respect of any failure by the Supplier You agree to make such claim directly against the Supplier. 8.5. Sawari Pakistan's aggregate liability in respect of these Terms and any matter arising out of it (including claims whether
made in contract or tort) shall be limited to £100. 8.6. Subject as expressly provided in these Terms all warranties, conditions or other terms implied by statute or common law are excluded as far as legally possible. 8.7. Nothing in this clause shall operate so as to exclude any rights You may have under consumer legislation or limit the Supplier's liability for death or personal injury arising out
of its negligence. 8.8. Any estimated trip times and times of arrival presented by Sawari Pakistan on its Public website or otherwise, are only to be taken as indicative, and are not subject to traffic and/or weather conditions and final route taken by driver. Therefore, You agree that You are responsible for setting an appropriate Agreed Time that allows You sufficient time to reach Your
destination to subsequently check-in for a flight, take a train or fulfil any other dependencies You might have. 8.9. Sawari Pakistan may keep You informed of the status of your Booking by sending You email, and SMS text alerts, although Sawari Pakistan does not guarantee the delivery nor the timing of these email and SMS text alerts, and shall not in any event be liable (whether in contract
or otherwise) for any indirect loss or consequential loss or loss of revenue, howsoever arising from the omission or delay of such email and SMS text alerts. 8.10. Each foregoing sub-clause of this clause shall constitute a separate and severable provision. The provisions of this clause shall continue in effect notwithstanding the termination, completion or any other matter which might
otherwise cause these Terms to become ineffective. 9. BEHAVIOUR 9.1. The Supplier reserves the right within its reasonable discretion to terminate the Trip, if You or Your party's conduct or behaviour is disruptive in any way and/or affects your safety or that of the driver of the vehicle. Neither Sawari Pakistan nor the Supplier accepts liability for any extra costs incurred by You/or Your party
as a result of the Supplier doing so. 9.2. Passengers are not permitted to take alcoholic drinks onto the vehicles for the purpose of consuming them during the Trip. 9.3. The Supplier further reserves the right to refuse carriage to any person who is thought to be under the influence of alcohol or drugs. 10. LIMITATIONS AND EXCLUSIONS 10.1. The Supplier shall not undertake the carriage or
delivery of:  money or securities, antiques, precious metals, furs, or jewellery or value, any goods or property (of whatsoever nature) of an intrinsic value of more than £10.  any goods or property of a hazardous, dangerous, inflammable, explosive or noxious nature, or are illegal to possess under existing English Law, and/or  any goods or property (of whatsoever nature) which may
deteriorate in transit. 10.2. The Supplier shall have no liability whatsoever for loss or damage, however arising in relation to the clause 10.1 of this agreement. 10.3. Without prejudice to the provisions of clause 10.1 the Supplier shall not in any event be liable directly or indirectly for:  consequential loss (whether for loss or profit or otherwise); and/or  loss, damage and/or breakage to any
fragile items whatsoever whether arising from the acts, omissions or negligence of the Supplier and/or its employees and/or agents or arising otherwise howsoever. 10.4. Without prejudice to the generality of clauses 10.1 and 10.2 in particular the Supplier shall not be liable for any loss and/or damage arising directly or indirectly from:  breakdown, accident, adverse weather conditions  any
act or omission on the part of the Customer.  any clause, act or circumstance beyond the control of the Supplier (including, without limitation, any strike, (official or not) lock-out or other form of industrial action or labour dispute, governmental regulations, legal restrictions, embargoes, fire, flood, Act of God, any consequence of riot, war, invasion, act of foreign enemy, hostilities (whether
war be declared or not) civil war, acts of terrorism, rebellion, military or usurped power, confiscation, requisition or destruction of or damage to property by or upon the order of or in the name of any Government or public local authority, or other conditions amounting to force majeure). 10.5. The provision of clauses 10.1, 10.2, 10.3, 10.4 and 11.1 apply to liability for loss or damage to goods
or property and do not apply to liability for death or personal injury. 10.6 - By using the website you agree that use of the site is non-exclusive. 10.7 - Every time a booking is generated, you agree at the date and time of the booking, that the terms & Conditions t



        </p>

        </div>


    </div>
    </div>

  

  `;

    // Add the content to the document body (necessary for html2canvas to render it)
    document.body.appendChild(content);


    // const companyLogo = document.getElementById('companylogo');

    // await new Promise((resolve) => {

    //   companyLogo.onload = resolve;
    // });

    try {
      // Generate the PDF using html2canvas

      const canvas = await html2canvas(content, { useCORS: true }, { scale: 3 }); // Adjust the scale as needed for zoom
      const imgData = canvas.toDataURL('image/png');
      const contentWidth = 350; // Your content width in pixels
      const contentHeight = canvas.height * (contentWidth / canvas.width);// Keep the original content height
      const pdfWidth = 500 / 2.83; // Convert content width to mm
      const pdfHeight = 360;

      // Calculate horizontal position to center the content
      const horizontalPos = (pdfWidth - contentWidth) / 2;

      // Calculate vertical position to center the content
      const verticalPos = (pdfHeight - contentHeight) / 2;

      const pdf = new jsPDF('p', 'mm', [pdfWidth, 250]);
      pdf.addImage(imgData, 'PNG', horizontalPos, 0, contentWidth, contentHeight);
      pdf.save(`bookingReceipt${booking.id}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      // Remove the temporary content from the document body
      document.body.removeChild(content);
    }

  }

  const openCancelBooking = (item) => {
    setDataClicked(item);
    setShowCancelModal(true);
  };

  const OpenPrintBooking = (booking) => {



    const styledContent = `
    <div>  
    <div style="width:700px;margin:0px auto;">
    	<table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td width="30%" style="width:30%;"><img src="https://smaztech.com/sawari/assets/email-template/logo.jpg" alt="SAWARI PAKISTAN" style="width:200px;"></td>
            <td width="70%" style="width:70%;">
            	<div style="text-align:right; font-family:Arial, Helvetica, sans-serif; line-height:18px;">
                	<div style="font-size:12px; color:#000; font-weight:bold;">SAWARI AIRPORT TRANSFERS (SMC-PRIVATE) LIMITED</div>
        			<div style="font-size:13px; color:#000;">Office No. 3, Rehmat House, Sandal Road, Gujarkhan, Rawalpindi, Pakistan.</div>
					<div style="font-size:13px; color:#000; font-weight:bold;"><span style="color:#006600;">24 Hour Support</span> <a href="mailto:hello@sawaripakistan.com" style="color:#000; text-decoration:none;">hello@sawaripakistan.com</a></div>
                    <div style="font-size:24px; color:#000; font-weight:bold; line-height:24px;">+92 336 4400044</div>
                </div>
            </td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
        </table>
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:16px; color:#fff; font-weight:bold; background-color:#006600; border-radius:8px; padding:10px; text-align:center; margin-bottom:10px;"><p style="margin-bottom:10px;">BOOKING CONFIRMATION</p></div>
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; font-weight:bold; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;" >Booking Reference Number : <span style="color:#ff0000;">${booking.id}</span></p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Main Passenger :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${booking.passenger_name}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.passenger_contact}</td>
                      </tr>
                    </table>
                </div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Paid Amount :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">PKR ${booking.booking_total_fare}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Payment Method :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.payment_type}</td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:11px; color:#000; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;" ><span style="font-weight:bold; ">Booked Date &amp; Time :</span> ${booking.booking_date} ${booking.booking_time}</p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:15px; padding-bottom:5px;">Account Holder :</td>
                        <td style="padding-top:15px; padding-bottom:5px;">${userData.name}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${userData.phone}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Email :</td>
                        <td  style="padding-top:5px; padding-bottom:5px; color:#000; text-decoration:none;">${userData.email}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:25px;">Account Type :</td>
                        <td  style="padding-top:5px; padding-bottom:25px;">Personal</td>
                      </tr>
                      
                    </table>
                </div>
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" >
        
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tr>
    <td style="font-weight:bold; width:18%;  padding-top:5px; padding-bottom:5px;">Journey Type :</td>
    <td style="padding-top:5px; padding-bottom:5px;">Airport Transfer</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Date :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_date}</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Time :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_time}</td>
  </tr>
  <tr>
    <td style="vertical-align:top; font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.pick_up}
    	${(booking.flight_arrival_time || booking.flight_no) && `<div>
        	<table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td width="13%" style="width:13%">Flight No:</td>
                <td width="12%" style="width:12%">${booking.flight_no}</td>
                <td width="15%" style="width:14%">&nbsp;</td>
                <td width="15%" style="width:15%">Arrival Time:</td>
                <td width="12%" style="width:12%">${booking.flight_arrival_time}</td>
                <td>&nbsp;</td>
              </tr>
            </table>
        </div>`}
    </td>
  </tr>
  
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Via :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.via_location}</td>
  </tr>
   <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Destination :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.drop_off}</td>
  </tr>
   <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Distance :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_distance} km</td>
  </tr>
</table>

        	 
        </div>
        
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
                <div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:6px; padding-bottom:5px;">Vehicle Type :</td>
                        <td style="padding-top:6px; padding-bottom:5px;">${booking.car_title}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:10px;"></td>
                        <td  style="padding-top:5px; padding-bottom:10px;"></td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; line-height:20px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" ><span style="font-weight:bold; ">Important Info :</span> ${booking.additional_note ? booking.additional_note : "No Additional Information Provided"}</div>
                
               
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; background-color:#595959; border-radius:8px; padding:8px; text-align:center; margin-bottom:10px;"><a href="#" style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; text-decoration:none;"><p style="color:#ffffff;margin-bottom:10px">TERMS &amp; CONDITIONS</p></a></div>

        <div style="background-color:#e6e6e6;margin-top:0px;margin-bottom:10px,padding-left:0px;padding-right:0px;border-radius:10px;height:fit-content;padding:0px;" >
        
        <p style="font-family:Arial, Helvetica, sans-serif;margin: 0;
        padding: 10px;
        font-size: 5px; /* Adjust as needed */
        line-height: 1.5; /* Adjust as needed */
        text-align: left;" >
        
        1.1. Sawari Airport Transfers (SMC- Private) Limited is a
                      Pakistan registered company which operates in
                      collaboration with Sawari Pakistan Limited which is UK
                      registered company. Both companies share domain name www.sawaripakistan.com to generate bookings.

                      2.1 If You book a taxi, minicab, minibus or coach via
                      this website, You agree to the terms below as the
                      exclusive basis which governs such booking by ticking
                      the "I agree to Sawari Pakistan Terms and Conditions".
                      If you are booking on behalf of someone else, by
                      clicking "I agree to Sawari Pakistan Terms &
                      Conditions", You are representing that You have their
                      authority to accept these terms and conditions on their
                      behalf.
                      2.2. Deviations from these Terms are only valid if and
                        to the extent that these are confirmed explicitly and in
                        writing by Sawari Pakistan Limited.
                        2.3. Should any provision of these Terms be pronounced
                        invalid or in violation of public order or law by the
                        competent judge, then only the provision in question
                        will be void, but the rest of these Terms will remain
                        
                        fully in effect. Instead of the invalid provision, a
                        provision will apply that best approached the intention
                        of the parties.
                        2.4. Sawari Pakistan Limited shall act as an agent on
                        Supplier’s behalf in establishing a contract between YOU
                        and the Supplier. Sawari Pakistan Limited will never
                        become a party to a contract for the provision of
                        transport service by Supplier to You. The contract for
                        the provision of the transport service is directly
                        between You and Supplier. The Services shall only be
                        account of the Supplier at the prices and the conditions
                        fixed by the Supplier.
                        2.5. Where in the Booking You have selected more than
                        one Supplier to provide Your Trips, then the contract
                        for the provision of the transport service relating to
                        each Trip is directly between You and the respective
                        Supplier you booking with.
                        3.1 In making a Booking via this Website You must
                        provide accurate Details and are responsible for any
                        failure to do so.

                        3.2. You hereby warrant to Sawari Pakistan Limited and
                        the Supplier that You are at least eighteen years of age
                        and legally able to make the booking

                        3.3. Sawari Pakistan Limited does not guarantee that it
                        will be able to fulfil Your requirements and reserves
                        the rights to refuse any Bookings which You wish to make
                        and to cancel any Bookings You have made, and in such
                        case to refund to You, in full and final settlement of
                        any claims you might have, the Fee paid by You.

                        3.4. Your Details and data relating to Your use of the
                        Website will be recorded by Sawari Pakistan Limited but
                        Your Personal Data shall not, subject to the provisions
                        of the Sawari Pakistan Limited Privacy Policy presented
                        below and Clause 3.5 below, be disclosed to third
                        parties other than the Supplier or used for any purpose
                        unrelated to the provision of the services pursuant
                        hereto

                        3.5. Sawari Pakistan Limited may send a small file to
                        Your computer when You visit the Website. This "cookie"
                        will enable to track Sawari Pakistan Limited Your
                        behaviour on the Website and to identify particular
                        areas of interest so as to enhance Your future visits to
                        the Website. The cookie will enable Sawari Pakistan
                        Limited to identify You and Sawari Pakistan Limited
                        shall not use it otherwise than in relation to this
                        Website. You can set Your computer browser to reject
                        cookies but this may preclude use of certain parts of
                        this Website Sawari Pakistan Limited may disclose Your
                        Personal Data if compelled to do so by law, or at the
                        request of a law enforcement agency or governmental
                        authority.

                        4.1 When You enter the details of the Trip, Sawari
                        Pakistan Limited will endeavour to provide You via the
                        Website with Quotes from suppliers that are able to
                        fulfil Your requirement. The Quotes are the current
                        special "one off" offers available from suppliers, which
                        are only available at the time of making Your Booking.

                        4.2. It is Your responsibility to ensure that Your
                        Details and Trip are correct and accurate by providing,
                        but not limited to; selecting a vehicle suitable for
                        your luggage requirements, a valid postcode which also
                        does not commit a traffic violation, correct date and
                        time for the journeys to take place, correct customer
                        name, correct and valid email address, correct and valid
                        telephone number. If the Trip details are inaccurate and
                        an invalid or no postcode or a postcode which causes a
                        traffic violation is provided or an; incorrect; date,
                        time, customer name, email address, telephone number
                        provided you may incur additional charges and may not be
                        due a refund, See 7.10. When You make a booking, this
                        constitutes an offer which Sawari Pakistan Limited may
                        or may not accept.
                        4.3. In the event, you need to amend or correct a
                        booking you most contact Sawari Pakistan Limited
                        directly. An administration fee will be charged on all
                        booking amendments. If there is a price increase caused
                        by any change you request to the Services that you have
                        booked, you will be required to pay the difference in
                        the price between the services originally booked by you,
                        and the price of the new services you have requested. If
                        there is a price decrease caused by any change you
                        request to the Services that you have booked, you will
                        be refunded the difference in the price between the
                        services originally booked by you, and the price of the
                        new services you have requested minus the admin charge.
                        This charge will be applied to your original payment
                        method.
                        4.4. Any Booking You make may also be subject to the
                        Supplier T&C, although where there is any conflict
                        between the Supplier T&C and the Terms, the provisions
                        contained in the Terms override those in the Supplier
                        T&C.
                        4.5. If the Supplier wishes to impose the Supplier T&C
                        on You, it shall be responsible for achieving this

                        4.6. Sawari Pakistan Limited cannot confirm the ability
                        of the Supplier to accommodate any Particular
                        Requirements and in the event that You have such
                        Particular Requirements You should not use this Website
                        but instead make alternative travel arrangements that
                        address your Particular Requirements.

                        4.7. Sawari Pakistan Limited reserves the right to
                        correct typographical errors in any elements of the
                        information that appears on the Website including
                        pricing mistakes. If, once You are informed of such
                        error, You wish to withdraw Your offer and do not wish
                        to proceed with the purchase, You shall be free to do so
                        and any monies already paid by You will be refunded in
                        full.
                        4.8. You agree that in the fulfilment of the Booking,
                        where the Supplier feels it is appropriate, the Supplier
                        gives Sawari Pakistan Limited the right to novate the
                        contract for the provision of transport services between
                        You and the Supplier to another suitable party, to
                        ensure the fulfilment of the Booking as effected via
                        this Website.
                        4.9. Sawari Pakistan Limited reserves the right to
                        change the type of vehicle you have booked, to ensure
                        the fulfilment of the Booking. With large vehicles
                        subject to availability, in the event the Supplier is no
                        longer able to provide their vehicle, Sawari Pakistan
                        Limited will endeavour to ensure You are offered
                        alternatives of a similar type and class.
                        4.10 Sawari Pakistan Limited reserves the right to use
                        third party companies such as Uber, Careem and local
                        supplier to ensure the fulfilment of the Booking, in the
                        case that the original Supplier is unable to fulfill the
                        Booking. For avoidance of doubt, You certify and agree
                        to receive SMS messages from Uber, Careem and local
                        supplier regarding any trip and You give consent to
                        Uber, Careem and local supplier to share the rider's
                        information, including trip status, with Sawari Pakistan
                        Limited.
                        5.1 When booking with the ‘Pay by Card’ option, You
                        agree to pay the Fare at the time You make Your Booking.
                        Sawari Pakistan Limited is entitled to collect all
                        monies due for the booked Trip from You acting entirely
                        and settle all Card Costs of the booked Trip on behalf
                        of the Supplier, in advance of making the payment due to
                        the Supplier for the booked Trip as the Supplier’s
                        agent. 5.2.When paying by Card you agree to Sawari
                        Pakistan Limited using an authorisation token on your
                        Card to allow us to charge your Card in event of any
                        amendments to your Booking. Sawari Pakistan Limited will
                        not store any of your Card details. In the event that
                        Your Card payment is refused by Your Card issuer for
                        whatever reason, Sawari Pakistan Limited will not be
                        able to fulfil Your Booking.
                        5.2. When booking with the ‘Pay by Cash’ option, You
                        agree to pay the Fare to the Supplier’s driver. When
                        booking with the ‘BACS’ option, You agree to pay the
                        Fare to the Supplier’s bank account. When booking with
                        the ‘Credit Balance’ option, You agree to pay the Fare
                        to Sawari Pakistan Limited nominated bank account.

                        5.3. Once You have confirmed Your Booking on the payment
                        screen, it cannot be cancelled or changed except in
                        accordance with clause 7 of these Terms

                        5.4. If paying by Card, in the event that Your Card
                        payment is refused by Your Card issuer for whatever
                        reason, Sawari Pakistan Limited will not be able to
                        fulfil Your Booking.
                        5.5. Sawari Pakistan Limited will provide You with a
                        receipt for Payment by email to the email address you
                        registered with the Booking.
                        5.6. Payment will not cover any extra requirements you
                        request, such as child seats. If you do not indicate any
                        additional luggage when booking, resulting in a larger
                        size vehicle being required, then the Supplier may
                        charge you extra, which you must pay them directly for.
                        If You wish to give a tip to the driver, You should do
                        so directly at your own discretion.
                        5.7. In the event that You make any deviations from the
                        route that You entered into the Website, You may be
                        subject to additional charges by the Supplier in respect
                        thereof and will make such payment directly to the
                        Supplier.
                        5.8. Sawari Pakistan Limited offer no warranties on
                        payments made direct to the Supplier with Cash or Bank
                        Transfers.


        </p>
      
        </div>


    </div>
    </div>

  

  `;

    const printWindow = window.open("Sawari Pakistan Limited", "_blank");
    printWindow.document.write(styledContent);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 2000)
  };

  const OpenRentPrintBooking = (booking) => {



    const styledContent = `
    <div>  
    <div style="width:700px;margin:0px auto;">
    	<table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td width="30%" style="width:30%;"><img src="https://smaztech.com/sawari/assets/email-template/logo.jpg" alt="SAWARI PAKISTAN" style="width:200px;"></td>
            <td width="70%" style="width:70%;">
            	<div style="text-align:right; font-family:Arial, Helvetica, sans-serif; line-height:18px;">
                	<div style="font-size:12px; color:#000; font-weight:bold;">SAWARI AIRPORT TRANSFERS (SMC-PRIVATE) LIMITED</div>
        			<div style="font-size:13px; color:#000;">Office No. 3, Rehmat House, Sandal Road, Gujarkhan, Rawalpindi, Pakistan.</div>
					<div style="font-size:13px; color:#000; font-weight:bold;"><span style="color:#006600;">24 Hour Support</span> <a href="mailto:hello@sawaripakistan.com" style="color:#000; text-decoration:none;">hello@sawaripakistan.com</a></div>
                    <div style="font-size:24px; color:#000; font-weight:bold; line-height:24px;">+92 336 4400044</div>
                </div>
            </td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
        </table>
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:16px; color:#fff; font-weight:bold; background-color:#006600; border-radius:8px; padding:10px; text-align:center; margin-bottom:10px;"><p style="margin-bottom:10px;">BOOKING CONFIRMATION</p></div>
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; font-weight:bold; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;" >Booking Reference Number : <span style="color:#ff0000;">${booking.id}</span></p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Main Passenger :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${booking.passenger_name}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.passenger_contact}</td>
                      </tr>
                    </table>
                </div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Paid Amount :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">PKR ${booking.booking_total_charges}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Payment Method :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.payment_type}</td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:11px; color:#000; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;" ><span style="font-weight:bold; ">Booked Date &amp; Time :</span> ${booking.booking_start_date} ${booking.booking_reporting_time}</p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:15px; padding-bottom:5px;">Account Holder :</td>
                        <td style="padding-top:15px; padding-bottom:5px;">${userData.name}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${userData.phone}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Email :</td>
                        <td  style="padding-top:5px; padding-bottom:5px; color:#000; text-decoration:none;">${userData.email}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:25px;">Account Type :</td>
                        <td  style="padding-top:5px; padding-bottom:25px;">Personal</td>
                      </tr>
                      
                    </table>
                </div>
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" >
        
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tr>
    <td style="font-weight:bold; width:18%;  padding-top:5px; padding-bottom:5px;">Journey Type :</td>
    <td style="padding-top:5px; padding-bottom:5px;">Vehicle Rentals</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Date :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_start_date}</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Time :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_reporting_time}</td>
  </tr>
  <tr>
    <td style="vertical-align:top; font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_address}
    </td>
  </tr>
  
  
   <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Destination :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_address}</td>
  </tr>
   <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Dropoff Date :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_end_date}</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Dropoff Time :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_end_time}</td>
  </tr>
</table>

        	 
        </div>
        
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
                <div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:6px; padding-bottom:5px;">Vehicle Type :</td>
                        <td style="padding-top:6px; padding-bottom:5px;">${booking.car_title}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:10px;"></td>
                        <td  style="padding-top:5px; padding-bottom:10px;"></td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; line-height:20px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" ><span style="font-weight:bold; ">Important Info :</span> ${booking.additional_note ? booking.additional_note : "No Additional Information Provided"}</div>
                
               
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; background-color:#595959; border-radius:8px; padding:8px; text-align:center; margin-bottom:10px;"><a href="#" style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; text-decoration:none;"><p style="color:#ffffff;margin-bottom:10px">TERMS &amp; CONDITIONS</p></a></div>

        <div style="width:700px;background-color:#e6e6e6;margin-top:0px;margin-bottom:10px,padding-left:10px;padding-right:10px;border-radius:10px;height:fit-content;padding:10px;" >
        
        <p style="font-size:5px;font-family:Arial, Helvetica, sans-serif;margin-right:5px;margin-left:5px;" >
        
        1.1. Sawari Airport Transfers (SMC- Private) Limited is a
                      Pakistan registered company which operates in
                      collaboration with Sawari Pakistan Limited which is UK
                      registered company. Both companies share domain name www.sawaripakistan.com to generate bookings.

                      2.1 If You book a taxi, minicab, minibus or coach via
                      this website, You agree to the terms below as the
                      exclusive basis which governs such booking by ticking
                      the "I agree to Sawari Pakistan Terms and Conditions".
                      If you are booking on behalf of someone else, by
                      clicking "I agree to Sawari Pakistan Terms &
                      Conditions", You are representing that You have their
                      authority to accept these terms and conditions on their
                      behalf.
                      2.2. Deviations from these Terms are only valid if and
                        to the extent that these are confirmed explicitly and in
                        writing by Sawari Pakistan Limited.
                        2.3. Should any provision of these Terms be pronounced
                        invalid or in violation of public order or law by the
                        competent judge, then only the provision in question
                        will be void, but the rest of these Terms will remain
                        
                        fully in effect. Instead of the invalid provision, a
                        provision will apply that best approached the intention
                        of the parties.
                        2.4. Sawari Pakistan Limited shall act as an agent on
                        Supplier’s behalf in establishing a contract between YOU
                        and the Supplier. Sawari Pakistan Limited will never
                        become a party to a contract for the provision of
                        transport service by Supplier to You. The contract for
                        the provision of the transport service is directly
                        between You and Supplier. The Services shall only be
                        account of the Supplier at the prices and the conditions
                        fixed by the Supplier.
                        2.5. Where in the Booking You have selected more than
                        one Supplier to provide Your Trips, then the contract
                        for the provision of the transport service relating to
                        each Trip is directly between You and the respective
                        Supplier you booking with.
                        3.1 In making a Booking via this Website You must
                        provide accurate Details and are responsible for any
                        failure to do so.

                        3.2. You hereby warrant to Sawari Pakistan Limited and
                        the Supplier that You are at least eighteen years of age
                        and legally able to make the booking

                        3.3. Sawari Pakistan Limited does not guarantee that it
                        will be able to fulfil Your requirements and reserves
                        the rights to refuse any Bookings which You wish to make
                        and to cancel any Bookings You have made, and in such
                        case to refund to You, in full and final settlement of
                        any claims you might have, the Fee paid by You.

                        3.4. Your Details and data relating to Your use of the
                        Website will be recorded by Sawari Pakistan Limited but
                        Your Personal Data shall not, subject to the provisions
                        of the Sawari Pakistan Limited Privacy Policy presented
                        below and Clause 3.5 below, be disclosed to third
                        parties other than the Supplier or used for any purpose
                        unrelated to the provision of the services pursuant
                        hereto

                        3.5. Sawari Pakistan Limited may send a small file to
                        Your computer when You visit the Website. This "cookie"
                        will enable to track Sawari Pakistan Limited Your
                        behaviour on the Website and to identify particular
                        areas of interest so as to enhance Your future visits to
                        the Website. The cookie will enable Sawari Pakistan
                        Limited to identify You and Sawari Pakistan Limited
                        shall not use it otherwise than in relation to this
                        Website. You can set Your computer browser to reject
                        cookies but this may preclude use of certain parts of
                        this Website Sawari Pakistan Limited may disclose Your
                        Personal Data if compelled to do so by law, or at the
                        request of a law enforcement agency or governmental
                        authority.

                        4.1 When You enter the details of the Trip, Sawari
                        Pakistan Limited will endeavour to provide You via the
                        Website with Quotes from suppliers that are able to
                        fulfil Your requirement. The Quotes are the current
                        special "one off" offers available from suppliers, which
                        are only available at the time of making Your Booking.

                        4.2. It is Your responsibility to ensure that Your
                        Details and Trip are correct and accurate by providing,
                        but not limited to; selecting a vehicle suitable for
                        your luggage requirements, a valid postcode which also
                        does not commit a traffic violation, correct date and
                        time for the journeys to take place, correct customer
                        name, correct and valid email address, correct and valid
                        telephone number. If the Trip details are inaccurate and
                        an invalid or no postcode or a postcode which causes a
                        traffic violation is provided or an; incorrect; date,
                        time, customer name, email address, telephone number
                        provided you may incur additional charges and may not be
                        due a refund, See 7.10. When You make a booking, this
                        constitutes an offer which Sawari Pakistan Limited may
                        or may not accept.
                        4.3. In the event, you need to amend or correct a
                        booking you most contact Sawari Pakistan Limited
                        directly. An administration fee will be charged on all
                        booking amendments. If there is a price increase caused
                        by any change you request to the Services that you have
                        booked, you will be required to pay the difference in
                        the price between the services originally booked by you,
                        and the price of the new services you have requested. If
                        there is a price decrease caused by any change you
                        request to the Services that you have booked, you will
                        be refunded the difference in the price between the
                        services originally booked by you, and the price of the
                        new services you have requested minus the admin charge.
                        This charge will be applied to your original payment
                        method.
                        4.4. Any Booking You make may also be subject to the
                        Supplier T&C, although where there is any conflict
                        between the Supplier T&C and the Terms, the provisions
                        contained in the Terms override those in the Supplier
                        T&C.
                        4.5. If the Supplier wishes to impose the Supplier T&C
                        on You, it shall be responsible for achieving this

                        4.6. Sawari Pakistan Limited cannot confirm the ability
                        of the Supplier to accommodate any Particular
                        Requirements and in the event that You have such
                        Particular Requirements You should not use this Website
                        but instead make alternative travel arrangements that
                        address your Particular Requirements.

                        4.7. Sawari Pakistan Limited reserves the right to
                        correct typographical errors in any elements of the
                        information that appears on the Website including
                        pricing mistakes. If, once You are informed of such
                        error, You wish to withdraw Your offer and do not wish
                        to proceed with the purchase, You shall be free to do so
                        and any monies already paid by You will be refunded in
                        full.
                        4.8. You agree that in the fulfilment of the Booking,
                        where the Supplier feels it is appropriate, the Supplier
                        gives Sawari Pakistan Limited the right to novate the
                        contract for the provision of transport services between
                        You and the Supplier to another suitable party, to
                        ensure the fulfilment of the Booking as effected via
                        this Website.
                        4.9. Sawari Pakistan Limited reserves the right to
                        change the type of vehicle you have booked, to ensure
                        the fulfilment of the Booking. With large vehicles
                        subject to availability, in the event the Supplier is no
                        longer able to provide their vehicle, Sawari Pakistan
                        Limited will endeavour to ensure You are offered
                        alternatives of a similar type and class.
                        4.10 Sawari Pakistan Limited reserves the right to use
                        third party companies such as Uber, Careem and local
                        supplier to ensure the fulfilment of the Booking, in the
                        case that the original Supplier is unable to fulfill the
                        Booking. For avoidance of doubt, You certify and agree
                        to receive SMS messages from Uber, Careem and local
                        supplier regarding any trip and You give consent to
                        Uber, Careem and local supplier to share the rider's
                        information, including trip status, with Sawari Pakistan
                        Limited.
                        5.1 When booking with the ‘Pay by Card’ option, You
                        agree to pay the Fare at the time You make Your Booking.
                        Sawari Pakistan Limited is entitled to collect all
                        monies due for the booked Trip from You acting entirely
                        and settle all Card Costs of the booked Trip on behalf
                        of the Supplier, in advance of making the payment due to
                        the Supplier for the booked Trip as the Supplier’s
                        agent. 5.2.When paying by Card you agree to Sawari
                        Pakistan Limited using an authorisation token on your
                        Card to allow us to charge your Card in event of any
                        amendments to your Booking. Sawari Pakistan Limited will
                        not store any of your Card details. In the event that
                        Your Card payment is refused by Your Card issuer for
                        whatever reason, Sawari Pakistan Limited will not be
                        able to fulfil Your Booking.
                        5.2. When booking with the ‘Pay by Cash’ option, You
                        agree to pay the Fare to the Supplier’s driver. When
                        booking with the ‘BACS’ option, You agree to pay the
                        Fare to the Supplier’s bank account. When booking with
                        the ‘Credit Balance’ option, You agree to pay the Fare
                        to Sawari Pakistan Limited nominated bank account.

                        5.3. Once You have confirmed Your Booking on the payment
                        screen, it cannot be cancelled or changed except in
                        accordance with clause 7 of these Terms

                        5.4. If paying by Card, in the event that Your Card
                        payment is refused by Your Card issuer for whatever
                        reason, Sawari Pakistan Limited will not be able to
                        fulfil Your Booking.
                        5.5. Sawari Pakistan Limited will provide You with a
                        receipt for Payment by email to the email address you
                        registered with the Booking.
                        5.6. Payment will not cover any extra requirements you
                        request, such as child seats. If you do not indicate any
                        additional luggage when booking, resulting in a larger
                        size vehicle being required, then the Supplier may
                        charge you extra, which you must pay them directly for.
                        If You wish to give a tip to the driver, You should do
                        so directly at your own discretion.
                        5.7. In the event that You make any deviations from the
                        route that You entered into the Website, You may be
                        subject to additional charges by the Supplier in respect
                        thereof and will make such payment directly to the
                        Supplier.
                        5.8. Sawari Pakistan Limited offer no warranties on
                        payments made direct to the Supplier with Cash or Bank
                        Transfers.

















        </p>

        
        
        </div>


    </div>
    </div>

  

  `;

    const printWindow = window.open("Sawari Pakistan Limited", "_blank");
    printWindow.document.write(styledContent);
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
    }, 1000)
  };

  const OpenPackageBooking = (booking) => {


    const styledContent = `<div>  
    <div style="width:700px;margin:0px auto;">
    	<table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td width="30%" style="width:30%;"><img src="https://smaztech.com/sawari/assets/email-template/logo.jpg" alt="SAWARI PAKISTAN" style="width:200px;"></td>
            <td width="70%" style="width:70%;">
            	<div style="text-align:right; font-family:Arial, Helvetica, sans-serif; line-height:18px;">
                	<div style="font-size:12px; color:#000; font-weight:bold;">SAWARI AIRPORT TRANSFERS (SMC-PRIVATE) LIMITED</div>
        			<div style="font-size:13px; color:#000;">Office No. 3, Rehmat House, Sandal Road, Gujarkhan, Rawalpindi, Pakistan.</div>
					<div style="font-size:13px; color:#000; font-weight:bold;"><span style="color:#006600;">24 Hour Support</span> <a href="mailto:hello@sawaripakistan.com" style="color:#000; text-decoration:none;">hello@sawaripakistan.com</a></div>
                    <div style="font-size:24px; color:#000; font-weight:bold; line-height:24px;">+92 336 4400044</div>
                </div>
            </td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
        </table>
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:16px; color:#fff; font-weight:bold; background-color:#006600; border-radius:8px; padding:10px; text-align:center; margin-bottom:10px;"><p style="margin-bottom:10px;">BOOKING CONFIRMATION</p></div>
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; font-weight:bold; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;" >Booking Reference Number : <span style="color:#ff0000;">${booking.id}</span></p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Main Passenger :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">${booking.booked_person}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.person_contact}</td>
                      </tr>
                    </table>
                </div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Paid Amount :</td>
                        <td style="padding-top:5px; padding-bottom:5px;">PKR ${booking.booking_price}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Payment Method :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${booking.payment_type}</td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:11px; color:#000; background-color:#ebf1de; border-radius:8px; padding:5px; margin-bottom:10px;" ><p style="margin-bottom:10px;" ><span style="font-weight:bold; ">Booked Date &amp; Time :</span> ${booking.booking_date}</p></div>
                
                <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:15px; padding-bottom:5px;">Account Holder :</td>
                        <td style="padding-top:15px; padding-bottom:5px;">${userData.name}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Telephone :</td>
                        <td  style="padding-top:5px; padding-bottom:5px;">${userData.phone}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:5px;">Email :</td>
                        <td  style="padding-top:5px; padding-bottom:5px; color:#000; text-decoration:none;">${userData.email}</td>
                      </tr>
                      <tr>
                        <td width="40%" style="width:40%; font-weight:bold; padding-top:5px; padding-bottom:25px;">Account Type :</td>
                        <td  style="padding-top:5px; padding-bottom:25px;">Personal</td>
                      </tr>
                      
                    </table>
                </div>
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" >
        
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tr>
    <td style="font-weight:bold; width:18%;  padding-top:5px; padding-bottom:5px;">Journey Type :</td>
    <td style="padding-top:5px; padding-bottom:5px;">Tourism Packages</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Pickup Date :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_date}</td>
  </tr>
  <tr>
    <td style="font-weight:bold;  padding-top:5px; padding-bottom:5px;">Tour Title :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.tour_title}</td>
  </tr>
  <tr>
    <td style="vertical-align:top; font-weight:bold;  padding-top:5px; padding-bottom:5px;">Persons :</td>
    <td style="padding-top:5px; padding-bottom:5px;">${booking.booking_persons}
    </td>
  </tr>

</table>

        	 
        </div>
        
        
        <table style="width:700px;" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="49%" style="width:49%; vertical-align:top;">
                <div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;">
                	<table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:6px; padding-bottom:5px;">Tour Title :</td>
                        <td style="padding-top:6px; padding-bottom:5px;">${booking.tour_title}</td>
                      </tr>
                      <tr>
                        <td width="45%" style="width:45%; font-weight:bold; padding-top:5px; padding-bottom:10px;"></td>
                        <td  style="padding-top:5px; padding-bottom:10px;"></td>
                      </tr>
                    </table>
                </div>
            </td>
            <td width="2%" style="width:2%; vertical-align:top;">&nbsp;</td>
            <td width="49%" style="width:49%; vertical-align:top;">
            	<div style="font-family:Arial, Helvetica, sans-serif; height:90px; line-height:18px; font-size:12px; line-height:20px; color:#000; background-color:#ebf1de; border-radius:8px; padding:10px; margin-bottom:10px;" ><span style="font-weight:bold; ">Important Info :</span> ${booking.additional_note ? booking.additional_note : "No Additional Information Provided"}</div>
                
               
            </td>
          </tr>
        </table>
        
        <div style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; background-color:#595959; border-radius:8px; padding:8px; text-align:center; margin-bottom:10px;"><a href="#" style="font-family:Arial, Helvetica, sans-serif; line-height:18px; font-size:12px; color:#ffffff; font-weight:bold; text-decoration:none;"><p style="color:#ffffff;margin-bottom:10px">TERMS &amp; CONDITIONS</p></a></div>

        <div style="width:700px;background-color:#e6e6e6;margin-top:0px;margin-bottom:10px,padding-left:10px;padding-right:10px;border-radius:10px;height:fit-content;padding:10px;" >
        
        <p style="font-size:5px;font-family:Arial, Helvetica, sans-serif;margin-right:5px;margin-left:5px;" >
        
        1.1. Sawari Airport Transfers (SMC- Private) Limited is a
                      Pakistan registered company which operates in
                      collaboration with Sawari Pakistan Limited which is UK
                      registered company. Both companies share domain name www.sawaripakistan.com to generate bookings.

                      2.1 If You book a taxi, minicab, minibus or coach via
                      this website, You agree to the terms below as the
                      exclusive basis which governs such booking by ticking
                      the "I agree to Sawari Pakistan Terms and Conditions".
                      If you are booking on behalf of someone else, by
                      clicking "I agree to Sawari Pakistan Terms &
                      Conditions", You are representing that You have their
                      authority to accept these terms and conditions on their
                      behalf.
                      2.2. Deviations from these Terms are only valid if and
                        to the extent that these are confirmed explicitly and in
                        writing by Sawari Pakistan Limited.
                        2.3. Should any provision of these Terms be pronounced
                        invalid or in violation of public order or law by the
                        competent judge, then only the provision in question
                        will be void, but the rest of these Terms will remain
                        
                        fully in effect. Instead of the invalid provision, a
                        provision will apply that best approached the intention
                        of the parties.
                        2.4. Sawari Pakistan Limited shall act as an agent on
                        Supplier’s behalf in establishing a contract between YOU
                        and the Supplier. Sawari Pakistan Limited will never
                        become a party to a contract for the provision of
                        transport service by Supplier to You. The contract for
                        the provision of the transport service is directly
                        between You and Supplier. The Services shall only be
                        account of the Supplier at the prices and the conditions
                        fixed by the Supplier.
                        2.5. Where in the Booking You have selected more than
                        one Supplier to provide Your Trips, then the contract
                        for the provision of the transport service relating to
                        each Trip is directly between You and the respective
                        Supplier you booking with.
                        3.1 In making a Booking via this Website You must
                        provide accurate Details and are responsible for any
                        failure to do so.

                        3.2. You hereby warrant to Sawari Pakistan Limited and
                        the Supplier that You are at least eighteen years of age
                        and legally able to make the booking

                        3.3. Sawari Pakistan Limited does not guarantee that it
                        will be able to fulfil Your requirements and reserves
                        the rights to refuse any Bookings which You wish to make
                        and to cancel any Bookings You have made, and in such
                        case to refund to You, in full and final settlement of
                        any claims you might have, the Fee paid by You.

                        3.4. Your Details and data relating to Your use of the
                        Website will be recorded by Sawari Pakistan Limited but
                        Your Personal Data shall not, subject to the provisions
                        of the Sawari Pakistan Limited Privacy Policy presented
                        below and Clause 3.5 below, be disclosed to third
                        parties other than the Supplier or used for any purpose
                        unrelated to the provision of the services pursuant
                        hereto

                        3.5. Sawari Pakistan Limited may send a small file to
                        Your computer when You visit the Website. This "cookie"
                        will enable to track Sawari Pakistan Limited Your
                        behaviour on the Website and to identify particular
                        areas of interest so as to enhance Your future visits to
                        the Website. The cookie will enable Sawari Pakistan
                        Limited to identify You and Sawari Pakistan Limited
                        shall not use it otherwise than in relation to this
                        Website. You can set Your computer browser to reject
                        cookies but this may preclude use of certain parts of
                        this Website Sawari Pakistan Limited may disclose Your
                        Personal Data if compelled to do so by law, or at the
                        request of a law enforcement agency or governmental
                        authority.

                        4.1 When You enter the details of the Trip, Sawari
                        Pakistan Limited will endeavour to provide You via the
                        Website with Quotes from suppliers that are able to
                        fulfil Your requirement. The Quotes are the current
                        special "one off" offers available from suppliers, which
                        are only available at the time of making Your Booking.

                        4.2. It is Your responsibility to ensure that Your
                        Details and Trip are correct and accurate by providing,
                        but not limited to; selecting a vehicle suitable for
                        your luggage requirements, a valid postcode which also
                        does not commit a traffic violation, correct date and
                        time for the journeys to take place, correct customer
                        name, correct and valid email address, correct and valid
                        telephone number. If the Trip details are inaccurate and
                        an invalid or no postcode or a postcode which causes a
                        traffic violation is provided or an; incorrect; date,
                        time, customer name, email address, telephone number
                        provided you may incur additional charges and may not be
                        due a refund, See 7.10. When You make a booking, this
                        constitutes an offer which Sawari Pakistan Limited may
                        or may not accept.
                        4.3. In the event, you need to amend or correct a
                        booking you most contact Sawari Pakistan Limited
                        directly. An administration fee will be charged on all
                        booking amendments. If there is a price increase caused
                        by any change you request to the Services that you have
                        booked, you will be required to pay the difference in
                        the price between the services originally booked by you,
                        and the price of the new services you have requested. If
                        there is a price decrease caused by any change you
                        request to the Services that you have booked, you will
                        be refunded the difference in the price between the
                        services originally booked by you, and the price of the
                        new services you have requested minus the admin charge.
                        This charge will be applied to your original payment
                        method.
                        4.4. Any Booking You make may also be subject to the
                        Supplier T&C, although where there is any conflict
                        between the Supplier T&C and the Terms, the provisions
                        contained in the Terms override those in the Supplier
                        T&C.
                        4.5. If the Supplier wishes to impose the Supplier T&C
                        on You, it shall be responsible for achieving this

                        4.6. Sawari Pakistan Limited cannot confirm the ability
                        of the Supplier to accommodate any Particular
                        Requirements and in the event that You have such
                        Particular Requirements You should not use this Website
                        but instead make alternative travel arrangements that
                        address your Particular Requirements.

                        4.7. Sawari Pakistan Limited reserves the right to
                        correct typographical errors in any elements of the
                        information that appears on the Website including
                        pricing mistakes. If, once You are informed of such
                        error, You wish to withdraw Your offer and do not wish
                        to proceed with the purchase, You shall be free to do so
                        and any monies already paid by You will be refunded in
                        full.
                        4.8. You agree that in the fulfilment of the Booking,
                        where the Supplier feels it is appropriate, the Supplier
                        gives Sawari Pakistan Limited the right to novate the
                        contract for the provision of transport services between
                        You and the Supplier to another suitable party, to
                        ensure the fulfilment of the Booking as effected via
                        this Website.
                        4.9. Sawari Pakistan Limited reserves the right to
                        change the type of vehicle you have booked, to ensure
                        the fulfilment of the Booking. With large vehicles
                        subject to availability, in the event the Supplier is no
                        longer able to provide their vehicle, Sawari Pakistan
                        Limited will endeavour to ensure You are offered
                        alternatives of a similar type and class.
                        4.10 Sawari Pakistan Limited reserves the right to use
                        third party companies such as Uber, Careem and local
                        supplier to ensure the fulfilment of the Booking, in the
                        case that the original Supplier is unable to fulfill the
                        Booking. For avoidance of doubt, You certify and agree
                        to receive SMS messages from Uber, Careem and local
                        supplier regarding any trip and You give consent to
                        Uber, Careem and local supplier to share the rider's
                        information, including trip status, with Sawari Pakistan
                        Limited.
                        5.1 When booking with the ‘Pay by Card’ option, You
                        agree to pay the Fare at the time You make Your Booking.
                        Sawari Pakistan Limited is entitled to collect all
                        monies due for the booked Trip from You acting entirely
                        and settle all Card Costs of the booked Trip on behalf
                        of the Supplier, in advance of making the payment due to
                        the Supplier for the booked Trip as the Supplier’s
                        agent. 5.2.When paying by Card you agree to Sawari
                        Pakistan Limited using an authorisation token on your
                        Card to allow us to charge your Card in event of any
                        amendments to your Booking. Sawari Pakistan Limited will
                        not store any of your Card details. In the event that
                        Your Card payment is refused by Your Card issuer for
                        whatever reason, Sawari Pakistan Limited will not be
                        able to fulfil Your Booking.
                        5.2. When booking with the ‘Pay by Cash’ option, You
                        agree to pay the Fare to the Supplier’s driver. When
                        booking with the ‘BACS’ option, You agree to pay the
                        Fare to the Supplier’s bank account. When booking with
                        the ‘Credit Balance’ option, You agree to pay the Fare
                        to Sawari Pakistan Limited nominated bank account.

                        5.3. Once You have confirmed Your Booking on the payment
                        screen, it cannot be cancelled or changed except in
                        accordance with clause 7 of these Terms

                        5.4. If paying by Card, in the event that Your Card
                        payment is refused by Your Card issuer for whatever
                        reason, Sawari Pakistan Limited will not be able to
                        fulfil Your Booking.
                        5.5. Sawari Pakistan Limited will provide You with a
                        receipt for Payment by email to the email address you
                        registered with the Booking.
                        5.6. Payment will not cover any extra requirements you
                        request, such as child seats. If you do not indicate any
                        additional luggage when booking, resulting in a larger
                        size vehicle being required, then the Supplier may
                        charge you extra, which you must pay them directly for.
                        If You wish to give a tip to the driver, You should do
                        so directly at your own discretion.
                        5.7. In the event that You make any deviations from the
                        route that You entered into the Website, You may be
                        subject to additional charges by the Supplier in respect
                        thereof and will make such payment directly to the
                        Supplier.
                        5.8. Sawari Pakistan Limited offer no warranties on
                        payments made direct to the Supplier with Cash or Bank
                        Transfers.

















        </p>

        
        
        </div>


    </div>
    </div>

  

  `;

    const printWindow = window.open("Sawari Pakistan Limited", "_blank");
    printWindow.document.write(styledContent);
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
    }, 1000)
  };

  let cancelBooking = () => {
    if (!cancelReason) {
      alert("Write Cancel Reason");
      return;
    }

    if (dataClicked.type == "airport") {
      let formData = new FormData();

      formData.append("user_id", userData.id);
      formData.append("booking_id", dataClicked.id);
      formData.append("message", cancelReason);
      setCancelLoading(true);
      axios
        .post(`${BaseUri}cancel_airport_car_request.php`, formData)
        .then((res) => {
          getAirportBookingData();
          setCancelLoading(false);
          setShowCancelModal(false);

        })
        .catch((error) => {
          setCancelLoading(false);
          console.log(error, "error");
        });
    }

    if (dataClicked.type == "rent") {
      let formData = new FormData();

      formData.append("user_id", userData.id);
      formData.append("booking_id", dataClicked.id);
      formData.append("message", cancelReason);
      setCancelLoading(true);
      axios
        .post(`${BaseUri}cancel_rent_a_car_request.php`, formData)
        .then((res) => {
          setCancelLoading(false);
          getRentCarBookingData();
          setShowCancelModal(false);
        })
        .catch((error) => {
          setCancelLoading(false);
          console.log(error, "error");
        });
    }

    if (dataClicked.type == "tour") {
      let formData = new FormData();

      formData.append("user_id", userData.id);
      formData.append("booking_id", dataClicked.id);
      formData.append("message", cancelReason);
      setCancelLoading(true);
      axios
        .post(`${BaseUri}cancel_tour_car_request.php`, formData)
        .then((res) => {
          getTourBookingData();
          setCancelLoading(false);
          setShowCancelModal(false);
        })
        .catch((error) => {
          setCancelLoading(false);
          console.log(error, "error");
        });
    }
  };

  return loading ? (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RingLoader size={150} color="black" loading={loading} />
    </div>
  ) : (
    userData && (
      <Container fluid className="mt-4">
        <h1
          className="smallScreenHeading text-center"
          style={{
            fontSize: 32,
            fontWeight: "500",
            color: "black",
            fontFamily: "sans-serif",
          }}
        >
          Your Bookings
        </h1>
        <h1
          className="smallScreenText text-center"
          style={{
            fontSize: 18,
            marginTop: 10,
            marginBottom: 20,
            fontWeight: "400",
            color: "gray",
            fontFamily: "sans-serif",
          }}
        >
          Please check your upcoming, completed and cancelled bookings here.
        </h1>

        <Row style={{ margin: 0, marginBottom: 20 }}>
          <Col style={{ margin: 0, padding: 0 }} xs={12} xl={2}>
            <button
              onClick={() => setSelectedBooking("airport")}
              style={{
                fontSize: 16,
                fontFamily: "sans-serif",
                fontWeight: "500",
                border: "1px solid black",
                backgroundColor:
                  selectedBooking == "airport" ? "green" : "white",

                color: selectedBooking == "airport" ? "white" : "black",
                padding: 10,
                borderRadius: 8,
                marginTop: 10
              }}
              className="smallScreenText"
            >
              Airport Transfers
            </button>
          </Col>
          <Col style={{ margin: 0, padding: 0 }} xl={2}>
            <button
              onClick={() => setSelectedBooking("rent")}
              style={{
                fontSize: 16,
                fontFamily: "sans-serif",
                fontWeight: "500",
                border: "1px solid black",
                marginTop: 10,

                backgroundColor: selectedBooking == "rent" ? "green" : "white",
                color: selectedBooking == "rent" ? "white" : "black",
                padding: 10,
                borderRadius: 8,
              }}
              className="smallScreenText"
            >
              Vehicle Rentals
            </button>
          </Col>
          <Col style={{ margin: 0, padding: 0 }} xl={2}>
            <button
              onClick={() => setSelectedBooking("tour")}
              style={{
                fontSize: 16,
                fontFamily: "sans-serif",
                fontWeight: "500",
                border: "1px solid black",
                backgroundColor: selectedBooking == "tour" ? "green" : "white",
                color: selectedBooking == "tour" ? "white" : "black",
                padding: 10,
                borderRadius: 8,
                marginTop: 10,
              }}
              className="smallScreenText"

            >
              Tourism Packages
            </button>
          </Col>
          <Col style={{ margin: 0, paddingLeft: 0 }} xs={12} xl={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: 10
              }}
              className="marginBottomTen"
            >
              <p
                style={{ fontSize: 16, fontFamily: "sans-serif" }}
                className="smallScreenText marginTop"
              >
                Upcoming Bookings:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    fontSize: 18,
                  }}
                >
                  {upcomingBooking}
                </span>
              </p>

              <p className="smallScreenText marginTop" style={{ fontSize: 16, fontFamily: "sans-serif" }}>
                Completed Bookings:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    fontSize: 18,
                  }}
                >
                  {completedBookings}
                </span>
              </p>

              <p className="smallScreenText marginTop" style={{ fontSize: 16, fontFamily: "sans-serif" }}>
                Cancelled Bookings:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    fontSize: 18,
                  }}
                >
                  {cancelledBooking}
                </span>
              </p>
            </div>
          </Col>
        </Row>

        {selectedBooking == "airport" && (
          <Row>
            <div style={{ display: "flex" }}>
              <Text
                style={{
                  fontSize: 24,
                  fontWeight: "500",
                  color: "green",
                  fontFamily: "sans-serif",
                  marginTop: 20
                }}
                className="zeroMarginLeft largeText"
              >
                AIRPORT BOOKING
              </Text>
              <select
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "center",
                  padding: 5,
                  borderRadius: 5,
                }}
                onChange={(e) => setStatus(e.target.value)}
                name="format"
                className="smallScreenText"
                value={status}
                id="format"
              >
                <option value="upcoming">Upcoming</option>
                <option value="cancelled">Cancel</option>
                <option value="completed">Completed</option>
              </select>
            </div>

            {AirportBooking && AirportBooking.length > 0 ? (
              AirportBooking.map((booking) => {

                let date = new Date(booking.booking_date);

                const options = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                const formattedDate = date.toLocaleDateString("en-US", options);

                let month = date.getMonth();
                let year = date.getFullYear();
                let day = date.getDate();

                return (
                  <Col style={{ margin: 0, padding: 0 }} key={booking.id} xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Card
                      style={{
                        backgroundColor:
                          booking?.booking_status == "upcoming"
                            ? "white"
                            : booking?.booking_status == "cancelled"
                              ? "pink"
                              : "lightGreen",
                      }}

                      className="paddingZaro zeroMarginLeft zeroMarginRight booking-card"
                    >
                      <Card.Body style={{ width: "100%" }} className="paddingZaro zeroMarginLeft zeroMarginRight" >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <Row className="marginTopTen marginBottomTen" style={{ margin: 0 }} >
                            <Col lg={3} sm={12} md={12} xs={12} xl={3}>
                              <Card.Text
                                style={{
                                  fontFamily: "monospace",
                                  fontSize: 18,
                                  textTransform: "capitalize",
                                  marginBottom: 10,
                                }}
                                className="zeroMarginBottom averageText"
                              >
                                {" "}
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    textTransform: "capitalize",
                                    fontWeight: "600",
                                    fontFamily: "sans-serif",
                                  }}
                                  className="averageText"
                                >
                                  {" "}
                                  Booking ID:{" "}
                                </span>{" "}
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "600",
                                    fontSize: 18,
                                  }}
                                  className="fourteen"
                                >
                                  {booking.id}
                                </span>
                              </Card.Text>
                            </Col>
                            <Col lg={6} sm={12} md={12} xs={12} xl={6}>
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <Card.Text
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontSize: 16,
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                  className="averageText title"
                                >
                                  Vehicle Type:{" "}
                                  <span
                                    style={{
                                      fontFamily: "sans-serif",
                                      fontSize: 16,
                                      textAlign: "center",
                                      fontWeight: "400",
                                    }}
                                    className="fourteen"
                                  >
                                    {" "}
                                    {booking.car_title}{" "}
                                  </span>
                                </Card.Text>
                              </div>
                            </Col>

                            <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                  color: "green",
                                  fontWeight: "600",
                                  textTransform: "capitalize",

                                }}
                                className="fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Status:{" "}
                                </span>{" "}
                                {booking.booking_status}
                              </Card.Text>
                            </Col>

                            <Row style={{ margin: 0, padding: 0 }}>
                              <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                                {(booking.booking_status == "pending" ||
                                  booking.booking_status == "upcoming") && (
                                    <Card.Text
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 16,
                                        color: "green",
                                        fontWeight: "600",
                                      }}
                                      className="fourteen"
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          fontSize: 16,

                                          fontWeight: "600",
                                        }}
                                      >
                                        {" "}
                                        Remaining Time:{" "}
                                      </span>{" "}
                                      {booking.remainingTime
                                        .toString()
                                        .includes("-")
                                        ? "Onboard"
                                        : booking.remainingTime
                                          .toString()
                                          .includes("hr")
                                          ? booking.remainingTime
                                          : `${booking.remainingTime}`}
                                    </Card.Text>
                                  )}
                              </Col>

                              <Col xl={6} lg={6} sm={12} md={12} xs={12}>
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <Card.Text
                                    style={{
                                      fontFamily: "sans-serif",
                                      fontSize: 16,
                                    }}
                                    className="fourteen"
                                  >
                                    {" "}
                                    <span
                                      style={{
                                        color: "black",
                                        fontSize: 16,
                                        fontFamily: "sans-serif",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {" "}
                                      Pickup:{" "}
                                    </span>{" "}
                                    {booking.pick_up}
                                  </Card.Text>
                                </div>
                              </Col>

                              <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                                <Card.Text
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontSize: 16,
                                    textTransform: "capitalize",
                                  }}
                                  className="fourteen"
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: 16,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    Payment Type:{" "}
                                  </span>{" "}
                                  {booking.payment_type}
                                </Card.Text>
                              </Col>
                            </Row>
                            <Row
                              className="zeroMarginTop" style={{ margin: "10px 0px 0px 0px", padding: 0 }}
                            >
                              <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                                <Card.Text
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontSize: 16,
                                  }}
                                  className="fourteen"
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: 16,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    Date:{" "}
                                  </span>{" "}
                                  {formattedDate}
                                </Card.Text>
                              </Col>

                              <Col xl={6} lg={6} sm={12} md={12} xs={12}>
                                {booking.via_location && (
                                  <Card.Text
                                    style={{
                                      fontFamily: "sans-serif",
                                      fontSize: 16,
                                    }}
                                    className="fourteen"
                                  >
                                    <span
                                      style={{
                                        color: "black",
                                        fontSize: 16,
                                        fontWeight: "600",
                                        fontFamily: "sans-serif",
                                      }}
                                    >
                                      {" "}
                                      Via:{" "}
                                    </span>{" "}
                                    {booking.via_location}
                                  </Card.Text>
                                )}
                              </Col>

                              <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                                <Card.Text
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontSize: 16,
                                  }}
                                  className="fourteen"
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: 16,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    Distance:{" "}
                                  </span>{" "}
                                  {Number(booking.booking_distance).toFixed(
                                    2
                                  )}{" "}
                                  Km
                                </Card.Text>
                              </Col>
                            </Row>
                            <Row
                              className="zeroMarginTop" style={{ margin: "10px 0px 0px 0px", padding: 0 }}
                            >
                              <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                                <Card.Text
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontSize: 16,
                                    textAlign: "left",
                                  }}
                                  className="fourteen"
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: 16,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    Time:{" "}
                                  </span>{" "}
                                  {booking.booking_time}
                                </Card.Text>
                              </Col>

                              <Col xl={6} lg={6} sm={12} md={12} xs={12}>
                                <div
                                  style={{ display: "flex" }}
                                >
                                  <Card.Text
                                    style={{
                                      fontFamily: "sans-serif",
                                      fontSize: 16,
                                      textAlign: "left",
                                    }}

                                    className="paddingZaro fourteen"
                                  >

                                    <span
                                      style={{
                                        color: "black",
                                        fontSize: 16,
                                        fontFamily: "sans-serif",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {" "}
                                      Dropoff:{" "}
                                    </span>{" "}
                                    {booking.drop_off}
                                  </Card.Text>
                                </div>
                              </Col>

                              <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                                <Card.Text
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontSize: 16,
                                    textAlign: "left",
                                  }}
                                  className="fourteen"
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: 16,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    Fare:{" "}
                                  </span>{" "}
                                  {new Intl.NumberFormat("en-PK", {
                                    style: "currency",
                                    currency: "PKR",
                                    maximumFractionDigits: 0,
                                  }).format(
                                    Math.ceil(
                                      Number(booking?.booking_total_fare)
                                    )
                                  )}
                                </Card.Text>
                              </Col>
                            </Row>

                            <Row
                              className="zeroMarginTop" style={{ margin: "10px 0px 0px 0px", padding: 0 }}
                            >
                              <Col xl={9} lg={6} sm={12} md={12} xs={12}>
                                <Card.Text
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontSize: 16,
                                    textAlign: "left",
                                  }}
                                  className="fourteen"
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: 16,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    Important Info:{" "}
                                  </span>{" "}
                                  {booking.additional_note
                                    ? booking.additional_note
                                    : "No additional details provided"}
                                </Card.Text>
                              </Col>

                              <Col className="marginTopTen" xl={2} lg={2} sm={6} md={4} xs={11}>
                                {(booking.booking_status == "pending" ||
                                  booking.booking_status == "upcoming") && (
                                    <Button
                                      className="btn-hover"
                                      style={{
                                        border: "green",
                                        width: "110%",
                                      }}
                                      onClick={() => generatePdf(booking)}
                                    >
                                      Download Confirmation
                                    </Button>
                                  )}
                              </Col>


                              {!booking?.remainingTime
                                .toString()
                                .includes("-") && (
                                  <Col className="marginTopTen" xl={1} lg={2} sm={3} md={4} xs={11}>
                                    {(booking.booking_status == "pending" ||
                                      booking.booking_status == "upcoming") && (
                                        <Button
                                          className="btn-hover"
                                          style={{
                                            border: "green",
                                            width: "110%",

                                            backgroundColor: "red",
                                          }}
                                          onClick={() => openCancelBooking(booking)}
                                        >
                                          Cancel
                                        </Button>
                                      )}
                                  </Col>
                                )}
                            </Row>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <div
                style={{
                  minWidth: "100%",
                  minHeight: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: 24,
                    fontFamily: "sans-serif",
                    fontWeight: "500",
                  }}
                >
                  No Airport Bookings
                </h1>
              </div>
            )}
          </Row>
        )}
        {selectedBooking == "rent" && (
          <Row style={{ padding: 0 }}  >
            <div style={{ display: "flex" }}>
              <Text
                style={{
                  fontSize: 22,
                  color: "green",
                  fontFamily: "sans-serif",

                  marginTop: 20,
                  textTransform: "uppercase",
                }}
                className="zeroMarginLeft largeText"
              >
                Vehicle Rental BOOKING
              </Text>
              <select
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  textAlign: "center",

                  borderRadius: 5,
                }}
                className="smallScreenText"
                onChange={(e) => setRentStatus(e.target.value)}
                name="format"
                value={rentStatus}
                id="format"
              >
                <option value="upcoming">upcoming</option>
                <option value="cancelled">Cancel</option>
                <option value="completed">Completed</option>
              </select>
            </div>

            {RentACarBooking && RentACarBooking.length > 0 ? (
              RentACarBooking.map((booking) => {
                let date = new Date(booking.booking_start_date);
                let endDate = new Date(booking.booking_end_date);

                const options = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };

                const formattedDate = date.toLocaleDateString("en-US", options);
                const formattedEndDate = endDate.toLocaleDateString(
                  "en-US",
                  options
                );

                return (
                  <Col style={{ margin: 0, padding: 0 }} key={booking.id} sm={12} md={12} lg={12}>
                    <Card
                      className="paddingZaro zeroMarginLeft zeroMarginRight booking-card"
                      style={{
                        backgroundColor:
                          booking?.booking_status == "upcoming"
                            ? "white"
                            : booking?.booking_status == "cancelled"
                              ? "pink"
                              : "lightGreen",
                      }}
                    >
                      <Card.Body className="paddingZaro zeroMarginLeft zeroMarginRight" >
                        <Row style={{ margin: "10px 0px 0px 0px", padding: 0 }}>
                          <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{
                                fontFamily: "monospace",
                                fontSize: 18,
                                color: "red",
                                fontWeight: "bold",

                              }}
                              className="zeroMarginBottom fourteen"
                            >
                              {" "}
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                                className="averageText"
                              >
                                {" "}
                                Booking Id:{" "}
                              </span>{" "}
                              {booking.id}
                            </Card.Text>
                          </Col>
                          <Col xl={6} lg={6} sm={12} md={12} xs={12}>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                                className="title fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "sans-serif",
                                    fontWeight: "600",
                                  }}
                                  className="averageText"
                                >
                                  {" "}
                                  Vehicle Type:{" "}
                                </span>

                                {booking.car_title}
                              </Card.Text>
                            </div>
                          </Col>
                          <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: 16,
                                color: "green",
                                fontWeight: "600",
                                textTransform: "capitalize",
                              }}
                              className="fourteen"
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Status:{" "}
                              </span>{" "}
                              {booking.booking_status}
                            </Card.Text>
                          </Col>
                        </Row>
                        <Row className="zeroMarginTop" style={{ margin: "10px 0px 0px 0px", padding: 0 }}>
                          <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                            {(booking.booking_status == "pending" ||
                              booking.booking_status == "upcoming") && (
                                <Card.Text
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontSize: 16,
                                    color: "green",
                                    fontWeight: "600",
                                  }}
                                  className="fourteen"
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: 16,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    Remaining Time:{" "}
                                  </span>{" "}
                                  {booking.remainingTime.toString().includes("-")
                                    ? "Onboard"
                                    : booking.remainingTime
                                      .toString()
                                      .includes("hr")
                                      ? booking.remainingTime
                                      : `${booking.remainingTime}`}
                                </Card.Text>
                              )}
                          </Col>
                          <Col xl={6} lg={6} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{ fontFamily: "sans-serif", fontSize: 16 }}
                              className="fourteen"

                            >
                              {" "}
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Pickup:{" "}
                              </span>{" "}
                              {booking.booking_address}
                            </Card.Text>
                          </Col>
                          <Col lg={3} sm={12} md={12} xs={12} xl={3}>
                            <Card.Text
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: 16,
                                color: "black",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                              className="fourteen"

                            >
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Payment Type:{" "}
                              </span>{" "}
                              {booking.payment_type}
                            </Card.Text>
                          </Col>
                        </Row>

                        <Row className="zeroMarginTop" style={{ margin: "10px 0px 0px 0px", padding: 0 }}>
                          <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{ fontFamily: "sans-serif", fontSize: 16 }}
                              className="fourteen"
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}


                              >
                                {" "}
                                Start Date:{" "}
                              </span>{" "}
                              {formattedDate}
                            </Card.Text>
                          </Col>
                          <Col xl={6} lg={6} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{ fontFamily: "sans-serif", fontSize: 16 }}
                              className="fourteen"
                            >
                              {" "}
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Dropoff:{" "}
                              </span>{" "}
                              {booking.booking_address}
                            </Card.Text>
                          </Col>
                          <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{ fontFamily: "sans-serif", fontSize: 16 }}
                              className="fourteen"
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Fare:{" "}
                              </span>{" "}
                              {new Intl.NumberFormat("en-PK", {
                                style: "currency",
                                currency: "PKR",
                                maximumFractionDigits: 0,
                              }).format(
                                Math.ceil(
                                  Number(booking?.booking_total_charges)
                                )
                              )}
                            </Card.Text>
                          </Col>
                        </Row>
                        <Row className="zeroMarginTop" style={{ margin: "10px 0px 0px 0px", padding: 0 }}>
                          <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{ fontFamily: "sans-serif", fontSize: 16 }}
                              className="fourteen"

                            >
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                End Date:{" "}
                              </span>{" "}
                              {formattedEndDate}
                            </Card.Text>
                          </Col>

                          <Col xl={6} lg={6} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{ fontFamily: "sans-serif", fontSize: 16 }}
                              className="fourteen"
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Duration:{" "}
                              </span>{" "}
                              {Number(booking.booking_total_days).toFixed(
                                0
                              )}{" "}
                              {booking.booking_total_days.toFixed(0) == 1
                                ? "Day"
                                : "Days"}
                            </Card.Text>
                          </Col>
                          <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{ fontFamily: "sans-serif", fontSize: 16 }}
                              className="fourteen"
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "sans-serif",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Pickup Time:{" "}
                              </span>{" "}
                              {booking?.booking_reporting_time}
                            </Card.Text>
                          </Col>
                        </Row>

                        <Row className="zeroMarginTop" style={{ margin: "10px 0px 0px 0px", padding: 0 }}>
                          <Col xl={9} lg={6} sm={12} md={12} xs={12}>
                            <Card.Text
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: 16,
                                textAlign: "left",
                              }}
                              className="fourteen"
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Important Info:{" "}
                              </span>{" "}
                              {booking.additional_note
                                ? booking.additional_note
                                : "No additional details provided"}
                            </Card.Text>
                          </Col>

                          <Col className="marginTopTen marginBottomTen" xl={2} lg={2} sm={6} md={4} xs={12}>
                            {(booking.booking_status == "pending" ||
                              booking.booking_status == "upcoming") && (
                                <Button
                                  className="fullwidth btn-hover"
                                  style={{
                                    border: "green",
                                    width: "110%",
                                  }}
                                  onClick={() => generateRentPdf(booking)}
                                >
                                  Download Confirmation
                                </Button>
                              )}
                          </Col>


                          {!booking?.remainingTime.toString().includes("-") && (
                            <Col className="marginBottomTen" xl={1} lg={2} sm={6} md={4} xs={12}>
                              {(booking.booking_status == "pending" ||
                                booking.booking_status == "upcoming") && (
                                  <Button
                                    className="btn-hover fullwidth"
                                    style={{
                                      border: "green",
                                      width: "110%",

                                      backgroundColor: "red",
                                    }}
                                    onClick={() => openCancelBooking(booking)}
                                  >
                                    Cancel
                                  </Button>
                                )}
                            </Col>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <div
                style={{
                  minWidth: "100%",
                  minHeight: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: 20,
                    fontFamily: "sans-serif",
                    fontWeight: "500",
                  }}
                >
                  No Rent Car Bookings
                </h1>
              </div>
            )}
            {/* {showCancelModal && (
          <CancelBookingModal
            heading={"Cancel Booking"}
            show={showCancelModal}
            reason={setCancelReason}
            close={cancelBooking}
            onHide={() => setShowCancelModal(false)}
          /> */}
            {/* )} */}
          </Row>
        )}

        {selectedBooking == "tour" && (
          <Row style={{ padding: 0 }} >
            <div style={{ display: "flex" }}>
              <Text
                style={{
                  fontSize: 24,
                  fontWeight: "500",
                  color: "green",
                  marginTop: 20,
                  fontFamily: "sans-serif",
                  textTransform: "uppercase",
                }}
                className="zeroMarginLeft mediumText"

              >
                Tourism Packages Booking
              </Text>
              <select
                style={{
                  marginLeft: 20,
                  marginTop: 20
                  ,
                  textAlign: "center",

                  borderRadius: 5,
                }}
                value={tourStatus}
                onChange={(e) => setTourStatus(e.target.value)}
                name="format"
                id="format"
              >
                <option value="upcoming">Upcoming</option>
                <option value="cancelled">Cancel</option>
                <option value="completed">Completed</option>
              </select>
            </div>

            {tourBooking && tourBooking.length > 0 ? (
              tourBooking.map((booking) => {
                let date = new Date(booking.booking_date);

                const options = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                const formattedDate = date.toLocaleDateString("en-US", options);

                return (
                  <Col style={{ margin: 0, padding: 0 }} key={booking.id} sm={12} md={12} lg={12}>
                    <Card
                      className="paddingZaro zeroMarginLeft zeroMarginRight booking-card"

                      style={{
                        backgroundColor:
                          booking.booking_status == "upcoming"
                            ? "white"
                            : booking.booking_status == "cancelled"
                              ? "pink"
                              : "lightgreen",
                      }}
                    >
                      <Card.Body className="paddingZaro zeroMarginLeft zeroMarginRight" >
                        <Row style={{ margin: "10px 0px 0px 0px", padding: 0 }} >
                          <Row
                            style={{ margin: "10px 0px 0px 0px", padding: 0 }}
                          >
                            <Col xl={3} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 18,
                                  color: "red",
                                }}
                                className="fourteen"
                              >
                                {" "}
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "sans-serif",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Booking Id:{" "}
                                </span>{" "}
                                {booking.id}
                              </Card.Text>
                            </Col>

                            <Col xl={6} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                }}
                                className="title fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "sans-serif",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Tour Title:{" "}
                                </span>{" "}
                                {booking.tour_title}
                              </Card.Text>
                            </Col>
                            <Col xl={3} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                  color: "green",
                                  fontWeight: "600",
                                  textTransform: "capitalize",
                                }}
                                className="fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "poppins",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Status:{" "}
                                </span>{" "}
                                {booking.booking_status}
                              </Card.Text>
                            </Col>
                          </Row>
                          <Row
                            style={{ margin: "10px 0px 0px 0px", padding: 0 }}
                            className="zeroMarginTop"
                          >
                            <Col xl={3} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                  color: "green",
                                  fontWeight: "600",
                                }}
                                className="fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "sans-serif",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Remaining Time:{" "}
                                </span>{" "}
                                {booking.remainingTime.toString().includes("-")
                                  ? "Onboard"
                                  : booking.remainingTime
                                    .toString()
                                    .includes("hr")
                                    ? booking.remainingTime
                                    : `${booking.remainingTime}`}
                              </Card.Text>
                            </Col>
                            <Col xl={6} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                }}
                                className="fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "sans-serif",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Persons:{" "}
                                </span>{" "}
                                {booking.booking_persons}
                              </Card.Text>
                            </Col>
                            <Col xl={3} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                  color: "black",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                }}
                                className="fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "sans-serif",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Payment Type:{" "}
                                </span>{" "}
                                {booking.payment_type}
                              </Card.Text>
                            </Col>
                          </Row>
                          <Row
                            style={{ margin: "10px 0px 0px 0px", padding: 0 }}
                            className="zeroMarginTop"
                          >
                            <Col xl={3} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                }}
                                className="fourteen"
                              >
                                {" "}
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "sans-serif",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Date:{" "}
                                </span>{" "}
                                {formattedDate}
                              </Card.Text>
                            </Col>
                            <Col xl={6} sm={12} md={12}></Col>

                            <Col xl={3} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                }}
                                className="fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "sans-serif",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Amount:{" "}
                                </span>{" "}
                                {new Intl.NumberFormat("en-PK", {
                                  style: "currency",
                                  currency: "PKR",
                                  maximumFractionDigits: 0,
                                }).format(
                                  Math.ceil(Number(booking?.booking_price))
                                )}
                              </Card.Text>
                            </Col>
                          </Row>

                          <Row
                            style={{ margin: "10px 0px 0px 0px", padding: 0 }}
                            className="zeroMarginTop"
                          >
                            <Col xl={9} lg={6} sm={12} md={12}>
                              <Card.Text
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: 16,
                                  textAlign: "left",
                                }}
                                className="fourteen"
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  Important Info:{" "}
                                </span>{" "}
                                {booking.additional_note
                                  ? booking.additional_note
                                  : "No additional information provided"}
                              </Card.Text>
                            </Col>

                            <Col className="marginTopTen marginBottomTen" xl={2} lg={2} xs={12} sm={4} md={4}>
                              {(booking.booking_status == "pending" ||
                                booking.booking_status == "upcoming") && (
                                  <Button
                                    className="fullwidth btn-hover"
                                    style={{
                                      border: "green",
                                      width: "110%",
                                    }}
                                    onClick={() => generateTourPdf(booking)}
                                  >
                                    Download Confirmation
                                  </Button>
                                )}
                            </Col>

                            {!booking?.remainingTime
                              .toString()
                              .includes("-") && (
                                <Col className="marginBottomTen" xl={1} lg={2} xs={4} sm={4} md={4}>
                                  {(booking.booking_status == "pending" ||
                                    booking.booking_status == "upcoming") && (
                                      <Button
                                        className="btn-hover fullwidth"
                                        style={{
                                          border: "green",
                                          width: "110%",
                                          backgroundColor: "red",
                                        }}
                                        onClick={() => openCancelBooking(booking)}
                                      >
                                        Cancel
                                      </Button>
                                    )}
                                </Col>
                              )}
                          </Row>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <div
                style={{
                  minWidth: "100%",
                  minHeight: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: 20,
                    fontFamily: "monospace",
                    fontWeight: "bold",
                  }}
                >
                  No Tour Packages Bookings
                </h1>
              </div>
            )}
          </Row>
        )}

        {showCancelModal && (
          <CancelBookingModal
            heading={"Cancel Booking"}
            show={showCancelModal}
            loading={cancelLoading}
            reason={setCancelReason}
            close={cancelBooking}
            onHide={() => setShowCancelModal(false)}
          />
        )}
      </Container>
    )
  );
}

export default BookingHistory;
