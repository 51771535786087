import React, {
  useState,
  Component,
  useEffect,
  useRef,
  useContext,
} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../../Navbar/Components/Navbar.css";
import "../../assets/LOGO.png";
import { Image, NavItem } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { BiSearch, BiUser, BiUserCircle } from "react-icons/bi";
import { AiOutlineHeart } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { MDBContainer } from "mdb-react-ui-kit";

import {
  BrowserRouter as Route,
  Router,
  Link,
  Switch,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import loginContext from "../../../Constant/LoginContext/loginContext";

export const Topnav = () => {
  const [isNavLink, setIsNavLink] = useState("Categories");
  const [openDrop, setOpenDrop] = useState(false);
  const context = useContext(loginContext);

  const navigate = useNavigate();

  let { userData, setUserData } = context;
  const [refresh, setRefresh] = useState(false);

  const handleSignOut = async () => {
    await localStorage.removeItem("userData");
    setUserData("");
    setOpenDrop(false);
    setRefresh(!refresh);
    navigate("/Login");
    alert("Successfully Signed Out");
  };

  const navigateToEditProfile = () => {
    setOpenDrop(false);
    navigate("/EditProfile");
  };

  const navigateToUpdatePassword = () => {
    setOpenDrop(false);
    navigate("/UpdatePassword");
  };

  return (
    <>
      <Navbar id="botone" expand="lg" style={{ height: 100 }}>
        <Navbar.Brand>
          <Link to="/">
            <Image src={require("../../assets/Logomain.png")} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" style={{ width: "100%" }}>
          <Nav className="ml-auto" navbarScroll>
            <Link to="/AirportService" className="nav-link">
              Airport Transfers
            </Link>
            <Link to="/RentACar" className="nav-link">
              Vehicle Rentals
            </Link>
            <Link to="/MainTravel" className="nav-link">
              Tourism Packages
            </Link>
            {userData && Object.keys(userData).length > 0 && (
              <Link
                to={
                  userData && Object.keys(userData).length > 0
                    ? "/BookingHistory"
                    : "/login"
                }
                className="nav-link"
              >
                Your Bookings
              </Link>
            )}
          </Nav>

          <Nav className="ml-auto" navbarScroll>
            {!userData ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 200,
                }}
              >
                {" "}
                <Link to="/Signup" className="nav-link">
                  <button className="btnsignup">Signup</button>
                </Link>
                <Link to="/Login" className="nav-link">
                  <button className="btnlogin gap-2 d-flex align-items-center">
                    <BiUserCircle size={25} />
                    Login
                  </button>
                </Link>
              </div>
            ) : (
              <MDBContainer
                style={{ width: 125, height: 80, flexDirection: "column" }}
                className="my-5 d-flex justify-content-center "
              >
                <button  onClick={() => setOpenDrop(!openDrop)}>
                  <img
                    src={
                      userData?.photo
                        ? `https://smaztech.com/sawari/${userData.photo}`
                        : require("../../assets/avatar.avif")
                    }
                    className="rounded-circle"
                    alt="Avatar"
                    stlye={{ width: 50, height: 50 }}
                  />
                </button>
                {openDrop && (
                  <button
                    onClick={() => navigateToEditProfile()}
                    style={{
                      color: "white",
                      textAlign: "center",
                      marginTop: 2,
                      border: "1px solid white",
                      position: "relative",
                      top: 20,
                      backgroundColor: "green",
                      borderBottom: "0px",
                      fontSize: 12,
                      fontFamily: "sans-serif",
                    }}
                  >
                    Edit Profile
                  </button>
                )}
                {openDrop && (
                  <button
                    onClick={() => navigateToUpdatePassword()}
                    style={{
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "green",
                      marginTop: 2,
                      position: "relative",
                      top: 20,
                      border: "1px solid white",
                      fontSize: 12,
                      fontFamily: "sans-serif",
                    }}
                  >
                    Update Pass
                  </button>
                )}
                {openDrop && (
                  <button
                    onClick={() => handleSignOut()}
                    style={{
                      color: "white",
                      textAlign: "center",
                      marginTop: 2,
                      position: "relative",
                      top: 20,
                      backgroundColor: "green",
                      border: "1px solid white",
                      borderTop: "0px",
                      fontSize: 12,
                      fontFamily: "sans-serif",
                    }}
                  >
                    SignOut
                  </button>
                )}
              </MDBContainer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
