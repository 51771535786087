import React, { useContext, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";
import BaseUri from "../../../Constant/BaseUri";
import "./travel.css";
import { useNavigate } from "react-router-dom";
import loginContext from "../../../Constant/LoginContext/loginContext";
import { RingLoader } from "react-spinners";
import { NumericFormat } from "react-number-format";

export const BannerTravel = () => {
  const navigate = useNavigate();

  const userContext = useContext(loginContext);

  let { userData, setUserData } = userContext;
  const [Currency, setCurrency] = React.useState("PKR");
  const [loading, setLoading] = useState(false);

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  React.useEffect(() => {
    handleCheckUser();
  }, []);

  const routeToDetailPage = (item) => {
    if (userData && Object.keys(userData).length > 0) {
      item.userData = userData;

      navigate("/TravelDetails", { state: item });
    } else {
      navigate("/login");
    }
  };

  const [packagesData, setPackagesData] = React.useState([]);

  const getTourPackages = () => {
    setLoading(true);
    axios
      .get(`${BaseUri}get_all_tours.php`)
      .then((res) => {
        let { data } = res;

        let sortData =
          data &&
          data.length > 0 &&
          data.sort((a, b) => a.tour_price - b.tour_price);

        setLoading(false);
        setPackagesData(sortData);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getTourPackages();
  }, []);

  return loading ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RingLoader size={150} color={"black"} loading={loading} />
    </div>
  ) : (
    <Container fluid>

      <h1
        className="text-center mt-5"
        style={{
          wordSpacing: 2,
          fontSize: 36,
          textTransform: "uppercase",
          color: "green",
          fontWeight: "400",
          fontFamily: "sans-serif",
        }}
      >
        TOURISM Packages
      </h1>
      
      {packagesData && packagesData.length > 0 && (
        <select
          style={{ width: 100, textAlign: "center" }}
          onChange={(e) => setCurrency(e.target.value)}
          name="format"
          value={Currency}
          className="marginTop"
          id="format"
        >
          <option value="PKR">PKR</option>
          <option value="GBP">GBP</option>
          <option value="USD">USD</option>
        </select>
      )}

      <Row style={{ margin: 0, padding: 0 }} className="mt-4">
        {packagesData && packagesData.length > 0 ? (
          packagesData.map((e) => (
            <Col key={e.id} xs={12} md={6} lg={4} className="mb-4">
              <Card style={{ minHeight: 460 }}>
                <Card.Img
                  variant="top"
                  style={{ width: "100%", height: 200 }}
                  src={`https://smaztech.com/sawari/${e.tour_photo}`}
                  alt={e.name}
                />
                <Card.Body>
                  <Card.Title
                    className="averageText"
                    style={{ fontSize: 18, fontFamily: "sans-serif" }}
                  >
                    {e.tour_title.slice(0, 30)}...
                  </Card.Title>
                  <Card.Title
                    style={{ fontSize: 16, fontFamily: "sans-serif" }}
                  >
                    {e.tour_location}
                  </Card.Title>
                  <Card.Text
                    className="extraSmall"
                    style={{ fontSize: 14, fontFamily: "sans-serif" }}
                  >
                    {e.tour_description.slice(0, 100)}...
                  </Card.Text>
                  <Card.Text
                    style={{
                      marginTop: 10,
                      color: "green",
                      fontWeight: "400",
                      fontSize: 24,
                      marginBottom: 10,
                      fontFamily: "sans-serif",
                    }}
                  >
                    {Currency == "PKR"
                      ? new Intl.NumberFormat("en-PK", {
                        style: "currency",
                        currency: "PKR",
                        maximumFractionDigits: 0,
                      }).format(Number(e?.tour_price))
                      : Currency == "GBP"
                        ? e.tour_price_gbp.toLocaleString("en-GB", {
                          style: "currency",
                          currency: "GBP",
                        })
                        : e.tour_price_usd.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                    {/* <span style={{ fontSize: 14, color: "black" }}>
                        {" "}
                        {Currency}
                      </span> */}
                  </Card.Text>
                  <Button
                    onClick={() => routeToDetailPage(e)}
                    style={{
                      position: "absolute",
                      bottom: 20,
                      left: 10,
                      right: 10,
                    }}
                    className="btn-hovers"
                  >
                    View Details
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <div
            style={{
              minWidth: "100%",
              minHeight: "20vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontSize: 20,
                fontFamily: "monospace",
                fontWeight: "bold",
              }}
            >
              No Tour Packages
            </h1>
            <h1
              style={{
                fontSize: 16,
                fontFamily: "monospace",
                fontWeight: "400",
              }}
            >
              Tour Packages will be announed soon
            </h1>
          </div>
        )}
      </Row>

    </Container>
  );
};
