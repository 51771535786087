import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const MainBannerRent = ({ scrollToCar }) => {
  return (
    <Container fluid className="bgRC" style={{ height: 600, width: "100%" }} >
      <Row>
        <Col xs={12} >
          <div className="webkit ser" style={{ width: '80vw' }} >
            <h2
              className="mediumText text-white font-extrabold"
              style={{ marginBottom: 10 }}
            >
              VEHICLE RENTALS
            </h2>
            <h4 className="smallScreenText text-white font-extrabold" style={{ fontSize: 18, marginBottom: 40 }}>
              Experience seamless travel with Sawari Pakistan's premium Vehicle
              Rental Service in Pakistan, offering chauffeur-driven vehicles for a
              hassle-free journey.
            </h4>
            <div className="fullwidth webkit" >
              <button
                onClick={scrollToCar}

                className="smallScreenText px-5 py-3 mt-4 rounded-xl text-white bgGreen"
              >
                Book Now
              </button>
            </div>
          </div>
        </Col>
      </Row>

    </Container>
  );
};
