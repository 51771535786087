import React, { useContext, useEffect } from "react";
import { MainRentCar } from "../RentACar";
import loginContext from "../../Constant/LoginContext/loginContext";

export const RentACar = () => {
  const context = useContext(loginContext);
  let { userData, setUserData } = context;

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  useEffect(() => {
    handleCheckUser();
  }, []);

  return (
    <>
      <MainRentCar />
    </>
  );
};
