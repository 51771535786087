import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import BaseUri from "../../Constant/BaseUri";


const AnyReactComponent = ({ text }) => (
  <div>
    {" "}
    <img
      src={require("../assets/location1.png")}
      style={{ height: 70, width: 50 }}
    />
  </div>
);

function ContactUs() {
  const [googleMapKey, setGoogleMapKey] = useState("");

  const getGoogleMapKey = () => {
    axios.get(`${BaseUri}get_all_api_keys.php`).then((res) => {
      let { data } = res;

      if (data && data.length > 0) {
        let key = data[0]?.api_key;
        setGoogleMapKey(key);
      }
    });
  };

  useEffect(() => {
    getGoogleMapKey();
  }, []);

  const center = {
    lat: 52.477482,
    lng: -1.851114,
  };
  const center2 = {
    lat: 33.2551975,
    lng: 73.2900122,
  };

  const zoom = 13;

  const mapContainerStyle = {
    width: "500px",
    height: "400px",
    border: "1px solid #ccc",
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 20,
                flexDirection: "column",
              }}
            >
              <img
                src={require("../assets/LOGO.png")}
                style={{ width: 250, height: 100 }}
              />

              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontSize: 18,
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                CONTACT US PAGE
              </h1>
            </div>
          </div>
        </Col>

        <Row>
          <Col style={{ marginTop: 20 }} sm={12} md={12} lg={5} xl={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={mapContainerStyle}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: googleMapKey,
                  }}
                  defaultCenter={center}
                  defaultZoom={zoom}
                >
                  <AnyReactComponent
                    lat={center.lat}
                    lng={center.lng}
                    text="Marker"
                  />
                </GoogleMapReact>
              </div>

              <h1
                style={{
                  fontSize: 18,
                  fontFamily: "sans-serif",
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: 20,

                    fontFamily: "sans-serif",
                  }}
                >
                  SAWARI PAKISTAN LIMITED
                </span>
                <br />
                R1, 179 Bordesley Green East,
                <br />
                Birmingham, West Midlands,
                <br />
                England, B9 5SR
                <br />
                United Kingdom
              </h1>

              <h1
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
              >
                Company No: 14810746
              </h1>
              <a
                href="http://smaztech.com/sawari/assets/info-pdfs/uk-company.pdf"
                target="_blank"
              >
                <h1
                  style={{
                    fontSize: 16,
                    fontFamily: "sans-serif",
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 5,
                  }}
                >
                  Click to view certificate
                </h1>
              </a>
            </div>
          </Col>

          <Col sm={12} md={12} lg={2} xl={2}>
            <div
              style={{
                display: "flex",
                height: "400px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                marginBottom: 10,
                paddingRight: 25,
              }}
            >
              <img
                src={require("../assets/handsShake.png")}
                style={{ width: 120, height: 80 }}
              />
              <h1
                style={{
                  fontSize: 10,
                  fontWeight: "600",
                  color: "black",
                  marginTop: 5,
                }}
              >
                In Collabaration with
              </h1>
            </div>
          </Col>

          <Col style={{ marginTop: 20 }} sm={12} md={12} lg={5} xl={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={mapContainerStyle}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: googleMapKey,
                  }}
                  defaultCenter={center2}
                  defaultZoom={zoom}
                >
                  <AnyReactComponent
                    lat={center2.lat}
                    lng={center2.lng}
                    text="Marker"
                  />
                </GoogleMapReact>
              </div>
              <h1
                style={{
                  fontSize: 18,
                  fontFamily: "sans-serif",
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: 20,

                    fontFamily: "sans-serif",
                  }}
                >
                  SAWARI AIRPORT TRANSFERS
                </span>
                <br />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: 20,

                    fontFamily: "sans-serif",
                  }}
                >
                  (SMC-PRIVATE) LIMITED
                </span>
                <br />
                Office No.3, Rehmat House,
                <br />
                Sandal Road, Ward No.14,
                <br />
                Gujarkhan, Rawalpindi, Pakistan
              </h1>

              <h1
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
              >
                Company No: 0207475
              </h1>
              <h1
                style={{
                  fontSize: 16,
                  fontFamily: "sans-serif",
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
              >
                <a
                  href="http://smaztech.com/sawari/assets/info-pdfs/pakistan-company.pdf"
                  target="_blank"
                >
                  Click to view certificate
                </a>
              </h1>
            </div>
          </Col>
        </Row>
      </Row>

      <Row>
        <Col sm={12} md={12} xl={12} lg={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <div style={{ display: "flex" }}>
              <h1
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Email:
              </h1>
              <h1
                style={{
                  fontSize: 18,
                  fontWeight: "400",
                  marginLeft: 30,
                  fontFamily: "sans-serif",
                }}
              >
                <a href="mailto:hello@sawaripakistan.com">
                  hello@sawaripakistan.com
                </a>
              </h1>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <h1
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Website:
              </h1>
              <h1
                style={{
                  fontSize: 18,
                  fontWeight: "400",
                  marginLeft: 30,
                  fontFamily: "sans-serif",
                }}
              >
                <a href="https://www.sawaripakistan.com">
                  www.sawaripakistan.com
                </a>
              </h1>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <h1
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Contact:
              </h1>
              <h1
                style={{
                  fontSize: 18,
                  fontWeight: "400",
                  marginLeft: 30,
                  fontFamily: "sans-serif",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href="tel:0092 336 4400044">0092 336 4400044</a>
                <img
                  src={require("../assets/whatsappIcon.png")}
                  style={{ width: 30, height: 30, marginLeft: 5 }}
                />
              </h1>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
