import React,{useState} from "react";
import LoginContext from "./loginContext";



const LoginState = (prop) => {

    const [userData,setUserData] = useState("")


    return (
        <LoginContext.Provider value={{userData,setUserData}} >
                {prop.children}
        </LoginContext.Provider>
    )

}

export default LoginState