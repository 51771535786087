import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const TravelDetails = () => {
  const location = useLocation();

  let { state } = location;

  return (
    state && (
      <>
        <Container>
          <Row>
            <Col lg="1" ></Col>
            <Col lg="6">
              <div style={{display:"flex",justifyContent:"space-between"}} >
              <h1 style={{ fontSize: 22, fontWeight: "500", marginTop: 5 }}>
                {state?.tour_title}
              </h1>
              <h1 style={{ fontSize: 18, fontWeight: "500", marginTop: 5,marginBottom:10 }}>
                {state?.tour_days} Days 
              </h1>
              </div>


              <h2 className="text-xl  font-semibold" style={{paddingTop:2,marginTop:20}} >Description:</h2>
              <p style={{color:"green"}} >Note: You must read description of this package carefully before proceeding.</p>

              <p style={{marginTop:10}} >{state?.tour_description}</p>



            </Col>
          </Row>
        </Container>
      </>
    )
  );
};

export default TravelDetails;
