import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Image } from 'react-bootstrap';
function PaymentModals(props) {


    let {visible,close,heading,text} = props

  const [show, setShow] = useState(true);

  const handleClose = () => close(false);
  
  return (
    <>
      

      <Modal show={visible}  >
        <Modal.Header closeButton>
          <Modal.Title >{heading}</Modal.Title>
        </Modal.Header>
        <Image src={require("../assets/payment.jpg")} style={{width:250,height:250,alignSelf:"center"}} />
        <Modal.Body style={{textAlign:"center",fontSize:18,fontFamily:"sans-serif"}} >{text}</Modal.Body>
        <Modal.Footer>
          
          <Button style={{backgroundColor:"green",width:100}} onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PaymentModals;