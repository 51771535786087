import React, { useContext, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiFillCalendar,
  AiFillClockCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import loginContext from "../../../Constant/LoginContext/loginContext";
import { useLocation, useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Modals from "../../modal/modal";
import axios from "axios";
import BaseUri from "../../../Constant/BaseUri";

export const OnewayAndReturn = () => {
  const navigate = useNavigate();

  const context = useContext(loginContext);
  let { userData, setUserData } = context;
  const [address, setAddress] = useState("");
  const [viaLocation, setViaLocation] = useState("");
  const [dropoffAddress, setDropoffAddress] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [dateClicked, setDateClicked] = useState(false);
  const [timeClicked, setTimeClicked] = useState(false);
  const [allAirports, setAllAirports] = useState([]);
  const [pickupPlace, setPickupPlace] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [passenger, setPassenger] = useState(0);
  const [pickupCord, setPickupCords] = useState("");
  const [viaCords, setViaCords] = useState("");
  const [dropoffCords, setDropoffCords] = useState("");
  const [modalVisible, setModalVisible] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [formError, setFormError] = useState("");
  const [googleMapKey, setGoogleMapKey] = useState("");

  const onChangeCallback = ({ target }) => {
    // a callback function when user select a date
  };

  const getGoogleMapKey = () => {
    axios.get(`${BaseUri}get_all_api_keys.php`).then((res) => {
      let { data } = res;

      if (data && data.length > 0) {
        let key = data[0]?.api_key;
        setGoogleMapKey(key);
      }
    });
  };

  useEffect(() => {
    getGoogleMapKey();
  }, []);

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setPickupCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };
  const handleViaSelect = async (selectedAddress) => {
    setViaLocation(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setViaCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };
  const handleDropoffSelect = async (selectedAddress) => {

    setDropoffAddress(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);
      setDropoffCords(latLng);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const getAllAirports = () => {
    axios
      .get(`${BaseUri}get_all_airports.php`)
      .then((res) => {
        let { data } = res;

        setAllAirports(data);
      })
      .catch((error) => {
        // alert("Internal Server Error");
      });
  };

  useEffect(() => {
    getAllAirports();
  }, []);

  const handleGetDropoffCords = async () => {
    try {
      const apiKey = googleMapKey;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        dropoffAddress
      )}&key=${apiKey}`;

      const response = await axios.get(apiUrl);

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setDropoffCords({
          lat: lat,
          lng: lng,
        });
      } else {
        console.error("No results found for the given address.");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching geolocation data:",
        error
      );
    }
  };

  const handleGetPickupCords = async () => {
    try {
      const apiKey = googleMapKey;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${apiKey}`;
      const response = await axios.get(apiUrl);

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setPickupCords({
          lat: lat,
          lng: lng,
        });
      } else {
        console.error("No results found for the given address.");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching geolocation data:",
        error
      );
    }
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const parsedHours = parseInt(hours, 10);

    const period = parsedHours >= 12 ? "PM" : "AM";
    const adjustedHours = parsedHours % 12 || 12;

    return `${adjustedHours}:${minutes} ${period}`;
  };

  useEffect(() => {
    if (pickupPlace == "location" && dropoffAddress) {
      handleGetDropoffCords();
    } else if (pickupPlace == "airport" && address) {
      handleGetPickupCords();
    }
  }, [pickupPlace, address, dropoffAddress, googleMapKey]);

  const navigateToMapScreen = async () => {
    const error = {};

    let formattedDate;
    let formattedTime;

    if (selectedDate && selectedTime) {
      let format = new Date(selectedDate);

      let date1 = format.getDate();
      let month1 = format.getMonth();
      let year1 = format.getFullYear();

      formattedDate = `${date1} / ${month1} / ${year1}`;
      formattedTime = await convertTo12HourFormat(selectedTime);
    }

    let data = {
      user_id: userData.id,
      booking_date: selectedDate,
      booking_time: selectedTime,
      booking_pickup: address,
      formattedDate: formattedDate,
      formattedTime: formattedTime,
      booking_dropoff: dropoffAddress,
      booking_via_location: viaLocation,
      pickupCords: pickupCord,
      pickupPlace: pickupPlace,
      viaCords: viaCords,
      dropoffCords: dropoffCords,
      passengers: passenger,
      allAirports: allAirports,
    };

    if (!data.booking_pickup) {
      error.pickupAddress = true;
    }
    if (!data.booking_dropoff) {
      error.dropoffAddress = true;
    }
    if (!data.pickupPlace) {
      error.pickupPlace = true;
    }
    if (!data.booking_date) {
      error.bookingDate = true;
    }
    if (!data.passengers) {
      error.passengers = true;
    }
    if (!data.booking_time) {
      error.bookingTime = true;
    }

    if (error && Object.keys(error).length > 0) {
      setFormError(error);
      return;
    }

    setFormError(error);

    let nowMonth = new Date().getMonth();
    let nowYear = new Date().getFullYear();
    let nowDate = new Date().getDate();

    let date = new Date(selectedDate);

    let month = date.getMonth();
    let year = date.getFullYear();
    let selectDate = date.getDate();

    if (year < nowYear) {
      // alert("Can't choose previous year");
      setModalHeading("Date Alert");
      setModalText("You can't choose previous year to book ride");
      setModalVisible(true);
      return;
    }

    if (year == nowYear && month < nowMonth) {
      setModalHeading("Date Alert");
      setModalText("You can't choose previous month to book ride");
      setModalVisible(true);

      return;
    }

    if (month == nowMonth && year == nowYear && selectDate < nowDate) {
      setModalHeading("Date Alert");
      setModalText("You can't choose previos date to book ride");
      setModalVisible(true);
      return;
    }

    let nowHours = new Date().getHours();
    let hours = selectedTime?.slice(0, 2);

    if (month == nowMonth && year == nowYear && selectDate == nowDate) {
      if (nowHours >= Number(hours)) {
        setModalHeading("Time Alert");
        setModalText("selected time must be greater than current time");
        setModalVisible(true);
        return;
      }
    }

    navigate("/MapScreen", { state: data });
  };

  return (
    <Container fluid>
      <Container style={{ zindex: 100 }}>
        <Row>
          <Col lg="12">
            <div className="oneway py-11">
              <Tabs>
                <TabList>
                  <Tab>One Way</Tab>
                </TabList>

                <TabPanel>
                  <div style={{ zindex: 100 }}>
                    <Row className="align-items-center">
                      {!pickupPlace ? (
                        <Col lg={6} xl={4} md={6} sm={12} xs={12}>
                          <div className="About_main_images_search">
                            <select
                              name="format"
                              id="format"
                              className="w-full"
                              // style={{ width: "100%", padding: 10 }}
                              onChange={(e) => setPickupPlace(e.target.value)}
                            >
                              <option selected disabled>
                                Select Pickup Location
                              </option>
                              <option value="location">Your Location</option>
                              <option value="airport">Airport</option>
                            </select>
                          </div>
                          {formError && formError.pickupPlace && (
                            <h1
                              style={{
                                color: "white",
                                fontFamily: "sans-serif",
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              choose pickup Place
                            </h1>
                          )}
                        </Col>
                      ) : (
                        <Row style={{ margin: 0, padding: 0 }} >
                          <Col xl={4} lg={6} sm={12} md={12} xs={12}  >
                            {pickupPlace == "location" ? (
                              <PlacesAutocomplete
                                vogleCallbackName="initMap"
                                value={address}
                                onChange={setAddress}
                                onSelect={handleSelect}
                                // searchOptions={{
                                //   types: ["geocode"], // Limit results to addresses only
                                // }}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                }) => (
                                  <div
                                    style={{ marginTop: 40 }}
                                    className="marginTop autocomplete-input-container"
                                  >
                                    <input
                                      {...getInputProps({
                                        placeholder: "Enter Pickup Location",
                                        className: "autocomplete-input",
                                      })}
                                      style={{
                                        width: "100%",
                                        padding: "10px",
                                        color: "black",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                      }}
                                    />
                                    {address && (
                                      <div className="autocomplete-dropdown-container">
                                        {suggestions.map(
                                          (suggestion, index) => (
                                            <div
                                              key={index}
                                              {...getSuggestionItemProps(
                                                suggestion
                                              )}
                                              className="autocomplete-suggestion"
                                              style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                color: "black",
                                                backgroundColor: "#fff",
                                                borderBottom: "1px solid #ccc",
                                              }}
                                            >
                                              {suggestion.description}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            ) : (
                              <div
                                style={{ marginTop: 37 }}
                                className="About_main_images_search"
                              >
                                <select
                                  name="format"
                                  id="format"
                                  className="w-full"
                                  style={{ width: "100%", padding: 4 }}
                                  onChange={(e) => setAddress(e.target.value)}
                                >
                                  <option selected disabled>
                                    Select Pickup Location
                                  </option>
                                  {allAirports &&
                                    allAirports.length > 0 &&
                                    allAirports.map((e, i) => {
                                      return (
                                        <option value={e.airport_address}>
                                          {e.airport_name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            )}
                            {formError && formError.pickupAddress && (
                              <h1
                                style={{
                                  color: "white",
                                  fontFamily: "sans-serif",
                                  fontWeight: "bold",
                                }}
                              >
                                Pickup address is missing
                              </h1>
                            )}
                          </Col>
                          <Col xl={4} lg={6} sm={12} md={12}  >
                            <PlacesAutocomplete
                              value={viaLocation}
                              onChange={setViaLocation}
                              onSelect={handleViaSelect}
                              vogleCallbackName="initMap"
                              // searchOptions={{
                              //   types: ["geocode"], // Limit results to addresses only
                              // }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                              }) => (
                                <div
                                  style={{ marginTop: 40 }}
                                  className="marginTop autocomplete-input-container"
                                >
                                  <input
                                    {...getInputProps({
                                      placeholder: "Enter Via Location",
                                      className: "autocomplete-input",
                                    })}
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      color: "black",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                    }}
                                  />
                                  {viaLocation && (
                                    <div className="autocomplete-dropdown-container">
                                      {suggestions.map((suggestion, index) => (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion
                                          )}
                                          className="autocomplete-suggestion"
                                          style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            color: "black",
                                            backgroundColor: "#fff",
                                            borderBottom: "1px solid #ccc",
                                          }}
                                        >
                                          {suggestion.description}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {formError && formError.viaAddress && (
                              <h1
                                style={{
                                  color: "white",
                                  fontFamily: "sans-serif",
                                  fontWeight: "bold",
                                }}
                              >
                                Via address is missing
                              </h1>
                            )}
                          </Col>
                          <Col xl={4} lg={6} sm={12} md={12}  >
                            {pickupPlace == "location" ? (
                              <div
                                style={{ marginTop: 37 }}
                                className="marginTop About_main_images_search"
                              >
                                <select
                                  name="format"
                                  id="format"
                                  className="w-full"
                                  // style={{ width: "100%", padding: 10 }}
                                  onChange={(e) =>
                                    setDropoffAddress(e.target.value)
                                  }
                                >
                                  <option selected disabled>
                                    Select Dropoff Location
                                  </option>
                                  {allAirports &&
                                    allAirports.length > 0 &&
                                    allAirports.map((e, i) => {
                                      return (
                                        <option value={e.airport_address}>
                                          {e.airport_name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            ) : (
                              <PlacesAutocomplete
                                vogleCallbackName="initMap"
                                value={dropoffAddress}
                                onChange={setDropoffAddress}
                                onSelect={handleDropoffSelect}
                                // searchOptions={{
                                //   types: ["geocode"], // Limit results to addresses only
                                // }}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                }) => (
                                  <div
                                    style={{ marginTop: 40 }}
                                    className="marginTop autocomplete-input-container"
                                  >
                                    <input
                                      {...getInputProps({
                                        placeholder: "Enter Dropoff Location",
                                        className: "autocomplete-input",
                                      })}
                                      style={{
                                        width: "100%",
                                        padding: "10px",
                                        color: "black",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                      }}
                                    />
                                    {pickupCord && (
                                      <div className="autocomplete-dropdown-container">
                                        {suggestions.map(
                                          (suggestion, index) => (
                                            <div
                                              key={index}
                                              {...getSuggestionItemProps(
                                                suggestion
                                              )}
                                              className="autocomplete-suggestion"
                                              style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                                color: "black",
                                                backgroundColor: "#fff",
                                                borderBottom: "1px solid #ccc",
                                              }}
                                            >
                                              {suggestion.description}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            )}
                            {formError && formError.dropoffAddress && (
                              <h1
                                style={{
                                  color: "white",
                                  fontFamily: "sans-serif",
                                  fontWeight: "bold",
                                }}
                              >
                                Dropoff address is missing
                              </h1>
                            )}
                          </Col>
                        </Row>
                      )}
                      <Col xl={pickupPlace ? "3" : "4"} lg="4" sm="12" xs="12"  >
                        <fieldset style={{ width: "100%" }}>
                          <div
                            style={{ width: "100%" }}
                            className="About_main_images_search"
                          >
                            <input
                              placeholder="Pickup Date"
                              type={"date"}
                              style={{
                                Width: "100%",
                                fontFamily: "sans-serif",
                                fontSize: 16,
                                color: "gray",
                                fontWeight: "500",
                              }}
                              onChange={(e) => setSelectedDate(e.target.value)}
                              className="border-white"
                            ></input>
                          </div>
                        </fieldset>
                        {formError && formError.bookingDate && (
                          <h1
                            style={{
                              color: "white",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            Pickup Date is missing
                          </h1>
                        )}
                      </Col>
                      <Col xl={pickupPlace ? "3" : "4"} lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Pickup Time"
                              className="border-white"
                              type="time"
                              style={{
                                Width: "100%",
                                fontFamily: "sans-serif",
                                fontSize: 16,
                                color: "gray",
                                fontWeight: "500",
                              }}
                              onChange={(e) => setSelectedTime(e.target.value)}
                            ></input>

                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                        {formError && formError.bookingTime && (
                          <h1
                            style={{
                              color: "white",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            Pickup Time is missing
                          </h1>
                        )}
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <select
                              name="format"
                              id="format"
                              onChange={(e) => setPassenger(e.target.value)}
                              className="w-full"
                            >
                              <option selected disabled>
                                Passenger Count
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>

                            </select>

                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                        {formError && formError.passengers && (
                          <h1
                            style={{
                              color: "white",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            Passenger count is missing
                          </h1>
                        )}
                      </Col>
                      <Col xl="2" lg="2" sm="12" xs="12">
                        <div className="webkit">
                          <button
                            onClick={() => navigateToMapScreen()}
                            className=" w-full py-3 mt-4 rounded-xl text-white"
                            style={{ background: "#1E1E1E" }}
                          >
                            Search
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    <Row className="align-items-center">
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Pickup Location"
                              className="border-white"
                            ></input>
                            <AiOutlineSearch
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Add Via Location"
                              className="border-white"
                            ></input>
                            <AiOutlineSearch
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Drop off Location"
                              className="border-white"
                            ></input>
                            <AiOutlineSearch
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="3" lg="3" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Pickup Date"
                              className="border-white"
                            ></input>
                            <AiFillCalendar
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="3" lg="3" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <input
                              placeholder="Pickup Time"
                              className="border-white"
                            ></input>
                            <AiFillClockCircle
                              size={25}
                              className="text-black border-rounded"
                            />
                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="4" lg="4" sm="12" xs="12">
                        <fieldset>
                          <div className="About_main_images_search">
                            <select
                              name="format"
                              id="format"
                              className="w-full"
                            >
                              <option selected disabled>
                                Passenger Count
                              </option>
                              <option value="pdf">1</option>
                              <option value="txt">2</option>
                              <option value="txt">3</option>
                              <option value="txt">4</option>
                              <option value="epub">5</option>
                              <option value="fb2">6</option>
                              <option value="mobi">7</option>
                            </select>

                            {/* <Button>Search</Button> */}
                          </div>
                        </fieldset>
                      </Col>
                      <Col xl="2" lg="2" sm="12" xs="12">
                        {/* <fieldset> */}
                        <div className="webkit">
                          <Link to="/SidebarNew">
                            <button
                              className=" w-full py-3 mt-4 rounded-xl text-white"
                              style={{ background: "#1E1E1E" }}
                            >
                              Search
                            </button>
                          </Link>
                        </div>
                        {/* </fieldset> */}
                      </Col>
                      <Col lg="12">
                        <div className="webkit pt-5">
                          <h2 className="text-lg text-white">
                            Or Book By Phone On 0349-3499847
                          </h2>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </Col>
        </Row>
        {modalVisible && (
          <Modals
            visible={modalVisible}
            heading={modalHeading}
            text={modalText}
            close={() => setModalVisible(false)}
          />
        )}
      </Container>
    </Container>
  );
};
