import React from "react";
import { MainAS } from "../AirportService";
// import { useLocation } from "react-router-dom";


export const AirportService = () => {
  
  // const location = useLocation()

  // console.log(location.state,"location")
  
  
  return (
    <>
      <MainAS />
    </>
  );
};
