import React, { useState, useEffect } from "react";
import { Col, Container, Image, Row, FormCheck, Form } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Stack,
  Box,
  SimpleGrid,
  List,
  ListItem,
  ListIcon,
  Select,
  useStatStyles,
} from "@chakra-ui/react";
import { CheckIcon, EmailIcon } from "@chakra-ui/icons";
import { BsEnvelope, BsMailbox } from "react-icons/bs";
import { AiFillLock, AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { PulseLoader, RingLoader } from "react-spinners";

// import "swiper/css/pagination";
// SwiperCore.use([Autoplay, Pagination, Navigation]);
import { MdCheckCircle, MdSettings, MdOutlineSell } from "react-icons/md";
import axios from "axios";
import BaseUri from "../../../Constant/BaseUri";
import { Link } from "react-router-dom";

export const Signup = () => {
  const navigate = useNavigate();

  const [selectedGender, setSelectedGender] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    phone: null,
    email: "",
    dob: "",
    address: "",
    password: "",
    confirmPass: "",
  });
  const [signupError, setSignupError] = useState("");
  const [terms, setTerms] = useState(false);

  function objectToFormData(obj) {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
      }
    }

    return formData;
  }

  const handleSignupPress = async () => {
    let dataToSend = { ...userData };
    dataToSend.gender = selectedGender;

    const phoneRegex = /^\+\d+$/;

    let checkPhoneNumber = phoneRegex.test(dataToSend.phone);

    let error = {};

    if (!dataToSend.name) {
      error.name = true;
    }
    if (!dataToSend.email) {
      error.email = true;
    }
    if (!dataToSend.address) {
      error.address = true;
    }
    if (!dataToSend.phone) {
      error.phone = true;
    }

    if (!checkPhoneNumber) {
      error.invalidPhone = true;
    }

    if (!dataToSend.dob) {
      error.dob = true;
    }
    if (!dataToSend.password) {
      error.password = true;
    }
    if (!dataToSend.confirmPass) {
      error.confirmPass = true;
    }

    if (dataToSend.password.length < 8) {
      error.passCharacter = true;
    }

    if (!terms) {
      error.terms = true;
    }

    if (dataToSend.password !== dataToSend.confirmPass) {
      error.matchPass = true;
    }
    if (!dataToSend.gender) {
      error.gender = true;
    }

    const strongRegex = new RegExp(
      "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$"
    );

    let flagCheck = strongRegex.test(dataToSend.email);

    if (!flagCheck) {
      error.emailInvalid = true;
    }

    if (!terms) {
      error.terms = true;
    }

    if (error && Object.keys(error).length > 0) {
      setSignupError(error);
      return;
    }

    setSignupError("");
    setLoading(true);
    delete dataToSend.confirmPass;
    const formData = await objectToFormData(dataToSend);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(`${BaseUri}user-signup.php`, formData, config)
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          alert("Successfully Signed Up");
          navigate("/Login");
          return;
        } else {
          setLoading(false);
          alert("Email Already Exist");
        }
      })
      .catch((error) => {
        setLoading(false);

      });
  };

  const handleRouteToLogin = () => {
    navigate("/Login");
  };

  return (
    <>
      <Container fluid className="py-5">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="webkit p-2">
                <div>
                  <Image src={require("../../assets/LoginSignup.png")} />
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              className="shadow-lg rounded-2xl shadow-gray-50 py-14 px-3 mt-4"
            >
              <div className="webkit">
                <div className="border-b-black border-b-4 pb-1 inline-block">
                  <h2 className="colorGreen font-semibold text-4xl">Sign up</h2>
                </div>
              </div>
              <div className="d-flex flex-col gap-3 py-6">
                <div>
                  <fieldset className="py-4">
                    <Stack spacing={4}>
                      {/* <Select variant="outline" placeholder="Personal">
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                      </Select> */}
                      <div className="d-flex gap-2" style={{ flexWrap: "wrap" }} >
                        <div
                          className="d-flex direction-column"
                          style={{ flexDirection: "column", width: "100%" }}
                        >
                          <InputGroup>
                            <Input
                              className="py-2"
                              type="text"
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  name: e.target.value,
                                })
                              }
                              placeholder="Name"
                            />
                          </InputGroup>
                          {signupError && signupError.name && (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              Name is missing
                            </h1>
                          )}
                        </div>
                        <div style={{ width: "100%" }}>
                          <InputGroup>
                            <Input
                              className="py-2"
                              type="tel"
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  phone: e.target.value,
                                })
                              }
                              placeholder="+92 xxx xxxxxxx"
                            />
                          </InputGroup>
                          {signupError && signupError.phone ? (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              Phone number is missing
                            </h1>
                          ) : signupError.invalidPhone ? (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              Invalid Phone number or country code is missing
                            </h1>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputGroup>
                          <Input
                            className="py-2"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                email: e.target.value,
                              })
                            }
                            type="email"
                            placeholder="Email"
                          />
                        </InputGroup>
                        {signupError && signupError.email ? (
                          <h1
                            style={{
                              color: "red",
                              marginLeft: 2,
                              fontWeight: "500",
                            }}
                          >
                            Email is missing
                          </h1>
                        ) : (
                          signupError &&
                          signupError.emailInvalid && (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              Invalid Email
                            </h1>
                          )
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputGroup>
                          <Input
                            className="py-2"
                            type="number"
                            placeholder="Enter Birth Year.."
                            onChange={(e) =>
                              setUserData({ ...userData, dob: e.target.value })
                            }
                          />
                        </InputGroup>
                        {signupError && signupError.dob && (
                          <h1
                            style={{
                              color: "red",
                              marginLeft: 2,
                              fontWeight: "500",
                            }}
                          >
                            DOB is missing
                          </h1>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputGroup>
                          <Input
                            className="py-2"
                            type="text"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                address: e.target.value,
                              })
                            }
                            placeholder="Enter Address..."
                          />
                        </InputGroup>

                        {signupError && signupError.address && (
                          <h1
                            style={{
                              color: "red",
                              marginLeft: 2,
                              fontWeight: "500",
                            }}
                          >
                            Address is missing
                          </h1>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputGroup>
                          <Input
                            className="py-2"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                password: e.target.value,
                              })
                            }
                            type="password"
                            placeholder="Password"
                          />
                        </InputGroup>
                        {signupError && signupError.password ? (
                          <h1
                            style={{
                              color: "red",
                              marginLeft: 2,
                              fontWeight: "500",
                            }}
                          >
                            Password is missing
                          </h1>
                        ) : (
                          signupError &&
                          signupError.passCharacter && (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              Password must have atleast 8 characters
                            </h1>
                          )
                        )}
                      </div>
                      <div>
                        <InputGroup>
                          <Input
                            className="py-2"
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                confirmPass: e.target.value,
                              })
                            }
                            type="password"
                            placeholder="Confirm Password"
                          />
                        </InputGroup>
                        {signupError && signupError.confirmPass ? (
                          <h1
                            style={{
                              color: "red",
                              marginLeft: 2,
                              fontWeight: "500",
                            }}
                          >
                            Confirm Password is missing
                          </h1>
                        ) : (
                          signupError &&
                          signupError.matchPass && (
                            <h1
                              style={{
                                color: "red",
                                marginLeft: 2,
                                fontWeight: "500",
                              }}
                            >
                              Confirm Password doesn't match
                            </h1>
                          )
                        )}
                      </div>

                      <div style={{ padding: "0px 10px" }}>
                        <h1 style={{ fontSize: 18, fontWeight: "bold" }}>
                          Gender:
                        </h1>

                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="mb-3 mt-1">
                              <Form.Check
                                inline
                                label="Male"
                                name="gender"
                                value="Male"
                                type={type}
                                id={`inline-${type}-1`}
                                onChange={(e) =>
                                  setSelectedGender(e.target.value)
                                }
                              />
                              <Form.Check
                                inline
                                label="Female"
                                value="Female"
                                name="gender"
                                type={type}
                                onChange={(e) =>
                                  setSelectedGender(e.target.value)
                                }
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                        {signupError && signupError.gender && (
                          <h1
                            style={{
                              color: "red",
                              marginLeft: 2,
                              fontWeight: "500",
                            }}
                          >
                            Kindly select gender
                          </h1>
                        )}
                      </div>
                      <div className="login-form-group single-row">
                        <div className="d-flex gap-1 align-items-center">
                          <FormCheck
                            onChange={(e) => setTerms(e.target.checked)}
                          />
                          <label for="description" className="smallScreenText  text-lg">
                            By signing up, i agree with{" "}
                            <span className="smallScreenText  colorGreen">terms of use</span> and
                            <span className="smallScreenText  colorGreen"> Privacy Policy</span>
                          </label>
                        </div>
                      </div>
                      {signupError?.terms && (
                        <h1
                          style={{
                            color: "red",
                            marginLeft: 2,
                            fontWeight: "500",
                          }}
                        >
                          Kindly accepts term and privacy policy
                        </h1>
                      )}
                      {/* <h2 className="colorGreen font-medium text-base text-right">
                        Forgot Password ?
                      </h2> */}
                    </Stack>
                  </fieldset>
                </div>
                <div className="webkit">
                  <button
                    className="btn btn--form py-2 font-semibold  w-full"
                    type="submit"
                    value="Log in"
                    onClick={handleSignupPress}
                  >
                    {loading ? (
                      <RingLoader color="black" loading={loading} size={25} />
                    ) : (
                      "Sign up"
                    )}
                  </button>
                  <p className="colorGreen font-medium text-base py-3">
                    <span className="text-gray-500">
                      Already have an account ?{" "}
                    </span>
                    <button onClick={() => handleRouteToLogin()}> Login</button>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          {/* <Row className="align-items-center mt-4">
            <Col lg="6">
              <div className="p-2">
                <SimpleGrid>
                  <h2 className="colorGreen font-extrabold text-4xl">
                    Sawari Advantages
                  </h2>
                  <List py={3}>
                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Booking car services provide a convenient mode of
                      transportation.
                    </ListItem>
                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Car services save time by reducing the need to navigate
                      traffic,find parking, or wait for public transportation.
                    </ListItem>
                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Car services operate around the clock, providing
                      transportation services even during late-night or
                      early-morning hours.
                    </ListItem>

                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Car services typically employ professional drivers who are
                      trained and experienced in providing safe and reliable
                      transportation.
                    </ListItem>
                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Car services prioritize passenger safety. Vehicles undergo
                      regular inspections, and drivers often go through
                      background checks.
                    </ListItem>
                  </List>
                </SimpleGrid>
              </div>
            </Col>
          </Row> */}
        </Container>
      </Container>
    </>
  );
};
