import React from "react";
import { Container } from "react-bootstrap";

export const Map = () => {
  return (
    <>
      <Container fluid className="bgAS">
        <Container>
          <div className="webkit ser">
            <h2 className="text-white font-extrabold">AIPORT SERVICES</h2>
          </div>
        </Container>
      </Container>
    </>
  );
};
