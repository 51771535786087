import React, { useContext, useEffect,useRef } from 'react'
import { SectionOne } from "./components/SectionOne";
import { SectionTwo } from "./components/SectionTwo";
import { SectionThree } from "./components/SectionThree";
import { SectionFour } from "./components/SectionFour";
import { SectionFive } from "./components/SectionFive";
import { SectionSix } from "./components/SectionSix";
import { SectionSeven } from './components/SectionSeven';
import { SectionEight } from './components/SectionEight';
import loginContext from '../../Constant/LoginContext/loginContext';

export const Main = () => {

  const bookingRef = useRef(null);

  const scrollToCar = () => {
    bookingRef.current.scrollIntoView({ behavior: "smooth" });
  };



    return (
        <>
          <SectionOne ref={bookingRef} />
          <SectionTwo />
          <SectionThree scrollToCar={scrollToCar} />
          <SectionFour />
          <SectionFive />
          <SectionSix />
          {/* <SectionSeven /> */}
          {/* <SectionEight /> */}
        </>
      );
}
