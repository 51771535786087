import React, { useEffect, useState } from "react";
import { Container, Row, Image, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Pagination } from "swiper";

// import "swiper/css";
// import "swiper/css/navigation";
import axios from "axios";
import BaseUri from "../../../Constant/BaseUri";
// import "swiper/css/pagination";

export const RecommendCars = () => {
  const [cars, setCars] = useState([]);

  const getCarData = () => {
    axios
      .get(`${BaseUri}get_airport_cars.php`, {
        params: { seats: 1 },
      })
      .then((res) => {
        let { data } = res;


        setCars(data);
      })

      .catch((error) => {
        console.log(error, "errir");
      });
  };

  useEffect(() => {
    getCarData();
  }, []);

  return (
    <>
      <Container fluid>
        <Container>
          <div className="pb-2 pt-5 webkit">
            <h2 className="smallScreenHeading text-black text-4xl font-extrabold">
              Recommended Car
            </h2>
          </div>
          {cars && cars.length > 0 ? (
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: 0,
              }}
            >
              <Swiper
                id="mm1"
                // install Swiper modules
                modules={[Navigation, A11y, Pagination]}

                loop={true}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                breakpoints={{
                  700: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  750: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                {cars &&
                  cars.length > 0 &&
                  cars.map((e, i) => {
                    return (

                      <SwiperSlide style={{ width: "100%" }} >
                        <div
                          style={{
                            backgroundColor: "rgba(220,220,220,0.6)",
                            width: "100%",
                          }}
                          className="text-center  border-black rounded-md"
                        >
                          <div className="rounded-md">
                            <img
                              src={`https://smaztech.com/sawari/${e.car_photo}`}
                              alt="Image"
                            />
                          </div>
                          <div className="p-2 webkit justify-center">
                            <h2 className="font-semibold text-xl text-black">
                              {e.car_title}
                            </h2>
                            <Row className="justify-center rrr py-2">
                              <Col>
                                <div>
                                  <h2 className="LargeScreenmediumText mediumText text-lg text-black">
                                    PKR{" "}
                                    <span className="LargeScreenmediumText mediumText colorGreen font-extrabold text-2xl">
                                      {new Intl.NumberFormat("en-PK", {
                                        style: "currency",
                                        currency: "PKR",
                                        maximumFractionDigits: 0,
                                      }).format(Number(e?.car_min_fare))}
                                    </span>
                                  </h2>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <h2 className="LargeScreenmediumText mediumText text-lg text-black">
                                    GBP{" "}
                                    <span className="LargeScreenmediumText mediumText colorGreen font-extrabold text-2xl">
                                      {e?.car_min_fare_gbp.toLocaleString(
                                        "en-GB",
                                        {
                                          style: "currency",
                                          currency: "GBP",
                                        }
                                      )}
                                    </span>
                                  </h2>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <h2 className="LargeScreenmediumText mediumText text-lg text-black">
                                    USD{" "}
                                    <span className="LargeScreenmediumText mediumText colorGreen font-extrabold text-2xl">
                                      {e?.car_min_fare_usd.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}
                                    </span>
                                  </h2>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr className="hr" />
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Row>
          ) : (
            <Row
              style={{
                width: "100%",
                display: "flex",
                margin: 0,
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <h1
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: 32,
                  color: "green",
                }}
              >
                No Car Found
              </h1>
            </Row>
          )}
        </Container>
      </Container>
    </>
  );
};
