import React, { useContext, useEffect } from "react";
import RentACarBooking from "../RentACar/components/RentACarBooking";
import RentBookDes from "../Profile/RentBookDes";
import { useLocation, useNavigate } from "react-router-dom";
import { useEditable, useStatStyles } from "@chakra-ui/react";
import loginContext from "../../Constant/LoginContext/loginContext";

const RentBook = () => {
  const loginCont = useContext(loginContext);
  const { userData, setUserData } = loginCont;
  const navigate = useNavigate();

  const location = useLocation();

  const { state } = location;

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  useEffect(() => {
    handleCheckUser();
  }, []);

  return (
    <>
      {state && <RentACarBooking />}
      {state && <RentBookDes />}
    </>
  );
};

export default RentBook;
