import React, { useContext, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { A11y } from "swiper";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Stack,
  Box,
  SimpleGrid,
  List,
  ListItem,
  ListIcon,
  useStatStyles,
} from "@chakra-ui/react";
import { CheckIcon, EmailIcon } from "@chakra-ui/icons";
import { BsEnvelope, BsMailbox } from "react-icons/bs";
import { AiFillLock, AiOutlineUser } from "react-icons/ai";
// import "swiper/css/pagination";
// SwiperCore.use([Autoplay, Pagination, Navigation]);
import { MdCheckCircle, MdSettings, MdOutlineSell } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUri from "../../../Constant/BaseUri";
import loginContext from "../../../Constant/LoginContext/loginContext";
import { RingLoader, CircleLoader } from "react-spinners";
export const Login = () => {
  const navigate = useNavigate();

  const [loginData, setLoginData] = React.useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const context = useContext(loginContext);
  let { userData, setUserData } = context;

  const routeToSignUp = () => {
    navigate("/Signup");
  };

  const handleLoginUser = () => {


    let error = {};

    if (!loginData.email) {
      error.email = true;
    }
    if (!loginData.password) {
      error.password = true;
    }

    if (error && Object.keys(error).length > 0) {
      setLoginError(error);
      return;
    }

    setLoginError(error);
    setLoading(true);

    axios
      .get(`${BaseUri}user-login.php`, { params: loginData })
      .then(async (res) => {
        let { data } = res;
        setLoading(false);
        if (!data.success) {
          alert(data.result);
          return;
        }

        let { result } = data;

        let localSend = JSON.stringify(result);

        await localStorage.setItem("userData", localSend);

        setUserData(result);

        navigate("/", { state: result });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const navigateToForgotPassword = () => {
    navigate("/ForgotPassword");
  };

  return (
    <>
      <Container fluid className="py-5">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="webkit p-2">
                <div>
                  <Image src={require("../../assets/LoginSignup.png")} />
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              className="shadow-lg rounded-2xl shadow-gray-50 py-14 px-3 mt-4"
            >
              <div className="webkit">
                <div className="border-b-black border-b-4 pb-1 inline-block">
                  <h2 className="colorGreen font-semibold text-4xl">Login</h2>
                </div>
              </div>
              <div className="d-flex flex-col gap-3 py-6">
                <div>
                  <fieldset className="py-4">
                    <Stack spacing={4}>
                      <div style={{ width: "100%" }}>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <AiOutlineUser
                              size={20}
                              className="text-gray-400"
                            />
                          </InputLeftElement>
                          <Input
                            onChange={(e) =>
                              setLoginData({
                                ...loginData,
                                email: e.target.value,
                              })
                            }
                            className="py-2"
                            type="email"
                            placeholder="Email"
                          />
                        </InputGroup>
                        {loginError && loginError.email && (
                          <h1
                            style={{
                              color: "red",
                              marginLeft: 2,
                              fontWeight: "500",
                            }}
                          >
                            Invalid Email Address
                          </h1>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <AiFillLock size={20} className="text-gray-400" />
                          </InputLeftElement>
                          <Input
                            className="py-2"
                            onChange={(e) =>
                              setLoginData({
                                ...loginData,
                                password: e.target.value,
                              })
                            }
                            type="password"
                            placeholder="Password"
                          />
                        </InputGroup>
                        {loginError && loginError.password && (
                          <h1
                            style={{
                              color: "red",
                              marginLeft: 2,
                              fontWeight: "500",
                            }}
                          >
                            Password is missing
                          </h1>
                        )}
                      </div>

                      <button
                        onClick={() => navigateToForgotPassword()}
                        className="colorGreen font-medium text-base text-right"
                      >
                        Forgot Password ?
                      </button>
                    </Stack>
                  </fieldset>
                </div>
                <div className="webkit">
                  <button
                    onClick={handleLoginUser}
                    className="btn btn--form py-2 font-semibold  w-full"
                    type="submit"
                    value="Log in"
                  >
                    {loading ? (
                      <RingLoader size={30} color="black" loading={loading} />
                    ) : (
                      "Log in"
                    )}
                  </button>
                  <p className="colorGreen font-medium text-base py-3">
                    <span className="text-gray-500">New User ? </span>{" "}
                    <button onClick={() => !loading && routeToSignUp()}>
                      {" "}
                      Sign up{" "}
                    </button>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <Container>
          <Row className="align-items-center mt-4">
            <Col lg="6">
              <div className="p-2">
                <SimpleGrid>
                  <h2 className="colorGreen font-extrabold text-4xl">
                    Sawari Advantages
                  </h2>
                  <List py={3}>
                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Booking car services provide a convenient mode of
                      transportation.
                    </ListItem>
                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Car services save time by reducing the need to navigate
                      traffic,find parking, or wait for public transportation.
                    </ListItem>
                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Car services operate around the clock, providing
                      transportation services even during late-night or
                      early-morning hours.
                    </ListItem>

                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Car services typically employ professional drivers who are
                      trained and experienced in providing safe and reliable
                      transportation.
                    </ListItem>
                    <ListItem className="text-gray-500 text-xl">
                      <ListIcon as={MdCheckCircle} className="text-black" />
                      Car services prioritize passenger safety. Vehicles undergo
                      regular inspections, and drivers often go through
                      background checks.
                    </ListItem>
                  </List>
                </SimpleGrid>
              </div>
            </Col>
            <Col lg="6"></Col>
          </Row>
        </Container> */}
      </Container>
    </>
  );
};
