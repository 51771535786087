import { ArrowLeftIcon, ArrowRightIcon, StarIcon } from "@chakra-ui/icons";
import React, { useContext, useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Image,
  Button,
  FormCheck,
  InputGroup,
} from "react-bootstrap";
import { AiOutlineArrowRight, AiOutlineSearch } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import loginContext from "../../Constant/LoginContext/loginContext";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import BaseUri from "../../Constant/BaseUri";
import Modals from "../modal/modal";
import { NumericFormat } from "react-number-format";

const TravelBooking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { state } = location;

  const images = [
    `https://smaztech.com/sawari/${state?.tour_photo}`,
    `https://smaztech.com/sawari/${state?.tour_photo}`,
    `https://smaztech.com/sawari/${state?.tour_photo}`,
    `https://smaztech.com/sawari/${state?.tour_photo}`,
    `https://smaztech.com/sawari/${state?.tour_photo}`,
  ];

  const context = useContext(loginContext);
  let { userData, setUserData } = context;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [formError, setFormError] = useState("");
  const [bookingPersons, setBookingPersons] = useState(0);
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  useEffect(() => {
    handleCheckUser();
  }, []);

  const handleArrowClick = (direction) => {
    let newImageIndex = currentImageIndex + direction;
    if (newImageIndex < 0) {
      newImageIndex = images.length - 1;
    } else if (newImageIndex >= images.length) {
      newImageIndex = 0;
    }

    setCurrentImageIndex(newImageIndex);
  };

  const bookRentCar = () => {
    let bookingPrice = Number(bookingPersons) * Number(state?.tour_price);
    let bookingPriceUsd =
      Number(bookingPersons) * Number(state?.tour_price_usd);
    let bookingPriceGbp =
      Number(bookingPersons) * Number(state?.tour_price_gbp);

    let dataToSend = {
      user_id: state?.userData?.id,
      tour_id: state?.id,
      tour_title: state?.tour_title,
      tour_days: state?.tour_days,
      booking_persons: bookingPersons,
      booking_price: bookingPrice,
      booking_price_usd: bookingPriceUsd,
      booking_price_gbp: bookingPriceGbp,
      booking_date: selectedDate,
    };

    const error = {};

    if (!dataToSend.booking_date) {
      error.bookingDate = true;
    }
    if (!dataToSend.booking_persons) {
      error.bookingPersons = true;
    }

    if (error && Object.keys(error).length > 0) {
      setFormError(error);
      return;
    }

    setFormError(error);

    let nowGetTime = new Date().getTime();
    let pickGetTime = new Date(selectedDate).getTime();

    if (nowGetTime >= pickGetTime) {
      setModalHeading("Alert");
      setModalText("Date must be greater then current date");
      setModalVisible(true);

      return;
    }

    navigate("/TourConfirmBooking", { state: dataToSend });
  };

  return (
    state && (
      <>
        <Container>
          <Row>
            <Col lg="8">
              <Row className="items-center">
                <Col lg="12">
                  <div
                    style={{ flexDirection: "column" }}
                    className="m-3 p-3 py-0 my-2  rounded-2xl flex items-center justify-center"
                  >
                    <h1
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        textAlign: "center",
                        marginBottom: 20,
                        textTransform: "uppercase",
                      }}
                    >
                      {state?.tour_title}
                    </h1>
                    <Image
                      className="w-12/12"
                      style={{ borderRadius: 10, height: 530 }}
                      src={images[currentImageIndex]}
                    />
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg="4">
              <div className="webkit py-2">
                <div className="bg-[#00803e] webkit text-white rounded-3xl py-5 p-4 flex flex-col gap-3">
                  <h2
                    style={{ textTransform: "uppercase", color: "white" }}
                    className="text-2xl  font-bold"
                  >
                    Make Booking
                  </h2>
                  <span
                    style={{
                      fontSize: 16,
                      fontFamily: "sans-serif",
                      color: "white",
                    }}
                  >
                    Explore the world with Sawari Pakistan - Your gateway to
                    unforgettable adventures, both near and far.
                  </span>
                  <form>
                    <fieldset>
                      <div className="About_main_images_search">
                        <input
                          placeholder="Package Name"
                          type={"text"}
                          style={{ fontFamily: "sans-serif" }}
                          editable={false}
                          value={state?.tour_location}
                          className="border-white"
                        ></input>
                        {/* <AiOutlineSearch
                          size={25}
                          className="text-black border-rounded"
                        /> */}
                        {/* <Button>Search</Button> */}
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className="About_main_images_search">
                        <select
                          placeholder="How many people are travelling?"
                          style={{ fontFamily: "sans-serif", width: "100%" }}
                          type={"number"}
                          onChange={(e) => setBookingPersons(e.target.value)}
                          className="border-white"
                        >
                          <option selected disabled>
                            How many people are travelling?
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                      {formError && formError.bookingPersons && (
                        <h1
                          style={{
                            color: "red",
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          Booking persons are missing
                        </h1>
                      )}
                    </fieldset>

                    <div className="d-flex gap-2">
                      <fieldset style={{ width: "100%" }}>
                        <div className="About_main_images_search">
                          <input
                            style={{ width: "100%", fontFamily: "sans-serif" }}
                            placeholder="Booking Date"
                            type={"date"}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            className="border-white"
                          ></input>
                          {/* <AiOutlineSearch
                          size={25}
                          className="text-black border-rounded"
                        /> */}
                          {/* <Button>Search</Button> */}
                        </div>
                        {formError && formError.bookingDate && (
                          <h1
                            style={{
                              color: "red",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                              textAlign: "left",
                            }}
                          >
                            Booking Date is missing
                          </h1>
                        )}
                      </fieldset>
                    </div>

                    <Row className="justify-center rrr py-2 mt-4">
                      <Col>
                        <div>
                          <h2 className="text-lg text-white">
                            PKR{" "}
                            <span className="text-white font-extrabold text-2xl">
                              {new Intl.NumberFormat("en-PK", {
                                style: "currency",
                                currency: "PKR",
                                maximumFractionDigits: 0,
                              }).format(Number(state?.tour_price))}
                            </span>
                          </h2>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <h2 className="text-lg text-white">
                            GBP{" "}
                            <span className="text-white font-extrabold text-2xl">
                              {state?.tour_price_gbp.toLocaleString("en-GB", {
                                style: "currency",
                                currency: "GBP",
                              })}
                            </span>
                          </h2>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <h2 className="text-lg text-white">
                            USD{" "}
                            <span className="text-white font-extrabold text-2xl">
                              {state?.tour_price_usd.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </span>
                          </h2>
                        </div>
                      </Col>
                    </Row>
                  </form>
                  <button
                    style={{
                      backgroundColor: "#ffcc00",
                      borderRadius: 10,
                      color: "green",
                      fontWeight: "bold",
                    }}
                    onClick={() => bookRentCar()}
                    className=" py-2 px-4"
                  >
                    Booking Now
                  </button>
                </div>
              </div>
            </Col>
            {/* <Col lg="12">
            <div className="webkit py-4">
              <h2 className="flex gap-2 p-2 justify-center w-24 items-center rounded-lg border-2 border-[#00803E] text-xl font-bold">
                <StarIcon className="startnew" />
                <span>4.7</span>
              </h2>
            </div>
          </Col> */}
          </Row>
          {modalVisible && (
            <Modals
              visible={modalVisible}
              heading={modalHeading}
              text={modalText}
              close={() => setModalVisible(false)}
            />
          )}
        </Container>
      </>
    )
  );
};

export default TravelBooking;
