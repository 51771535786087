import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";
import loginContext from "../../Constant/LoginContext/loginContext";
import BaseUri from "../../Constant/BaseUri";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modals from "../modal/modal";
import { RingLoader } from "react-spinners";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import PaymentModals from "../modal/paymentModal";
import { Col, Container, Row } from "react-bootstrap";
import GoogleMapReact from "google-map-react";

function AirportConfirmBooking() {
  const stripe = useStripe();
  const elements = useElements();
  const [userData, setUserData] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const [error, setError] = useState(null);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [googleMapKey, setGoogleMapKey] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [additionalInfo, setAddtionalInfo] = useState("");
  const [mainPassengerName, setMainPassengerName] = useState("");
  const [mainPassengerTel, setMainPassengerTel] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  let { state } = location;
  const getUserDatA = async () => {
    let userData = await localStorage.getItem("userData");
    userData = JSON.parse(userData);

    setUserData(userData);
  };

  useEffect(() => {
    getUserDatA();
  }, []);

  const getGoogleMapKey = () => {
    axios.get(`${BaseUri}get_all_api_keys.php`).then((res) => {
      let { data } = res;

      if (data && data.length > 0) {
        let key = data[0]?.api_key;
        setGoogleMapKey(key);
      }
    });
  };

  useEffect(() => {
    getGoogleMapKey();
  }, []);

  const directionsService = new window.google.maps.DirectionsService();
  const directionsRendererRef = useRef(null); // Ref to hold directionsRenderer instance

  function objectToFormData(obj) {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
      }
    }

    return formData;
  }

  const handleBookCar = async () => {
    if (!mainPassengerName) {
      setVisible(true);
      setModalHeading("Error Alert");
      setModalText("Main Passenger Name is missing");
      return;
    }

    if (location?.state?.pickupPlace == "airport") {
      if (!flightNumber) {
        setVisible(true);
        setModalHeading("Error Alert");
        setModalText("Flight Number is missing");
        return;
      }
      if (!arrivalTime) {
        setVisible(true);
        setModalHeading("Error Alert");
        setModalText("Arrival Time is missing");
        return;
      }
    }

    if (!mainPassengerTel) {
      setVisible(true);
      setModalHeading("Error Alert");
      setModalText("Passenger Phone Number is missing");
      return;
    }

    const phoneRegex = /^\+\d+$/;

    let checkPhoneNumber = phoneRegex.test(mainPassengerTel);

    if (!checkPhoneNumber) {
      setVisible(true);
      setModalHeading("Error Alert");
      setModalText("Invalid Phone number or missing country code");
      return;
    }

    if (!paymentMethod) {
      setVisible(true);
      setModalHeading("Error Alert");
      setModalText("Kindly Choose payment method");
      return;
    }

    if (paymentMethod == "card") {
      if (!stripe || !elements) {
        return;
      }

      setIsProcessing(true);

      const cardElement = elements.getElement(CardNumberElement);
      const cvcElement = elements.getElement(CardCvcElement);
      const expiryElement = elements.getElement(CardExpiryElement);
      const addressElement = elements.getElement(AddressElement);

      let addressValues = await addressElement.getValue();
      if (!addressValues.complete) {
        setVisible(true);
        setModalText("Your Address fields are missing");
        setModalHeading("Missing Fields");
        return;
      }

      try {
        const { token, error } = await stripe.createToken(cardElement);

        if (error) {
          setVisible(true);
          setModalText(error.message);
          setModalHeading("Missing Fields");
          return;
        } else {
          const timeObj = new Date(`2000-01-01T${state?.booking_time}`);
          let format = timeObj.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });



          let dataToSend = {
            user_id: state.user_id,
            car_id: state.id,
            booking_date: state.booking_date,
            booking_time: format,
            booking_fare: Number(state.booking_fare).toFixed(2),
            booking_pickup: state.booking_pickup,
            booking_dropoff: state.booking_dropoff,
            booking_via_location: state.booking_via_location
              ? state.booking_via_location
              : "",
            booking_distance: state.booking_distance,
            payment_type: "card",
            payment_id: token.id,
            flight_no: flightNumber,
            flight_arrival_time: arrivalTime,
            passenger_name: mainPassengerName,
            passenger_contact: mainPassengerTel,
            additional_note: additionalInfo,
          };

          console.log(dataToSend, "SENMDDD")


          const formData = await objectToFormData(dataToSend);
          setLoading(true);
          axios
            .post(`${BaseUri}book_airport_car.php`, formData)
            .then((res) => {
              if (res?.data?.success) {
                setLoading(false);
                setModalVisible(true);
                setModalHeading("Payment Successful");
                setModalText("Your car has been successfully booked");
              }
            })
            .catch((error) => {
              setLoading(false);
            });
        }
      } catch (error) {
        console.error(error);
        setError("An error occurred. Please try again.");
      }

      return;
    }

    const timeObj = new Date(`2000-01-01T${state?.booking_time}`);
    let format = timeObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    let dataToSend = {
      user_id: state.user_id,
      car_id: state.id,
      booking_date: state.booking_date,
      booking_time: format,
      booking_fare: Number(state.booking_fare).toFixed(2),
      booking_pickup: state.booking_pickup,
      booking_dropoff: state.booking_dropoff,
      booking_via_location: state.booking_via_location
        ? state.booking_via_location
        : "",
      booking_distance: state.booking_distance,
      payment_type: "cash",
      payment_id: "",
      flight_no: flightNumber,
      flight_arrival_time: arrivalTime,
      passenger_name: mainPassengerName,
      passenger_contact: mainPassengerTel,
      additional_note: additionalInfo,
    };


    const formData = await objectToFormData(dataToSend);
    setLoading(true);
    axios
      .post(`${BaseUri}book_airport_car.php`, formData)
      .then((res) => {
        if (res?.data?.success) {
          setLoading(false);
          setModalVisible(true);
          setModalHeading("Payment Successful");
          setModalText("Your car has been successfully booked");
        }
      })
      .catch((data) => {
        setLoading(false);
      });
  };

  const screenContainerStyle = {
    display: "flex",
    paddingLeft: 80,
    paddingRight: 80,
  };
  const defaultProps = {
    center: {
      lat: location?.state?.dropoffCords?.lat,
      lng: location?.state?.dropoffCords.lng,
    }, // New York City coordinates
    zoom: 10,
  };

  const journeyDetailsStyle = {
    flex: 1,
    padding: "20px",
  };

  const cardStyle = {
    backgroundColor: "white",
    borderRadius: "10px",
    marginBottom: "20px",
    paddingBottom: 20,
  };

  const accountCardStyle = {
    ...cardStyle,
    width: "100%",
  };

  const customerCardStyle = {
    ...cardStyle,
    width: "100%",
  };

  const paymentCardStyle = {
    ...cardStyle,
    width: "100%",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover", // This makes the image cover the container while preserving aspect ratio
  };

  const calculateAndDisplayRoute = () => {
    // const start = markers[0]; // First marker as the starting point
    // const via =  location?.state?.viaCords?.lat && markers[1]; // Second marker as the ending point
    // const end = markers[2]; // Second marker as the ending point

    if (state?.viaCords && Object.keys(state?.viaCords).length > 0) {
      directionsService.route(
        {
          origin: {
            lat: state?.pickupCords.lat,
            lng: state?.pickupCords.lng,
          },
          waypoints: [
            {
              location: { lat: state?.viaCords.lat, lng: state?.viaCords.lng },
            },
          ],
          destination: {
            lat: state?.dropoffCords.lat,
            lng: state?.dropoffCords.lng,
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            directionsRendererRef?.current?.setDirections(response);
            const route = response.routes[0];
          } else {
            console.error("Directions request failed due to " + status);
          }
        }
      );

      return;
    }

    directionsService.route(
      {
        origin: {
          lat: state?.pickupCords.lat,
          lng: state?.pickupCords.lng,
        },
        destination: {
          lat: state?.dropoffCords.lat,
          lng: state?.dropoffCords.lng,
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK") {
          directionsRendererRef?.current?.setDirections(response);
          const route = response.routes[0];
        } else {
          console.error("Directions request failed due to " + status);
        }
      }
    );
  };

  const handleApiLoaded = () => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: state?.pickupCords,
      zoom: 10,
    });

    directionsRendererRef.current = new window.google.maps.DirectionsRenderer({
      map: map,
    });

    // Call the route calculation function
    calculateAndDisplayRoute();
  };

  return (
    state && (
      <Container fluid>
        <Row
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Col xl={5} lg={5} sm={12} xs={12}>
            {/* Long card showing journey details */}
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  padding: 10,
                  backgroundColor: "green",
                  borderTopRightRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderTopLeftRadius: "10px",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    fontSize: 24,
                    paddingLeft: 10,
                  }}
                  className="fourteen"
                >
                  Journey Map
                </h1>
                <h1
                  style={{
                    color: "white",

                    fontFamily: "sans-serif",
                    fontSize: 18,
                    paddingLeft: 10,
                  }}
                  className="smallScreenText"
                >
                  Total Distance: {Number(state?.booking_distance).toFixed(2)}{" "}
                  km
                </h1>
              </div>

              <div
                style={{ height: 240, width: "100%", borderRadius: 10 }}
                id="map"
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: googleMapKey,
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={10}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={(map) => {
                    handleApiLoaded(map);
                  }}
                  // onGoogleApiLoaded={({ map, maps }) => {
                  //   directionsRenderer.setMap(map);
                  //   calculateAndDisplayRoute();
                  // }}
                  style={{ height: 200 }}
                ></GoogleMapReact>
              </div>
            </div>

            <div style={cardStyle}>
              <div
                style={{
                  padding: 10,
                  backgroundColor: "green",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    fontSize: 24,
                    paddingLeft: 10,
                  }}
                  className="averageText"
                >
                  Journey Details
                </h1>
              </div>
              <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                {/* <p
                  style={{
                    padding: 20,
                    textAlign: "center",
                    fontSize: 16,
                    fontFamily: "sans-serif",
                    fontWeight: "400",
                    borderBottom: "1px solid gray",
                  }}
                >
                  Booking assinged to you at this place
                </p> */}
              </div>

              <div
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  display: "flex",
                  width: "96%",
                  justifyContent: "space-around",
                  borderBottom: "1px solid gray",
                  marginBottom: 10,
                  marginLeft: 15,
                  marginRight: 15,
                }}
              >
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    borderRight: "2px solid gray",
                    paddingRight: 10,
                    display: "flex",
                    justifyContent: "center",
                    width: "33%",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontFamily: "sans-serif",
                      fontWeight: "400",
                    }}
                    className="fourteen"
                  >
                    <span
                      style={{
                        fontSize: 24,
                        fontWeight: "bold",
                        color: "green",
                        fontFamily: "sans-serif",
                      }}
                      className="fourteen"
                    >
                      {new Intl.NumberFormat("en-PK", {
                        style: "currency",
                        currency: "PKR",
                        maximumFractionDigits: 0,
                      }).format(Number(state?.booking_fare))}
                    </span>{" "}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    borderRight: "2px solid gray",
                    paddingRight: 20,
                    display: "flex",
                    justifyContent: "center",
                    width: "33%",
                  }}
                  className="paddingZaro"
                >
                  <p
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                      fontWeight: "400",
                      textAlign: "center",

                    }}
                    className="fullwidth fourteen"

                  >
                    <span
                      style={{
                        fontSize: 24,
                        fontWeight: "bold",
                        color: "green",
                      }}
                      className="fourteen"

                    >
                      {state?.booking_fare_gbp.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })}
                    </span>{" "}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "center",
                    width: "33%",
                  }}

                >
                  <p
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                      fontWeight: "400",
                    }}
                    className="fourteen"

                  >
                    <span
                      style={{
                        fontSize: 24,
                        fontWeight: "bold",
                        color: "green",
                      }}
                      className="fourteen"

                    >
                      {state?.booking_fare_usd.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </span>{" "}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottom: "1px solid gray",
                  marginLeft: 15,
                  marginRight: 15,
                  padding: 10,
                  marginBottosm: 10,
                }}
              >
                <div
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="fullwidth"
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "gray",
                      width: "30%",
                      fontFamily: "sans-serif",
                    }}
                    className="fourteen"
                  >
                    Pickup
                  </p>
                  <p
                    style={{
                      color: "black",
                      fontSize: 16,
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      width: "70%",
                    }}
                    className="smallScreenText"
                  >
                    {state.booking_pickup}
                  </p>
                </div>
                {location.state.pickupPlace == "airport" && (
                  <div
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                    className="fullwidth"
                  >
                    <p
                      style={{
                        fontSize: 16,
                        color: "gray",
                        width: "30%",
                        fontFamily: "sans-serif",
                      }}
                      className="fourteen"
                    >
                      Enter Details
                    </p>
                    <div
                      style={{
                        display: "flex",
                        width: "70%",
                        justifyContent: "space-between",
                      }}
                      className="flexWrap"
                    >
                      <input
                        placeholder="Flight Number"
                        style={{
                          width: "47%",
                          border: "1px solid black",
                          padding: 7,
                          borderRadius: 5,
                        }}
                        className="fullwidth"
                        onChange={(e) => setFlightNumber(e.target.value)}
                      />
                      <input
                        style={{
                          width: "47%",
                          border: "1px solid black",
                          padding: 7,
                          borderRadius: 5,
                        }}
                        className="fullwidth marginTopTen"
                        onChange={(e) => setArrivalTime(e.target.value)}
                        placeholder="Arrival Time"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid gray",
                  marginLeft: 15,
                  marginRight: 15,
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="fullwidth"
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "gray",
                      width: "30%",
                      fontFamily: "sans-serif",
                    }}
                    className="fourteen"
                  >
                    Via
                  </p>
                  <p
                    style={{
                      fontSize: 16,
                      color: "black",
                      fontFamily: "sans-serif",
                      fontWeight: "400",
                      width: "70%",
                    }}
                    className="smallScreenText"
                  >
                    {state.booking_via_location}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid gray",
                  marginLeft: 15,
                  marginRight: 15,
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="fullwidth"
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "gray",
                      width: "30%",
                      fontFamily: "sans-serif",
                    }}
                    className="fourteen"
                  >
                    Destination
                  </p>
                  <p
                    style={{
                      fontSize: 16,
                      color: "black",
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      width: "70%",
                    }}
                    className="smallScreenText"
                  >
                    {state.booking_dropoff}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid gray",
                  marginLeft: 15,
                  marginRight: 15,
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="fullwidth"
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "gray",
                      width: "30%",
                      fontFamily: "sans-serif",
                    }}
                    className="fourteen"
                  >
                    Outgoing Date
                  </p>
                  <p
                    style={{
                      fontSize: 16,
                      color: "black",
                      fontFamily: "sans-serif",
                      fontWeight: "400",
                      width: "70%",
                    }}
                    className="smallScreenText"
                  >
                    {state.formattedDate.toString().slice(0, 15)}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid gray",
                  marginLeft: 15,
                  padding: 10,
                  marginRight: 15,
                }}
              >
                <div
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="fullwidth"
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "gray",
                      width: "30%",
                      fontFamily: "sans-serif",
                    }}
                    className="fourteen"
                  >
                    Outgoing Time
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      color: "black",
                      fontSize: 16,
                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      width: "70%",
                    }}
                    className="smallScreenText"
                  >
                    {state.formattedTime}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "1px solid gray",
                  marginLeft: 15,
                  marginRight: 15,
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",

                    alignItems: "center",
                    fontFamily: "sans-serif",
                  }}
                  className="fullwidth"
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "gray",
                      width: "30%",
                      fontFamily: "sans-serif",
                    }}
                    className="fourteen"
                  >
                    Passengers
                  </p>
                  <p
                    style={{
                      fontSize: 16,
                      color: "black",

                      fontWeight: "400",
                      fontFamily: "sans-serif",
                      width: "70%",
                    }}

                    className="smallScreenText"


                  >
                    {state.passengers} Passengers
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 15,
                  padding: 10,
                  borderBottom: "1px solid gray",
                  marginRight: 15,
                }}
              >
                <div
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="fullwidth"
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "gray",
                      width: "30%npm",
                      fontFamily: "sans-serif",
                    }}
                    className="fourteen"
                  >
                    Vehicle
                  </p>
                  <div style={{ width: "70%" }}>
                    <p
                      style={{
                        fontSize: 16,
                        color: "black",

                        fontWeight: "400",
                        fontFamily: "sans-serif",
                        width: "100%",
                      }}
                      className="smallScreenText"
                    >
                      {state.car_title}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 15,
                  padding: 10,
                  marginTop: 10,
                  marginRight: 15,
                }}
              >
                <div
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="fullwidth"
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "gray",
                      width: "30%",
                      fontFamily: "sans-serif",
                    }}
                    className="fourteen"
                  >
                    Additional Info
                  </p>
                  <div style={{ width: "70%" }}>
                    <textarea
                      col="5"
                      placeholder="Optional"
                      style={{
                        width: "100%",
                        border: "1px solid black",
                        padding: 10,
                        borderRadius: 5,
                      }}
                      onChange={(e) => setAddtionalInfo(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={5} lg={5} sm={12} xs={12}>
            <Row>
              <Col sm={12} xs={12} lg={12} xl={12}>
                <div style={accountCardStyle}>
                  {/* Account details content */}

                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 24,
                        fontFamily: "sans-serif",
                        paddingLeft: 10,
                      }}
                      className="fourteen"
                    >
                      Account Details
                    </h1>
                  </div>

                  <div
                    style={{
                      padding: 10,
                      justifyContent: "space-between",
                      display: "flex",
                      height: 120,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <MDBContainer
                        style={{ height: 130, width: 165 }}
                        className="my-5 d-flex justify-content-center "
                      >
                        <img
                          src={
                            userData.photo
                              ? `https://smaztech.com/sawari/${userData.photo}`
                              : require("../assets/avatar.avif")
                          }
                          className="rounded-circle"
                          alt="Avatar"
                          style={imageStyle}
                        />
                      </MDBContainer>

                      <div style={{ marginLeft: 5, width: "100%" }}>
                        <p
                          style={{
                            color: "green",
                            fontSize: 18,
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                          }}
                          className="smallScreenText"
                        >
                          {userData.name}
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontSize: 14,
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                            wordWrap: "normal"
                          }}
                          className="fourteen"
                        >
                          This booking will be saved to your account
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} xs={12} lg={12} xl={12}>
                <div style={customerCardStyle}>
                  {/* Customer details content */}
                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        fontSize: 24,
                        paddingLeft: 10,
                      }}
                      className="averageText"
                    >
                      Account Holder Details
                    </h1>
                  </div>

                  <div
                    style={{
                      padding: 20,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          fontFamily: "sans-serif",
                          color: "gray",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Booking Type:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                          }}
                        >
                          Personal
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Customer Name:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                          }}
                        >
                          {userData.name}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Telephone:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontFamily: "sans-serif",
                            fontSize: 14,
                          }}
                        >
                          {userData.phone}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          fontFamily: "sans-serif",
                          color: "gray",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Email:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          fontFamily: "sans-serif",
                          borderRadius: 5,
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: 14,
                          }}
                        >
                          {userData.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} xs={12} lg={12} xl={12}>
                <div style={customerCardStyle}>
                  {/* Customer details content */}
                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        fontSize: 24,
                        paddingLeft: 10,
                      }}
                      className="averageText"
                    >
                      Passenger Details
                    </h1>
                  </div>

                  <div
                    style={{
                      padding: 20,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: 10,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Main Passenger Name:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <input
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                            width: "100%",
                          }}
                          placeholder="Enter Name..."
                          onChange={(e) => setMainPassengerName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontWeight: "400",
                          fontFamily: "sans-serif",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Telephone:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                        }}
                      >
                        <input
                          style={{
                            color: "black",
                            fontFamily: "sans-serif",
                            fontWeight: "500",
                            fontSize: 14,
                            width: "100%",
                          }}
                          onChange={(e) => setMainPassengerTel(e.target.value)}
                          type="tel"
                          maxLength={13}
                          placeholder="+92 xxx xxxxxxx"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} xs={12} lg={12} xl={12}>
                <div style={paymentCardStyle}>
                  {/* Payment details content */}

                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "green",
                      borderTopRightRadius: "10px",

                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        fontSize: 24,
                        paddingLeft: 10,
                      }}
                      className="averageText"
                    >
                      Payment Details
                    </h1>
                  </div>

                  <div style={{ padding: 20, width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          color: "gray",
                          fontFamily: "sans-serif",
                          fontWeight: "400",
                          width: "30%",
                        }}
                        className="smallScreenText"
                      >
                        Available payment methods:
                      </p>

                      <div
                        style={{
                          border: "1px solid gray",
                          padding: 7,
                          paddingLeft: 10,
                          width: "60%",
                          marginLeft: 30,
                          borderRadius: 5,
                          marginBottom: 10,
                        }}
                      >
                        <select
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          name="format"
                          id="format"
                          className="w-full"
                        >
                          <option selected disabled>
                            Payment Method
                          </option>
                          <option value="card">Credit/Debit Card</option>
                          {/* <option value="Cash">Cash</option> */}
                        </select>
                      </div>
                    </div>

                    {paymentMethod == "card" && (
                      <div style={{ width: "100%" }}>
                        <label
                          style={{
                            width: "100%",
                            fontFamily: "sans-serif",
                            fontWeight: "400",
                          }}
                        >
                          Card Number:
                          <div
                            style={{
                              border: "1px solid gray",
                              padding: 10,
                              borderRadius: 5,
                            }}
                          >
                            <CardNumberElement />
                          </div>
                        </label>
                        <label
                          style={{
                            fontFamily: "sans-serif",
                            width: "100%",
                            marginTop: 10,
                            fontWeight: "400",
                          }}
                        >
                          Expiry:
                          <div
                            style={{
                              border: "1px solid gray",

                              padding: 10,
                              borderRadius: 5,
                            }}
                          >
                            <CardExpiryElement />
                          </div>
                        </label>
                        <label
                          style={{
                            fontFamily: "sans-serif",
                            width: "100%",
                            marginTop: 10,
                            fontWeight: "400",
                          }}
                        >
                          CVC:
                          <div
                            style={{
                              border: "1px solid gray",
                              padding: 10,

                              borderRadius: 5,
                            }}
                          >
                            <CardCvcElement />
                          </div>
                        </label>
                        <label
                          style={{
                            fontFamily: "sans-serif",
                            width: "100%",
                            marginTop: 10,
                          }}
                        >
                          Address
                          <div
                            style={{
                              padding: 8,
                              borderRadius: 5,
                            }}
                          >
                            <AddressElement options={{ mode: "billing" }} />
                          </div>
                        </label>
                      </div>
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          fontSize: 14,
                          padding: 8,
                          width: 200,
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "center",
                          fontFamily: "sans-serif",
                          borderRadius: 5,
                        }}
                        onClick={() => !loading && handleBookCar()}
                      >
                        {loading ? (
                          <RingLoader
                            size={30}
                            color="white"
                            loading={loading}
                          />
                        ) : (
                          "Pay now"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {modalVisible && (
          <PaymentModals
            visible={modalVisible}
            heading={modalHeading}
            text={modalText}
            close={() => navigate("/")}
          />
        )}

        {visible && (
          <Modals
            visible={visible}
            heading={modalHeading}
            text={modalText}
            close={() => setVisible(false)}
          />
        )}
      </Container>
    )
  );
}

export default AirportConfirmBooking;
