import React from 'react'
import { Topnav } from './Components/Topnav'

const index = () => {
  return (
<>
<Topnav/>
</>
  )
}

export default index
