import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Modals(props) {


    let {visible,close,heading,text} = props

  const [show, setShow] = useState(true);

  const handleClose = () => close(false);
  
  return (
    <>
      

      <Modal show={visible}>
        <Modal.Header closeButton>
          <Modal.Title >{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          
          <Button style={{backgroundColor:"green",width:100}} onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Modals;