import React, { Component } from "react";
// import { DropzoneArea } from "mui-file-dropzone";
import { Col, Container, Row, Button, Image, FormCheck } from "react-bootstrap";
import { BiUser } from "react-icons/bi";
import { BsBagCheck, BsFillFuelPumpFill } from "react-icons/bs";
import { SlBag } from "react-icons/sl";
import { GrSettingsOption } from "react-icons/gr";
import { TbTopologyStar3 } from "react-icons/tb";
import { AiFillCheckCircle } from "react-icons/ai";

const UserReview = () => {
  const Data = [
    {
      img: require("../../assets/aqua.png"),
      heading: "Small 5dr Hatchback",
      icon1: <BiUser className="text-gray text-xl" />,
      icon2: <SlBag className="text-gray text-xl" />,
      icon3: <BsBagCheck className="text-gray text-xl" />,
      icon4: <TbTopologyStar3 className="text-gray text-xl" />,
      icon5: <BsFillFuelPumpFill className="text-gray text-xl" />,
      check: <AiFillCheckCircle className="text-[#00803e] text-xl" />,
      freeCancel: "Free Cancellation",
      priceGuarante: "Price Guarantee",
      rating: "4.7",
      Excelent: "Excellent",
      rewiews: "(180 reviews)",
      pkr: "5000",
      gbp: "20.00",
      usd: "25.00",
    },
    {
      img: require("../../assets/brv.png"),
      heading: "Medium 5dr Hatchback ",
      icon1: <BiUser className="text-gray text-xl" />,
      icon2: <SlBag className="text-gray text-xl" />,
      icon3: <BsBagCheck className="text-gray text-xl" />,
      icon4: <TbTopologyStar3 className="text-gray text-xl" />,
      icon5: <BsFillFuelPumpFill className="text-gray text-xl" />,
      check: <AiFillCheckCircle className="text-[#00803e] text-xl" />,
      freeCancel: "Free Cancellation",
      priceGuarante: "Price Guarantee",
      rating: "3.7",
      Excelent: "Good",
      rewiews: "(50 reviews)",
      pkr: "9000",
      gbp: "76.00",
      usd: "45.00",
    },
    {
      img: require("../../assets/corollaRec.png"),
      heading: "Small 5dr Hatchback  ",
      icon1: <BiUser className="text-gray text-xl" />,
      icon2: <SlBag className="text-gray text-xl" />,
      icon3: <BsBagCheck className="text-gray text-xl" />,
      icon4: <TbTopologyStar3 className="text-gray text-xl" />,
      icon5: <BsFillFuelPumpFill className="text-gray text-xl" />,
      check: <AiFillCheckCircle className="text-[#00803e] text-xl" />,
      freeCancel: "Free Cancellation",
      priceGuarante: "Price Guarantee",
      rating: "4.6",
      Excelent: "Good",
      rewiews: "(80 reviews)",
      pkr: "3000",
      gbp: "36.00",
      usd: "35.00",
    },
    {
      img: require("../../assets/aqua.png"),
      heading: "Small 5dr Hatchback",
      icon1: <BiUser className="text-gray text-xl" />,
      icon2: <SlBag className="text-gray text-xl" />,
      icon3: <BsBagCheck className="text-gray text-xl" />,
      icon4: <TbTopologyStar3 className="text-gray text-xl" />,
      icon5: <BsFillFuelPumpFill className="text-gray text-xl" />,
      check: <AiFillCheckCircle className="text-[#00803e] text-xl" />,
      freeCancel: "Free Cancellation",
      priceGuarante: "Price Guarantee",
      rating: "4.7",
      Excelent: "Excellent",
      rewiews: "(180 reviews)",
      pkr: "5000",
      gbp: "20.00",
      usd: "25.00",
    },
    {
      img: require("../../assets/brv.png"),
      heading: "Medium 5dr Hatchback ",
      icon1: <BiUser className="text-gray text-xl" />,
      icon2: <SlBag className="text-gray text-xl" />,
      icon3: <BsBagCheck className="text-gray text-xl" />,
      icon4: <TbTopologyStar3 className="text-gray text-xl" />,
      icon5: <BsFillFuelPumpFill className="text-gray text-xl" />,
      check: <AiFillCheckCircle className="text-[#00803e] text-xl" />,
      freeCancel: "Free Cancellation",
      priceGuarante: "Price Guarantee",
      rating: "3.7",
      Excelent: "Good",
      rewiews: "(50 reviews)",
      pkr: "9000",
      gbp: "76.00",
      usd: "45.00",
    },
    {
      img: require("../../assets/corollaRec.png"),
      heading: "Small 5dr Hatchback  ",
      icon1: <BiUser className="text-gray text-xl" />,
      icon2: <SlBag className="text-gray text-xl" />,
      icon3: <BsBagCheck className="text-gray text-xl" />,
      icon4: <TbTopologyStar3 className="text-gray text-xl" />,
      icon5: <BsFillFuelPumpFill className="text-gray text-xl" />,
      check: <AiFillCheckCircle className="text-[#00803e] text-xl" />,
      freeCancel: "Free Cancellation",
      priceGuarante: "Price Guarantee",
      rating: "4.6",
      Excelent: "Good",
      rewiews: "(80 reviews)",
      pkr: "3000",
      gbp: "36.00",
      usd: "35.00",
    },
  ];
  return (
    <view>
      <div className="px-3">
        <Row className="py-4 align-items-center">
          <Col lg="8">
            <div className="p-2 flex flex-col md:flex-row md:flex gap-4 items-center">
              <h2 className="text-4xl colorGreen font-bold">User reviews</h2>
              <h2 className="text-2xl font-medium" style={{ color: "gray" }}>
                320 Car Found
              </h2>
            </div>
          </Col>
          <Col lg="4">
            <div className="p-2">
              <div className="d-flex gap-6 float-none justify-center md:justify-end md:float-right">
                <div className="d-flex gap-3 align-items-center">
                  <h2 className="text-xl text-black font-semibold">Sort By:</h2>
                  <select name="size" id="size" className="p-2 bg-white">
                    <option value="0" selected="">
                      Popular
                    </option>
                    {/* <option value="9">6 Seater</option>
                    <option value="8">2 Seater</option>
                    <option value="7">10 Seater</option> */}
                  </select>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pb-4">
          {Data.map((i, index) => (
            <Col lg="4">
              <div className="text-center bg-white border-black rounded-md mt-2 p-2">
                <div className="rounded-md webkit py-3">
                  <Image className="w-2/3" src={i.img} />
                </div>

                <div className="p-2 webkit justify-center" key={index}>
                  <h2 className="font-semibold text-2xl text-black py-2">
                    {i.heading}
                  </h2>
                  <Row className="py-2">
                    <Col>
                      <div className="rounded-md">{i.icon1}</div>
                    </Col>
                    <Col>
                      <div className="rounded-md">{i.icon2}</div>
                    </Col>
                    <Col>
                      <div className="rounded-md">{i.icon3}</div>
                    </Col>
                    <Col>
                      <div className="rounded-md">{i.icon4}</div>
                    </Col>
                    <Col>
                      <div className="rounded-md">{i.icon5}</div>
                    </Col>
                  </Row>
                  <div className="flex flex-col py-2">
                    <div className="flex gap-2 items-center">
                      {i.check}
                      <h2 className="text-lg">{i.freeCancel}</h2>
                    </div>
                    <div className="flex gap-2 items-center">
                      {i.check}
                      <h2 className="text-lg text-gray">{i.priceGuarante}</h2>
                    </div>
                    <div className="flex gap-2 items-center mt-3">
                      <span className="text-[#fff] text-sm p-2 bg-[#00803e] rounded-md">
                        {i.rating}
                      </span>
                      <h2 className="text-lg text-black font-medium">
                        {i.Excelent}
                        <span className="text-sm text-[#575757]">
                          {i.rewiews}
                        </span>
                      </h2>
                    </div>
                  </div>

                  <hr className="mt-3 border-t-2 border-gray" />
                  <Row className="justify-center rrr py-2">
                    <Col>
                      <div>
                        <h2 className="text-lg text-black">
                          PKR
                          <span className="colorGreen font-extrabold text-2xl">
                            {i.pkr}
                          </span>
                        </h2>
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <h2 className="text-lg text-black">
                          GBP{" "}
                          <span className="colorGreen font-extrabold text-2xl">
                            {i.gbp}
                          </span>
                        </h2>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <h2 className="text-lg text-black">
                          USD{" "}
                          <span className="colorGreen font-extrabold text-2xl">
                            {i.usd}
                          </span>
                        </h2>
                      </div>
                    </Col>
                  </Row>
                  <hr className="border-t-2 border-gray" />
                </div>

                <hr className="hr" />
                <Row className="p-2 justify-center align-items-center">
                  <Col className="text-right">
                    <button className="bg-white btn hover:bottom-2 border-black font-semibold py-2 px-3">
                      More Detail
                    </button>
                  </Col>
                  <Col className="text-right">
                    <button className="bg-black btn border-black text-white font-semibold py-2 px-3 hover:bg-none hover:text-black">
                      Book Now
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </view>
  );
};

export default UserReview;
