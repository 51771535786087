import React, { useContext } from 'react'
import { BannerSection } from './components/BannerSection'
import { OnewayAndReturn } from './components/OnewayAndReturn'
import { SawariBenifits } from './components/SawariBenifits'
import { RecommendCars } from './components/RecommendCars'
import loginContext from '../../Constant/LoginContext/loginContext'

export const MainAS = () => {

  const userContext = useContext(loginContext)
  let {userData,setUserData} = userContext

  const handleCheckUser = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    if (data && Object.keys(data).length > 0) {
      setUserData(data);
    }
  };

  React.useEffect(() => {
    handleCheckUser();
  }, []);

  
  return (
    <view  style={{ background: "#EDEEF0" }}>
    <BannerSection />
    <OnewayAndReturn />
    {/* <SawariBenifits /> */}
    <RecommendCars />
    </view>
  )
}
