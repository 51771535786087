import React, { useContext, useState, useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y } from "swiper";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import "swiper/css";
import "swiper/css/navigation";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Stack,
  Box,
  SimpleGrid,
  List,
  ListItem,
  ListIcon,
  useStatStyles,
} from "@chakra-ui/react";
import { CheckIcon, EmailIcon } from "@chakra-ui/icons";
import { BsEnvelope, BsMailbox } from "react-icons/bs";
import { AiFillLock, AiOutlineUser } from "react-icons/ai";
// import "swiper/css/pagination";
// SwiperCore.use([Autoplay, Pagination, Navigation]);
import { MdCheckCircle, MdSettings, MdOutlineSell } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUri from "../../Constant/BaseUri";
import loginContext from "../../Constant/LoginContext/loginContext";
import { RingLoader } from "react-spinners";
export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");

  const getUserData = async () => {
    let data = await localStorage.getItem("userData");

    data = JSON.parse(data);

    setUserData(data);
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  function objectToFormData(obj) {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
      }
    }

    return formData;
  }

  const handleUpdatePassword = async () => {
    if (!email) {
      alert("Email is missing");
      return;
    }

    const strongRegex = new RegExp(
      "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$"
    );

    let flagCheck = strongRegex.test(email);

    if (!flagCheck) {
      alert("Invalid Email");
      return;
    }

    let loginData = {
      email: email,
    };
    setLoader(true);
    const formData = await objectToFormData(loginData);

    axios
      .post(`${BaseUri}reset_password.php`, formData)
      .then(async (res) => {
        let { data } = res;
        setLoader(false);

        if (data.error) {
          alert(data.error);
          return;
        }

        if (data?.message) {
          alert(data.message);
          navigate("/Login");
        }
      })
      .catch((error) => {
        setLoader(false);
        // alert("Internal Server Error");
      });
  };

  return (
    <Container fluid className="py-5">
      <Container>
        <Row className="align-items-center">
          <Col lg="6">
            <div className="webkit p-2">
              <div>
                <Image src={require("../assets/LoginSignup.png")} />
              </div>
            </div>
          </Col>
          <Col
            lg="6"
            className="shadow-lg rounded-2xl shadow-gray-50 py-14 px-3 mt-4"
          >
            <div className="webkit">
              <div className="border-b-black border-b-4 pb-1 inline-block">
                <h2 className="colorGreen font-semibold text-4xl">
                  Forgot Password
                </h2>
              </div>
            </div>
            <div className="d-flex flex-col gap-3 py-6">
              <div>
                <fieldset className="py-4">
                  <Stack spacing={4}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <AiFillLock size={20} className="text-gray-400" />
                      </InputLeftElement>
                      <Input
                        className="py-2"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Enter Email..."
                      />
                    </InputGroup>
                  </Stack>
                </fieldset>
              </div>
              <div className="webkit">
                <button
                  onClick={handleUpdatePassword}
                  className="btn btn--form py-2 font-semibold  w-full"
                  type="submit"
                >
                  {loader ? (
                    <RingLoader color="black" size={30} loading={loader} />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <Row className="align-items-center mt-4">
          <Col lg="6">
            <div className="p-2">
              <SimpleGrid>
                <h2 className="colorGreen font-extrabold text-4xl">
                  Sawari Advantages
                </h2>
                <List py={3}>
                  <ListItem className="text-gray-500 text-xl">
                    <ListIcon as={MdCheckCircle} className="text-black" />
                    Booking car services provide a convenient mode of
                    transportation.
                  </ListItem>
                  <ListItem className="text-gray-500 text-xl">
                    <ListIcon as={MdCheckCircle} className="text-black" />
                    Car services save time by reducing the need to navigate
                    traffic,find parking, or wait for public transportation.
                  </ListItem>
                  <ListItem className="text-gray-500 text-xl">
                    <ListIcon as={MdCheckCircle} className="text-black" />
                    Car services operate around the clock, providing
                    transportation services even during late-night or
                    early-morning hours.
                  </ListItem>

                  <ListItem className="text-gray-500 text-xl">
                    <ListIcon as={MdCheckCircle} className="text-black" />
                    Car services typically employ professional drivers who are
                    trained and experienced in providing safe and reliable
                    transportation.
                  </ListItem>
                  <ListItem className="text-gray-500 text-xl">
                    <ListIcon as={MdCheckCircle} className="text-black" />
                    Car services prioritize passenger safety. Vehicles undergo
                    regular inspections, and drivers often go through background
                    checks.
                  </ListItem>
                </List>
              </SimpleGrid>
            </div>
          </Col>
          <Col lg="6"></Col>
        </Row>
      </Container> */}
    </Container>
  );
};
